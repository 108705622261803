
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import RailCommertialConfigurationItem from "./RailCommertialConfigurationItem";
const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function RailCommertialConfiguration({ action, originCountryInputList, countries, cabinClassList,
    handleOriginCountryInputChange, fareTypeList, handleRemoveOriginCountryRow, handleAddOriginCountryRowClick,
    productType, countryAndStationData
}) {    

    const classes = useStyles();
    return (<>
        <div className="vendor-view-edit-drawer-if-container">
            <div className="vendor-view-edit-drawer-if-line"></div>
            <div className="text-wrap-cls">Commertial Configuration</div>
            <div className="vendor-view-edit-drawer-if-line"></div>
        </div>
        <div className="vendor-view-edit-drawer-input-container">
            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Origin Country
            </div>

            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                Origin City
            </div>

            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                Destination Country{" "}
            </div>
            {
                productType === 'Rail' &&
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                    Destination City
                </div>
            }

            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                {" "}
                {productType === 'Flight' ? ' Issuance Fees' : 'Service Fees'}
            </div>
            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                Service Currency
            </div>
            {action !== "view" && (
                <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1 vendor-multifield-label">
                    {" "}
                    Action
                </div>
            )}
        </div>
        <>
            {originCountryInputList.map((x, i) => {
                // alert(x)                
                return (
                    <Fragment key={i}>
                        {
                            //action !== "view" && (
                            <>
                                <RailCommertialConfigurationItem i={i} action={action} originCountryInputList={originCountryInputList} countries={countries}
                                    cabinClassList={cabinClassList} handleOriginCountryInputChange={handleOriginCountryInputChange} fareTypeList={fareTypeList}
                                    countryAndStationData={countryAndStationData} handleRemoveOriginCountryRow={handleRemoveOriginCountryRow}
                                    handleAddOriginCountryRowClick={handleAddOriginCountryRowClick} productType={productType}
                                />
                            </>
                            //)
                        }
                    </Fragment>
                );
            })}
        </>


    </>);
}