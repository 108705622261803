import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Currencies from "../../../../data/Currencies";
import { useEffect, useState } from "react";

const RailCommertialConfigurationItem = ({ i, action, originCountryInputList, countries, cabinClassList,
    handleOriginCountryInputChange, fareTypeList, handleRemoveOriginCountryRow, handleAddOriginCountryRowClick,
    productType, countryAndStationData
}) => {    

    const [originCityOptions, setOriginCityOptions] = useState([]);
    const [destinationCityOptions, setDestinationCityOptions] = useState([]);

    useEffect(() => {
        if (countryAndStationData) {
            const origincityOptionsData = countryAndStationData.find((v) => v.country === originCountryInputList[i].originCountry)?.destinations || [];
            setOriginCityOptions([...origincityOptionsData]);
            const destinationcityOptionsData = countryAndStationData.find((v) => v.country === originCountryInputList[i].destinationCountry)?.destinations || [];
            setDestinationCityOptions([...destinationcityOptionsData]);
        }
    }, [countryAndStationData, originCountryInputList]);    

    return (
        <div className="vendor-view-edit-drawer-input-container">
            {action !== "view" && (
                <>
                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <Autocomplete
                            id={`rail_originCountry_${i}`}
                            name={`rail_originCountry_${i}`}
                            value={
                                countryAndStationData.find(
                                    (v) =>
                                        v.country ===
                                        originCountryInputList[i].originCountry
                                ) || null
                            }
                            onChange={(event, value) => {

                                originCountryInputList[i].originCountry =
                                    value?.country ? value?.country : "";
                                if (value) {
                                    let temp = countryAndStationData.find(
                                        (v) =>
                                            v.country ===
                                            originCountryInputList[i].originCountry
                                    ).destinations
                                    setOriginCityOptions(temp);
                                } else {
                                    setOriginCityOptions([])
                                }
                                handleOriginCountryInputChange(event, i);
                                //originCountryInputList[i].originCountry = Object.keys(value).map(function(k){return value[k].code}).join(',');
                            }}
                            options={countryAndStationData || null}
                            autoHighlight
                            getOptionLabel={(option) =>
                                option.country === "All"
                                    ? "All"
                                    : option.country
                            }
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label="Origin Country"
                                    value={
                                        originCountryInputList[i].originCountry
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",                                      
                                    }}
                                    required
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}

                                />
                            )}
                            style={{ maxWidth: "34vw" }}
                        />
                    </div>

                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <Autocomplete
                            id={`originStation_${i}`}
                            name={`originStation_${i}`}
                            value={
                                originCityOptions.length > 0 && originCityOptions?.find(
                                    (v) =>
                                        v.stationCode ===
                                        originCountryInputList[i].originStation
                                ) || null
                            }
                            onChange={(event, value) => {

                                originCountryInputList[i].originStation =
                                    value.stationCode ? value.stationCode : "";
                                handleOriginCountryInputChange(event, i);
                                //originCountryInputList[i].originCountry = Object.keys(value).map(function(k){return value[k].code}).join(',');
                            }}
                            options={originCityOptions ?? []}
                            autoHighlight
                            getOptionLabel={(option) =>
                                option?.stationName + " (" + option?.stationCode + ")"
                            }
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label="Origin City"
                                    value={
                                        originCountryInputList[i]?.originStation
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",                                       
                                    }}
                                    required
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}                                    
                                />
                            )}
                            style={{ maxWidth: "34vw" }}
                        />
                    </div>


                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <Autocomplete
                            id={`destinationCountry-${i}`}
                            name={`destinationCountry-${i}`}
                            value={
                                countryAndStationData.find(
                                    (v) =>
                                        v.country ===
                                        originCountryInputList[i].destinationCountry
                                ) || null
                            }
                            onChange={(event, value) => {

                                originCountryInputList[i].destinationCountry =
                                    value?.country ? value?.country : "";
                                if (value) {
                                    let temp = countryAndStationData.find(
                                        (v) =>
                                            v.country ===
                                            originCountryInputList[i].destinationCountry
                                    ).destinations
                                    setDestinationCityOptions(temp)
                                } else {
                                    setDestinationCityOptions([])
                                }
                                handleOriginCountryInputChange(event, i);
                                //originCountryInputList[i].destinationCountry = Object.keys(value).map(function(k){return value[k].code}).join(',');
                            }}
                            options={countryAndStationData || null}
                            autoHighlight
                            getOptionLabel={(option) =>
                                option.country === "All"
                                    ? "All"
                                    : option.country
                            }
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label="Destination Country"
                                    value={
                                        originCountryInputList[i].destinationCountry
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",                                       
                                    }}
                                    required
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}      
                                />
                            )}
                            style={{ maxWidth: "34vw" }}
                        />
                    </div>

                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <Autocomplete
                            id={`destinationStation_${i}`}
                            name={`destinationStation_${i}`}
                            value={
                                destinationCityOptions.length > 0 && destinationCityOptions?.find(
                                    (v) =>
                                        v.stationCode ===
                                        originCountryInputList[i].destinationStation
                                ) || null
                            }
                            onChange={(event, value) => {
                                originCountryInputList[i].destinationStation =
                                    value.stationCode ? value.stationCode : "";
                                handleOriginCountryInputChange(event, i);
                            }}
                            options={destinationCityOptions}
                            autoHighlight
                            getOptionLabel={(option) =>
                                option?.stationName + " (" + option?.stationCode + ")"
                            }
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label="Destination City"
                                    value={
                                        originCountryInputList[i]?.destinationStation
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",                                        
                                    }}
                                    required
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}   
                                />
                            )}
                            style={{ maxWidth: "34vw" }}
                        />
                    </div>


                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <TextValidator
                            id="serviceFees"
                            name="serviceFees"
                            variant="outlined"
                            label="Service Fees"
                            size="small"
                            color="secondary"
                            value={originCountryInputList[i].serviceFees ?? ''}
                            onChange={(e) =>
                                handleOriginCountryInputChange(e, i)
                            }
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            required
                            validators={["required"]}
                            errorMessages={["This Field is Required."]}   
                        />
                    </div>
                    <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                        <Autocomplete
                            id="serviceCurrency"
                            name="serviceCurrency"
                            value={
                                Currencies.find(
                                    (v) =>
                                        v.code ===
                                        originCountryInputList[i].serviceCurrency
                                ) || null
                            }
                            onChange={(event, value) => {

                                originCountryInputList[i].serviceCurrency =
                                    value?.code ? value?.code : "";
                                handleOriginCountryInputChange(event, i);
                            }}
                            options={Currencies || null}
                            autoHighlight
                            getOptionLabel={(option) =>
                                option.country === "All"
                                    ? "All"
                                    : option.name + " (" + option.code + ")"
                            }
                            renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label="Service Currency"
                                    value={
                                        originCountryInputList[i].serviceCurrency
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",                                        
                                    }}
                                    required
                                    validators={["required"]}
                                    errorMessages={["This Field is Required."]}   
                                />
                            )}
                            style={{ maxWidth: "34vw" }}
                        />
                    </div>
                    <div className="search-view-edit-drawer-input config-input-flex-1">
                        {originCountryInputList.length !== 1 && (
                            <RemoveCircleOutlineIcon
                                style={{
                                    fontSize: "31px",
                                    color: "red",
                                    cursor: "pointer",
                                    paddingRight: "5px",
                                }}
                                onClick={() =>
                                    handleRemoveOriginCountryRow(i)
                                }
                            />
                        )}
                        {originCountryInputList.length - 1 === i && (
                            <AddCircleOutlineIcon
                                style={{
                                    fontSize: "31px",
                                    color: "green",
                                    cursor: "pointer",
                                    paddingRight: "5px",
                                }}
                                onClick={handleAddOriginCountryRowClick}
                            />
                        )}
                    </div>
                </>
            )}
            {action === "view" && (
                <>
                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].originCountry}
                        </span>
                    </div>
                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].originStation}
                        </span>
                    </div>
                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].destinationCountry}
                        </span>
                    </div>
                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].destinationStation}
                        </span>
                    </div>

                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].serviceFees}
                        </span>
                    </div>
                    <div className="view-configuration-drawer config-input-flex-1 px-3">
                        <span className="view-configuration-drawer-description">
                            {originCountryInputList[i].serviceCurrency}
                        </span>
                    </div>
                </>
            )}
        </div>
    )
}

export default RailCommertialConfigurationItem;