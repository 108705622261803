import onlyUnique from "./onlyUnique";

export default function getConnectingAirports(segs) {
  const airports = [];
  segs.forEach((seg) => {
    airports.push(seg.origin);
    airports.push(seg.destination);
  });
  airports.shift();
  airports.pop();
  let unique = airports.filter(onlyUnique);
  return unique;
}
