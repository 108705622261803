import React, { useState, Component } from "react";
import "./AddSearchCategoryConfigurationCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";
import { Autocomplete } from "@material-ui/lab";
import { Button, TextField } from "@material-ui/core";


export default function AddSearchCategoryConfigurationCard(
  { filterSearchCategory, supplierName, setSupplierName, pcc, setPCC, settravelType, setcabinClass, isActive, setIsActive, loadCategoryConfiguration, statusList, ClearFilters }) {
 return (
    <div className="add-search-card-container">
      <div className="add-search-card-title">Search Category Configuration</div>
      <div className="create-search-contents-container">
        <div className="create-search-contents">
          <div className="add-search-if-items-container">
            {/* <div className="add-search-if-item margin-right-16">
              <div className="add-search-if-container">IF</div>
            </div> */}
            <div className="add-search-if-item flex-1-input margin-right-16 ">
              <TextField
                variant="outlined"
                label="Supplier Name"
                size="small"
                color="secondary"
                className="border"
                value={supplierName}
                onChange={(event) => setSupplierName(event.target.value)}
                style={{ width: '200px' }}
              />
            </div>
            <div className="add-search-if-item flex-1-input margin-right-16">
              <TextField
                variant="outlined"
                label="PCC"
                size="small"
                color="secondary"
                className="border"
                value={pcc}
                onChange={(event) => setPCC(event.target.value)}
                style={{ width: '200px' }}
              />
            </div>
            <div className="add-search-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="status select"
                value={statusList.find((v) => v === isActive) || null}
                onChange={(event, newValue) => {
                  setIsActive(newValue ? newValue : "");
                }}
                options={statusList}
               autoHighlight
                getOptionLabel={(option) => option}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    value={isActive}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="border"
                    style={{ width: '200px' }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="add-supplier-if-item flex-1-input margin-right-16">
          {/* <div className="add-search-button" onClick={() => loadCategoryConfiguration(true)} >Search Category Configuration</div> */}
     <Button className="srn-btn" onClick={() => filterSearchCategory()}>Search</Button>
          <Button className="srn-btn ms-3" onClick={() => ClearFilters()}>Clear</Button>
        </div>
        {/* <div className="create-search-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
