export default function getFareTypeFilter(flights) {
  let isFilterAvailable = false;
  flights.forEach((element) => {
    if (element.fareGroups[0].refundable) {
      isFilterAvailable = true;
    }
  });
  if (isFilterAvailable) {
    return [
      {
        title: "Refundable",
        checked: false,
      },
    ];
  } else {
    return [];
  }
}
