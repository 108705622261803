import React, { useState, Component } from "react";
import "./AddBookingSwitchesCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";

export default function AddBookingSwitchesCard({setbookingSwitchName, setsearchPCC, setsupplierName, setsupplierNameBooking, setbookingPCC, loadBookingSwitches}) {

  return (
    <div className="add-bookingswitches-card-container">
      <div className="add-bookingswitches-card-title">Search Config</div>
      <div className="create-bookingswitches-contents-container">
        <div className="create-bookingswitches-contents">
          <div className="add-bookingswitches-if-items-container">
            {/* <div className="add-bookingswitches-if-item margin-right-16">
              <div className="add-bookingswitches-if-container">IF</div>
            </div> */}
            <div className="add-bookingswitches-if-item flex-1-input  margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Config Name"
                  size="small"
                  color="secondary"
                  onChange={(event) => setbookingSwitchName(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-bookingswitches-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Search PCC"
                  size="small"
                  color="secondary"
                  onChange={(event) => setsearchPCC(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-bookingswitches-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Search Supplier Name"
                  size="small"
                  color="secondary"
                  onChange={(event) => setsupplierName(event.target.value)}                  
                />
              </ValidatorForm>
            </div>
            <div className="add-bookingswitches-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Booking PCC"
                  size="small"
                  color="secondary"
                  onChange={(event) => setbookingPCC(event.target.value)}                  
                />
              </ValidatorForm>
            </div>
            <div className="add-bookingswitches-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Booking Supplier Name"
                  size="small"
                  color="secondary"
                  onChange={(event) => setsupplierNameBooking(event.target.value)}                  
                />
              </ValidatorForm>
            </div>
          </div>
        </div>
        <div className="add-bookingswitches-button-container">
          <div className="add-bookingswitches-button" onClick={() => loadBookingSwitches()} >Search Config</div>
        </div>
        {/* <div className="create-bookingswitches-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
