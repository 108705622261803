export default function getDepartArrival() {
  return [
    {
      time: "05-12",
      checked: false,
    },
    {
      time: "12-18",
      checked: false,
    },
    {
      time: "18-23",
      checked: false,
    },
    {
      time: "23-05",
      checked: false,
    },
  ];
}
