import React, { useState, useEffect } from "react";
import "./ClientTable.css";
import ConfigurationMock from "../../../data/mock/ConfigurationMock";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import countries from "../../../data/Country";
import { Button } from "@material-ui/core";

import { Row, Col, Nav, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}


export default function ClientTable({
  openDrawer,
  setAction,
  clientProfiles,
  setPage,
  page,
  getClients,
  showLoader,
  setClientClick,
  l1CacheGroups
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);



  const handleChangePage = (event, newPage, page, rowsPerPage) => {
    setPage(newPage + 1);


  };


  useEffect(() => {

    if (clientProfiles) {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = page * rowsPerPage;
      setTableData(clientProfiles.slice(startIndex, endIndex));
    }



  }, [clientProfiles, page])


  // useEffect(() => {
  //   getClients();
  // }, [])

  const [searchedVal, setSearchedVal] = useState("");

  return (
    <div className="client-listing">
      {clientProfiles ? (
        clientProfiles.length === 0 ? (
          <Alert severity="error">
            No record found.
          </Alert>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Client Type</TableCell>
                    <TableCell>Client Category</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>L1 Cache Group</TableCell>
                    <TableCell width={80}>Contact</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Is Live</TableCell>
                    <TableCell width={270}>Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {((tableData !== 'undefined') ? tableData : clientProfiles)?.map((row) => (
                    <StyledTableRow key={JSON.stringify(row)}>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.clientpforile.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.clientpforile.clientType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.clientpforile.clientCategory}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          setClientClick(row.clientpforile.id)
                          setAction("view");
                          openDrawer(row);
                        }}
                      >
                        <div className="table-cell-title pointer">
                          {row.clientpforile.clientName}
                        </div>
                        <div className="table-cell-description">
                          {row.clientpforile.paymentType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {l1CacheGroups.find(
                            (v) => v.id === row.clientpforile.l1CacheGroupId
                          )?.l1GroupName || "None"}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.clientpforile.email}
                        </div>
                        <div className="table-cell-description">
                          {row.clientpforile.phoneNumber}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {row.clientpforile.currencyCode}
                        </div>
                        <div className="table-cell-description">
                          TimeZone: {row.clientpforile.timeZone}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {countries.find(
                            (v) => v.code === row.clientpforile.countryCode
                          )?.label +
                            " (" +
                            row.clientpforile.countryCode +
                            ")" || null}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* <div className="table-cell-title">
                    {row.provider.supplier}
                  </div> */}
                        <div className="table-cell-description-chip">
                          {row.clientpforile.isActive ? (
                            <Chip
                              label="Active"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="In Active"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-description-chip">
                          {row.clientpforile.isLiveTicket ? (
                            <Chip
                              label="Live"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="Pending"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell >
                        {/* <div className="table-cell-des"> */}
                        <div className="table-cell-description">Created by: <span style={{ color: "black" }}>{row.createdBy}</span></div>
                        <div className="table-cell-description">
                          Created on:{" "}
                          {row.clientpforile.created.replace("T", " , ")}

                        </div>
                        <div className="table-cell-description">Last Modified by: <span style={{ color: "black" }}>{row.modefiedBy}</span></div>
                        <div className="table-cell-description">
                          Last Modified on:{" "}
                          {row.clientpforile.modified.replace("T", " , ")}

                        </div>

                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"

              count={clientProfiles?.length}
              rowsPerPage="25"
              page={page - 1}
              onChangePage={(event, newPage) => handleChangePage(event, newPage, page, rowsPerPage)}
              ActionsComponent={TablePaginationActions}
            />
          </React.Fragment>
        )) : ""}

      {showLoader ? <Row>
        <div className="col-12 text-center py-5 loader-cls">
          <Box className="loader-box-content">
            <div className="flt-main-heading py-3"><h3>Please Wait...</h3> We're fetching Clients List...</div>
            <CircularProgress />
          </Box>
        </div>
      </Row> : ""}
    </div>
  );
}
