import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import clsx from "clsx";
import "./FilterComponents.css";
import findAirlinebyCode from "../../../functions/findAirlineByCode";

function Airlines({ search, setSearch, toggleDrawer, currencyCode }) {
  const classes = useCheckboxStyles();

  const handleChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.filters.airlines.find(
      (item) => item.airline === event.target.name
    ).checked = event.target.checked;
    toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className="number-of-stops-container mt-12 mb-16">
      <FormControl component="fieldset">
        <FormGroup>
          {search.filters.airlines &&
            search.filters.airlines.length > 0 &&
            search.filters.airlines.map((airline, index) => (
                airline.airline !== undefined &&
                <div className="stops-item-container" key={index}>
                  <div className="stop-item-title">
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.root}
                          color="default"
                          checkedIcon={
                            <span
                              className={clsx(classes.icon, classes.checkedIcon)}
                            />
                          }
                          icon={<span className={classes.icon} />}
                          checked={airline.checked}
                          onChange={handleChange}
                          name={airline.airline}
                        />
                      }
                      label={findAirlinebyCode(airline.airline) + " - " + airline.airline}
                    />
                  </div>
                  <div className="stop-item-price">{currencyCode}{" "} {airline.startingFrom.toFixed(2)}</div>
                </div>
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default Airlines;
