export default function getLayoverTime(firstSeg, secondSeg) {
  let arrivalTime = firstSeg.arrivalOn;
  let departureTime = secondSeg.departureOn;
  return getHumanizedDiff(
    new Date(departureTime).getTime() - new Date(arrivalTime).getTime()
  );
}
function getHumanizedDiff(diff) {
  let hours = Math.floor(Math.floor(Math.abs(diff) / 60000) / 60);
  const minutes = Math.floor(Math.floor(Math.abs(diff) / 60000) % 60);
  const days = Math.floor(
    Math.floor(Math.floor(Math.abs(diff) / 60000) / 60) / 24
  );
  if(hours > 24){
    hours = hours - 24;
  }
  return (
    (days !== 0 ? days + "d" : "") +
    (hours !== 0 ? String(hours).padStart(2,'0') + " h " : "") +
    (String(minutes).padStart(2,'0') + " m ")

    // (days !== 0 ? days + `${days === 1 ? " day " : " days "}` : "") +
    // (hours !== 0 ? hours + `${hours === 1 ? " hour " : " hours "}` : "") +
    // (minutes !== 0
    //   ? minutes + `${minutes === 1 ? " minute " : " minutes "}`
    //   : "")
  );
}
