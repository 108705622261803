import React, { useRef } from "react";
import "./AddReceiptCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function AddReceiptCard({name,setName}) {

  const ReceiptForm = useRef();

  return (
    <div className="add-receipt-card-container">
      <div className="add-receipt-card-title">Receipt</div>
      <div className="create-receipt-contents-container">
        <div className="create-receipt-contents">
          <div className="add-receipt-if-items-container">
            {/* <div className="add-receipt-if-item margin-right-16">
              <div className="add-receipt-if-container">IF</div>
            </div> */}
            <div className="add-receipt-if-item flex-1-input  margin-right-16">
              {/* <ValidatorForm onError={(errors) => console.log(errors)} ref={ReceiptForm} onSubmit={ () => loadCategories() } >
                <TextValidator
                  variant="outlined"
                  label="Group Name"
                  size="small"
                  color="secondary"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["required"]}
                  required
                />
              </ValidatorForm> */}
            </div>
          </div>
        </div>
        <div className="add-receipt-button-container">
          <div className="add-receipt-button" onClick={() => ReceiptForm.current && ReceiptForm.current.submit()} >Receipt</div>
        </div>
        {/* <div className="create-receipt-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
