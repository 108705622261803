const SortData = [
  {
    title: "Cheapest",
    value: 0,
    checked: false,
    name: "price",
  },
  {
    title: "Shortest",
    value: 0,
    checked: false,
    name: "totalDuration",
  },
  {
    title: "Recommended",
    value: 0,
    checked: false,
    name: "recommended",
  },
];
export default SortData;
