
import { loadCreditCardDetails } from '../services/apiService';

export default async function loadFop() {
    try {
        let result = await loadCreditCardDetails();
        let cardArray=[];
        cardArray.push({
          id: "Cash",
          des: "Cash",
        })
        cardArray.push({
          id: "Cheque",
          des: "Cheque",
        })
        cardArray.push({
          id: "Passenger Card",
          des: "Passenger Card",
        })
        if (result.length) {
          result.map((data) => {
            let string = data.cardinfo.cardNumber.toString();
            data.cardinfo.cardNumber=string.substr(string.length - 4)
            data.cardinfo.code =
              data.cardinfo.cardType == "American Express"
                ? "AX"
                : data.cardinfo.cardType == "Visa"
                  ? "VI"
                  : data.cardinfo.cardType == "MasterCard"
                    ? "CA"
                    : data.cardinfo.cardType == "Diners Club"
                      ? "DC"
                      : data.cardinfo.cardType == "Discover"
                        ? "DS"
                        : data.cardinfo.cardType == "JCB"
                          ? "JC"
                          : data.cardinfo.cardType == "Maestro"
                            ? "TO"
                            : data.cardinfo.cardType == "UATP"
                              ? "TP"
                              : "";
              cardArray.push({id:data.cardinfo.id,des:`${data.cardinfo.cardType} ${data.cardinfo.cardNumber}`})
          });
          debugger
          return cardArray
  
        } else {
         
          
        }
      } catch (error) { }
}
