import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import "./TripTypeRadioButtons.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import tripType from "../../data/TripType";
import moment from "moment";
function TripTypeRadioButtons({ search, setSearch }) {
  const [value, setValue] = useState(search.type);

  const handleChange = (event) => {
    setTripType(event.target.value);
    setValue(event.target.value);
  };

  const setTripType = (value) => {
    const existingSearch = { ...search };
    existingSearch.type = value;
    existingSearch.destinations = [];
    if (existingSearch?.type === "Multidestination") {
      existingSearch?.destinations.push({
        id: existingSearch?.destinations.length + 1,
        from: existingSearch?.to,
        to: "",
        depart: getNextDay(existingSearch?.depart),
        // depart: "",
      });
    }

    if (existingSearch.type === "RoundTrip") {
      // existingSearch.depart = "";
      existingSearch.return = existingSearch?.return ? (existingSearch?.return) : moment(existingSearch?.depart)?.add(1, "d");
    }
    setSearch(existingSearch);
  };

  const getNextDay = (day) => {
    const nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 1);
    return nextDay;
  };

  useEffect(() => {
    if (search.type !== value) {
      setValue(search.type);
    }
  }, [search]);

  // Delete "All" From Trip Type
  let toRemove = "All";
  let index = tripType.indexOf(toRemove);
  if (index > -1) {
    tripType.splice(0, 1);
  }

  return (
    <div className="trip-type-select-width">
      <FormControl component="fieldset">
        <Select
          value={value}
          onChange={handleChange}
          autoWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {tripType.map((item) => (
            <MenuItem value={item}>
              {item.replace(/([A-Z])/g, " $1").trim()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default TripTypeRadioButtons;
