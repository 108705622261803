import moment from "moment";
import React, { useEffect, useRef } from 'react';
import findAirlinebyCode from "../../functions/findAirlineByCode";
import getDuration from "../../functions/getDuration";
import getAirportByCode from "../../functions/getAirportByCode";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { Grid, Table } from "@mui/joy";
export default function FlightSegmentDetails({ flight, setMaxHeight, bookingDetails }) {
    const tableBodyRef = useRef(null);
    useEffect(() => {
        if (tableBodyRef.current) {
            const columns = tableBodyRef.current.children;
            let height = 0;

            // Find the tallest column
            for (const column of columns) {
                if (column.clientHeight > height) {
                    height = column.clientHeight;
                }
            }
            // Update the maxHeight in the parent component
            setMaxHeight((maxHeight) => height);
        }
    }, [flight]);
    return (
        <>

            {flight.segGroups && flight.segGroups.length > 0 ? (


                flight.segGroups.map((segGroup) => (
                    <div className="flight-itenary">
                        <div className="flight-itenary-header">
                            <div className="flight-itenary-title-container">
                                <div>
                                    Itinerary Details
                                </div>

                            </div>

                        </div>
                        {segGroup.segments && segGroup.segments.length > 0 ? (
                            <>
                                <Table aria-label="basic table" id="seggroupTable">
                                    <thead>
                                        <tr >
                                            <BootstrapTooltip1 title={'Airline/Flight No/ Coach'}>
                                                <th className="flightItineraryColumnHeader">Airline/Flight No/ Coach</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={'Departure Airport / Terminal'}>
                                                <th className="flightItineraryColumnHeader">Departure Airport / Terminal</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={'Departure Date & Time'}>
                                                <th className="flightItineraryColumnHeader">Departure Date & Time</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={'Arrival Airport / Terminal'}>
                                                <th className="flightItineraryColumnHeader">Arrival Airport / Terminal</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={'Arrival Date & Time'}>
                                                <th className="flightItineraryColumnHeader">Arrival Date & Time</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title={'Duration'}>
                                                <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>Duration</th>
                                            </BootstrapTooltip1>
                                            {/*<BootstrapTooltip1 title={''}>
                                              <th style={{textAlign:'center'}}>FBA</th> 
                                            </BootstrapTooltip1>*/}
                                            <BootstrapTooltip1 title={'Airline PNR'}>
                                                <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>Airline PNR</th>
                                            </BootstrapTooltip1>
                                        </tr>
                                    </thead>
                                    <tbody ref={tableBodyRef}>
                                        {
                                            segGroup.segments.map((segment, index) => (
                                                <tr >
                                                    <td className="flightItineraryColumn" key={`${index}`}>{findAirlinebyCode(segment.mrkAirline)}  <br />  <b>{segment.flightNum}</b>  /  {segment.cabinClass}</td>
                                                    <td className="flightItineraryColumn">{getAirportByCode(segment.origin)} <br /> <b>{segment.origin}</b>  / {segment.depTerminal}</td>
                                                    <td className="flightItineraryColumn">{moment(segment.departureOn).format("Do MMM  YYYY")}<br />
                                                        {moment(segment.departureOn).format("hh:mm A")}</td>
                                                    <td className="flightItineraryColumn">{getAirportByCode(segment.destination)}<br /> <b>{segment.destination}</b>  / {segment.arrTerminal}</td>
                                                    <td className="flightItineraryColumn">{moment(segment.arrivalOn).format("Do MMM  YYYY")} <br />
                                                        {moment(segment.arrivalOn).format("hh:mm A")}</td>
                                                    <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{getDuration(segment.duration)}</td>
                                                    {/* <td></td> */}
                                                    <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{segment.pnr ? segment.pnr : "-"}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </Table>
                                {/* {
                                    segGroup.segments.map((segment) => (
                                        <div>
                                            <div className="flight-itenary-details">
                                                <div className="flight-itenary-details-item">
                                                    <div className="flight-itenary-details-item-text">
                                                        {segment.cabinClass}
                                                    </div>
                                                    <div className="flight-itenary-details-item-subtext">
                                                        Class Of Service
                                                    </div>
                                                </div>
                                                <div className="flight-itenary-details-item">
                                                    <div className="flight-itenary-details-item-text">
                                                        {findAirlinebyCode(segment.mrkAirline)}
                                                    </div>
                                                    <div className="flight-itenary-details-item-subtext">
                                                        {segment.mrkAirline}-{segment.flightNum}
                                                    </div>
                                                </div>
                                                <div className="flight-itenary-details-item">
                                                    <div className="flight-itenary-details-item-text">
                                                        {moment(segment.departureOn).format(
                                                            "HH:mm"
                                                        )}
                                                    </div>
                                                    <div className="flight-itenary-details-item-subtext">
                                                        Departure
                                                    </div>
                                                </div>
                                                {bookingDetails.bookingSource !==
                                                    "OfflineBooking" && (
                                                        <div className="flight-itenary-details-item">
                                                            <div className="flight-itenary-details-item-text">
                                                                {getDuration(segment.duration)}
                                                            </div>
                                                            <div className="flight-itenary-details-item-subtext">
                                                                Duration
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className="flight-itenary-details-item">
                                                    <div className="flight-itenary-details-item-text">
                                                        {moment(segment.arrivalOn).format(
                                                            "HH:mm"
                                                        )}
                                                    </div>
                                                    <div className="flight-itenary-details-item-subtext">
                                                        Arrival
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight-confirmation-details">
                                                <div className="flight-confirmation-details-item">
                                                    <div className="flight-confirmation-details-item-text">
                                                        Origin
                                                    </div>
                                                    <div className="flight-confirmation-details-item-subtext">
                                                        {getAirportByCode(segment.origin)} -{" "}
                                                        {segment.origin}
                                                    </div>
                                                </div>
                                                <div className="flight-confirmation-details-item">
                                                    <div className="flight-confirmation-details-item-text">
                                                        Destination
                                                    </div>
                                                    <div className="flight-confirmation-details-item-subtext">
                                                        {getAirportByCode(segment.destination)} -{" "}
                                                        {segment.destination}
                                                    </div>
                                                </div>
                                                <div className="flight-confirmation-details-item">
                                                    <div className="flight-confirmation-details-item-text">
                                                        PNR
                                                    </div>
                                                    <div className="flight-confirmation-details-item-subtext">
                                                        {segment.pnr}
                                                    </div>
                                                </div>
                                                <div className="flight-confirmation-details-item">
                                                    <div className="flight-confirmation-details-item-text">
                                                        Segment Status
                                                    </div>
                                                    <div className="flight-confirmation-details-item-subtext full-opacity">
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                } */}
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                ))
            ) : (
                <div></div>
            )}
        </>
    );
}