import React, { useState, useEffect } from "react";
import "./BankInfo.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import BankInfoTable from "./BankInfoTable/BankInfoTable";
import BankInfoDrawer from "./BankInfoDrawer/BankInfoDrawer";
import AddBankInfoCard from "./AddBankInfoCard/AddBankInfoCard";
import axios from "axios";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddCreditCardModal from "./creditCardComponents/AddCreditCardModal";
import CreditCardsListModal from "./creditCardComponents/CreditCardsList";
import { loadCreditCardDetails } from '../../services/apiService';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BankInfo() {
  const [drawer, setDrawer] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [action, setAction] = useState("new");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [bankInfos, setBankInfos] = useState([]);
  const [name, setName] = useState("");
  const [value, setValue] = React.useState(0);
  // const [showLoader, setShowLoader] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [mode, SetMode] = useState('');
  const [selected, setSelected] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    console.log("creditCards in bankinfo", creditCards)
  }, [creditCards]);

  async function loadData() {
    await getBankInfo();
    await loadCreditCards();
  }
  async function getBankInfo() {
    // let filter = "";

    // if (pccCode !== "") filter = "pccCode==" + pccCode;

    // if (iPcc !== "") {
    //   if (filter === "") filter = "iPcc==" + iPcc;
    //   else filter = filter + "iPcc==" + iPcc;
    // }
    // if (supplierName !== "") {
    //   if (filter === "") filter = "supplierName==" + supplierName;
    //   else filter = filter + "supplierName==" + supplierName;
    // }
    // if (filter !== "") filter = "?filters=" + filter;

    await axios
      .get(
        process.env.REACT_APP_CORE_URL + "api/Configuration/Account/GetBankInfo"
      )
      .then((response) => {
        setBankInfos(response.data);
      })
      .catch((error) => console.log(error));
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
    getBankInfo();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const closeAddModal = () => {
    setPopupOpen(false);
    // reload();
  }
  // used to repload components
  const reload = async () => {
    await loadCreditCards();
  }

  const loadCreditCards = async () => {

    try {
      let result = await loadCreditCardDetails();
      if (result.length) {
        result.map(data => {
          data.cardinfo.code = data.cardinfo.cardType == 'American Express' ? 'AX' : data.cardinfo.cardType == 'Visa' ? 'VI' : data.cardinfo.cardType == 'MasterCard' ? 'CA' : data.cardinfo.cardType == 'Diners Club' ? 'DC' : data.cardinfo.cardType == 'Discover' ? 'DS' : data.cardinfo.cardType == 'JCB' ? 'JC' : data.cardinfo.cardType == 'Maestro' ? 'TO' : data.cardinfo.cardType == 'UATP' ? 'TP' : ''
        })
        setCreditCards(result);
      } else {
        setCreditCards([]);
      }
    } catch (error) {

    }
  }
  const handleSubmit = async (data) => {

    const headers = { "Content-Type": "application/json" }
    let reqObj = {
      "CardHolderName": "",
      "CardNumber": "",
      "CardType": "",
      "CardCode": "",
      "ExpDate": "",
      "Cvc": "",
      "AddressLine1": "",
      "AddressLine2": "",
      "City": "",
      "State": "",
      "Zipcode": "",
      "Country": "",
      "Phone": "",
      "Remarks": '',
      "Email": "",
    };
    reqObj.CardHolderName = data.CardHolderName
    reqObj.CardNumber = data.cardNumber
    reqObj.ExpDate = data.ExpDate
    reqObj.CardType = data.CardType
    reqObj.isActive = data.isActive
    reqObj.CardCode = data.code
    reqObj.Remarks = data.Remarks
    if (mode === "Edit") {
      reqObj.CreatedBy = data.CreatedBy === undefined ? "" : data.CreatedBy
      reqObj.Created = data.Created === undefined ? "" : data.Created
    }

    try {
      if (mode === "Edit") {
        reqObj.id = data.id
        let res = await axios.put(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/UpdateCardInfo/" + data.id, (reqObj), { headers });
        // let res = await axios.put(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/UpdateCardInfo/", + data.id, JSON.stringify(reqObj), { headers });
        if (res) {
          await reload()
        }
      } else {
        let res = await axios.post(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/AddCardInfo", (reqObj), { headers });
        // let res = await axios.post(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/AddCardInfo", JSON.stringify(reqObj), { headers });
        if (res) {
          await reload()
        }
      }

    } catch (error) {

      console.log(error)
      if (mode == "Edit") {
      } else {
      }
    }
  }

  const removedCreditCard = (number) => {
    creditCards.map(item => {
      if (item.id == number) {
        item.isActive = !(item.isActive);
      }
    })
    setCreditCards(creditCards);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Bank Info" {...a11yProps(0)} />
          <Tab label="Credit Cards" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="bankInfo-container">
          <div className="bankInfo-content-container">
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <div className="bankInfo-bankInfo-container">
                  <div className="bankInfo-table-container">
                    <div className="bankInfo-title-add-button-container">
                      <div className="bankInfo-heading">Bank Information</div>
                      <div className="bankInfo-icon-button-container">
                        {/* <SearchIcon
                      fontSize="small"
                      className="bankInfo-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                        <div
                          className="add-bankInfo-button mt-0px"
                          onClick={() => {
                            setAction("new");
                            setDrawer(true);
                          }}
                        >
                          Add Bank
                        </div>
                      </div>
                    </div>
                    <Collapse in={openSearch} timeout="auto" unmountOnExit>
                      <div className="bankInfo-card-container">
                        <AddBankInfoCard
                          name={name}
                          setName={setName}
                        ></AddBankInfoCard>
                      </div>
                    </Collapse>
                    <BankInfoTable
                      openDrawer={openDrawer}
                      setAction={setAction}
                      bankInfos={bankInfos}
                    ></BankInfoTable>
                  </div>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </div>
          <Drawer
            anchor="right"
            open={drawer}
            onClose={toggleDrawer(false)}
            BackdropProps={{ invisible: true }}
            variant="persistent"
            className="drawer-width"
            transitionDuration={{ enter: 500, exit: 500 }}
          >
            <div className="drawer-min-width-40">
              <BankInfoDrawer
                selectedItem={selectedItem}
                toggleDrawer={toggleDrawer}
                action={action}
                setAction={setAction}
                handleSnackbarToggle={handleSnackbarToggle}
              ></BankInfoDrawer>
            </div>
          </Drawer>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackBarClose}
          >
            <Alert onClose={handleSnackBarClose} severity="success">
              {`Setting has been ${action === "new" ? "created" : "updated"}`}
            </Alert>
          </Snackbar>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="bankInfo-container">
          <div className="bankInfo-content-container">
            {/* <Grid container> */}
            {/* <Grid item xs={1}></Grid> */}
            {/* <Grid item xs={10}> */}
            <div className="bankInfo-bankInfo-container">
              <div className="bankInfo-table-container">
                <div className="bankInfo-title-add-button-container">
                  <div className="bankInfo-heading">Credit Card Information</div>
                  <div className="bankInfo-icon-button-container">
                    <div
                      className="add-bankInfo-button mt-0px"
                      onClick={() => {
                        setPopupOpen(true);
                        SetMode('Add');
                      }}
                    >
                      Add Credit Card
                    </div>
                  </div>
                </div>
                <div className="bankInfo-card-container">
                  <div className="row cardslist">
                    <CreditCardsListModal
                      creditCards={creditCards}
                      handleRemove={removedCreditCard}
                      setPopupOpen={setPopupOpen}
                      setSelected={setSelected}
                      SetMode={SetMode}
                    />
                  </div>

                </div>
              </div>
            </div>
            {/* </Grid> */}
            {/* <Grid item xs={1}></Grid> */}
            {/* </Grid> */}
          </div>
          <Drawer
            anchor="right"
            open={drawer}
            onClose={toggleDrawer(false)}
            BackdropProps={{ invisible: true }}
            variant="persistent"
            className="drawer-width"
            transitionDuration={{ enter: 500, exit: 500 }}
          >
            <div className="drawer-min-width-40">
              {popupOpen && (
                <AddCreditCardModal
                  open={popupOpen}
                  closeAddModal={closeAddModal}
                  handleSave={handleSubmit}
                  selected={selected}
                  mode={mode}
                />
              )}
            </div>
          </Drawer>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackBarClose}
          >
            <Alert onClose={handleSnackBarClose} severity="success">
              {`Setting has been ${action === "new" ? "created" : "updated"}`}
            </Alert>
          </Snackbar>
        </div>
      </CustomTabPanel>
    </Box>

  );
}
