import React, { useState } from "react";
import "./MulticitySearchBar.css";
import Grid from "@material-ui/core/Grid";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import DestinationSearchMenu from "../DestinationSearchMenu/DestinationSearchMenu";
import Menu from "@material-ui/core/Menu";
import Popover from "@material-ui/core/Popover";
import DateRangePicker from "../DatePicker/DatePicker";
import months from "../../data/Months";
import Days from "../../data/Days";
import Tooltip from "@material-ui/core/Tooltip";

function MulticitySearchBar({ destination, search, setSearch }) {
  const [fromSearchMenuAnchorEl, setFromSearchMenuAnchorEl] = useState(null);
  const [toSearchMenuAnchorEl, setToSearchMenuAnchorEl] = useState(null);
  const [datePickerMenuAnchorEl, setDatePickerMenuAnchorEl] = useState(null);

  const fromSearchMenuHandleClick = (event) => {
    setFromSearchMenuAnchorEl(event.currentTarget);
  };

  const fromSearchMenuAnchorElHandleClose = (event) => {
    setFromSearchMenuAnchorEl(null);
  };

  const toSearchMenuHandleClick = (event) => {
    setToSearchMenuAnchorEl(event.currentTarget);
  };

  const toSearchMenuAnchorElHandleClose = (event) => {
    setToSearchMenuAnchorEl(null);
  };
  const datePickerMenuHandleClick = (event) => {
    setDatePickerMenuAnchorEl(event.currentTarget);
  };

  const datePickerMenuHandleClose = () => {
    setDatePickerMenuAnchorEl(null);
  };

  const addAnotherDestination = () => {
    const existingSearch = { ...search };
    if (existingSearch.type === "Multidestination") {
      existingSearch.destinations.push({
        id: existingSearch.destinations.length + 1,
        from:
          existingSearch.destinations[existingSearch.destinations.length - 1]
            .to,
        to: "",
        depart:
          existingSearch.destinations[existingSearch.destinations.length - 1]
            .depart,
      });
    }
    setSearch(existingSearch);
  };

  const removeLastDestination = () => {
    const existingSearch = { ...search };
    if (existingSearch.type === "Multidestination") {
      existingSearch.destinations.pop();
    }
    setSearch(existingSearch);
  };
  return (
    <div className="basic-search-bar">
      <Grid container className="full-height">
        <Grid item xs={4} className="pl-16 br-1 hover-fill">
          <Menu
            id="fromSearchMenu"
            anchorEl={fromSearchMenuAnchorEl}
            keepMounted
            open={Boolean(fromSearchMenuAnchorEl)}
            onClose={fromSearchMenuAnchorElHandleClose}
          >
            <DestinationSearchMenu
              search={search}
              setSearch={setSearch}
              destination={destination}
              type="from"
              closeMenu={fromSearchMenuAnchorElHandleClose}
            />
          </Menu>
          <div
            className="from-to-height-width"
            onClick={fromSearchMenuHandleClick}
          >
            <div
              className={`bold small-font medium-opacity pointer heading-font ${
                typeof destination.from !== "string" ? "" : "no-opacity"
              }`}
              onClick={fromSearchMenuHandleClick}
            >
              From
            </div>
            <Tooltip title="Leaving From">
              <div
                className="station-text  pointer "
                onClick={fromSearchMenuHandleClick}
              >
                {destination.from.city ? destination.from.city : "Select"}
              </div>
            </Tooltip>
            <div
              className="description pointer "
              onClick={fromSearchMenuHandleClick}
            >
              {destination.from.city_code ? destination.from.city_code : ""}{" "}
              {destination.from.airport_name
                ? destination.from.airport_name
                : ""}
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className="pl-16 br-1 hover-fill">
          <Menu
            id="toSearchMenu"
            anchorEl={toSearchMenuAnchorEl}
            keepMounted
            open={Boolean(toSearchMenuAnchorEl)}
            onClose={toSearchMenuAnchorElHandleClose}
          >
            <DestinationSearchMenu
              search={search}
              setSearch={setSearch}
              destination={destination}
              type="to"
              closeMenu={toSearchMenuAnchorElHandleClose}
            />
          </Menu>
          <div
            className="from-to-height-width"
            onClick={toSearchMenuHandleClick}
          >
            <div
              className={`bold small-font medium-opacity pointer heading-font ${
                typeof destination.to !== "string" ? "" : "no-opacity"
              }`}
              onClick={toSearchMenuHandleClick}
            >
              To
            </div>
            <Tooltip title="Going To">
              <div
                className="station-text  pointer "
                onClick={toSearchMenuHandleClick}
              >
                {destination.to.city ? destination.to.city : "Select"}
              </div>
            </Tooltip>
            <div
              className="description pointer "
              onClick={toSearchMenuHandleClick}
            >
              {destination.to.city_code ? destination.to.city_code : ""}{" "}
              {destination.to.airport_name ? destination.to.airport_name : ""}
            </div>
          </div>
        </Grid>
        <Grid item xs={search.destinations.length !== 1 ? destination.id === search.destinations.length ? 2 : 4 : 4} className="pl-16 br-1 hover-fill">
          <Popover
            id="departDatePicker"
            anchorEl={datePickerMenuAnchorEl}
            keepMounted
            open={Boolean(datePickerMenuAnchorEl)}
            onClose={datePickerMenuHandleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <DateRangePicker
              destination={destination}
              search={search}
              setSearch={setSearch}
              closeMenu={datePickerMenuHandleClose}
            />
          </Popover>
          <div
            className="bold small-font medium-opacity search-title pointer heading-font"
            onClick={datePickerMenuHandleClick}
          >
            Depart
            <div
              className="expand-icon pointer"
              onClick={datePickerMenuHandleClick}
            >
              <ExpandMoreRoundedIcon className="icon-blue" fontSize="small" />
            </div>
          </div>
          <div
            className="station-text pointer"
            onClick={datePickerMenuHandleClick}
          >
            {destination.depart.getDate()}
            <span className="station-sub-text pointer">
              {months[destination.depart.getMonth()]},
              {destination.depart.getFullYear().toString().substring(2, 4)}
            </span>
          </div>
          <div
            className="description pointer"
            onClick={datePickerMenuHandleClick}
          >
            {Days[destination.depart.getDay()]}
          </div>
        </Grid>
        {/* <Grid item xs={4} className="p-8 pl-16">
          <Grid container className="full-height">
            <Grid
              item
              xs={10}
              className={
                "full-height " +
                (destination.id === search.destinations.length &&
                search.destinations.length !== 1
                  ? "br-1"
                  : "")
              }
            >
              {destination.id === search.destinations.length &&
                search.destinations.length < 4 && (
                  <div className="add-city-button-container">
                    <Button
                      variant="outlined"
                      onClick={() => addAnotherDestination()}
                      startIcon={<AddRoundedIcon />}
                    >
                      Add another city
                    </Button>
                  </div>
                )}
            </Grid>
            {search.destinations.length !== 1 &&
              destination.id === search.destinations.length && (
                <Grid item xs={2} className="full-height">
                  <div
                    className="remove-button full-height hover-fill"
                    onClick={() => removeLastDestination()}
                  >
                    <HighlightOffRoundedIcon className="remove-icon" />
                  </div>
                </Grid>
              )}
          </Grid>
        </Grid> */}
        {search.destinations.length !== 1 &&
              destination.id === search.destinations.length && (
                <Grid item xs={2} className="full-height">
                  <div
                    className="remove-button full-height hover-fill"
                    onClick={() => removeLastDestination()}
                  >
                    <HighlightOffRoundedIcon className="remove-icon" />
                  </div>
                </Grid>
              )}
      </Grid>
      { destination.id === search.destinations.length &&
        search.destinations.length < 4 && (
          <div className="add-city-button-container">
            <Button
              className="mb-3p"
              variant="outlined"
              onClick={() => addAnotherDestination()}
              startIcon={<AddRoundedIcon />}
            >
              Add another city
            </Button>
          </div>
        )}

    </div>
  );
}

export default MulticitySearchBar;
