import React from "react";
export default function NoItienaryDiv() {
    return (
        <div
            style={{
                textAlign: "center",
                margin: "10%",
                fontSize: "16px",
                fontWeight: "500",
            }}
        >
            Search PNR to Load Itinerary
        </div>
    );
}