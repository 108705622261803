import React, { } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import FlightPassengerDetails from "./FlightPassengerDetails";
import FlightSegmentDetails from "./FlightSegmentDetails";
import FlightFareDetailsSupplierTableComponent from "./FlightFareDetailsSupplierTableComponent";
import FlightFareDetailsClientTableComponent from "./FlightFareDetailsClientTableComponent";
import FlightBaggageDetails from "./FlightBaggageDetails/FlightBaggageDetails"

export default function FlightImportBooking({ bookingDetails, flight, selectedProductType,
    handlecabinClassChange, handlePNRChange, handleticketChange, flightIndex, clientId,
    OnChangeCalculateTotalFare, CalculateTotalFare, reissue,
    segGroups, setPassengerTemplate, vendorDetail, clientCurrency, adultBase,
    childBase, infantBase, adultTax, childTax, infantTax,
    adultBaggage, childBaggage, infantBaggage, adultMarkup, childMarkup,
    infantMarkup, adultIata, childIata, infantIata, adultPlb, childPlb,
    infantPlb, adultTotal, childTotal, infantTotal, adultVendorTotal,
    childVendorTotal, infantVendorTotal, adultVendorIata, childVendorIata,
    infantVendorIata, adultVendorPlb, childVendorPlb, infantVendorPlb, adultVendorIssuanceFee,
    childVendorIssuanceFee, infantVendorIssuanceFee, adultVendorTax, childVendorTax, infantVendorTax,
    clientROE, selectedItem, flightTicketNumber, baggageDetails, setBaggageDetails
}) {
    let disableInfantMarkup = false;
    return (
        <div >
            {
                bookingDetails && bookingDetails.flights && bookingDetails.flights.length > 0 ?
                    (<>

                        <FlightSegmentDetails segGroups={flight && flight.segGroups && flight.segGroups}
                            flightIndex={flightIndex} handlecabinClassChange={handlecabinClassChange} handlePNRChange={handlePNRChange} />
                        <FlightPassengerDetails selectedProductType={selectedProductType} passengers={flight && flight.passengers && flight.passengers}
                            reissue={reissue} handleticketChange={handleticketChange} flightIndex={flightIndex}
                            selectedItem={selectedItem} setPassengerTemplate={setPassengerTemplate}
                            baggages={flight && flight.baggages && flight.baggages} flightTicketNumber={flightTicketNumber}
                        />
                        {bookingDetails && bookingDetails.flights && bookingDetails.flights[0].baggages.length === 0 &&
                            (<FlightBaggageDetails segGroups={flight && flight.segGroups && flight.segGroups}
                                baggageDetails={baggageDetails} setBaggageDetails={setBaggageDetails}
                                passengers={flight && flight.passengers && flight.passengers}
                                flight={flight} />)
                        }
                        <FlightFareDetailsSupplierTableComponent
                            flight={flight}
                            CalculateTotalFare={CalculateTotalFare}
                            OnChangeCalculateTotalFare={
                                OnChangeCalculateTotalFare
                            }
                            adultBase={adultBase}
                            adultTax={adultTax}
                            adultBaggage={adultBaggage}
                            childBase={childBase}
                            childTax={childTax}
                            childBaggage={childBaggage}
                            infantBase={infantBase}
                            infantTax={infantTax}
                            infantBaggage={infantBaggage}
                            adultVendorIssuanceFee={adultVendorIssuanceFee}
                            childVendorIssuanceFee={childVendorIssuanceFee}
                            infantVendorIssuanceFee={infantVendorIssuanceFee}
                            adultMarkup={adultMarkup}
                            childMarkup={childMarkup}
                            infantMarkup={infantMarkup}
                            adultVendorTax={adultVendorTax}
                            childVendorTax={childVendorTax}
                            infantVendorTax={infantVendorTax}
                            adultVendorTotal={adultVendorTotal}
                            childVendorTotal={childVendorTotal}
                            infantVendorTotal={infantVendorTotal}
                            adultIata={adultIata}
                            childIata={childIata}
                            infantIata={infantIata}
                            adultVendorIata={adultVendorIata}
                            childVendorIata={childVendorIata}
                            infantVendorIata={infantVendorIata}
                            adultPlb={adultPlb}
                            childPlb={childPlb}
                            infantPlb={infantPlb}
                            adultVendorPlb={adultVendorPlb}
                            childVendorPlb={childVendorPlb}
                            infantVendorPlb={infantVendorPlb}
                            disableInfantMarkup={disableInfantMarkup}
                            currency={
                                vendorDetail && vendorDetail.currencyCode
                                    ? vendorDetail.currencyCode
                                    : ""
                            }
                        />
                        <FlightFareDetailsClientTableComponent
                            flight={flight}
                            CalculateTotalFare={CalculateTotalFare}
                            OnChangeCalculateTotalFare={
                                OnChangeCalculateTotalFare
                            }
                            adultBase={adultBase}
                            adultTax={adultTax}
                            adultBaggage={adultBaggage}
                            childBase={childBase}
                            childTax={childTax}
                            childBaggage={childBaggage}
                            infantBase={infantBase}
                            infantTax={infantTax}
                            infantBaggage={infantBaggage}
                            adultVendorIssuanceFee={adultVendorIssuanceFee}
                            childVendorIssuanceFee={childVendorIssuanceFee}
                            infantVendorIssuanceFee={infantVendorIssuanceFee}
                            adultMarkup={adultMarkup}
                            childMarkup={childMarkup}
                            infantMarkup={infantMarkup}
                            adultVendorTax={adultVendorTax}
                            childVendorTax={childVendorTax}
                            infantVendorTax={infantVendorTax}
                            adultTotal={adultTotal}
                            childTotal={childTotal}
                            infantTotal={infantTotal}
                            adultIata={adultIata}
                            childIata={childIata}
                            infantIata={infantIata}
                            adultVendorIata={adultVendorIata}
                            childVendorIata={childVendorIata}
                            infantVendorIata={infantVendorIata}
                            adultPlb={adultPlb}
                            childPlb={childPlb}
                            infantPlb={infantPlb}
                            adultVendorPlb={adultVendorPlb}
                            childVendorPlb={childVendorPlb}
                            infantVendorPlb={infantVendorPlb}
                            disableInfantMarkup={disableInfantMarkup}
                            currency={
                                clientCurrency ? clientCurrency : flight.currency
                            }
                            ROE={clientROE}
                        />
                    </>)
                    : ''
            }
        </div>
    );
}
