import React, { useEffect, useState, useReducer, useRef } from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

let bagArray = [];
let mealArray = [];
let bTotal = 0;
let mTotal = 0;
export default function DetailedFare({ open, currency, travellers, toggleDrawer }) {
    const theme = useTheme();
    const [baggageInfo, dispatchBaggageInfo] = useReducer(baggageReducer, []);
    const [mealInfo, dispatchMealInfo] = useReducer(mealReducer, []);
    const [baggageTotal, dispatchBaggageTotal] = useReducer(baggageTotalReducer, 0);
    const [mealTotal, dispatchMealTotal] = useReducer(mealTotalReducer, 0);
    const [value, setValue] = React.useState(0);
    // const [selectedcard, dispatch] = useReducer(reducer, initialcardState);
    function baggageReducer(state, action) {

        switch (action.type) {
            case 'baggage':
                return action.newValue ? action.newValue : []
            default:
                return action.newValue
        }
    }
    function mealReducer(state, action) {

        switch (action.type) {
            case 'meal':
                return action.newValue ? action.newValue : []
            default:
                return action.newValue
        }
    }
    function baggageTotalReducer(state, action) {

        switch (action.type) {
            case 'baggage':
                return action.newValue ? action.newValue : []
            default:
                return action.newValue
        }
    }
    function mealTotalReducer(state, action) {

        switch (action.type) {
            case 'meal':
                return action.newValue ? action.newValue : []
            default:
                return action.newValue
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        console.log("open", open)
    }, [open])
    useEffect(() => {
        console.log("baggageInfo", baggageInfo)
    }, [baggageInfo])
    useEffect(() => {
        console.log("mealInfo", mealInfo)
    }, [mealInfo])
    useEffect(() => {
        console.log("travellers", travellers)
    }, [travellers])
    useEffect(() => {
        resetToDefault();
        exceuteDataMod();
    }, [])
    const resetToDefault = () => {
        bagArray = [];
        mealArray = [];
        bTotal = 0;
        mTotal = 0;
        dispatchBaggageInfo({ type: 'baggage', newValue: [] });
        dispatchBaggageTotal({ type: 'baggage', newValue: 0 });
        dispatchMealInfo({ type: 'meal', newValue: [] });
        dispatchMealTotal({ type: 'meal', newValue: 0 });
    }
    const exceuteDataMod = () => {
        if (travellers && travellers.length) {
            travellers.map(traveller => {
                if (traveller.extraOutwardLuggage && traveller.extraOutwardLuggage.length) {

                    traveller.extraOutwardLuggage.map((item, index) => {
                        let tempObj = {};
                        tempObj.SegmentInfo = item.SegmentInfo
                        tempObj.label = item.label
                        tempObj.price = item.price
                        tempObj.name = traveller.firstName + traveller.lastName
                        tempObj.paxType = traveller.paxType
                        bagArray.push(tempObj);
                        bagArray.sort(function (a, b) {
                            if (a.paxType < b.paxType) { return -1; }
                            if (a.paxType > b.paxType) { return 1; }
                            return 0;
                        })
                        bTotal = bTotal + parseInt(item.price)
                    })
                    dispatchBaggageInfo({ type: 'baggage', newValue: bagArray });
                    dispatchBaggageTotal({ type: 'baggage', newValue: bTotal });
                }
                if (traveller.extraReturnLuggage && traveller.extraReturnLuggage.length) {

                    traveller.extraReturnLuggage.map((item, index) => {
                        let tempObj = {};
                        tempObj.SegmentInfo = item.SegmentInfo
                        tempObj.label = item.label
                        tempObj.price = item.price
                        tempObj.name = traveller.firstName + traveller.lastName
                        tempObj.paxType = traveller.paxType
                        bagArray.push(tempObj);
                        bagArray.sort(function (a, b) {
                            if (a.paxType < b.paxType) { return -1; }
                            if (a.paxType > b.paxType) { return 1; }
                            return 0;
                        })
                        bTotal = bTotal + parseInt(item.price)
                    })
                    dispatchBaggageInfo({ type: 'baggage', newValue: bagArray });
                    dispatchBaggageTotal({ type: 'baggage', newValue: bTotal });
                }
                if (traveller.extraReturnMeal && traveller.extraReturnMeal.length) {

                    traveller.extraReturnMeal.map((item, index) => {
                        let tempObj = {};
                        tempObj.SegmentInfo = item.SegmentInfo
                        tempObj.label = item.label
                        tempObj.price = item.price
                        tempObj.name = traveller.firstName + traveller.lastName
                        tempObj.paxType = traveller.paxType
                        mealArray.push(tempObj);
                        mealArray.sort(function (a, b) {
                            if (a.paxType < b.paxType) { return -1; }
                            if (a.paxType > b.paxType) { return 1; }
                            return 0;
                        })
                        mTotal = mTotal + parseInt(item.price)
                    })
                    dispatchMealInfo({ type: 'meal', newValue: mealArray });
                    dispatchMealTotal({ type: 'meal', newValue: mTotal });
                }
                if (traveller.extraOutwardMeal && traveller.extraOutwardMeal.length) {

                    traveller.extraOutwardMeal.map((item, index) => {
                        let tempObj = {};
                        tempObj.SegmentInfo = item.SegmentInfo
                        tempObj.label = item.label
                        tempObj.price = item.price
                        tempObj.name = traveller.firstName + traveller.lastName
                        tempObj.paxType = traveller.paxType
                        mealArray.push(tempObj);
                        mealArray.sort(function (a, b) {
                            if (a.paxType < b.paxType) { return -1; }
                            if (a.paxType > b.paxType) { return 1; }
                            return 0;
                        })
                        mTotal = mTotal + parseInt(item.price)
                    })
                    dispatchMealInfo({ type: 'meal', newValue: mealArray });
                    dispatchMealTotal({ type: 'meal', newValue: mTotal });
                }
            })
        }

    }
    return (

        <div className="ticket-drawer-container detailedFare">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={toggleDrawer(false)}
                ></CloseIcon>
            </div>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    centered
                >
                    <Tab label="Baggage" {...a11yProps(0)} />
                    <Tab label="Meal" {...a11yProps(1)} />
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} dir={theme.direction}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="ancillaryInfoTable" aria-label="simple table">
                        <TableHead className="ancillaryInfoTableHead">
                            <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell align="left">Passenger</TableCell>
                                <TableCell align="left">Pax</TableCell>
                                <TableCell align="left">Luggage Type</TableCell>
                                <TableCell align="left">Segment</TableCell>
                                <TableCell align="left">Price ({currency})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {baggageInfo.map((row, index) => (
                                <TableRow
                                    key={index + 1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{(row.name).length === 0 ? ('Passenger ' + (index + 1)) : row.name}</TableCell>
                                    <TableCell align="left">{row.paxType}</TableCell>
                                    <TableCell align="left" style={{ maxWidth: '350px !important' }}>{row.label}</TableCell>
                                    <TableCell align="left">{row.SegmentInfo}</TableCell>
                                    <TableCell align="left">{row.price}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow className="mealTotal">
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Total Price ({currency})</TableCell>
                                <TableCell align="left">{baggageTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell align="left">Passenger</TableCell>
                                <TableCell align="left">Pax</TableCell>
                                <TableCell align="left">Meal</TableCell>
                                <TableCell align="left">Segment</TableCell>
                                <TableCell align="left">Price ({currency})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mealInfo.map((row, index) => (
                                <TableRow
                                    key={index + 1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{(row.name).length === 0 ? ('Passenger ' + (index + 1)) : row.name}</TableCell>
                                    <TableCell align="left">{row.paxType}</TableCell>
                                    <TableCell align="left" style={{ maxWidth: '350px !important' }}>{row.label}</TableCell>
                                    <TableCell align="left">{row.SegmentInfo}</TableCell>
                                    <TableCell align="left">{row.price}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow className="mealTotal">
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Total Price ({currency})</TableCell>
                                <TableCell align="left">{mealTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
            </TabPanel> */}
        </div>


    );
}