import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import { Row, Col, Nav } from "react-bootstrap";
import axios from "axios";
import useCheckboxStyles from "../../hooks/useCheckboxStyles";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import clsx from "clsx";
import "./ReviewBooking.css";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import TextField from "@material-ui/core/TextField";
import Tickets from "../SearchResultsPage/Results/Row/Tickets";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import IconButton from "@mui/material/IconButton";
import mealPreferences from "../../data/mealPreference";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { useLocation } from "react-router-dom";
import validateFlights from "../../functions/validateFlights";
import flightBooking from "../../functions/flightBooking";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import countries from "../../data/Country";
import DialogContentText from "@mui/material/DialogContentText";
import { current } from "@reduxjs/toolkit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import HoldRulesDrawer from "./HoldRulesDrawer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DetailedFare from "../../services/DetailedFare";
import Drawer from "@material-ui/core/Drawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import RiseLoading from "../Loader/RiseLoader";
import BrandedSingleFareTable from "../BrandedFareComponents/BrandedSingleFareTable";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../services/AuthService";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { encryptData } from "../../providers/dataEncryptDecryptor";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import moment from 'moment';
import airlineDetails from "../../data/AirlineDetails";
import CloseIcon from "@material-ui/icons/Close";
import CCpayment from "./CCpayment";
import { RadioGroup, Radio, Snackbar, Alert } from '@mui/material';
import MiniRulesComponent from "../MiniRules/MiniRulesComponent";
import getTransactionDetails from "../../functions/GetTransactionDetails";
import PaymentStatus from "../../data/paymentStatus";
import Terms from "../Footer/Terms";
import ReviewBookingTravelerInput from "./ReviewBookingTravelerInput";
import Cancellation from "../MiniRules/Cancellation";
import logFrontEndData from "../../utils/logFrontEndData";
import getGfsDetails from "../../functions/getGfsDetails";

// import TimerSharpIcon from '@mui/icons-material/TimerSharp';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapTooltip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.grey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.grey,
  },
}));

function ViewBooking() {
  // const navigate = useNavigate();
  const location = useLocation();
  const classes = useCheckboxStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [acceptValue, setAcceptValue] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [extraBaggageChrgs, setExtraBaggageChrgs] = useState(0);
  const [extraMealChrgs, setExtraMealChrgs] = useState(0);
  const [travelProtection, setTravelProtection] = useState(false);
  const [alertChecked, setAlertChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [baggageInfoOpen, setBaggageInfoOpen] = useState(false);
  const [flightInfoOpen, setFlightInfoOpen] = useState(false);
  const [cancellationInfoOpen, setCancellationInfoOpen] = useState(false);
  const [dateChangeInfoOpen, setDateChangeInfoOpen] = useState(false);
  // const [openFlightDetails, setOpenFlightDetails] = useState(false);
  const [bookingProcess, setbookingProcess] = useState(false);
  const [travelers, setTravelers] = useState([]);
  const femaList = ["Corporate", "Retailer"];
  const genders = ["Male", "Female", "Transgender"];
  const title = [
    { type: "ADT", values: ["Mr", "Mrs", "Ms", "Mx"] },
    { type: "CHD", values: ["Mstr", "Miss"] },
    { type: "INF", values: ["Mstr", "Miss"] },
  ];
  const mealPreference = mealPreferences;
  const [email, setEmail] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [corporatePaxList, setCorporatePaxList] = useState([]);
  const [iscorporatePaxMandate, setiscorporatePaxMandate] = useState(false);
  const [currentClientID, setcurrentClientID] = useState([]);
  const [currentClientName, setcurrentClientName] = useState([]);
  const [currentClientCountryCode, setcurrentClientCountryCode] = useState([]);
  const [currentClientCurrencyCode, setcurrentClientCurrencyCode] = useState();
  const [isHoldChargeRefundable, setIsHoldChargeRefundable] = useState();
  const [holdCharge, setHoldCharge] = useState();
  const [currentTicket, setCurrentTicket] = useState();
  const [isDeclarationMandate, setisDeclarationMandate] = useState(false);
  const [isPassMandate, setisPassMandate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isHold, setIsHold] = useState(false);
  const [holdDialogueOpen, setHoldDialogueOpen] = useState(false);
  const [showCountdown, setshowCountdown] = useState(false);
  const [displayMealSelect, setDisplayMealSelect] = useState(false);
  const [displayBaggageSelect, setDisplayBaggageSelect] = useState(false);
  const [billingInformation, setBillingInformation] = useState({
    country: "",
    address: "",
    zip: "",
    city: "",
    state: "",
    contact: "",
  });
  const [countDownText, setcountDownText] = useState("");
  const [timerDialogOpen, settimerDialogOpen] = useState(false);
  const [fareChangeDialog, setFareChangeDialogOpen] = useState(false);
  // const [aboutToExpireDialogOpen, setAboutToExpireDialogOpen] = useState(false);
  const [expiredDialogOpen, setexpiredDialogOpen] = useState(false);
  // const [isAboutToExpireDialogOpened, setisAboutToExpireDialogOpened] = useState(false);
  const [fontColor, setfontColor] = useState("green");
  const [drawer, setDrawer] = useState(false);
  const [selectDate, setSelectDate] = useState({
    departureDate: "",
    today: "",
    childLimit: "",
    infantLimit: "",
  });
  const [value, setValue] = useState("Baggage Information");
  const [exBaggageInfo, setExBaggageInfo] = useState([]);
  const [exMealInfo, setExMealInfo] = useState([]);
  const [additionalserviceItinary, setAdditionalserviceItinary] = useState([
    {},
  ]);
  const [additionalserviceMealItinary, setAdditionalserviceMealItinary] =
    useState([{}]);
  const emailSubmit = useRef();
  const passengerSubmit = useRef();
  const bookingSubmit = useRef();
  const [onwardBaggageOptions, setOnwardBaggageOptions] = useState([]);
  const [returnBaggageOptions, setReturnBaggageOptions] = useState([]);
  const [showNetfare, setShowNetFare] = useState(false);
  const [clientMarkup, setClientMarkup] = useState(0);
  const [grossFare, setGrossfare] = useState(0);
  const [netFare, setNetFare] = useState(0);
  const [savePassengerCheck, setSavePassengerCheck] = useState(false);
  const [passengerData, setPassengerData] = useState([]);
  const [selectedPassengers, setSelectedPassengers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clientID, setClientID] = useState("");
  const [paxCount, setPaxCount] = useState({
    ADT: "",
    CHD: "",
    INF: ""
  });
  const [airline, setAirline] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [cardNumber, setCardNumber] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('wallet');
  const [errCardFields, setErrCardFields] = useState(false);
  const [passthroughObj, setPassthroughObj] = useState();
  const [ptMarkup, setPtMarkup] = useState('');
  const [ptError, setPtError] = useState('');
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [paymentState, setPaymentState] = useState({
    orderId: "",
    traceId: "",
    encRequest: "",
    accessCode: "",
    amount: "",
    clientId: 0,
    url: "",
    clientBalance: 0,
    transactionTokenID: 0,
    transactionTokens: [],
    paymentId: "",
    paymentStatus: "",
    rechargeRefNo: "",
    pgRefNo: "",
    currency: "",
    failureMessage: "",
    attempt: 0,
  })
  const [revalState, setRevalState] = useState(localStorage.getItem("revalState") ? JSON.parse(localStorage.getItem("revalState")) : {
  })
  const [paymentGatewayId, setPaymentGatewayId] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const history = useHistory();
  const [isValidNameLengthTrue, setIsValidNameLengthTrue] = useState(false);

  const handleShowNetFaresClick = () => {
    showNetfare == false ? setShowNetFare(true) : setShowNetFare(false);
  };
  // useLayoutEffect(() => {
  //   debugger;
  //   if (performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD) {
  //     debugger;
  //     window.location.reload(); // Force a reload when back button is used
  //   }
  //   if (performance.navigation.type === performance.navigation.TYPE_NAVIGATE) {
  //     debugger;
  //     window.location.reload(); // Force a reload when back button is used
  //   }
  // }, []);
  const clearlocalStorage = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("travelers");
    localStorage.removeItem("reValTimer");
    localStorage.removeItem("currentTicket");
    localStorage.removeItem("paymentAttempt");
    localStorage.removeItem("currentTicket");
    localStorage.removeItem("isDeclarationMandate");
    localStorage.removeItem("paymentAttempt");
    localStorage.removeItem("paymentState");
    localStorage.removeItem("reValTimer");
    localStorage.removeItem("travelers");
  }

  useEffect(() => {
    let paymentAttempt = localStorage.getItem("paymentAttempt");
    if (paymentAttempt === 1 || paymentAttempt === 2) {
      setActiveStep((prev) => 1);
    }
    let tempState = JSON.parse(localStorage.getItem("paymentState"));
    // if (!localStorage.getItem("redirectId")) {
    if (revalState.traceId === localStorage.getItem("traceId") && sessionStorage.getItem("orderId") && !localStorage.getItem("redirectId")) {
      alert("You cannot directly access this page. Redirecting to Search...");
      history.replace("/flight");
    } else if (revalState.traceId === localStorage.getItem("traceId") && sessionStorage.getItem("orderId") && localStorage.getItem("redirectId")) {
      if (tempState && tempState !== "{}" && tempState.orderId !== "" && tempState.traceId !== "" && tempState.transactionId !== ""
      ) {
        localStorage.removeItem("redirectId");
        getPaymentResponse(tempState.transactionId);
        getClients();
        startTimer();
        setUserRole(getCurrentRole());
      }
    } else if (revalState.traceId !== localStorage.getItem("traceId")) {
      localStorage.removeItem("redirectId");
      getClients();
      startTimer();
      setUserRole(getCurrentRole());
      setIsHold(revalState.hold == "true" ? true : false);

      if (tempState && tempState !== "{}" && tempState.orderId !== "" && tempState.traceId !== "" && tempState.transactionId !== ""
      ) {
        getPaymentResponse(tempState.transactionId);
      } else {
        setData();
        validateFlightSearch();
      }
    }
    // } else {
    //   alert("You cannot directly access this page. Redirecting to Search...");
    //   history.replace("/flight");
    // }
  }, []);

  useEffect(() => {
    console.log("currentTicket", currentTicket)
  }, [currentTicket]);


  const checkFullnameLength = (travelers, currentTicket) => {
    const infantsArr = travelers.filter((traveler) => traveler.paxType === 'INF');
    if (infantsArr.length > 0) {
      const adultArr = travelers.filter((traveler) => traveler.paxType === 'ADT');
      for (let i = 0; i < infantsArr.length; i++) {
        const fullNameLengthWithInf = `${adultArr[i].title}${adultArr[i].firstName}${adultArr[i].lastName}${infantsArr[i].title}${infantsArr[i].firstName}${infantsArr[i].lastName}`
        if (!(fullNameLengthWithInf.length <= (currentTicket.vendor === '1A' ? 48 : 56))) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (travelers && currentTicket) {
      if (checkFullnameLength(travelers, currentTicket)) {
        setIsValidNameLengthTrue(true);
        return;
      }
      setIsValidNameLengthTrue(false);
    }
  }, [travelers, currentTicket]);

  const handleSnackBarClose = () => {
    setIsValidNameLengthTrue(false);
  }


  const handleChange = (event, newValue) => {
    setValue((value) => newValue);
  };

  let interval;
  const toggleDrawer = (value) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    await setDrawer(value);
  };

  async function getClients() {
    let filter = 0;
    if (checkIfUserStaff()) {
      filter = "id==" + revalState.clientId;
      setClientID(revalState.clientId)
    } else {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user !== null && user !== undefined) {
        filter = "id==" + user["clientref_id"];
        setClientID(user["clientref_id"]);
      }
    }

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        //console.log(response?.data[0]?.clientpforile.clientName);
        setHoldCharge(response?.data[0]?.clientpforile.holdCharge);
        setIsHoldChargeRefundable(
          response?.data[0]?.clientpforile.isHoldChargeRefundable
        );
        setcurrentClientID(response?.data[0]?.clientpforile.id);
        setcurrentClientName(response?.data[0]?.clientpforile.clientName);
        setcurrentClientCountryCode(
          response?.data[0]?.clientpforile.countryCode
        );
        setAreaCode(response?.data[0]?.clientpforile.countryCode);
        setEmail(response?.data[0]?.clientpforile.email);
        setMobile(response?.data[0]?.clientpforile.phoneNumber);
        setPaymentGatewayId((prev) => response?.data[0]?.clientpforile.paymentGateWayListId);
        setcurrentClientCurrencyCode(
          response?.data[0]?.clientpforile.currencyCode
        );
      });
  }


  useEffect(() => {
    console.log("paymentGatewayId", paymentGatewayId)
  }, [paymentGatewayId]);

  const validateFlightSearch = () => {
    validateFlights(
      revalState.traceId,
      revalState.clientId,
      revalState.additionalClientmarkup,
      [revalState.purchaseId],
      location.pathname,
      'Book button to review booking page',
    ).then((data) => {
      if (
        data != undefined &&
        data.passengerRequiredFields !== undefined &&
        data.passengerRequiredFields.length > 0
      ) {
        setisPassMandate(data.passengerRequiredFields[0].passportNumber);
      }
      if (
        data === "" ||
        data === undefined ||
        data.flights === undefined ||
        data.flights.length <= 0
      ) {
        setIsError(true);
      } else {
        if (data.flights[0]) {
          localStorage.setItem("currentTicket", JSON.stringify(data.flights[0]));
          const result = data.flights[0];
          let baggageCount = 0;
          let mealCount = 0;
          if (result.additionalServices) {
            result.additionalServices.map((item) => {
              if (item.additionalServiceType === "Baggage") {
                baggageCount++;
              } else if (item.additionalServiceType === "Meal") {
                mealCount++;
              }
            });

            if (baggageCount > 0) {
              setDisplayBaggageSelect(true);
            }
            if (mealCount > 0) {
              setDisplayMealSelect(true);
            }
          }
        }
        setValue((value) =>
          data.flights[0].fareGroups[0].pricedDescription &&
            data.flights[0].fareGroups[0].pricedDescription.length > 0
            ? "Brand Description"
            : "Baggage Information"
        );
        if (
          data !== undefined &&
          data.flights[0] &&
          data.flights[0].isFareChange === true
        ) {
          openfareChangeDialog();
          setCurrentTicket(data.flights[0]);

          getMealData(data.flights[0]);
          setIsError(false);

          setClientMarkup(data.flights[0] && data.flights[0].fareGroups[0] && data.flights[0].fareGroups[0].clientMarkup);
          setGrossfare(data.flights[0] && data.flights[0].fareGroups[0] && data.flights[0].fareGroups[0].grossFare);
          setNetFare(data.flights[0] && data.flights[0].fareGroups[0] && data.flights[0].fareGroups[0].netFare);

        } else {
          if (data.flights[0].vendor === "AT") {
            selectBaggageData(data.flights[0]);
          }
          setCurrentTicket(data.flights[0]);
          getMealData(data.flights[0]);
          setIsError(false);
        }

        setAirline(data.flights[0] && data.flights[0].airline)
      }
    });
  };
  const openfareChangeDialog = () => {
    setFareChangeDialogOpen(true);
  };
  function getMealData(data) {
    if (data.additionalServices) {
      let temp = data.additionalServices;

      let newArray = temp.filter(function (el) {
        return el.additionalServiceType === "Meal";
      });
    }
  }

  useEffect(() => {
  }, [exMealInfo]);
  useEffect(() => {
  }, [displayBaggageSelect]);
  useEffect(() => {
  }, [exBaggageInfo]);

  useEffect(() => {
    let departureDate =
      currentTicket?.segGroups[0]?.departureOn.length > 0
        ? currentTicket?.segGroups[0].departureOn.split("T")[0]
        : new Date();
    let tempChildLimit = new Date(departureDate);
    let tempInfantLimit = new Date(departureDate);
    tempChildLimit.setFullYear(tempChildLimit.getFullYear() - 12);
    tempInfantLimit.setFullYear(tempInfantLimit.getFullYear() - 2);

    setSelectDate({
      departureDate,
      today: new Date().toISOString().slice(0, 10),
      childLimit: tempChildLimit.toISOString().slice(0, 10),
      infantLimit: tempInfantLimit.toISOString().slice(0, 10),
    });
    if (isError === false && currentTicket) {
      setshowCountdown(true);
    }
  }, [currentTicket, isError]);
  const setData = () => {
    const passengerDetails = [];
    let tempTicket = JSON.parse(localStorage.getItem("currentTicket"));
    if (tempTicket) {
      setCurrentTicket((prev) => {
        return tempTicket;
      })
    }
    setisDeclarationMandate(localStorage.getItem("isDeclarationMandate") ? localStorage.getItem("isDeclarationMandate") === true ? true : false : false);
    if (JSON.parse(localStorage.getItem("travelers"))) {
      setTravelers((prev) => {
        return JSON.parse(localStorage.getItem("travelers"))
      });
    } else {
      for (let i = 0; i < revalState?.activeSearch?.adult; i++) {
        passengerDetails.push({
          id: passengerDetails.length + 1,
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          gender: "",
          mealPreference: "",
          specialService: "",
          tsaRedress: "",
          frequentFlyer: "",
          paxType: "ADT",
          passportNumber: "",
          passengerNationality: "",
          passportDOI: "",
          passportDOE: "",
          passportIssuedCountry: "",
          fema: "",
          panCard: "",
          extraOutwardLuggage: [],
          extraReturnLuggage: [],
          extraOutwardLuggageChrgs: 0,
          extraReturnLuggageChrgs: 0,
          extraOutwardMeal: [],
          extraReturnMeal: [],
          extraOutwardMealChrgs: 0,
          extraReturnMealChrgs: 0,
        });
      }
      for (let i = 0; i < revalState?.activeSearch?.children; i++) {
        passengerDetails.push({
          id: passengerDetails.length + 1,
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          gender: "",
          mealPreference: "",
          specialService: "",
          tsaRedress: "",
          frequentFlyer: "",
          paxType: "CHD",
          passportNumber: "",
          passengerNationality: "",
          passportDOI: "",
          passportDOE: "",
          passportIssuedCountry: "",
          fema: "",
          panCard: "",
          extraOutwardLuggage: [],
          extraReturnLuggage: [],
          extraOutwardLuggageChrgs: 0,
          extraReturnLuggageChrgs: 0,
          extraOutwardMeal: [],
          extraReturnMeal: [],
          extraOutwardMealChrgs: 0,
          extraReturnMealChrgs: 0,
        });
      }
      for (let i = 0; i < revalState?.activeSearch?.infant; i++) {
        passengerDetails.push({
          id: passengerDetails.length + 1,
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          gender: "",
          mealPreference: "",
          specialService: "",
          tsaRedress: "",
          frequentFlyer: "",
          paxType: "INF",
          passportNumber: "",
          passengerNationality: "",
          passportDOI: "",
          passportDOE: "",
          passportIssuedCountry: "",
          fema: "",
          panCard: "",
          extraOutwardLuggage: [],
          extraReturnLuggage: [],
          extraOutwardLuggageChrgs: 0,
          extraReturnLuggageChrgs: 0,
          extraOutwardMeal: [],
          extraReturnMeal: [],
          extraOutwardMealChrgs: 0,
          extraReturnMealChrgs: 0,
        });
      }
      setTravelers((prev) => passengerDetails);
    }
    let bagArrLength = [];
    passengerDetails.forEach((element, index) => {
      bagArrLength.push({ index: index, bagInfo: [{ nestedBag: {} }] });
    });
    let mealArrLength = [];
    passengerDetails.forEach((element, index) => {
      mealArrLength.push({ index: index, mealInfo: [{ nestedMeal: {} }] });
    });
    setExMealInfo(mealArrLength);
    setExBaggageInfo(bagArrLength);
    setExtraBaggageChrgs(0);
    setCorporatePaxList([]);
    setiscorporatePaxMandate(false);
    ValidatorForm.addValidationRule("isEmail", (value) => {
      let email_format = new RegExp(
        /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,3})$/
      );
      if (!value.match(email_format)) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule("isEmail");
    };
  }


  const startTimer = () => {
    let countDown = "";
    setcountDownText("");
    clearInterval(interval);
    var aboutToExpire;
    let checkVariable = localStorage.getItem("reValTimer");
    if (checkVariable) {
      let x = new Date(checkVariable);
      aboutToExpire = new Date(checkVariable).getTime() + 600000;
      countDown = new Date(checkVariable).getTime() + 780000;
    } else {
      let tempTime = new Date();
      aboutToExpire = new Date().getTime() + 600000;
      countDown = new Date().getTime() + 780000;
      localStorage.setItem("reValTimer", tempTime);
    }
    interval = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDown - now;
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);
      setcountDownText(sec >= 0 ? min + "m " + sec + "s " : "Expired");
      if (distance < 2 && isError === false) {
        clearInterval(interval);
        setcountDownText("Expired");
        setexpiredDialogOpen(true);
        setshowCountdown(false);
      } else if (isError === true && !currentTicket) {
        clearInterval(interval);
        setcountDownText("Expired");
        setexpiredDialogOpen(false);
        setshowCountdown(false);
      } else if (now >= aboutToExpire) {
        setfontColor("red");
      }
    }, 1000);
  };
  const goBack = () => {
    // if (localStorage.getItem("autoQuote")) {
    //   window.close()
    // } else {
    logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, `User clicked Go Back for the popup, "The Fare has been Updated"`, '', '')
    closeTimerDialog();
    closeExpiredDialog();
    setFareChangeDialogOpen(false);
    clearInterval(interval);
    history.push("/flight");
    // }

  };
  const getSteps = () => {
    return ["Passenger Details", "Review and Book"];
  };
  const closeTimerDialog = () => {
  };
  const closeFareChangeDialog = () => {
    logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, `User clicked Continue for the popup, "The Fare has been Updated"`, '', '');
    setFareChangeDialogOpen(false);
  };

  const closeExpiredDialog = () => {
    setexpiredDialogOpen(false);
  };

  const steps = getSteps();

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setIsChecked(false);
      setOpen(false);
    }

    if (typeof onClose === "function") {
      setIsChecked(false);
      setOpen(false);
    }
    logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, `User clicked Cancel for the popup, "WARNING *ELIGIBILITY CHECK*"`, '', '');
    setIsChecked(false);
    setOpen1(false);
  };

  const handleNext = () => {
    if (checkFullnameLength(travelers, currentTicket)) {
      setIsValidNameLengthTrue(true);
      return;
    }
    if (paymentMethod === 'card') {
      if (!cardNumber || !expiryMonth || !expiryYear || !!ptError || (currentTicket.cardTypeAccepted !== "TP" && !cvv)) {
        setErrCardFields(true);
      }
      else {
        setErrCardFields(false);
        if (activeStep + 1 === 1) {
          emailSubmit.current && emailSubmit.current.submit();
        } else {
          CheckSpecialFare();
        }
      }
    }
    else {
      if (activeStep + 1 === 1) {
        emailSubmit.current && emailSubmit.current.submit();
      } else {
        CheckSpecialFare();
      }
    }

    setShowNetFare(false);
  };

  const [openErrorMessageDialog, setOpenErrorMessageDialog] = useState(false);
  const [openErrorMessageDialog1, setOpenErrorMessageDialog1] = useState(false);
  const [openErrorMessageHTML, setOpenErrorMessageHTML] = useState("");
  const [closePopup, setClosePopup] = useState("");
  const [errorTimeout, setErrorTimeout] = useState("");


  const handleClickOpenErrorMessageDialog = () => {
    setOpenErrorMessageDialog(true);
  };

  const handleCloseErrorMessageDialog = () => {
    setOpenErrorMessageDialog(false);
    if (!closePopup) {
      history.replace(`/flight/`);
      setClosePopup(false);
    }
    else {
      setClosePopup(false);
    }

  };
  const handleCloseErrorMessageDialog1 = () => {
    setOpenErrorMessageDialog1(false);
    if (sessionStorage.getItem("orderId")) {
      if (errorTimeout) {
        clearlocalStorage()
        history.replace(`/flight/QueueTable`);
      }
      else {
        clearlocalStorage()
        history.replace('/flight/view-booked-tickets');
      }
    } else {
      closeTimerDialog();
      closeExpiredDialog();
      setFareChangeDialogOpen(false);
      clearInterval(interval);
      history.replace(`/flight/`);

    }
  };
  useEffect(() => {
    console.log("bookingProcess", bookingProcess)
  }, [bookingProcess])
  const handleBackToPassengerDtls = () => {
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddService = (idx) => {
    if (extraSegmentBaggageInfo.length > exBaggageInfo[idx].bagInfo.length) {
      exBaggageInfo[idx].bagInfo.push({ nestedBag: {} });
      setAdditionalserviceItinary([...additionalserviceItinary, current + 1]);
    }
    // validateBaggage();
  };

  const handleRemoveService = (idx, nidx) => {
    if (exBaggageInfo[idx].bagInfo.length > 1) {
      exBaggageInfo[idx].bagInfo.pop();
      const existingTravelers = [...travelers];
      if (existingTravelers[idx].extraOutwardLuggage.length > nidx) {
        existingTravelers[idx].extraOutwardLuggage.pop();
        setTravelers(existingTravelers);
      }
      setAdditionalserviceItinary(additionalserviceItinary.slice(0, -1));
      handleExtraBaggageChrgs();
      // validateBaggage();
    }
  };
  const handleMealAddService = (idx) => {
    if (extraSegmentMealInfo.length > exMealInfo[idx].mealInfo.length) {
      exMealInfo[idx].mealInfo.push({ nestedMeal: {} });
      setAdditionalserviceMealItinary([
        ...additionalserviceMealItinary,
        current + 1,
      ]);
    }
  };

  const handleMealRemoveService = (idx, nidx) => {
    if (exMealInfo[idx].mealInfo.length > 1) {
      exMealInfo[idx].mealInfo.pop();
      const existingTravelers = [...travelers];
      if (existingTravelers[idx].extraOutwardMeal.length > nidx) {
        existingTravelers[idx].extraOutwardMeal.pop();
        setTravelers(existingTravelers);
      }
      setAdditionalserviceMealItinary(
        additionalserviceMealItinary.slice(0, -1)
      );
      handleExtraMealChrgs();
    }
  };

  const handleExtraBaggageChrgs = () => {
    let totalextraOutwardLuggageChrgs = 0;
    travelers.forEach((item, idx) => {
      let travelerLuggageChrgs = 0;
      if (item.extraOutwardLuggage.length > 0) {
        item.extraOutwardLuggage.forEach((obj) => {
          if (obj) {
            totalextraOutwardLuggageChrgs += parseFloat(obj.price);
            travelerLuggageChrgs += parseFloat(obj.price);
          }
        });
      }
      travelers[idx]["extraOutwardLuggageChrgs"] =
        Math.round(travelerLuggageChrgs * 100) / 100;
    });
    setExtraBaggageChrgs(Math.round(totalextraOutwardLuggageChrgs * 100) / 100);
  };
  const handleExtraMealChrgs = () => {
    let totalextraOutwardMealChrgs = 0;
    travelers.forEach((item, idx) => {
      let travelerMealChrgs = 0;
      if (item.extraOutwardMeal.length > 0) {
        item.extraOutwardMeal.forEach((obj) => {
          totalextraOutwardMealChrgs += parseFloat(obj.price);
          travelerMealChrgs += parseFloat(obj.price);
        });
      }
      travelers[idx]["extraOutwardMealChrgs"] =
        Math.round(travelerMealChrgs * 100) / 100;
    });
    setExtraMealChrgs(Math.round(totalextraOutwardMealChrgs * 100) / 100);
  };

  let extraSegmentBaggageInfo = [];
  let getsegGroup_len = 0;
  const getExtraSegmentBaggageInfo = () => {
    const getResult = currentTicket;

    const chkAdditionalServices = getResult?.additionalServices
      ? getResult.additionalServices.length > 0
        ? true
        : false
      : false;
    if (chkAdditionalServices === true) {
      if (getResult && getResult?.additionalServices.length > 0) {
        getsegGroup_len = getResult.segGroups.length;
        if (getsegGroup_len > 0) {
          getResult.additionalServices.map((item) => {
            var findItem = extraSegmentBaggageInfo.find(
              (x) => x.label === item.cityPair
            );
            if (!findItem)
              extraSegmentBaggageInfo.push({
                label: item.cityPair,
                paxType: item.paxType,
              });
          });
        }
      }
    }
  };

  let extraSegmentMealInfo = [];
  let getsegGroup_lenMeal = 0;
  const getExtraSegmentMealInfo = () => {
    const getResult = currentTicket;

    const chkAdditionalServices = getResult?.additionalServices
      ? getResult.additionalServices.length > 0
        ? true
        : false
      : false;
    if (chkAdditionalServices === true) {
      if (getResult && getResult?.additionalServices.length > 0) {
        getsegGroup_lenMeal = getResult.segGroups.length;
        if (getsegGroup_lenMeal > 0) {
          getResult.additionalServices.map((item) => {
            var findItem = extraSegmentMealInfo.find(
              (x) => x.label === item.cityPair
            );
            if (!findItem)
              extraSegmentMealInfo.push({
                label: item.cityPair ? item.cityPair : "",
                paxType: item.paxType ? item.paxType : "",
              });
          });
        }
      }
    }
  };

  let extraMealInfo = [];

  const getExtraMealInfo = (item, index, nestedIndx, id) => {
    const mealSugment = document.getElementById(id).value;
    const getResult = currentTicket;
    if (item != null) {
      const chkAdditionalServices = getResult?.additionalServices
        ? getResult.additionalServices.length > 0
          ? true
          : false
        : false;
      if (chkAdditionalServices === true) {
        if (getResult && getResult?.additionalServices.length > 0) {
          getsegGroup_lenMeal = getResult.segGroups.length;
          if (getsegGroup_lenMeal > 0) {
            getResult.additionalServices
              .filter(
                (flt) =>
                  flt.cityPair == item?.label &&
                  (getResult.vendor == "TF" || flt.paxType == item?.paxType)
              )
              .map((item) => {
                if (item.additionalServiceType === "Meal") {
                  const optionItem = {
                    label:
                      item.serviceDescription +
                      " Total (" +
                      item.flightFares[0].amount.toFixed(2) +
                      " " +
                      item.currencyCode +
                      ")",
                    price: item.flightFares[0].amount.toFixed(2),
                    mealRefNo: item.freeText,
                  };
                  extraMealInfo.push(optionItem);
                }
              });
          }
        }
      }
    }
    if (item == null) {
      const existingTravelers = [...travelers];
      const filteredMealList = existingTravelers[index].extraOutwardMeal.filter(
        (item) => item.SegmentInfo != mealSugment
      );
      existingTravelers[index].extraOutwardMeal = filteredMealList;
      setTravelers(existingTravelers);
    } else if (item != null && mealSugment != "") {
      const existingTravelers = [...travelers];
      const filteredMealList = existingTravelers[index].extraOutwardMeal.filter(
        (item) => item.SegmentInfo != mealSugment
      );
      existingTravelers[index].extraOutwardMeal = filteredMealList;
      setTravelers(existingTravelers);
    }

    let updatedList = exMealInfo.map((item) => {
      if (item.index == index) {
        item.mealInfo[nestedIndx].nestedMeal = extraMealInfo;
        return item; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });

    setExMealInfo(updatedList);
    handleExtraMealChrgs();
  };
  const getExtraMealSelectedValue = (mealList, travelerselected, nidx) => {

    let strspt;
    let selected;
    if (mealList.length > 0 && mealList[nidx] && mealList[nidx].mealRefNo) {
      strspt = mealList[nidx].mealRefNo.split("_")[1];
      selected = travelerselected.filter((item) =>
        String(item.mealRefNo)?.includes(strspt)
      );

      if (
        selected.length > 1 &&
        selected[nidx] &&
        selected[nidx].mealRefNo
      ) {
        return mealList.find(
          (i) => i.mealRefNo === selected[nidx].mealRefNo
        );
      } else {
        if (selected[0]?.mealRefNo) {
          return mealList?.find(
            (i) => i?.mealRefNo === selected[0]?.mealRefNo
          );
        } else {
          return [];
        }
      }
    } else if (
      mealList.length === 1 &&
      mealList[0] &&
      mealList[0].mealRefNo
    ) {
      strspt = mealList[0].mealRefNo.split("_")[1];
      selected = travelerselected.filter((item) =>
        String(item.mealRefNo)?.includes(strspt)
      );

      if (
        selected.length > 1 &&
        selected[nidx] &&
        selected[nidx].mealRefNo
      ) {
        return mealList?.find(
          (i) => i?.mealRefNo === selected[nidx]?.mealRefNo
        );
      } else {
        if (selected[0]?.mealRefNo) {
          return mealList?.find(
            (i) => i?.mealRefNo === selected[0]?.mealRefNo
          );
        } else {
          return [];
        }
      }
    }
    return mealList;
  };
  // atrip baggage functions start
  const groupBaggageBySegments = (data) => {
    const onwardSegments = data.segGroups[0];
    const returnSegments = data.segGroups[1];

    if (onwardSegments) {
      const onwardBaggage = getBaggageForSegments(onwardSegments.segs, data);
      const filteredData = calculateTotalPrice(onwardBaggage);
      setOnwardBaggageOptions(filteredData);
    }

    if (returnSegments) {
      const returnBaggage = getBaggageForSegments(returnSegments.segs, data);
      const filteredData = calculateTotalPrice(returnBaggage);
      setReturnBaggageOptions(filteredData);
    }
  };
  const getBaggageForSegments = (segments, ticketData) => {
    const baggage = [];
    let segmentBaggage = [];
    let temp = [];
    if (ticketData.additionalServices) {
      segments.forEach((segment) => {
        let temp1 = ticketData.additionalServices.filter((service) =>
          service.cityPair?.includes(segment.origin + segment.destination)
        );
        temp1.forEach((item) => {
          temp.push(item);
        });
      });
      segmentBaggage = temp;
      segmentBaggage.forEach((bag, index) => {
        if (baggage[index] && baggage[index].serviceDescription) {
          baggage[index] = {
            label:
              bag.serviceDescription +
              " Total (" +
              (baggage[bag].price + bag.flightFares[0].amount).toFixed(2) +
              " " +
              bag.currencyCode +
              ")",
            price: (baggage[bag].price + bag.flightFares[0].amount).toFixed(2),
            baggageRefNo: bag.freeText,
            cityPair: bag.cityPair,
            currencyCode: bag.currencyCode,
            freeText: bag.freeText,
            paxType: bag.paxType,
            serviceDescription: bag.serviceDescription,
          };
        } else {
          baggage[index] = {
            label:
              bag.serviceDescription +
              " Total (" +
              bag.flightFares[0].amount.toFixed(2) +
              " " +
              bag.currencyCode +
              ")",
            serviceDescription: bag.serviceDescription,
            price: bag.flightFares[0].amount.toFixed(2),
            baggageRefNo: bag.freeText,
            cityPair: bag.cityPair,
            currencyCode: bag.currencyCode,
            freeText: bag.freeText,
            paxType: bag.paxType,
          };
        }
      });
      return baggage;
    } else {
      return [];
    }
  };
  function calculateTotalPrice(array) {
    const combined = {};
    array.forEach((item) => {
      const baggageRefNo = item.baggageRefNo.split("&")[0];
      if (!combined[baggageRefNo]) {
        combined[baggageRefNo] = { ...item };
      } else {
        combined[baggageRefNo].price = (
          parseFloat(combined[baggageRefNo].price) + parseFloat(item.price)
        ).toFixed(2);
        combined[baggageRefNo].label =
          item.serviceDescription +
          " Total (" +
          combined[baggageRefNo].price +
          " " +
          item.currencyCode +
          ")";
        combined[baggageRefNo].cityPair =
          combined[baggageRefNo].cityPair + "," + item.cityPair;
        combined[baggageRefNo].baggageRefNo =
          combined[baggageRefNo].baggageRefNo + "," + item.baggageRefNo;
      }
    });
    return Object.values(combined);
  }

  const selectBaggageFortraveller = (value, index, selItem) => {
    const existingTravelers = [...travelers];
    let cityPairs = [];
    if (selItem === "onward") {
      currentTicket?.segGroups[0]?.segs.forEach(seg => {
        let cityPair = `${seg.origin}${seg.destination}`;
        cityPairs.push(cityPair);
      });
    } else if (selItem === "return") {
      currentTicket?.segGroups[1]?.segs.forEach(seg => {
        let cityPair = `${seg.origin}${seg.destination}`;
        cityPairs.push(cityPair);
      });
    }
    if (value && value.baggageRefNo) {
      let temp = value.baggageRefNo.split(",");
      let selectedBaggages = currentTicket.additionalServices.filter(
        (item) => temp?.includes(item.freeText)
      );
      existingTravelers[index].extraOutwardLuggage = [
        ...existingTravelers[index].extraOutwardLuggage.filter(
          (luggage) => !temp?.includes(luggage.freeText)
        ),
        ...selectedBaggages,
      ];
      existingTravelers[index].selectedBaggage = value;
    } else {
      if (selItem === "onward" || selItem === "return") {
        existingTravelers[index].extraOutwardLuggage = existingTravelers[index].extraOutwardLuggage.filter((luggage) => {
          return !cityPairs.some(pair => luggage.cityPair?.includes(pair));
        });
      }
      existingTravelers[index].selectedBaggage = null;
    }
    setTravelers(existingTravelers);
    calculateBaggageForTraveller(existingTravelers);
  };

  const calculateBaggageForTraveller = (travelers) => {
    let totalextraOutwardLuggageChrgs = 0;
    travelers.forEach((item, idx) => {
      let travelerLuggageChrgs = 0;
      item.extraOutwardLuggage.forEach((obj) => {
        if (obj) {
          totalextraOutwardLuggageChrgs += parseFloat(obj.flightFares[0].amount);
          travelerLuggageChrgs += parseFloat(obj.flightFares[0].amount);
        }
      });
      travelers[idx]["extraOutwardLuggageChrgs"] =
        Math.round(travelerLuggageChrgs * 100) / 100;
    });
    setExtraBaggageChrgs(Math.round(totalextraOutwardLuggageChrgs * 100) / 100);
  };
  const selectBaggageData = (data) => {
    groupBaggageBySegments(data);
  };

  // atrip baggage functions end

  let extraBaggageInfo = [];
  const getExtraBaggageInfo = (selItem, index, nestedIndx, id) => {
    const bagSugment = document.getElementById(id).value;
    const getResult = currentTicket;
    if (getResult.vendor !== "AT") {
      if (selItem != null) {
        const chkAdditionalServices = getResult?.additionalServices
          ? getResult.additionalServices.length > 0
            ? true
            : false
          : false;
        if (chkAdditionalServices === true) {
          if (getResult && getResult?.additionalServices.length > 0) {
            getsegGroup_len = getResult.segGroups.length;
            if (getsegGroup_len > 0) {
              getResult.additionalServices
                .filter(
                  (flt) =>
                    flt.cityPair == selItem?.label &&
                    (getResult.vendor == "TF" ||
                      flt.paxType == selItem?.paxType)
                )
                .map((item) => {
                  if (item.additionalServiceType === "Baggage") {
                    const optionItem = {
                      label:
                        item.serviceDescription +
                        " Total (" +
                        item.flightFares[0].amount.toFixed(2) +
                        " " +
                        item.currencyCode +
                        ")",
                      price: item.flightFares[0].amount.toFixed(2),
                      baggageRefNo: item.freeText,
                    };
                    extraBaggageInfo.push(optionItem);
                  }
                });
            }
          }
        }
      }
      if (selItem == null) {
        const existingTravelers = [...travelers];
        const filteredLugList = existingTravelers[
          index
        ].extraOutwardLuggage.filter((item) => item.SegmentInfo != bagSugment);
        existingTravelers[index].extraOutwardLuggage = filteredLugList;
        setTravelers(existingTravelers);
      } else if (selItem != null && bagSugment != "") {
        const existingTravelers = [...travelers];
        const filteredLugList = existingTravelers[
          index
        ].extraOutwardLuggage.filter((item) => item.SegmentInfo != bagSugment);
        existingTravelers[index].extraOutwardLuggage = filteredLugList;
        setTravelers(existingTravelers);
      }

      let updatedList = exBaggageInfo.map((item) => {
        if (item.index === index) {
          item.bagInfo[nestedIndx].nestedBag = extraBaggageInfo;
          return item; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      });
      setExBaggageInfo(updatedList);
      handleExtraBaggageChrgs();
    } else {
      if (selItem != null) {
        const chkAdditionalServices = getResult?.additionalServices
          ? getResult.additionalServices.length > 0
            ? true
            : false
          : false;
        if (chkAdditionalServices === true) {
          if (getResult && getResult?.additionalServices.length > 0) {
            getsegGroup_len = getResult.segGroups.length;
            if (getsegGroup_len > 0) {
              if (getResult.vendor === "AT") {
                getResult.additionalServices
                  .filter(
                    (flt) =>
                      flt.cityPair === selItem?.label &&
                      flt.paxType === selItem?.paxType
                  )
                  .map((item) => {
                    if (item.additionalServiceType === "Baggage") {
                      const optionItem = {
                        label:
                          item.serviceDescription +
                          " Total (" +
                          item.flightFares[0].amount.toFixed(2) +
                          " " +
                          item.currencyCode +
                          ")",
                        price: item.flightFares[0].amount.toFixed(2),
                        baggageRefNo: item.freeText,
                      };
                      let onwardtrip = getResult.segGroups[0];
                      let returnTrip = getResult.segGroups[1];
                      let isSelectedOnward;
                      let isSelectedReturn;
                      let isSelectedExBagOnward;
                      let isSelectedExBagReturn;
                      if (
                        onwardtrip &&
                        onwardtrip.segs &&
                        onwardtrip.segs.length
                      ) {
                        isSelectedOnward = onwardtrip.segs.find((x) => {
                          if (x.origin + x.destination === selItem?.label)
                            return true;
                        });
                        isSelectedExBagOnward = onwardtrip.segs.find((x) => {
                          if (
                            travelers[index].extraOutwardLuggage.find(
                              (y) =>
                                y.SegmentInfo.split("_").pop() ===
                                x.origin + x.destination
                            )
                          )
                            return true;
                        });
                      }

                      if (
                        returnTrip &&
                        returnTrip.segs &&
                        returnTrip.segs.length
                      ) {
                        isSelectedReturn = returnTrip.segs.find((x) => {
                          if (x.origin + x.destination === selItem?.label)
                            return true;
                        });
                        isSelectedExBagReturn = returnTrip.segs.find((x) => {
                          if (
                            travelers[index].extraOutwardLuggage.find(
                              (y) =>
                                y.SegmentInfo.split("_").pop() ===
                                x.origin + x.destination
                            )
                          )
                            return true;
                        });
                      }

                      if (isSelectedOnward && isSelectedExBagOnward) {
                        const existingTravelers = [...travelers];
                        if (
                          existingTravelers[index].extraOutwardLuggage.length
                        ) {
                          existingTravelers[index].extraOutwardLuggage.map(
                            (item1) => {
                              onwardtrip.segs.map((item2) => {
                                if (
                                  item1.SegmentInfo ===
                                  item2.origin + item2.destination &&
                                  item1.baggageRefNo.split("&")[0] ===
                                  optionItem.baggageRefNo.split("&")[0]
                                ) {
                                  extraBaggageInfo.push(optionItem);
                                }
                              });
                            }
                          );
                        }
                      } else if (isSelectedReturn && isSelectedExBagReturn) {
                        const existingTravelers = [...travelers];
                        if (
                          existingTravelers[index].extraOutwardLuggage.length
                        ) {
                          existingTravelers[index].extraOutwardLuggage.map(
                            (item1) => {
                              returnTrip.segs.map((item2) => {
                                if (
                                  item1.SegmentInfo ===
                                  item2.origin + item2.destination &&
                                  item1.baggageRefNo.split("&")[0] ===
                                  optionItem.baggageRefNo.split("&")[0]
                                ) {
                                  extraBaggageInfo.push(optionItem);
                                }
                              });
                            }
                          );
                        }
                      } else {
                        extraBaggageInfo.push(optionItem);
                      }
                    }
                  });
              } else {
                getResult.additionalServices
                  .filter(
                    (flt) =>
                      flt.cityPair == selItem?.label &&
                      (getResult.vendor == "TF" ||
                        flt.paxType == selItem?.paxType)
                  )
                  .map((item) => {
                    if (item.additionalServiceType === "Baggage") {
                      const optionItem = {
                        label:
                          item.serviceDescription +
                          " Total (" +
                          item.flightFares[0].amount.toFixed(2) +
                          " " +
                          item.currencyCode +
                          ")",
                        price: item.flightFares[0].amount.toFixed(2),
                        baggageRefNo: item.freeText,
                      };
                      extraBaggageInfo.push(optionItem);
                    }
                  });
              }
            }
          }
        }
      }
      if (selItem == null) {
        const existingTravelers = [...travelers];
        const filteredLugList = existingTravelers[
          index
        ].extraOutwardLuggage.filter((item) => item.SegmentInfo != bagSugment);
        existingTravelers[index].extraOutwardLuggage = filteredLugList;
        setTravelers(existingTravelers);
      } else if (selItem != null && bagSugment != "") {
        const existingTravelers = [...travelers];
        const filteredLugList = existingTravelers[
          index
        ].extraOutwardLuggage.filter((item) => item.SegmentInfo != bagSugment);
        existingTravelers[index].extraOutwardLuggage = filteredLugList;
        setTravelers(existingTravelers);
      }
      let updatedList = exBaggageInfo.map((item) => {
        if (item.index == index) {
          item.bagInfo[nestedIndx].nestedBag = extraBaggageInfo;
          return item; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      });
      setExBaggageInfo(updatedList);
      handleExtraBaggageChrgs();
    }
  };
  const removeDuplicates = (array) => {
    return array.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
  };
  const getExtraBaggageSelectedValue = (baglist, travelerselected, nidx) => {
    if (currentTicket.vendor === "AT") {
      let strspt;
      let selected;
      if (baglist.length > 0 && baglist[nidx] && baglist[nidx].baggageRefNo) {
        strspt = baglist[nidx].baggageRefNo.split("_")[1];
        selected = travelerselected.filter((item) =>
          String(item.baggageRefNo)?.includes(strspt)
        );

        if (
          selected.length > 1 &&
          selected[nidx] &&
          selected[nidx].baggageRefNo
        ) {
          return baglist.find(
            (i) => i.baggageRefNo === selected[nidx].baggageRefNo
          );
        } else {
          return baglist.find(
            (i) => i.baggageRefNo === selected[0].baggageRefNo
          );
        }
      } else if (
        baglist.length === 1 &&
        baglist[0] &&
        baglist[0].baggageRefNo
      ) {
        strspt = baglist[0].baggageRefNo.split("_")[1];
        selected = travelerselected.filter((item) =>
          String(item.baggageRefNo)?.includes(strspt)
        );

        if (
          selected.length > 1 &&
          selected[nidx] &&
          selected[nidx].baggageRefNo
        ) {
          return baglist.find(
            (i) => i.baggageRefNo == selected[nidx].baggageRefNo
          );
        } else {
          return baglist.find(
            (i) => i.baggageRefNo == selected[0].baggageRefNo
          );
        }
      }
      // console.log("baglist", baglist);
      return baglist;
    } else {
      // if (baglist.length > 0) {
      //   let strspt = baglist[0].baggageRefNo;
      //   // let strspt = baglist[0].baggageRefNo.split("_")[1];
      //   const selected = travelerselected.filter(
      //     (item) => String(item.baggageRefNo === strspt)
      //     // String(item.baggageRefNo)?.includes(strspt)
      //   );
      //   if (selected.length > 0) {
      //     return baglist.find(
      //       (i) => i.baggageRefNo === selected[0].baggageRefNo
      //     );
      //   }
      // }
      // return baglist;
      let strspt;
      let selected;
      if (baglist.length > 0 && baglist[nidx] && baglist[nidx].baggageRefNo) {
        strspt = baglist[nidx].baggageRefNo.split("_")[1];
        selected = travelerselected.filter((item) =>
          String(item.baggageRefNo)?.includes(strspt)
        );

        if (
          selected.length > 1 &&
          selected[nidx] &&
          selected[nidx].baggageRefNo
        ) {
          return baglist.find(
            (i) => i.baggageRefNo == selected[nidx].baggageRefNo
          );
        } else {
          if (selected[0]?.baggageRefNo) {
            return baglist?.find(
              (i) => i?.baggageRefNo == selected[0]?.baggageRefNo
            );
          } else {
            return [];
          }
        }
      } else if (
        baglist.length === 1 &&
        baglist[0] &&
        baglist[0].baggageRefNo
      ) {
        strspt = baglist[0].baggageRefNo.split("_")[1];
        selected = travelerselected.filter((item) =>
          String(item.baggageRefNo)?.includes(strspt)
        );

        if (
          selected.length > 1 &&
          selected[nidx] &&
          selected[nidx].baggageRefNo
        ) {
          return baglist?.find(
            (i) => i?.baggageRefNo == selected[nidx]?.baggageRefNo
          );
        } else {
          if (selected[0]?.baggageRefNo) {
            return baglist?.find(
              (i) => i?.baggageRefNo == selected[0]?.baggageRefNo
            );
          } else {
            return [];
          }
        }
      }
      return baglist;
    }
  };
  const getAdultPrice = () => {
    let basePrice = 0,
      taxPrice = 0,
      adultTotalPrice = 0;

    const ticketDetails = currentTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "ADT") {
          basePrice = fare.base;
          adultTotalPrice = adultTotalPrice + fare.base;
          fare.taxes.forEach((item) => {
            taxPrice = taxPrice + item.amt;
            adultTotalPrice = adultTotalPrice + item.amt;
          });
        }
      });
    }
    return { adultTotalPrice, basePrice, taxPrice };
  };

  const getChildrenPrice = () => {
    let basePrice = 0,
      taxPrice = 0,
      childrenTotalPrice = 0;
    const ticketDetails = currentTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "CHD") {
          basePrice = fare.base;
          childrenTotalPrice = childrenTotalPrice + fare.base;
          fare.taxes.forEach((item) => {
            taxPrice = taxPrice + item.amt;
            childrenTotalPrice = childrenTotalPrice + item.amt;
          });
        }
      });
    }
    return { childrenTotalPrice, basePrice, taxPrice };
  };

  const getInfantPrice = () => {
    let basePrice = 0,
      taxPrice = 0,
      infantTotalPrice = 0;
    const ticketDetails = currentTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "INF") {
          infantTotalPrice = infantTotalPrice + fare.base;
          basePrice = fare.base;
          fare.taxes.forEach((item) => {
            taxPrice = taxPrice + item.amt;
            infantTotalPrice = infantTotalPrice + item.amt;
          });
        }
      });
    }
    return { infantTotalPrice, basePrice, taxPrice };
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleBillingFieldChange = (value, field) => {
    const existingbilling = { ...billingInformation };
    existingbilling[field] = value;
    setBillingInformation(existingbilling);
  };

  const getCorporatePaxDetails = () => {
    const existingTravelers = [...travelers];
    const setCorporatePax = [];
    setCorporatePaxList([]);
    existingTravelers.forEach((item) => {
      if (
        item.fema === "Corporate" &&
        item.firstName !== "" &&
        item.lastName !== ""
      ) {
        let getPaxDetails =
          item.id +
          ": " +
          item.title +
          " " +
          item.firstName +
          " " +
          item.lastName;
        setCorporatePax.push(getPaxDetails);
      }
    });

    const chk_Total_Count = existingTravelers.filter(function (x) {
      return x.fema === "Corporate";
    }).length;
    if (chk_Total_Count > 0) {
      setiscorporatePaxMandate(true);
    } else {
      setiscorporatePaxMandate(false);
    }

    setCorporatePaxList(setCorporatePax);
  };

  const handleFieldChange = (value, field, index, nidex) => {
    const existingTravelers = [...travelers];
    if (field === "passportIssuedCountry" || field === "passengerNationality") {
      if (field === "passengerNationality") {
        if (value != null) {
          if (value.code != "IN") {
            existingTravelers[index]["fema"] = "";
            existingTravelers[index]["panCard"] = "";
            setisDeclarationMandate(false);
          }
        }
      }
      existingTravelers[index][field] = value != null ? value.code : "";
    } else if (field === "fema") {
      if (value === "Corporate") {
        existingTravelers[index]["panCard"] = "";
      }

      existingTravelers[index][field] = value;
    } else if (field === "extraOutwardMeal" || field === "extraReturnMeal") {
      if (value != null) {
        let seg = value.mealRefNo.split("_").pop();

        if (
          existingTravelers[index][field].findIndex(
            (item) => item.SegmentInfo === seg
          ) !== -1
        ) {
          let updatedList = existingTravelers[index][field].map((item) => {
            if (item.SegmentInfo == seg) {
              return {
                ...item,
                mealRefNo: value.mealRefNo,
                label: value.label,
                price: value.price,
              }; //gets everything that was already in item, and updates "done"
            }
            return item; // else return unmodified item
          });
          existingTravelers[index][field] = updatedList;
        } else {
          existingTravelers[index][field].push({
            mealRefNo: value.mealRefNo,
            SegmentInfo: seg,
            label: value.label,
            price: value.price,
          });
        }
      } else {
        const selSeg = document.getElementById(
          "extraSegmentOutwardMeal_select_" + index + "_" + nidex
        ).value;
        if (selSeg != "") {
          const res = existingTravelers[index][field].filter(
            (x) => x.SegmentInfo != selSeg
          );
          existingTravelers[index][field] = res;
        }
      }
      handleExtraMealChrgs();
    } else if (
      field === "extraOutwardLuggage" ||
      field === "extraReturnLuggage"
    ) {
      if (value != null) {
        let seg = value.baggageRefNo.split("_").pop();

        if (
          existingTravelers[index][field].findIndex(
            (item) => item.SegmentInfo === seg
          ) !== -1
        ) {
          let updatedList = existingTravelers[index][field].map((item) => {
            if (item.SegmentInfo == seg) {
              return {
                ...item,
                baggageRefNo: value.baggageRefNo,
                label: value.label,
                price: value.price,
              }; //gets everything that was already in item, and updates "done"
            }
            return item; // else return unmodified item
          });
          existingTravelers[index][field] = updatedList;
        } else {
          existingTravelers[index][field].push({
            baggageRefNo: value.baggageRefNo,
            SegmentInfo: seg,
            label: value.label,
            price: value.price,
          });
        }
      } else {
        const selSeg = document.getElementById(
          "extraSegmentOutwardLuggage_select_" + index + "_" + nidex
        ).value;
        if (selSeg != "") {
          const res = existingTravelers[index][field].filter(
            (x) => x.SegmentInfo != selSeg
          );
          existingTravelers[index][field] = res;
        }
      }
      // validateBaggage();
      handleExtraBaggageChrgs();
    } else if (field === "title") {
      if (value === "Mr" || value === "Mstr") {
        existingTravelers[index]["gender"] = "Male";
      } else if (value === "Mrs" || value === "Ms" || value === "Miss") {
        existingTravelers[index]["gender"] = "Female";
      } else if (value === "Mx") {
        existingTravelers[index]["gender"] = "Transgender";
      }
      existingTravelers[index]["title"] = value;
    } else {
      existingTravelers[index][field] = value;
    }

    setTravelers(existingTravelers);
  };

  const handlePassengerSubmit = () => {

    const existingTravelers = [...travelers];
    const chk_Total_Count = existingTravelers.filter(function (x) {
      return x.fema === "Corporate";
    }).length;
    if (chk_Total_Count > 0) {

      if (isDeclarationMandate) {
        passengerSubmit.current && passengerSubmit.current.submit();
      } else if (localStorage.getItem("isDeclarationMandate") === 'true' || localStorage.getItem("paymentAttempt") !== '0') {
        passengerSubmit.current && passengerSubmit.current.submit();
      }
    } else {
      passengerSubmit.current && passengerSubmit.current.submit();
    }
  };
  const specialFare = () => {
    logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, `User clicked Proceed for the popup, "WARNING *ELIGIBILITY CHECK*"`, '', '');
    handleClose();
    handleBookingSubmit();
  };
  const CheckSpecialFare = () => {
    if (revalState && revalState?.activeSearch && revalState?.activeSearch?.preference && revalState?.activeSearch?.preference?.value === "EthnicFare") {
      setOpen1(true);
    } else {
      handleBookingSubmit();
      handleSavePassenger();
    }
  };

  const handleBookingSubmit = () => {
    bookingSubmit.current && bookingSubmit.current.submit();
    if (isHold) {
      handleHoldDialogueOpen();
    } else {
      continueToPayment();
    }
  };
  useEffect(() => {
    if (paymentState.orderId && paymentState.traceId) {
      let tempState = JSON.parse(localStorage.getItem("paymentState"));
      if (tempState) {
        setbookingProcess((prev) => false);
        if (tempState?.paymentStatus !== "" && tempState.transactionId !== "" && tempState.rechargeRefNo === "") {
          if (tempState.paymentId && (tempState.paymentId).includes('T1') && tempState.attempt === 2 && localStorage.getItem("paymentAttempt") === '2') {
            sessionStorage.setItem("orderId", encryptData(tempState?.orderId));
            clearlocalStorage()
            history.replace('/flight/view-booked-tickets');
          }
          else if (tempState.transactionId && (tempState.transactionId).includes('T1') && tempState.attempt === 2 && localStorage.getItem("paymentAttempt") === '2') {
            if (tempState?.url && tempState?.provider === "WorldPay") {
              document.getElementById("paymentWorldForm").click();
            } else if (tempState?.accessCode && tempState?.encRequest && tempState?.url && tempState?.provider === "HDFC_CCAvenue") {
              document.getElementById("paymentForm").submit();
            }
          }
          else {
            setbookingProcess((prev) => false);
            setPaymentDialogOpen(true);
          }
        }
        else if (tempState?.paymentStatus !== ""
          && tempState?.pgRefNo !== "" && tempState?.paymentId !== "" && tempState?.rechargeRefNo !== "") {
          if (tempState?.paymentStatus === 2) {
            sessionStorage.setItem("orderId", encryptData(tempState?.orderId));
          }
          setbookingProcess((prev) => false);
          clearlocalStorage()
          history.replace('/flight/view-booked-tickets');
        }
        else if (tempState?.paymentStatus !== "" && tempState?.paymentId !== "" && tempState.attempt === 1) {
          if (tempState?.paymentStatus === 2) {
            sessionStorage.setItem("orderId", encryptData(tempState?.orderId));
          }
          else {
            setPaymentDialogOpen(true);
          }
          setbookingProcess((prev) => false);
        }
        else if (tempState?.provider === "HDFC_CCAvenue" && tempState?.paymentId === "" && tempState.url) {
          if (tempState?.accessCode && tempState?.encRequest && tempState?.url) {
            document.getElementById("paymentForm").submit();
          }
        } else if (tempState?.provider === "WorldPay" && tempState?.paymentId === "" && tempState.url) {
          if (tempState?.url) {
            document.getElementById("paymentWorldForm").click();
          }
        }
      }
    }
  }, [paymentState]);

  const continueToPayment = () => {
    let amount = (
      (revalState?.activeSearch?.children > 0
        ? revalState?.activeSearch?.children *
        getChildrenPrice().childrenTotalPrice
        : 0) +
      (revalState?.activeSearch?.adult > 0
        ? revalState?.activeSearch?.adult *
        getAdultPrice().adultTotalPrice
        : 0) +
      (revalState?.activeSearch?.infant > 0
        ? revalState?.activeSearch?.infant *
        getInfantPrice().infantTotalPrice
        : 0) +
      extraBaggageChrgs +
      extraMealChrgs +
      (travelProtection
        ? 1000 *
        (revalState?.activeSearch?.children +
          revalState?.activeSearch?.adult +
          revalState?.activeSearch?.infant)
        : 0)
      // + (paymentMethod === 'paymentGateway' ? (total * 0.02) : 0)
    ).toFixed(2)
    const totalAmount = (Number(amount) + (Number(amount) * 0.02)).toFixed(2)
    setButtonDisabled(true);

    setbookingProcess((prev) => true);
    let country = countries.find((el) => el.code === areaCode);
    let paymentType = paymentMethod === 'paymentGateway' ? "PG" : null;
    ;
    let PaymentGateWayRequest =
      paymentMethod === 'paymentGateway' ? {
        "ClientId": clientID,
        "Amount": amount,
        "PGCharge": paymentGatewayId === 2 ? (Number(amount) * 0.02).toFixed(2) : 0,
        "TotalAmount": paymentGatewayId === 2 ? totalAmount : amount,
        "Currency": currentClientCurrencyCode,
        "RechargeRefNo": (paymentMethod === 'paymentGateway' && paymentState?.transactionId) ? paymentState?.transactionId : null ///pass this field if second time trying payment in the case initial failure 
      } : null
    localStorage.setItem("isDeclarationMandate", isDeclarationMandate);
    localStorage.setItem("paymentState", JSON.stringify(paymentState));
    localStorage.setItem("travelers", JSON.stringify(travelers));
    flightBooking(
      revalState.traceId,
      revalState.clientId,
      paymentState.orderId ? paymentState.orderId : null,
      [revalState.purchaseId],
      travelers,
      billingInformation,
      email,
      country.phone,
      mobile,
      revalState.hold,
      passthroughObj,
      ptMarkup,
      paymentType,
      PaymentGateWayRequest,
      location.pathname,
      'Continue button to ticketing',
    )
      .then((data) => {
        setbookingProcess((prev) => false);
        sessionStorage.setItem("orderId", encryptData(data.orderId));
        localStorage.setItem("traceId", revalState.traceId);
        if (data.traceId && data.errors && data.errors.length > 0 && data.errors[0].errorCode === "Err100") {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          sessionStorage.setItem("orderId", encryptData(data.orderId));
          getGfsDetails(revalState.traceId)
            .then((gfsData) => {
              if (gfsData.orderRefId) {
                sessionStorage.setItem("orderId", encryptData(gfsData.orderRefId));
                history.replace('/flight/view-booked-tickets');
              }
              else {
                setOpenErrorMessageHTML(data.errors[0].errorDetail);
              
              }
            })
            .catch((gfsErr) => {
              setOpenErrorMessageHTML(data.errors[0].errorDetail);
             
            })
          setOpenErrorMessageDialog1(true);
        }
        else if (paymentMethod === 'paymentGateway' && data?.pgDetails?.provider === "HDFC_CCAvenue" && data?.pgDetails?.encRequest && data?.pgDetails?.access_code) {
          let temp = structuredClone(paymentState);
          temp.encRequest = data?.pgDetails?.encRequest;
          temp.accessCode = data?.pgDetails?.access_code;
          temp.url = data?.pgDetails?.paymentUrl;
          temp.provider = data?.pgDetails?.provider;
          temp.transactionId = data?.pgDetails?.transactionId;
          temp.orderId = data?.orderId;
          temp.traceId = data?.traceId;
          temp.attempt = data?.pgDetails?.attempt;
          localStorage.setItem("paymentState", JSON.stringify(temp));
          let tempAttempt = localStorage.getItem("paymentAttempt");
          if (!tempAttempt) {
            localStorage.setItem("paymentAttempt", "1");
          } else if (tempAttempt === '1') {
            localStorage.setItem("paymentAttempt", "2");
          }
          setPaymentState((prev) => {
            return {
              ...prev,
              encRequest: data?.pgDetails?.encRequest,
              accessCode: data?.pgDetails?.access_code,
              url: data?.pgDetails?.paymentUrl,
              provider: data?.pgDetails?.provider,
              transactionId: data?.pgDetails?.transactionId,
              orderId: data?.orderId,
              traceId: data?.traceId,
              attempt: data?.pgDetails?.attempt
            };
          });
        } else if (paymentMethod === 'paymentGateway' && data?.pgDetails?.provider === "WorldPay" && data?.pgDetails?.transactionId && data?.pgDetails?.paymentUrl) {
          let temp = structuredClone(paymentState);
          temp.encRequest = data?.pgDetails?.encRequest;
          temp.accessCode = data?.pgDetails?.access_code;
          temp.url = data?.pgDetails?.paymentUrl;
          temp.provider = data?.pgDetails?.provider;
          temp.transactionId = data?.pgDetails?.transactionId;
          temp.amount = data?.pgDetails?.amount;
          temp.orderId = data?.orderId;
          temp.traceId = data?.traceId;
          temp.attempt = data?.pgDetails?.attempt;
          localStorage.setItem("paymentState", JSON.stringify(temp));
          let tempAttempt = localStorage.getItem("paymentAttempt");
          if (!tempAttempt) {
            localStorage.setItem("paymentAttempt", "1");
          } else if (tempAttempt === '1') {
            localStorage.setItem("paymentAttempt", "2");
          }
          setPaymentState((prev) => {
            return {
              ...prev,
              encRequest: data?.pgDetails?.encRequest,
              accessCode: data?.pgDetails?.access_code,
              url: data?.pgDetails?.paymentUrl,
              provider: data?.pgDetails?.provider,
              transactionId: data?.pgDetails?.transactionId,
              amount: data?.pgDetails?.amount,
              orderId: data?.orderId,
              traceId: data?.traceId,
              attempt: data?.pgDetails?.attempt
            };
          });
        }
        else if (data.orderId && data.traceId && !data.errors) {
          sessionStorage.setItem("orderId", encryptData(data.orderId));
          clearlocalStorage()
          history.replace('/flight/view-booked-tickets');
        }
        else if (!data.orderId && (data.status === 403 || data.status === 400)) {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          getGfsDetails(revalState.traceId)
            .then((gfsData) => {
              if (gfsData.orderRefId) {
                sessionStorage.setItem("orderId", encryptData(gfsData.orderRefId));
                history.replace('/flight/view-booked-tickets');
              }
              else {
                setOpenErrorMessageHTML(data.detail);
              
                setOpenErrorMessageDialog(true);
              }
            })
            .catch((gfsErr) => {
              setOpenErrorMessageHTML(data.detail);

              setOpenErrorMessageDialog(true);
            })
          setClosePopup(true);
          setButtonDisabled(false);
        }
        else if (!data.orderId && (data.status === 504 || data.status === 503 || data.status === 502)) {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          getGfsDetails(revalState.traceId)
            .then((gfsData) => {
              if (gfsData.orderRefId) {
                sessionStorage.setItem("orderId", encryptData(gfsData.orderRefId));
                history.replace('/flight/view-booked-tickets');
              }
              else {
                setOpenErrorMessageHTML("The booking may or may not have been created. Please check the My Booking queue or contact Customer Care.");
              
                setOpenErrorMessageDialog1(true);
              }
            })
            .catch((gfsErr) => {
              setOpenErrorMessageHTML("The booking may or may not have been created. Please check the My Booking queue or contact Customer Care.");
              setOpenErrorMessageDialog1(true);
            })
          setErrorTimeout(true);
          setButtonDisabled(false);
        }
        else if (!data.orderId) {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          getGfsDetails(revalState.traceId)
            .then((gfsData) => {
              if (gfsData.orderRefId) {
                sessionStorage.setItem("orderId", encryptData(gfsData.orderRefId));
                history.replace('/flight/view-booked-tickets');
              }
              else {
              
                setOpenErrorMessageDialog1(true);
              }
            })
            .catch((gfsErr) => {
            
              setOpenErrorMessageDialog1(true);
            })
        }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setbookingProcess((prev) => false);
        if (err.traceId && err.Errors && err.Errors.length && err.Errors[0].ErrorCode === "Err100") {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          setOpenErrorMessageHTML(err.Errors[0].errorDetail);
          setOpenErrorMessageDialog(true);
          console.log(err);
        }
        else if (err.status === 504 || err.status === 503 || err.status === 502) {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          setOpenErrorMessageHTML("The booking may or may not have been created. Please check the My Booking queue or contact Customer Care.");
          setOpenErrorMessageDialog1(true);
          setErrorTimeout(true);
        }
        else {
          logFrontEndData(revalState.traceId, revalState.clientId, location.pathname, 'Continue to booking', '', '');
          setOpenErrorMessageHTML('An Error Has Occurred');
          setOpenErrorMessageDialog(true);
          console.log(err);
        }

      });
  };
  const getPaymentResponse = async (transactionId) => {
    await getTransactionDetails(transactionId).then((response) => {
      let tempState = JSON.parse(localStorage.getItem("paymentState"))
      let temp = structuredClone(paymentState);
      temp.paymentId = response.paymentId
      temp.transactionId = response.paymentId
      temp.paymentStatus = response.paymentStatus
      temp.rechargeRefNo = response.rechargeRefNo
      temp.pgRefNo = response.pgRefNo
      temp.amount = response.amount ? response.amount : 0
      temp.currency = response.currency ? response.currency : ""
      temp.failureMessage = response.failureMessage ? response.failureMessage : ""
      temp.orderId = tempState.orderId
      temp.traceId = tempState.traceId
      temp.attempt = tempState.attempt
      setData();
      setPaymentState((prev) => {
        return {
          ...prev,
          transactionId: response.paymentId,
          paymentId: response.paymentId,
          paymentStatus: response.paymentStatus,
          rechargeRefNo: response.rechargeRefNo,
          pgRefNo: response.pgRefNo,
          amount: response.amount ? response.amount : 0,
          currency: response.currency ? response.currency : "",
          failureMessage: response.failureMessage ? response.failureMessage : "",
          orderId: tempState.orderId,
          traceId: tempState.traceId,
          attempt: tempState.attempt
        };
      })
      localStorage.setItem("paymentState", JSON.stringify(temp))
    })
  }
  useEffect(() => {

    if (currentTicket) {
      setClientMarkup(currentTicket && currentTicket.fareGroups[0] && currentTicket.fareGroups[0].clientMarkup);
      setGrossfare(currentTicket && currentTicket.fareGroups[0] && currentTicket.fareGroups[0].grossFare);
      setNetFare(currentTicket && currentTicket.fareGroups[0] && currentTicket.fareGroups[0].netFare);
    }
  }, [currentTicket])

  const backToSearchPage = () => {
    let temp = localStorage.getItem("isReQuote");
    if (temp === "true") {
      clearlocalStorage();
      sessionStorage.removeItem("orderId");
      history.replace(`${window.location.origin}/flight`);
    } else {
      clearlocalStorage()
      sessionStorage.removeItem("orderId");
      history.replace({
        pathname: "/flight/search-results-new",
        state: {
          searchedResults: revalState.searchResults,
          search: revalState.activeSearch
        },
      });
    }

  }





  // Get Baggage inforamtion function
  const getBaggageInfo = (baggages, origin, destination) => {
    let setBaggageInfoDtls = [];
    let setBaggInfo = [];
    const joinedPair = origin + destination;
    if (baggages !== undefined) {
      const cityPairs = baggages
        .filter((item) => {
          return item.cityPair === joinedPair;
        })
        .sort(function (a, b) {
          if (a.paxType < b.paxType) {
            return -1;
          }
          if (a.paxType > b.paxType) {
            return 1;
          }
          return 0;
        });
      //console.log(cityPairs)
      cityPairs.forEach((item, index) => {
        setBaggageInfoDtls[index] = {
          paxType: item.paxType,
          checkInBag: item.checkInBag ? item.checkInBag : "-",
          cabinBag: "-",
        };
      });

      cityPairs.forEach((itemCabin, inx) => {
        if (itemCabin.hasOwnProperty("cabinBag")) {
          setBaggageInfoDtls[inx].cabinBag = itemCabin.cabinBag
            ? itemCabin.cabinBag
            : "-";
        } else {
          setBaggageInfoDtls[inx].cabinBag = "-";
        }
      });
    }
    //console.log(setBaggageInfoDtls)
    setBaggageInfoDtls.forEach((key, index) => {
      if (key.checkInBag !== "" || key.cabinBag !== "") {
        let setValue = {
          paxType: key.paxType,
          checkInBag: key.checkInBag ? key.checkInBag : "-",
          cabinBag: key.cabinBag ? key.cabinBag : "-",
        };
        setBaggInfo.push(setValue);
      }
    });

    const removeDuplicateInfo = [
      ...new Map(
        setBaggInfo.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
    //console.log(setBaggInfo)

    return { removeDuplicateInfo };
  };
  const processHold = () => {
    continueToPayment();
  };

  const handleHoldDialogueOpen = () => {
    setHoldDialogueOpen(true);
  };

  const handleHoldDialogClose = () => {
    setHoldDialogueOpen(false);
  };

  async function handleSavePassenger() {

    const phoneCode = countries.filter((country) => country.code === areaCode);

    const headers = {
      "Content-Type": "application/json",
    };

    if (savePassengerCheck) {

      const airlineData = airlineDetails.filter((airlines) => airlines.Code === airline);


      const travelersSave = travelers.filter((traveler) => {

        return !passengerData.some((data) => data.passengerProfile.id === traveler.id);
      });



      travelersSave.map(async (traveler) => {
        try {
          const res = await axios.post(
            process.env.REACT_APP_CORE_URL + "api/Configuration/PassengerProfile",
            JSON.stringify({
              isActive: true,
              clientId: parseInt(clientID),
              title: traveler.title,
              firstName: traveler.firstName,
              lastName: traveler.lastName,
              dob: traveler.dob,
              genderType: traveler.gender === "Male" ? "M" : "F",
              frequentFlyer: [{
                airline: airlineData[0].Name,
                frequentFlyerNumber: traveler.frequentFlyer
              }],
              areaCode: phoneCode[0].phone,
              mobile: mobile,
              telePhone: "",
              email: email,
              country: "",
              state: "",
              city: "",
              pinCode: "",
              passportNumber: traveler.passportNumber,
              passengerNationality: traveler.passengerNationality,
              passportDOI: traveler.passportDOI,
              passportDOE: traveler.passportDOE,
              passportIssuedCountry: traveler.passportIssuedCountry,
            }),
            {
              headers,
            }
          );
        } catch (err) {
          console.log('Error saving traveler:', err);
        }
      })

    }
  }


  useEffect(() => {
    if (Number(clientID) > 0) {
      getPassengerData();
    }
  }, [clientID])


  const getPassengerData = async () => {

    setIsLoading(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/PassengerProfile?filters=clientId==" +
        clientID
      )
      .then((response) => {
        setPassengerData(response.data)
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };



  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };


  const getPassengerType = (dob) => {

    const age = calculateAge(dob);

    if (age >= 13) {
      return 'ADT';
    } else if (age >= 2 && age <= 12) {
      return 'CHD';
    } else {
      return 'INF';
    }
  };


  const filterPassengers = (passenger) => {
    const { adult, children, infant } = revalState;
    const passengerType = getPassengerType(passenger.passengerProfile.dob);

    if (adult > 0 && children > 0 && infant > 0) {
      return true;
    }

    if (adult > 0 && passengerType === 'ADT') {
      return true;
    }

    if (children > 0 && passengerType === 'CHD') {
      return true;
    }

    if (infant > 0 && passengerType === 'INF') {
      return true;
    }

    return false;
  };


  const handlePassengerSelection = (event, newValue, action) => {

    setSelectedPassengers(newValue);


    if (action == "add" && newValue.length > 0) {
      newValue.forEach(selectedPassenger => {
        const existingTraveler = travelers.find(traveler =>
          (traveler.firstName === selectedPassenger.passengerProfile.firstName) &&
          (traveler.lastName === selectedPassenger.passengerProfile.lastName)
        );
        const airlineData = airlineDetails.filter((airlines) => airlines.Code === airline);

        const indexToUpdate = travelers.findIndex(
          (traveler) => traveler.paxType === getPassengerType(selectedPassenger.passengerProfile.dob) && traveler.firstName === ''
        );


        if (indexToUpdate !== -1 && !existingTraveler) {
          const updatedTravelers = [...travelers];
          const travelerToUpdate = updatedTravelers[indexToUpdate];

          travelerToUpdate.id = selectedPassenger.passengerProfile.id;
          travelerToUpdate.title = selectedPassenger.passengerProfile.title;
          travelerToUpdate.firstName = selectedPassenger.passengerProfile.firstName;
          travelerToUpdate.lastName = selectedPassenger.passengerProfile.lastName;
          travelerToUpdate.dob = moment(selectedPassenger.passengerProfile.dob).format("YYYY-MM-DD");
          travelerToUpdate.gender = selectedPassenger.passengerProfile.genderType === "M" ? "Male" : "Female";
          travelerToUpdate.frequentFlyer = airlineData[0].Name === selectedPassenger.passengerProfile.frequentFlyer[0]?.airline ? selectedPassenger.passengerProfile.frequentFlyer[0]?.frequentFlyerNumber : "" || "";
          travelerToUpdate.passportNumber = selectedPassenger.passengerProfile.passportNumber;
          travelerToUpdate.passengerNationality = selectedPassenger.passengerProfile.passengerNationality;
          travelerToUpdate.passportDOI = moment(selectedPassenger.passengerProfile.passportDOI).format("YYYY-MM-DD");
          travelerToUpdate.passportDOE = moment(selectedPassenger.passengerProfile.passportDOE).format("YYYY-MM-DD");
          travelerToUpdate.passportIssuedCountry = selectedPassenger.passengerProfile.passportIssuedCountry;
          setTravelers(updatedTravelers);
        }
      });
    } else if (action === "del") {
      const filteredPassengers = selectedPassengers.filter(
        (passenger) => passenger !== newValue
      );
      setSelectedPassengers(filteredPassengers);

      const updatedTravelers = travelers.map((traveler) => {
        if (traveler.id === newValue.passengerProfile.id) {

          return {
            ...traveler,
            id: "",
            title: "",
            firstName: "",
            lastName: "",
            dob: "",
            gender: "",
            frequentFlyer: "",
            passportNumber: "",
            passengerNationality: "",
            passportDOI: "",
            passportDOE: "",
            passportIssuedCountry: ""
          };
        }
        return traveler;
      });

      setTravelers(updatedTravelers);

    }
    else {
      const updatedTravelers = travelers.map(traveler => ({
        ...traveler,
        id: "",
        title: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        frequentFlyer: "",
        passportNumber: "",
        passengerNationality: "",
        passportDOI: "",
        passportDOE: "",
        passportIssuedCountry: ""
      }));
      setTravelers(updatedTravelers);
    }

  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  useEffect(() => {
    if (paymentMethod === "card") {
      const tempObj = {
        mode: "PassThrough",
        number: cardNumber,
        expiry: `${expiryMonth}/${expiryYear.slice(-2)}`,
      };

      if (cvv) {
        tempObj.verification = cvv;
      }

      if (nameOnCard) {
        tempObj.name = nameOnCard;
      }

      setPassthroughObj(tempObj);
    } else {
      setPassthroughObj({});
    }
  }, [cardNumber, expiryMonth, expiryYear, cvv, nameOnCard]);


  const totalBaseFare = (getAdultPrice().basePrice * (currentTicket && currentTicket.adtNum)) + (getChildrenPrice().basePrice * (currentTicket && currentTicket.chdNum)) + (getInfantPrice().basePrice * (currentTicket && currentTicket.infNum));
  const maxAllowedMarkup = totalBaseFare * 0.21;

  const handlePtMarkupChange = (value) => {
    const parsedValue = value !== "" ? parseFloat(value) : 0;
    setPtMarkup(value);
    if (!isNaN(parsedValue) && parsedValue <= maxAllowedMarkup) {
      setPtError('');
    } else {
      setPtError(`Markup should not exceed ${maxAllowedMarkup.toFixed(2)}`);
    }
  };

  const handleRetryPayment = () => {
    setActiveStep((prev) => 0);
    setPaymentDialogOpen((prev) => false);
  }
  // useEffect(() => {
  //   let total = (revalState.activeSearch.children > 0
  //     ? revalState.activeSearch.children *
  //     getChildrenPrice().childrenTotalPrice
  //     : 0) +
  //     (revalState.activeSearch.adult > 0
  //       ? revalState.activeSearch.adult *
  //       getAdultPrice().adultTotalPrice
  //       : 0) +
  //     (revalState.activeSearch.infant > 0
  //       ? revalState.activeSearch.infant *
  //       getInfantPrice().infantTotalPrice
  //       : 0) +
  //     extraBaggageChrgs +
  //     extraMealChrgs +
  //     (travelProtection
  //       ? 1000 *
  //       (revalState.activeSearch.children +
  //         revalState.activeSearch.adult +
  //         revalState.activeSearch.infant)
  //       : 0)
  // },[revalState,extraBaggageChrgs,extraMealChrgs,travelProtection])
  const total = revalState && (
    (revalState?.activeSearch?.children > 0
      ? revalState?.activeSearch?.children *
      getChildrenPrice().childrenTotalPrice
      : 0) +
    (revalState?.activeSearch?.adult > 0
      ? revalState?.activeSearch?.adult *
      getAdultPrice().adultTotalPrice
      : 0) +
    (revalState?.activeSearch?.infant > 0
      ? revalState?.activeSearch?.infant *
      getInfantPrice().infantTotalPrice
      : 0) +
    extraBaggageChrgs +
    extraMealChrgs +
    (travelProtection
      ? 1000 *
      (revalState?.activeSearch?.children +
        revalState?.activeSearch?.adult +
        revalState?.activeSearch?.infant)
      : 0)
  )
  const fee = total > 0 ? (
    <span className="walletTopupAmountText" style={{ textTransform: "capitalize" }}>
      (
      <CurrencyCodeComponent
        style={{ float: "none" }} currencyCode={currentClientCurrencyCode} />
      {(total * 0.02).toFixed(2)}
      )
    </span>
  ) : '';
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isValidNameLengthTrue}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="error">
          {currentTicket && currentTicket.vendor === '1A' ?
            "The total name length must be 49 characters. If an infant is included, their name should also fit within the 49-character limit."
            : "The total name length must be 57 characters. If an infant is included, their name should also fit within the 57-character limit."}
        </Alert>
      </Snackbar>

      <div className="view-booking-page-container">
        <Dialog
          open={paymentDialogOpen}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <h3
              className={PaymentStatus[paymentState.paymentStatus]}
              style={{ textAlign: "center" }}
            >
              {" "}
              Payment {PaymentStatus[paymentState.paymentStatus]}{" "}
            </h3>
          </DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            <p style={{ color: "#000" }} id="alert-dialog-description">
              your Payment {PaymentStatus[paymentState.paymentStatus]} for amount{" "}
              <span className="walletTopupAmountText">
                <strong>
                  <CurrencyCodeComponent
                    style={{ float: "none" }}
                    currencyCode={paymentState.currency}
                  />{" "}
                  {paymentState.amount}
                </strong>
              </span>
            </p>
            <p style={{ color: "#000" }} id="alert-dialog-description">
              Order Reference: <strong>{paymentState.orderId}</strong>
              <br />
              TransactionID: <strong>{paymentState.paymentId || paymentState.transactionId}</strong>
              <br />
              {PaymentStatus[paymentState.paymentStatus] === "Success" && paymentState.orderId ? (
                <>
                  Order ID: <strong>{paymentState.orderId}</strong>
                </>
              ) : (
                <>
                  Fail reason: <strong>{paymentState.failureMessage}</strong>
                </>
              )}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRetryPayment}
              autoFocus
              size="large"
              style={{ textTransform: "capitalize", color: "#9f155a", border: "1px solid #9f155a", background: "#fff", fontWeight: "bold", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              Retry
            </Button>
            {/* } */}

          </DialogActions>
        </Dialog>
        <a
          style={{ display: "none" }}
          rel="noreferrer"
          href={paymentState?.url}
          id="paymentWorldForm"
        >
          IBoss Invoice
        </a>
        <form id="paymentForm" action={paymentState?.url} method="post">
          <input type="hidden" name="encRequest" value={paymentState?.encRequest} />
          <input
            type="hidden"
            name="access_code"
            value={paymentState?.accessCode}
          />
        </form>
        <>
          {countDownText !== "Expired" && showCountdown && (
            <div className="timerFAB">
              <span className="sessionText">Complete Booking in </span>
              <span className={" sessionTimer" + fontColor}>{countDownText}</span>
            </div>
          )}
        </>
        <Drawer
          anchor="right"
          open={drawer}
          onClose={toggleDrawer(false)}
          BackdropProps={{ invisible: true }}
          variant="persistent"
          className="drawer-width"
          transitionDuration={{ enter: 500, exit: 500 }}
        >
          <div className="drawer-min-width-40 detailedFareDiv">
            {drawer && (
              <DetailedFare
                open={drawer}
                currency={currentTicket.currency}
                travellers={travelers}
                toggleDrawer={toggleDrawer}
              />
            )}
          </div>
        </Drawer>
        <HoldRulesDrawer
          processHold={processHold}
          open={holdDialogueOpen}
          close={handleHoldDialogClose}
          holdCharge={holdCharge}
          isHoldChargeRefundable={isHoldChargeRefundable}
          currencyCode={currentClientCurrencyCode}
          total={`${(
            (revalState?.activeSearch?.children > 0
              ? revalState?.activeSearch?.children *
              getChildrenPrice().childrenTotalPrice
              : 0) +
            (revalState?.activeSearch?.adult > 0
              ? revalState?.activeSearch?.adult *
              getAdultPrice().adultTotalPrice
              : 0) +
            (revalState?.activeSearch?.infant > 0
              ? revalState?.activeSearch?.infant *
              getInfantPrice().infantTotalPrice
              : 0) +
            extraBaggageChrgs +
            extraMealChrgs +
            (travelProtection
              ? 1000 *
              (revalState?.activeSearch?.children +
                revalState?.activeSearch?.adult +
                revalState?.activeSearch?.infant)
              : 0)
          ).toFixed(2)}`}
        />
        <Dialog
          open={fareChangeDialog}
          classes={{ paper: classes.dialog }}
          TransitionComponent={Transition}
        >
          <DialogContent className="textCenter">
            <Typography variant="body2">The Fare has been Updated</Typography>
            <Typography variant="body2">{`Would you like to continue ?`}</Typography>
          </DialogContent>
          <DialogActions className="sessionActionButtons">
            <Button
              onClick={goBack}
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "red",
                borderRadius: "10px",
              }}
              className={classes.button}
            >
              Go Back
            </Button>
            <Button
              onClick={closeFareChangeDialog}
              style={{
                color: "#fff",
                backgroundColor: "green",
                borderRadius: "10px",
              }}
              variant="contained"
              className={classes.button}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={expiredDialogOpen}
          classes={{ paper: classes.dialog }}
          TransitionComponent={Transition}
        >
          <DialogContent className="textCenter">
            <Typography variant="body2">The current session has</Typography>
            <Typography variant="body2">
              <span className={"sessionTimer" + fontColor}>{countDownText}</span>
            </Typography>
            <Typography variant="body2">{`Would you like to go back to search ?`}</Typography>
          </DialogContent>
          <DialogActions className="sessionActionButtons">
            <Button
              onClick={goBack}
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "red",
                borderRadius: "10px",
              }}
              className={classes.button}
            >
              Go Back
            </Button>
          </DialogActions>
        </Dialog>

        <div className="view-booking-header-container">
          <div className="header-page-title-container">
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <div className="header-page-title-items">
                  {currentTicket && Object.keys(currentTicket).length > 0 ? (
                    ""
                  ) : (
                    <div
                      className="header-page-icon pointer"
                      onClick={() => {
                        sessionStorage.removeItem("orderId");
                        clearlocalStorage();
                        history.replace(`/flight/search-results-new`)
                      }}
                    >
                      <ArrowBackRoundedIcon
                        className="header-icon-fill"
                        fontSize="small"
                      ></ArrowBackRoundedIcon>
                    </div>
                  )}

                  <div className="header-page-title-text">Review Booking</div>
                  <div className="flex">
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </div>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </div>
        </div>
        <div className="view-booking-details-container">
          <div className="container">
            {currentTicket && Object.keys(currentTicket).length > 0 && (
              <>
                {currentTicket && currentTicket ? getExtraSegmentMealInfo() : ""}
                {currentTicket && currentTicket
                  ? getExtraSegmentBaggageInfo()
                  : ""}
                {
                  currentTicket && paymentState.orderId && (paymentState.paymentId?.includes("-T1") || paymentState.paymentId?.includes("-T2") || paymentState.failureMessage || paymentState.paymentStatus === 17 || paymentState.paymentStatus === 3) ?

                    <Row style={{ marginTop: "20px", backgroundColor: "white", padding: "10px", fontSize: "16px", borderRadius: "10px" }}>
                      <section className="col-9">
                        <p className="textTransCap">Sorry!! We could not Process Your Transaction.
                          <br />
                          We Encountered a Problem while Connecting With the blank.
                          <br />
                          We suggest to use any other Payment Option to Quickly Complete the Booking.
                          <br />
                          Please Try Making The Payment Once Again.
                        </p>
                      </section>
                      <section className="col-3  px-3">
                        <p><b>Booking Reference</b></p>
                        <p style={{ color: "red", fontWeight: "bolder", fontSize: "22px" }}>{paymentState.orderId}</p>
                      </section>
                    </Row>

                    : ""}
                <Row>
                  <section className="col-9">
                    {
                      (paymentState.attempt !== 1 && paymentState.attempt !== 2 && localStorage.getItem("paymentAttempt") !== '1' && localStorage.getItem("paymentAttempt") !== '2') ?
                        <span
                          onClick={() => backToSearchPage()}
                          className="backToSearch"
                        >
                          <ArrowBackRoundedIcon fontSize="small"></ArrowBackRoundedIcon>{" "}
                          Back To Search
                          <Link variant="body2" underline="always"></Link>
                        </span>
                        : ''
                    }
                    <div className="flt-bkg-dtls-section-review my-2">
                      <div className="flt-bkg-heading px-2">
                        Review Your Itinerary
                      </div>
                      <section className="col-12 flt-bkg-itinerary-section-review px-0">
                        <Tickets
                          result={currentTicket}
                          fareGroups={currentTicket.fareGroups[0]}
                          segGroups={currentTicket.segGroups}
                        ></Tickets>
                        {currentTicket && currentTicket.vendor === "ER" && <span className="mt-1 text-danger">Group Fares</span>}
                      </section>
                    </div>
                    {(currentTicket.fareGroups.length &&
                      currentTicket.fareGroups[0].baggages &&
                      currentTicket.fareGroups[0].baggages.length > 0) ||
                      (currentTicket.fareGroups.length &&
                        currentTicket.fareGroups[0].miniRules &&
                        currentTicket.fareGroups[0].miniRules.length) ? (
                      <div className="flt-bkg-dtls-section-review my-2">
                        {currentTicket &&
                          Object.keys(currentTicket).length > 0 && (
                            <>
                              {currentTicket && currentTicket
                                ? getExtraSegmentMealInfo()
                                : ""}
                              {currentTicket && currentTicket
                                ? getExtraSegmentBaggageInfo()
                                : ""}
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  value={value}
                                  textColor="primary"
                                  indicatorColor="primary"
                                  onChange={handleChange}
                                  variant="fullWidth"
                                >
                                  {currentTicket.fareGroups.length &&
                                    currentTicket.fareGroups[0]
                                      .pricedDescription &&
                                    currentTicket.fareGroups[0].pricedDescription
                                      .length > 0 && (
                                      <Tab
                                        className="review-itenary-container-header-details"
                                        value="Brand Description"
                                        label="Brand Description "
                                      />
                                    )}
                                  {currentTicket.vendor !== "G9" &&
                                    currentTicket.fareGroups.length &&
                                    currentTicket.fareGroups[0].baggages &&
                                    currentTicket.fareGroups[0].baggages.length >
                                    0 && (
                                      <Tab
                                        className="review-itenary-container-header-details"
                                        value="Baggage Information"
                                        label="Baggage "
                                      />
                                    )}
                                  {currentTicket.fareGroups.length &&
                                    currentTicket.fareGroups[0].miniRules &&
                                    currentTicket.fareGroups[0].miniRules
                                      .length && (
                                      <Tab
                                        className="review-itenary-container-header-details"
                                        value="Cancellation"
                                        label="Cancellation"
                                      />
                                    )}
                                  {currentTicket.fareGroups.length &&
                                    currentTicket.fareGroups[0].miniRules &&
                                    currentTicket.fareGroups[0].miniRules
                                      .length && (
                                      <Tab
                                        className="review-itenary-container-header-details"
                                        value="Date Change"
                                        label="Date Change"
                                      />
                                    )}
                                </Tabs>
                              </Box>
                            </>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                    {value === "Brand Description" &&
                      currentTicket.fareGroups[0].pricedDescription &&
                      currentTicket.fareGroups[0].pricedDescription.length > 0 ? (
                      <BrandedSingleFareTable result={currentTicket} vendor={currentTicket.vendor} />
                    ) : (
                      ""
                    )}
                    {value === "Baggage Information" ? (
                      <>
                        {" "}
                        {currentTicket.segGroups &&
                          currentTicket.segGroups.length > 0
                          ? currentTicket.segGroups.map(
                            (segGroup, segGroupIndex) =>
                              segGroup.segs.map((item, index) => (
                                <>
                                  {getBaggageInfo(
                                    currentTicket.fareGroups[0].baggages,
                                    item.origin,
                                    item.destination
                                  ).removeDuplicateInfo.length > 0 ? (
                                    <div className="baggageDisplay">
                                      <TableContainer
                                        className="baggageTableContainer"
                                        component={Paper}
                                      >
                                        <Table className="fareDetailsTable mb-3">
                                          <TableHead>
                                            <TableRow className="opacity1 color0 backddd47">
                                              <TableCell
                                                className="text-start"
                                                colSpan={3}
                                              >
                                                {item.origin} -{" "}
                                                {item.destination}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="opacity1 color0 backddd82">
                                              <TableCell
                                                align="center"
                                                className="text-start"
                                              >
                                                Baggage
                                              </TableCell>
                                              <TableCell align="center">
                                                Check In
                                              </TableCell>
                                              <TableCell align="center">
                                                Cabin
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {getBaggageInfo(
                                              currentTicket.fareGroups[0]
                                                .baggages,
                                              item.origin,
                                              item.destination
                                            ).removeDuplicateInfo.map(
                                              (listItem, index) => (
                                                <TableRow>
                                                  <TableCell
                                                    width={150}
                                                    align="center"
                                                    className="text-start"
                                                  >
                                                    {listItem.paxType}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    width={400}
                                                  >
                                                    {listItem.checkInBag}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    width={400}
                                                  >
                                                    {/* {currentTicket.vendor ===
                                                      "1A" ||
                                                    currentTicket.vendor ===
                                                      "1S" ||
                                                    currentTicket.vendor ===
                                                      "1G"
                                                      ? "7KG"
                                                      : listItem.cabinBag} */}
                                                    {listItem.cabinBag}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))
                          )
                          : ""}{" "}
                      </>
                    ) : (
                      ""
                    )}

                    <MiniRulesComponent
                      value={value}
                      refundable={currentTicket.fareGroups[0].refundable}
                      miniRules={currentTicket.fareGroups.length &&
                        currentTicket.fareGroups[0].miniRules &&
                        currentTicket.fareGroups[0].miniRules}
                      currentTicket={currentTicket} currencyCode={currentClientCurrencyCode}
                      vendor={currentTicket.vendor}
                      selectedItinerary={0}
                      traceId={revalState.traceId} clientId={revalState.clientId} additionalClientmarkup={revalState.additionalClientmarkup}
                      flag={"reviewbooking"} adtCount={currentTicket.adtNum} chdCount={currentTicket.chdNum} infCount={currentTicket.infNum}
                      purchaseId={revalState.purchaseId}
                    />

                    <div className="flt-bkg-dtls-section-review my-4">
                      <div className="flt-bkg-heading px-2 py-2">
                        Traveler Information
                      </div>
                      <div className="view-booking-tickets-container">
                        <div className="view-booking-tickets-container-info">
                          Enter traveler name(s) and date(s) of birth exactly as
                          shown on the passport or other government-issued photo
                          ID to be used on this trip.
                        </div>


                        {activeStep !== 1 && (
                          <ValidatorForm
                            ref={emailSubmit}
                            onSubmit={() => handlePassengerSubmit()}
                            onError={(errors) => console.log(errors)}
                          >
                            <div className="passenger-detail-field-container">
                              <div className="passenger-fields">
                                <div className="mr-8 col-4">
                                  {activeStep !== 1 && (
                                    <Autocomplete
                                      id="areaCode select"
                                      value={
                                        areaCode &&
                                        countries.find(
                                          (el) => el.code === areaCode
                                        )
                                      }
                                      disabled={paymentState.attempt > 0 ? true : false}
                                      onBlur={(event) => getCorporatePaxDetails()}
                                      onChange={(event, value) => {
                                        value && setAreaCode(value.code);
                                      }}
                                      options={countries.filter(
                                        (item) => item.code !== "All"
                                      )}
                                      getOptionLabel={(option) =>
                                        option == ""
                                          ? ""
                                          : option.label +
                                          " (" +
                                          option.phone +
                                          ")"
                                      }
                                      componentsProps={{
                                        paper: {
                                          sx: {
                                            fontSize: 14,
                                          },
                                        },
                                      }}
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          required
                                          label="Country Code"
                                          variant="outlined"
                                          size="small"
                                          color="secondary"
                                          value={
                                            areaCode &&
                                            countries.find(
                                              (el) => el.code === areaCode
                                            )
                                          }
                                          validators={["required"]}
                                          errorMessages={["required"]}
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                                <div className="ml-8 mr-8 col-4">
                                  <TextValidator
                                    required
                                    label="Mobile Number"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    value={mobile}
                                    onChange={handleMobileChange}
                                    disabled={paymentState.attempt > 0 ? true : false}
                                    validators={[
                                      "required",
                                      "matchRegexp:^[0-9\b]+$",
                                    ]}
                                    errorMessages={[
                                      "required",
                                      "Only number are allowed",
                                    ]}
                                  />
                                </div>
                                <div className="mr-8 col-4">
                                  <TextValidator
                                    required
                                    label="Email Address"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    disabled={paymentState.attempt > 0 ? true : false}
                                    value={email}
                                    onChange={handleEmailChange}
                                    validators={["required", "isEmail"]}
                                    errorMessages={["required", "Invalid email"]}
                                  />
                                </div>
                              </div>
                            </div>
                          </ValidatorForm>
                        )}
                        {activeStep !== 0 && (
                          <div>
                            <div className="passenger-detail-field-container mt-1">
                              <div className="passenger-fields static-info-description">
                                <div className="ml-8 mr-8 col-4">
                                  <span className="pax-type">Country Code</span>
                                  <span className="block-display">
                                    {areaCode &&
                                      countries.find((el) => el.code === areaCode)
                                        .phone}
                                  </span>
                                </div>
                                <div className="ml-8 mr-8 col-4">
                                  <span className="pax-type">Mobile</span>
                                  <span className="block-display">{mobile}</span>
                                </div>
                                <div className="ml-8 mr-8 col-4">
                                  <span className="pax-type">Email Address</span>
                                  <span className="block-display">{email}</span>
                                </div>
                              </div>
                            </div>
                            {travelers.map((traveler, index) => (
                              <div>
                                <Row className="mx-0 mt-3 flt-bkg-heading-bg px-2">
                                  <div className="col-12 flt-bkg-main-heading px-2">
                                    Traveler {index + 1}
                                    <span className="pax-type px-2">
                                      {" "}
                                      {traveler.paxType}
                                    </span>
                                  </div>
                                </Row>
                                <div className="passenger-detail-field-container">
                                  <div className="passenger-fields-readonly static-info-description mt-11 ">
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">Title</span>
                                      <span className="block-display">
                                        {traveler.title}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">First Name</span>
                                      <span className="block-display">
                                        {traveler.firstName}
                                      </span>
                                    </div>
                                    {traveler.middleName && (
                                      <div className="confirm-booking-heading-container col-4">
                                        <span className="pax-type">
                                          Middle Name
                                        </span>
                                        <span className="block-display">
                                          {traveler.middleName}
                                        </span>
                                      </div>
                                    )}
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">Last Name</span>
                                      <span className="block-display">
                                        {traveler.lastName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="passenger-detail-field-container">
                                  <div className="passenger-fields-readonly static-info-description mt-11 ">
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">DOB</span>
                                      <span className="block-display">
                                        {traveler.dob}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">Gender</span>
                                      <span className="block-display">
                                        {traveler.gender}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Passport Number
                                      </span>
                                      <span className="block-display">
                                        {traveler.passportNumber}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="passenger-detail-field-container">
                                  <div className="passenger-fields-readonly static-info-description mt-11">
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Passport Date of Issuance
                                      </span>
                                      <span className="block-display">
                                        {traveler.passportDOI}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Passport Date of Expiry
                                      </span>
                                      <span className="block-display">
                                        {traveler.passportDOE}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Passport Issued Country
                                      </span>
                                      <span className="block-display">
                                        {traveler.passportIssuedCountry}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="passenger-detail-field-container">
                                  <div className="passenger-fields-readonly static-info-description mt-11">
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Passport Nationality
                                      </span>
                                      <span className="block-display">
                                        {traveler.passengerNationality}
                                      </span>
                                    </div>
                                    {traveler.passengerNationality === "IN" &&
                                      currentClientCountryCode === "IN" ? (
                                      <>
                                        <div className="confirm-booking-heading-container col-4">
                                          <span className="pax-type">
                                            Corporate/Retailer
                                          </span>
                                          <span className="block-display">
                                            {traveler.fema}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {traveler.fema === "Retailer" ? (
                                      <>
                                        <div className="confirm-booking-heading-container col-4">
                                          <span className="pax-type">
                                            PAN Card Number
                                          </span>
                                          <span className="block-display">
                                            {traveler.panCard}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {activeStep !== 1 && (
                          <ValidatorForm
                            ref={passengerSubmit}
                            onSubmit={() =>
                              setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1
                              )
                            }
                            onError={(errors) => console.log(errors)}
                          >
                            {travelers
                              .sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                  return -1;
                                }
                                if (a.paxType > b.paxType) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((traveler, index) => {
                                return (
                                  <ReviewBookingTravelerInput key={index} index={index} traveler={traveler} selectedPassengers={selectedPassengers} handlePassengerSelection={handlePassengerSelection}
                                    passengerData={passengerData} filterPassengers={filterPassengers} activeStep={activeStep} handleFieldChange={handleFieldChange} title={title}
                                    getCorporatePaxDetails={getCorporatePaxDetails} selectDate={selectDate} genders={genders} isPassMandate={isPassMandate} countries={countries}
                                    currentClientCountryCode={currentClientCountryCode} femaList={femaList} travelers={travelers} setSavePassengerCheck={setSavePassengerCheck}
                                    extraSegmentMealInfo={extraSegmentMealInfo} extraSegmentBaggageInfo={extraSegmentBaggageInfo} displayMealSelect={displayMealSelect}
                                    exMealInfo={exMealInfo} getExtraMealInfo={getExtraMealInfo} extraMealInfo={extraMealInfo} getExtraMealSelectedValue={getExtraMealSelectedValue}
                                    handleMealAddService={handleMealAddService} handleMealRemoveService={handleMealRemoveService} displayBaggageSelect={displayBaggageSelect} currentTicket={currentTicket}
                                    exBaggageInfo={exBaggageInfo} getExtraBaggageInfo={getExtraBaggageInfo} extraBaggageInfo={extraBaggageInfo} getExtraBaggageSelectedValue={getExtraBaggageSelectedValue}
                                    handleRemoveService={handleRemoveService} onwardBaggageOptions={onwardBaggageOptions} returnBaggageOptions={returnBaggageOptions} paymentState={paymentState}
                                    isValidNameLengthWithInfTrue={isValidNameLengthTrue} selectBaggageFortraveller={selectBaggageFortraveller} handleAddService={handleAddService}
                                  />
                                )
                              }

                              )
                            }
                          </ValidatorForm>
                        )}
                        <div className="frequent-flyer-container ">
                          <div
                            className="view-flight-details-container pointer"
                            onClick={() => setOpen(!open)}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              className="airline-detail-expand-button"
                            >
                              {open ? (
                                <KeyboardArrowUpRoundedIcon
                                  fontSize="small"
                                  className="airline-detail-expand-icon-fill"
                                />
                              ) : (
                                <KeyboardArrowDownRoundedIcon
                                  fontSize="small"
                                  className="airline-detail-expand-icon-fill"
                                />
                              )}
                            </IconButton>
                            <div className="view-flight-details-text">
                              Frequent Flyer and Optional Requests
                            </div>
                          </div>
                        </div>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          {travelers.map((traveler, index) => (
                            <div className="passenger-detail-field-container my-1">
                              <div className="passenger-text">
                                Traveler {index + 1}
                              </div>
                              {activeStep !== 1 && (
                                <>
                                  <div className="passenger-fields passenger-frequent-flyer-fields">
                                    <div className="ml-8 mr-8 col-4 min-width-200">
                                      <TextField
                                        select
                                        label="Meal Preference"
                                        variant="outlined"
                                        size="small"
                                        disabled={paymentState.attempt > 0 ? true : false}
                                        color="secondary"
                                        value={traveler.mealPreference}
                                        onChange={(event) =>
                                          handleFieldChange(
                                            event.target.value,
                                            "mealPreference",
                                            index
                                          )
                                        }
                                      >
                                        {mealPreference.map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>
                                    <div className="ml-8 mr-8 col-4">
                                      <TextField
                                        label="TSA Redress"
                                        variant="outlined"
                                        size="small"
                                        disabled={paymentState.attempt > 0 ? true : false}
                                        color="secondary"
                                        value={traveler.tsaRedress}
                                        onChange={(event) =>
                                          handleFieldChange(
                                            event.target.value,
                                            "tsaRedress",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="ml-8 mr-8 col-4">
                                      <TextField
                                        label="Frequent Flyer"
                                        variant="outlined"
                                        disabled={paymentState.attempt > 0 ? true : false}
                                        size="small"
                                        color="secondary"
                                        value={traveler.frequentFlyer}
                                        onChange={(event) =>
                                          handleFieldChange(
                                            event.target.value,
                                            "frequentFlyer",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {activeStep !== 0 && (
                                <>
                                  <div className="passenger-fields-readonly static-info-description mt-11">
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Meal Preference
                                      </span>
                                      <span className="block-display">
                                        {traveler.mealPreference !== ""
                                          ? traveler.mealPreference
                                          : "-"}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        TSA Redress
                                      </span>
                                      <span className="block-display">
                                        {traveler.tsaRedress !== ""
                                          ? traveler.tsaRedress
                                          : "-"}
                                      </span>
                                    </div>
                                    <div className="confirm-booking-heading-container col-4">
                                      <span className="pax-type">
                                        Frequent Flyer
                                      </span>
                                      <span className="block-display">
                                        {traveler.frequentFlyer !== ""
                                          ? traveler.tsaRedress
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </Collapse>

                        {/* {(activeStep !== 1 || (activeStep === 1 && paymentState.failureMessage !== '' && PaymentStatus[paymentState.paymentStatus] !== 'Success')) && */}
                        {(activeStep === 0) &&
                          <div>
                            <Row className="mx-0 mt-5 flt-bkg-heading-bg px-2">
                              <div className="col-12 flt-bkg-main-heading px-2 pt-2">
                                Payment By
                              </div>
                            </Row>
                            <RadioGroup className="mt-3 ms-3" row value={paymentMethod} onChange={handlePaymentMethodChange}>
                              <FormControlLabel value="wallet" control={<Radio size="small" />} label="Akbar Wallet" />
                              {paymentGatewayId && !revalState.hold ? <FormControlLabel value="paymentGateway" control={<Radio size="small" />} label="Payment Gateway" /> : ''}
                              {/* { currentTicket.paymentGateway &&<FormControlLabel value="paymentGateway" control={<Radio size="small" />} label="Payment Gateway" />} */}
                              {currentTicket.passThrough && <FormControlLabel value="card" control={<Radio size="small" />} label="Card (Pass Through)" />}
                            </RadioGroup>
                            {
                              paymentMethod === 'paymentGateway' && (
                                <div className="mt-3 ms-3 textEnd" >
                                  {/* <div className="pt-markup-tf">Payment Gateway</div> */}
                                  <div className="mt-2">
                                    <p>Payable Amount - <b className="pg-charge-bold"><CurrencyCodeComponent
                                      currencyCode={currentClientCurrencyCode}
                                    />{" "}{`${(
                                      (revalState?.activeSearch?.children > 0
                                        ? revalState?.activeSearch?.children *
                                        getChildrenPrice().childrenTotalPrice
                                        : 0) +
                                      (revalState?.activeSearch?.adult > 0
                                        ? revalState?.activeSearch?.adult *
                                        getAdultPrice().adultTotalPrice
                                        : 0) +
                                      (revalState?.activeSearch?.infant > 0
                                        ? revalState?.activeSearch?.infant *
                                        getInfantPrice().infantTotalPrice
                                        : 0) +
                                      extraBaggageChrgs +
                                      extraMealChrgs +
                                      (travelProtection
                                        ? 1000 *
                                        (revalState?.activeSearch?.children +
                                          revalState?.activeSearch?.adult +
                                          revalState?.activeSearch?.infant)
                                        : 0)
                                      +
                                      (paymentMethod === 'paymentGateway' && paymentGatewayId === 2 ? (total * 0.02) : 0)
                                    ).toFixed(2)}`}</b> </p>
                                    {
                                      paymentGatewayId === 2 && (
                                        <>
                                          <p>Payment Gateway Charges Applicable</p>
                                          <p style={{ color: "red", textTransform: 'capitalize', textAlign: 'left' }}>Note - 2% of total amount will be collected as Payment Gateway Charges on Next Page {fee}</p>
                                          <p style={{ color: "red", textTransform: 'capitalize', textAlign: 'left' }}>Note - Payment Gateway Charges are non refundable</p>
                                        </>
                                      )
                                    }
                                  </div>
                                </div>
                              )
                            }
                            {currentTicket.passThrough && paymentMethod === 'card' && (
                              <>
                                {currentTicket.fareGroups[0].fareType == 'Private' && (
                                  <div className="m-3">
                                    <div className="pt-markup-tf">PassThrough Markup</div>
                                    <div className="mt-2" style={{ width: "200px" }}>
                                      <TextField
                                        placeholder="Enter markup here"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        value={ptMarkup}
                                        onChange={(event) => handlePtMarkupChange(event.target.value)}
                                        error={!!ptError}
                                        helperText={ptError}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="">
                                  <div className="mt-4 ms-3 pt-markup-tf">PassThrough Details</div>
                                  <CCpayment
                                    cardNumber={cardNumber}
                                    setCardNumber={setCardNumber}
                                    nameOnCard={nameOnCard}
                                    setNameOnCard={setNameOnCard}
                                    expiryMonth={expiryMonth}
                                    setExpiryMonth={setExpiryMonth}
                                    expiryYear={expiryYear}
                                    setExpiryYear={setExpiryYear}
                                    cvv={cvv}
                                    setCvv={setCvv}
                                    errCardFields={errCardFields}
                                    cardTypeAccepted={currentTicket.cardTypeAccepted}
                                  />
                                </div>

                              </>
                            )}
                          </div>
                        }
                        {
                          activeStep === 1 &&
                          // (activeStep === 1 && !localStorage.getItem('paymentAttempt')) &&
                          <div>
                            <Row className="mx-0 mt-5 flt-bkg-heading-bg px-2">
                              <div className="col-12 flt-bkg-main-heading px-2 pt-2">
                                Payment By - <b>{paymentMethod === 'card' ? 'Card (Pass Through)' : paymentMethod === 'paymentGateway' ? 'Payment Gateway' : 'Akbar Wallet'}</b>
                              </div>
                            </Row>
                          </div>
                        }


                        {activeStep !== 1 && revalState.hold && (
                          <div>
                            <div
                              className="mt-3 mb-2"
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Hold Booking
                            </div>
                            <div
                              className="border border-dark p-2"
                              style={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              <div>
                                <p>Hold Booking Rules and Regulations</p>
                              </div>
                              <div>
                                <p>
                                  Hold PNR will only create booking at Airline
                                  end, but no tickets will be issued until you
                                  confirm ticketing.<br></br> We are not
                                  responsible, if any changes done at Airline end.
                                  <br></br> Creating a "HOLD PNR" under ANY
                                  Airline can lead to an ADM for which agency will
                                  be charged as per the airline circular if its
                                  not `Ticketed`or if its `auto cancelled` after
                                  HOLD.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {activeStep !== 1 &&
                        (iscorporatePaxMandate ? (
                          <>
                            <div className="row mb-3 mx-0">
                              <div className="col-12 decl-info-bg">
                                <div className="decl-main-heading py-2 mb-3">
                                  Declaration
                                </div>
                                {!isDeclarationMandate ? (
                                  <>
                                    <div className="decl-mandatory-txt">
                                      required
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="decl-normal-txt">
                                  <FormControlLabel
                                    id="declarationCheck"
                                    name="declarationCheck"
                                    control={
                                      <Checkbox
                                        id="declarationCheck"
                                        name="declarationCheck"
                                        value="declaration"
                                        color="primary"
                                        disabled={paymentState.attempt > 0 ? true : false}
                                        checked={isDeclarationMandate}
                                        onChange={(event) =>
                                          setisDeclarationMandate(
                                            event.target.checked
                                          )
                                        }
                                        required
                                        validators={["required"]}
                                        errorMessages={["required"]}
                                      />
                                    }
                                    labelPlacement="end"
                                    label={
                                      <>
                                        We{" "}
                                        <span className="decl-sub-heading">
                                          {currentClientName}
                                        </span>
                                        , as registered clients of Akbar Offshore
                                        Pvt. Ltd. Hereby declare that the tickets
                                        issued for passenger/s are being paid by
                                        their corporate for company travel.
                                      </>
                                    }
                                  />
                                </div>
                                <div className="py-2">
                                  <div className="decl-sub-heading">
                                    Passenger Name/Names :
                                  </div>
                                  {corporatePaxList.length > 0
                                    ? corporatePaxList
                                      .sort((a, b) => (a > b ? 1 : -1))
                                      .map((paxListItem) => (
                                        <>
                                          <div className="decl-normal-txt mt-1">
                                            Pax {paxListItem}
                                          </div>
                                        </>
                                      ))
                                    : ""}
                                </div>
                                <div className="decl-normal-txt py-2">
                                  <div>
                                    <span className="decl-sub-heading">
                                      Agency Name:
                                    </span>{" "}
                                    {currentClientName}
                                  </div>
                                  <div>
                                    <span className="decl-sub-heading">
                                      Agency Code:
                                    </span>{" "}
                                    {currentClientID}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        ))}
                      {!bookingProcess && (
                        <>
                          {activeStep !== 1 && (

                            <div className="continue-button-container mb-3">
                              <Button
                                className="coupon-submit-button ml-0"
                                onClick={() => handleNext()}
                                disabled={!checkIfUserStaff() && !isClientUserHasPermission("IsBook", "FlightbookDetail") && userRole !== "ClientSuperAdmin"}
                                variant="contained"
                              // disabled={
                              //   displayBaggageMatchError ||
                              //   displayBaggageSelectError
                              // }
                              >
                                {revalState.hold
                                  ? " Proceed to Hold"
                                  : " Continue"}
                              </Button>
                            </div>


                          )}

                          {/* {activeStep === 1 && (
                          <div className="continue-button-container mb-3">
                            <div
                              className={`coupon-submit-button ml-0 ${buttonDisabled ? "disabled" : ""}`}
                              onClick={() => handleNext()}
                              id="bookButton"
                            >
                              {revalState.hold
                                ? " Continue to Hold"
                                : " Continue to Book"}
                            </div>
                          </div>
                        )} */}
                          {
                            activeStep === 1 && paymentMethod === 'paymentGateway' ?
                              <div>
                                <Row className="">
                                  <div className="col-12 flt-bkg-main-heading px-2 pt-2">
                                    <p>By Clicking On Continue To Book I Agree To The Booking Policies,<a style={{ color: "blue" }} onClick={() => setShowTerms(true)}>Read More</a></p>
                                  </div>
                                </Row>
                              </div> : ''
                          }
                          {activeStep !== 0 && (
                            <div className="back-button-container mb-3">
                              <div
                                className={`coupon-submit-button ml-0 ${buttonDisabled ? "disabled" : ""}`}
                                onClick={() => handleNext()}
                                id="bookButton"
                              >
                                {revalState.hold
                                  ? " Continue to Hold"
                                  : " Continue to Book"}
                              </div>

                              <div
                                className="back-btn ml-0"
                                onClick={() => handleBackToPassengerDtls()}
                              >
                                Back
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {bookingProcess === true ? (
                      <RiseLoading isReviewBooking={bookingProcess} />
                    ) : ''}
                  </section>
                  <section className="col-3 px-3">
                    <Row className="flt-bkg-dtls-section-review my-2" style={{ position: 'sticky', top: '10vh' }}>
                      <div className="d-flex align-items-center">
                        <div className="flt-bkg-heading px-2 py-2">
                          Fare Summary ({currentTicket && currentTicket.currency})
                        </div>

                        {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                          netFare > 0 && <div align=''>
                            <Button
                              variant="text"
                              onClick={() => {
                                setShowNetFare(handleShowNetFaresClick);
                              }}
                              endIcon={
                                showNetfare == false ? (
                                  <AddCircleOutlineOutlinedIcon />
                                ) : (
                                  <RemoveCircleOutlineOutlinedIcon />
                                )
                              }
                            ></Button>
                          </div>
                        }
                      </div>

                      <Table className="fareSummaryTable">
                        <TableBody>
                          <TableRow>
                            <p className="reviewBookingHeading">Base Fare</p>
                          </TableRow>
                          {revalState?.activeSearch?.adult > 0 ? (
                            <TableRow>
                              <TableCell align="left">Adult(s)</TableCell>
                              <TableCell align="left">
                                <span>
                                  {revalState?.activeSearch?.adult +
                                    " x " +
                                    getAdultPrice().basePrice.toFixed(2)}
                                </span>
                              </TableCell>
                              <TableCell align="right">
                                {(
                                  revalState?.activeSearch?.adult *
                                  getAdultPrice().basePrice
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {revalState?.activeSearch?.children > 0 ? (
                            <TableRow>
                              <TableCell align="left">Children(s)</TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {revalState?.activeSearch?.children +
                                    " x " +
                                    getChildrenPrice().basePrice.toFixed(2)}
                                </span>
                              </TableCell>
                              <TableCell align="right">
                                {(
                                  revalState?.activeSearch?.children *
                                  getChildrenPrice().basePrice
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {revalState?.activeSearch?.infant > 0 ? (
                            <TableRow>
                              <TableCell align="left">Infant(s)</TableCell>
                              <TableCell align="left">
                                {" "}
                                <span>
                                  {revalState?.activeSearch?.infant +
                                    " x " +
                                    getInfantPrice().basePrice.toFixed(2)}
                                </span>
                              </TableCell>
                              <TableCell align="right">
                                {(
                                  revalState?.activeSearch?.infant *
                                  getInfantPrice().basePrice
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          <TableRow>
                            <p className="reviewBookingHeading">Taxes</p>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Tax and Surcharge</TableCell>
                            <TableCell colSpan={2} align="right">
                              {(
                                revalState?.activeSearch?.adult *
                                getAdultPrice().taxPrice +
                                revalState?.activeSearch?.children *
                                getChildrenPrice().taxPrice +
                                revalState?.activeSearch?.infant *
                                getInfantPrice().taxPrice
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                          {extraMealChrgs !== 0 || extraBaggageChrgs !== 0 ? (
                            <TableRow>
                              <p className="reviewBookingHeading">AddOns</p>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {((extraSegmentMealInfo &&
                            extraSegmentMealInfo.length > 0) ||
                            (extraSegmentBaggageInfo &&
                              extraSegmentBaggageInfo.length)) &&
                            (extraMealChrgs !== 0 || extraBaggageChrgs !== 0) ? (
                            <>
                              {extraBaggageChrgs !== 0 ? (
                                <>
                                  <TableRow>
                                    <TableCell align="left">Baggage</TableCell>
                                    <TableCell colSpan={2} align="right">
                                      {extraBaggageChrgs.toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                </>
                              ) : (
                                ""
                              )}
                              {extraMealChrgs !== 0 ? (
                                <>
                                  <TableRow>
                                    <TableCell align="left">Meal</TableCell>

                                    <TableCell colSpan={2} align="right">
                                      {extraMealChrgs.toFixed(2)}{" "}
                                    </TableCell>
                                  </TableRow>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {/* {
                            paymentMethod === 'paymentGateway' ? (

                              <TableRow>
                                <TableCell align="left">Payment Gateway Charges</TableCell>
                                <TableCell colSpan={2} align="right">
                                  {(
                                  total * 0.02
                                ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ) : ''
                          } */}
                          <TableRow>
                            <TableCell>
                              <p className="reviewBookingHeading">
                                Total Amount{" "}
                              </p>
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                              <div className="d-flex align-items-center">
                                <div className="reviewBookingHeading">
                                  <CurrencyCodeComponent
                                    currencyCode={currentClientCurrencyCode}
                                  />
                                  {`${(
                                    (revalState?.activeSearch?.children > 0
                                      ? revalState?.activeSearch?.children *
                                      getChildrenPrice().childrenTotalPrice
                                      : 0) +
                                    (revalState?.activeSearch?.adult > 0
                                      ? revalState?.activeSearch?.adult *
                                      getAdultPrice().adultTotalPrice
                                      : 0) +
                                    (revalState?.activeSearch?.infant > 0
                                      ? revalState?.activeSearch?.infant *
                                      getInfantPrice().infantTotalPrice
                                      : 0) +
                                    extraBaggageChrgs +
                                    extraMealChrgs +
                                    (travelProtection
                                      ? 1000 *
                                      (revalState?.activeSearch?.children +
                                        revalState?.activeSearch?.adult +
                                        revalState?.activeSearch?.infant)
                                      : 0)
                                    // + (paymentMethod === 'paymentGateway' ? (total * 0.02) : 0)
                                  ).toFixed(2)}`}
                                </div>
                                <div>
                                  {netFare > 0 && (
                                    <BootstrapTooltip1
                                      title={
                                        <>
                                          <p
                                            style={{
                                              borderBottom: "2px solid grey",
                                            }}
                                          >
                                            Fare Details
                                          </p>

                                          {showNetfare == true &&
                                            clientMarkup >
                                            0 && (
                                              <div className="d-flex justify-content-between">
                                                <div>You Earn</div>
                                                <div>
                                                  <CurrencyCodeComponent
                                                    currencyCode={currentClientCurrencyCode}
                                                  />

                                                  {(
                                                    parseFloat(
                                                      clientMarkup
                                                    ) +
                                                    (currentTicket.fareGroups[0].commission > 0
                                                      ? parseFloat(
                                                        currentTicket.fareGroups[0].commission
                                                      )
                                                      : 0)
                                                  ).toFixed(2)}
                                                </div>
                                              </div>
                                            )}
                                          {showNetfare == true &&
                                            clientMarkup >
                                            0 && (
                                              <div
                                                className="d-flex justify-content-between"
                                                style={{
                                                  fontSize: "10px",
                                                  color: "lightgray",
                                                }}
                                              >
                                                <div>Agent Mark-up</div>

                                                <div>
                                                  <CurrencyCodeComponent
                                                    currencyCode={currentClientCurrencyCode}
                                                  />
                                                  {clientMarkup}
                                                </div>
                                              </div>
                                            )}
                                          {showNetfare == true &&
                                            currentTicket.fareGroups[0].commission >
                                            0 && (
                                              <div
                                                className="d-flex justify-content-between"
                                                style={{
                                                  fontSize: "10px",
                                                  color: "lightgray",
                                                }}
                                              >
                                                <div>Commission</div>
                                                <div>
                                                  <CurrencyCodeComponent
                                                    currencyCode={currentClientCurrencyCode}
                                                  />
                                                  0.0
                                                </div>
                                              </div>
                                            )}
                                          <div
                                            className="d-flex justify-content-between"
                                            style={{
                                              borderTop: "2px solid grey",
                                            }}
                                          >
                                            <div>Total Amount</div>
                                            <div>
                                              <CurrencyCodeComponent
                                                currencyCode={currentClientCurrencyCode}
                                              />
                                              {grossFare}
                                            </div>
                                          </div>
                                          {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                            showNetfare == true && (
                                              <div
                                                className="d-flex justify-content-between"
                                              // style={{ fontSize: "9px" }}
                                              >
                                                <div>Net Fare</div>
                                                <div>
                                                  <CurrencyCodeComponent
                                                    currencyCode={currentClientCurrencyCode}
                                                  />
                                                  {netFare}
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      }
                                    >
                                      <InfoOutlinedIcon />
                                    </BootstrapTooltip1>
                                  )}
                                </div>
                              </div>

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Row>
                  </section>
                </Row>
              </>
            )}
          </div>
          {isError === false &&
            currentTicket === undefined &&
            !fareChangeDialog && (
              <Row>
                <div className="col-12 text-center py-5 loader-cls">
                  <Box className="loader-box-content">
                    <div className="flt-main-heading py-3">
                      <h3>Same Flight lowest fares!!</h3> We're fetching
                      Details...
                    </div>
                    <CircularProgress />
                  </Box>
                </div>
              </Row>
            )}
          {isError === true && currentTicket === undefined && (
            <div className="no-results-container">
              <div className="no-results-text">
                Selected itinerary is not available for booking, Please select
                different itinerary.
              </div>
            </div>
          )}
        </div>

        {/* No Sufficient Client Balance Dialog Box */}
        <Dialog
          open={openErrorMessageDialog1}
          onClose={handleCloseErrorMessageDialog1}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
          className="error-dialog-box"
        >
          <DialogTitle id="error-dialog-title" className="main-heading-cls">
            {errorTimeout ? "Oops!" : "Booking In Progress"}
          </DialogTitle>
          <DialogContent className="mt-3 py-2">
            <DialogContentText id="error-dialog-description">
              <Row>
                <div className="col-12 sub-heading-cls mb-2">
                  Dear Travel Partner
                </div>
                <div className="normal-txt-cls">
                  <p className="sub-heading-cls">{openErrorMessageHTML}</p>
                  <p>
                    Kindly contact our Customer Success Group at{" "}
                    <a href="mailto:customersuccess@getfares.com">
                      customersuccess@getfares.com
                    </a>{" "}
                    to assist you and proceed towards a successful booking.
                  </p>
                </div>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="error-dialog-action text-center my-2">
            <Button variant="outlined" onClick={handleCloseErrorMessageDialog1}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openErrorMessageDialog}
          onClose={handleCloseErrorMessageDialog}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
          className="error-dialog-box"
        >
          <DialogTitle id="error-dialog-title" className="main-heading-cls">
            Notification
          </DialogTitle>
          <DialogContent className="mt-3 py-2">
            <DialogContentText id="error-dialog-description">
              <Row>
                <div className="col-12 sub-heading-cls mb-2">
                  Dear Travel Partner
                </div>
                <div className="normal-txt-cls">
                  <p className="sub-heading-cls" style={{ textTransform: 'capitalize' }}>{openErrorMessageHTML}</p>
                  <p>
                    Kindly contact our Customer Success Group at{" "}
                    <a href="mailto:customersuccess@getfares.com">
                      customersuccess@getfares.com
                    </a>{" "}
                    to assist you and proceed towards a successful booking. <span className="text-bold">Please check the My Booking queue before proceeding for same booking again.</span>
                  </p>
                </div>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="error-dialog-action text-center my-2">
            <Button variant="outlined" onClick={handleCloseErrorMessageDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open1}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        >
          <DialogContent dividers={scroll === "paper"}>
            <div className="country-select-modal">
              <h3 className="warning-mode-h3">WARNING *ELIGIBILITY CHECK*</h3>
              <h4 className="warning-mode-h4">
                You have selected a fare that is designed for a specific type of
                Passenger.
              </h4>
              <p>
                Please be aware that the incorrect application of the fare could
                result in a Passenger's denied boarding by the airliner as well as
                the request for payment of a penalty and/or a fine, or the
                generation of ADM (Agency Debit Memo).
              </p>

              <h4 className="warning-mode-h4">
                By clicking that check box, you confirm that You:
              </h4>

              <p>1) Have carefully read the rules and regulations.</p>
              <p>2) Have understood the applicability of the fare.</p>
              <p>
                3) Exempt us from any liability in case airline denied boarding
                and/or applied penalties/fine to the Pax.
              </p>
              <p>
                4) Will pay ADM within fourteen days without objection,in case any
                arises.
              </p>
              <h4 className="warning-mode-h4">
                Ethnic fares – Originating from Italy
              </h4>
              <p>
                Passenger must hold passport of the destination country<b> OR </b>{" "}
                if travelling as family, one of the immediate family members must
                be holding passport of the destination country.
              </p>
              <h4 className="warning-mode-h4">
                Ethnic/Special fares – Originating from Germany
              </h4>
              <p>No restrictions of nationality.</p>
            </div>
            <FormControlLabel
              required
              control={
                <Checkbox
                  value={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                  }}
                />
              }
              label="I Accept All Terms And Conditions"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={!isChecked} onClick={specialFare}>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog className="terms-dialog" style={{ inset: "40px" }} open={showTerms} onClose={() => setShowTerms(false)} maxWidth="md" fullWidth>
          <DialogTitle>Terms of Use</DialogTitle>
          <DialogContent>
            <Terms />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTerms(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  );
}

export default ViewBooking;
