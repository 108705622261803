import React, { useEffect, useState } from "react";
import "./BookingSwitchesDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../../data/TimeZones";
import axios from "axios";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function BookingSwitchesDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
}) {
  const classes = useStyles();

  const [bookingSwitchName, setbookingSwitchName] = useState("");
  const [searchSupplier, setsearchSupplier] = useState("");
  const [searchPCC, setsearchPCC] = useState("");
  const [bookingSupplier, setbookingSupplier] = useState("");
  const [bookingPCC, setbookingPCC] = useState("");
  const [cabinClass, setcabinClass] = useState("");
  const [includeAirlines, setincludeAirlines] = useState("");
  const [excludeAirlines, setexcludeAirlines] = useState("");
  const [bufferAmount, setbufferAmount] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setbookingSwitchName(selectedItem.bookingSwitchName);
      setsearchSupplier(selectedItem.searchSupplier);
      setsearchPCC(selectedItem.searchPCC);
      setbookingSupplier(selectedItem.bookingSupplier);
      setbookingPCC(selectedItem.bookingPCC);
      setcabinClass(selectedItem.cabinClass);
      setincludeAirlines(selectedItem.includeAirlines);
      setexcludeAirlines(selectedItem.excludeAirlines);
      setbufferAmount(selectedItem.bufferAmount);
      setIsActive(selectedItem.isActive);
      setId(selectedItem.id);
    } else {
      setbookingSwitchName("");
      setsearchSupplier("");
      setsearchPCC("");
      setbookingSupplier("");
      setbookingPCC("");
      setcabinClass("");
      setincludeAirlines("");
      setexcludeAirlines("");
      setbufferAmount(0);
      setIsActive(true);
      setId("");
    }
  }, [toggleDrawer, action]);

  async function handleUpdate() {
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/BookingSwitchConfiguration/" +
            selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,
            bookingSwitchName:
              bookingSwitchName === undefined ? "" : bookingSwitchName,
            searchSupplier: searchSupplier === undefined ? "" : searchSupplier,
            searchPCC: searchPCC === undefined ? "" : searchPCC,
            bookingSupplier:
              bookingSupplier === undefined ? "" : bookingSupplier,
            bookingPCC: bookingPCC === undefined ? "" : bookingPCC,
            cabinClass: cabinClass === undefined ? "" : cabinClass,
            includeAirlines:
              includeAirlines === undefined ? "" : includeAirlines,
            excludeAirlines:
              excludeAirlines === undefined ? "" : excludeAirlines,
            bufferAmount: bufferAmount === undefined ? "0" : bufferAmount,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/BookingSwitchConfiguration",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            bookingSwitchName:
              bookingSwitchName === undefined ? "" : bookingSwitchName,
            searchSupplier: searchSupplier === undefined ? "" : searchSupplier,
            searchPCC: searchPCC === undefined ? "" : searchPCC,
            bookingSupplier:
              bookingSupplier === undefined ? "" : bookingSupplier,
            bookingPCC: bookingPCC === undefined ? "" : bookingPCC,
            cabinClass: cabinClass === undefined ? "" : cabinClass,
            includeAirlines:
              includeAirlines === undefined ? "" : includeAirlines,
            excludeAirlines:
              excludeAirlines === undefined ? "" : excludeAirlines,
            bufferAmount: bufferAmount === undefined ? "0" : bufferAmount,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          handleSnackbarToggle();
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="supplier-heading-container supplier-title-flex-container">
        <div>Supplier</div>
        {action === "view" && (
          <div className="supplier-heading-icons">
            <IconButton fontSize="small">
              <EditIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditIcon>
            </IconButton>
          </div>
        )}
      </div>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="supplier-view-edit-drawer-container">
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Config Name"
                  size="small"
                  color="secondary"
                  value={bookingSwitchName}
                  onChange={(event) => setbookingSwitchName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Cabin Calss"
                  size="small"
                  color="secondary"
                  value={cabinClass}
                  onChange={(event) => cabinClass(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Config Name</div>
                <div className="view-supplier-drawer-description ">
                  {bookingSwitchName}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Cabin Class</div>
                <div className="view-supplier-drawer-description">
                  {cabinClass}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Search Supplier"
                  size="small"
                  color="secondary"
                  value={searchSupplier}
                  onChange={(event) => setsearchSupplier(event.target.value)}
                  validators={["required"]}
                  errorMessages={[
                    "This Field is Required., if ipcc is not available please provide pcc value",
                  ]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Search PCC"
                  size="small"
                  color="secondary"
                  value={searchPCC}
                  onChange={(event) => setsearchPCC(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Search Supplier
                </div>
                <div className="view-supplier-drawer-description ">
                  {searchSupplier}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Search PCC</div>
                <div className="view-supplier-drawer-description">
                  {searchPCC}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Booking Supplier"
                  size="small"
                  color="secondary"
                  value={bookingSupplier}
                  onChange={(event) => setbookingSupplier(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Booking PCC"
                  size="small"
                  color="secondary"
                  value={bookingPCC}
                  onChange={(event) => setbookingPCC(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Booking Supplier
                </div>
                <div className="view-supplier-drawer-description ">
                  {bookingSupplier}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Booking PCC</div>
                <div className="view-supplier-drawer-description">
                  {bookingPCC}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Include Airlines"
                  size="small"
                  color="secondary"
                  value={includeAirlines}
                  onChange={(event) => setincludeAirlines(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Exclude Airlines"
                  size="small"
                  color="secondary"
                  value={excludeAirlines}
                  onChange={(event) => setexcludeAirlines(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Include Airlines
                </div>
                <div className="view-supplier-drawer-description ">
                  {includeAirlines}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Exclude Airlines
                </div>
                <div className="view-supplier-drawer-description">
                  {excludeAirlines}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Buffer Amount"
                  size="small"
                  color="secondary"
                  value={bufferAmount}
                  onChange={(event) => setbufferAmount(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number",
                      min: 0,
                      max: 10000,
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Status
                  </InputLabel>
                  <NativeSelect
                    variant="outlined"
                    label="Status"
                    size="small"
                    color="secondary"
                    value={isActive}
                    onChange={(event) => setIsActive(event.target.value)}
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </NativeSelect>
                </FormControl>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Buffer Amount</div>
                <div className="view-supplier-drawer-description ">
                  {bufferAmount}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Status</div>
                <div className="view-supplier-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
