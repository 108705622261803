function formatDate(date) {
    let date1 = new Date(date);
    let date2 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 10, 30, 0);
    const date3 = new Date(date2);
    const yyyy = date3.getFullYear();
    let mm = date3.getMonth() + 1;
    let dd = date3.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formatedDate = dd + '/' + mm + '/' + yyyy;
    return formatedDate;
}
export { formatDate };