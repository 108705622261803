import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import "./FilterComponents.css";

function NumberOfStops({ search, setSearch, toggleDrawer, currencyCode }) {
  const classes = useCheckboxStyles();

  const handleChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.filters.stops.find(
      (item) => item.title === event.target.name
    ).checked = event.target.checked;
    toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className="number-of-stops-container mt-12 mb-16">
      <FormControl component="fieldset">
        <FormGroup>
          {search.filters.stops &&
            search.filters.stops.length > 0 &&
            search.filters.stops.map((stop, index) => (
              <div key={`NumofStopsMainDiv_`+index}>
                { stop?.startingFrom && stop?.startingFrom !== 0 && stop?.startingFrom !== Infinity && (
                  <div className="stops-item-container" key={`NumofStopsSubDiv_`+index}>
                    <div className="stop-item-title">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.root}
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            checked={stop.checked}
                            onChange={handleChange}
                            name={stop.title}
                          />
                        }
                        label={stop.title}
                      />
                    </div>
                    <div className="stop-item-price">{currencyCode}{" "}{ stop?.startingFrom && stop?.startingFrom.toFixed(2)}</div>
                  </div>
                )}
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default NumberOfStops;
