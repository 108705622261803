import getFare from "./getFare";
import getTotalFare from "./getTotalFare";

export default function getLowestAirlineFare(airline, flights) {
  const prices = [];
  flights.forEach((flight) => {
    if (flight.airline === airline) {
      prices.push(
        getTotalFare(
          flight.fareGroups[0],
          flight.adtNum,
          flight.chdNum,
          flight.infNum
        ).total.toFixed(2));
        //getFare(flight.fareGroups[0].fares[0]));
    }
  });
  return Math.min(...prices);
}
