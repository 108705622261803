import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import L1CacheTable from "./L1CacheTable/L1CacheTable"
import L1CacheDrawer from "./L1CacheDrawer/L1CacheDrawer";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const L1CacheGroup = () => {
    const [drawer, setDrawer] = useState(false);
    const [action, setAction] = useState("new");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState("");
    const history = useHistory();
    useEffect(() => {
        // if (isUserHasPermission("SearchCategoryGroupRead")) {
        loadCategories();
        // } else {
        //     history.push("/Unauthorized");
        // }
    }, []);

    async function loadCategories() {

        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/L1GroupCache"
            )
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => console.log(error));
    }


    const toggleDrawer = (value) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawer(value);

    };

    const openDrawer = (selectedItem) => {

        setSelectedItem(selectedItem);
        setDrawer(true);
    };

    const handleSnackbarToggle = () => {
        setDrawer(false);
        setSnackbarOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div className="search-container">
            <div className="search-content-container">
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <div className="search-search-container">
                            <div className="search-table-container">
                                <div className="search-title-add-button-container">
                                    <div className="search-heading">L1 Cache Group</div>
                                    <div className="search-icon-button-container">

                                        {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
                                        <div
                                            className="add-search-button mt-0px"
                                            onClick={() => {
                                                setAction("new");
                                                setDrawer(true);
                                            }}
                                        >
                                            Add New Group
                                        </div>
                                        {/* ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>

                                <L1CacheTable
                                    openDrawer={openDrawer}
                                    setAction={setAction}
                                    categories={categories}
                                ></L1CacheTable>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
            <Drawer
                anchor="right"
                open={drawer}
                onClose={toggleDrawer(false)}
                BackdropProps={{ invisible: true }}
                variant="persistent"
                className="drawer-width"
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <div className="drawer-min-width-40">
                    <L1CacheDrawer
                        selectedItem={selectedItem}
                        toggleDrawer={toggleDrawer}
                        action={action}
                        setAction={setAction}
                        handleSnackbarToggle={handleSnackbarToggle}
                        loadCategories={loadCategories}
                    ></L1CacheDrawer>
                </div>
            </Drawer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity="success">
                    {`Setting has been ${action === "new" ? "created" : "updated"}`}
                </Alert>
            </Snackbar>
        </div>
    );
}
export default L1CacheGroup