import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@material-ui/core";

export default function FlightFareDetailsClientTableComponent({
  flight,
  adultBase,
  adultTax,
  adultBaggage,
  childBase,
  childTax,
  childBaggage,
  infantBase,
  infantTax,
  infantBaggage,
  adultVendorIssuanceFee,
  childVendorIssuanceFee,
  infantVendorIssuanceFee,
  adultMarkup,
  childMarkup,
  infantMarkup,
  adultAdditionalMarkup,
  childAdditionalMarkup,
  infantAdditionalMarkup,
  adultBookingfee,
  adultVendorTax,
  childVendorTax,
  infantVendorTax,
  adultTotal,
  childTotal,
  infantTotal,
  adultIata,
  childIata,
  infantIata,
  adultVendorIata,
  childVendorIata,
  infantVendorIata,
  adultPlb,
  childPlb,
  infantPlb,
  adultVendorPlb,
  childVendorPlb,
  infantVendorPlb,
  currency,
  ROE,
  markupROE,
  CalculateTotalFare,
  OnChangeCalculateTotalFare,
  mode,
  iataType,
  plbType,
  vendorIataType,
  vendorPlbType,
  clientAdultTotal,
  clientChildTotal,
  clientInfantTotal,
  adultClientDropnet,
  childClientDropnet,
  infantClientDropnet,
  adultCount,
  childCount,
  infantCount
}) {
  return (
    <>
      {" "}
      <div className="itenary-container-import booking-padding-top-40">
        <div>Client Fare Details ({flight.currency})</div>
      </div>
      <div className="itenary-passenger-details-import">
        <Table className="itenary-passenger-table ImportBookingFareDetails">
          <TableHead>
            <TableRow className="itenary-passenger-table-header">
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
              >
                Pax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Base Fare
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Tax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Baggage
              </TableCell>
              {/* <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                MarkUp
              </TableCell> */}

              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IssuanceFee
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                TAX
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                Total
              </TableCell>
              {mode !== "importPnr" && <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IATA
              </TableCell>}
              {mode !== "importPnr" && <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight itenary-passenger-table-header-item-final"
              >
                <small>Vendor</small>
                <br />
                PLB
              </TableCell>}
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                IATA
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                PLB
              </TableCell>
              {mode === "importPnr" && <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                Drop Net
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody className="ImportBookingFareDetails ">
            {flight.flightFares !== undefined &&
              flight.flightFares.length > 0 ? (
              flight.flightFares
                .sort(function (a, b) {
                  if (a.paxType < b.paxType) {
                    return -1;
                  }
                  if (a.paxType > b.paxType) {
                    return 1;
                  }
                  return 0;
                })
                .map((fare) => (
                  <>
                    <TableRow className="itenary-passenger-table-item">
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                      >
                        {fare.paxType === "ADT"
                          ? "Adult"
                          : fare.paxType === "CHD"
                            ? "Child"
                            : fare.paxType === "INF"
                              ? "Infant"
                              : "Adult"}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                      >
                        {
                          fare.paxType === "ADT" ? (
                            ((Number(adultBase) * ROE) +
                              (adultMarkup +
                                (mode === "importPnr" && adultAdditionalMarkup ? Number(adultAdditionalMarkup) : 0)
                              ) * (mode === "importPnr" && markupROE ? markupROE : ROE)
                            ).toFixed(2)
                          ) : fare.paxType === "CHD" ? (
                            ((Number(childBase) * ROE) +
                              (childMarkup +
                                (mode === "importPnr" && childAdditionalMarkup ? Number(childAdditionalMarkup) : 0)
                              ) * (mode === "importPnr" && markupROE ? markupROE : ROE)
                            ).toFixed(2)
                          ) : fare.paxType === "INF" ? (
                            ((Number(infantBase) * ROE) +
                              (infantMarkup +
                                (mode === "importPnr" && infantAdditionalMarkup ? Number(infantAdditionalMarkup) : 0)
                              ) * (mode === "importPnr" && markupROE ? markupROE : ROE)
                            ).toFixed(2)
                          ) : '0'
                        }
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          (BaseFare + Markup {mode === "importPnr" ? "+ Additional Markup + Issuance Fee" : ""})
                        </span>
                      </TableCell>

                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                      >
                        {fare.paxType === "ADT"
                          ? (Number(adultTax) * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (Number(childTax) * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (Number(infantTax) * ROE).toFixed(2)
                              : 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                      >
                        {fare.paxType === "ADT"
                          ? (Number(adultBaggage) * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (Number(childBaggage) * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (Number(infantBaggage) * ROE).toFixed(2)
                              : 0}
                      </TableCell>
                      {/* <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                    >
                      <TextValidator
                        id={"outlined-number-Markup" + fare.paxType}
                        type="number"
                        variant="outlined"
                        value={
                          fare.paxType === "ADT"
                            ? adultMarkup
                            : fare.paxType === "CHD"
                            ? childMarkup
                            : fare.paxType === "INF"
                            ? infantMarkup
                            : 0
                        }
                        onBlur={() => CalculateTotalFare()}
                        onChange={(event) =>
                          OnChangeCalculateTotalFare(
                            event,
                            fare.paxType,
                            "markup"
                          )
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TableCell> */}

                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                      >
                        {fare.paxType === "ADT"
                          ? (adultVendorIssuanceFee * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childVendorIssuanceFee * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantVendorIssuanceFee * ROE).toFixed(2)
                              : 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                      >
                        {fare.paxType === "ADT"
                          ? (adultVendorTax * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childVendorTax * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantVendorTax * ROE).toFixed(2)
                              : 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                      >
                        {mode !== "importPnr" ?
                          (fare.paxType === "ADT"
                            ? (adultTotal * ROE).toFixed(2)
                            : fare.paxType === "CHD"
                              ? (childTotal * ROE).toFixed(2)
                              : fare.paxType === "INF"
                                ? (infantTotal * ROE).toFixed(2)
                                : 0) :
                          (fare.paxType === "ADT"
                            ? ((clientAdultTotal) + (adultBookingfee * adultCount)).toFixed(2)
                            : fare.paxType === "CHD"
                              ? ((clientChildTotal) + (adultBookingfee * childCount)).toFixed(2)
                              : fare.paxType === "INF"
                                ? ((clientInfantTotal) + (adultBookingfee * infantCount)).toFixed(2)
                                : 0)
                        }
                      </TableCell>

                      {mode !== "importPnr" && <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                      >

                        {(fare.paxType === "ADT"
                          ? (adultVendorIata * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childVendorIata * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantVendorIata * ROE).toFixed(2)
                              : 0)}

                      </TableCell>}
                      {mode !== "importPnr" && <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                      >
                        {(fare.paxType === "ADT"
                          ? (adultVendorPlb * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childVendorPlb * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantVendorPlb * ROE).toFixed(2)
                              : 0)}

                      </TableCell>}
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                      >
                        {mode !== "importPnr" ? (fare.paxType === "ADT"
                          ? (adultIata * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childIata * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantIata * ROE).toFixed(2)
                              : 0) :
                          (fare.paxType === "ADT"
                            ? ((iataType === "pct" ? (adultIata * adultBase) / 100 : adultIata) * ROE).toFixed(2)
                            : fare.paxType === "CHD"
                              ? ((iataType === "pct" ? (childIata * childBase) / 100 : childIata) * ROE).toFixed(2)
                              : fare.paxType === "INF"
                                ? (infantIata * ROE).toFixed(2)
                                : 0)
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                      >
                        {
                          mode !== "importPnr" ?
                            (fare.paxType === "ADT"
                              ? (adultPlb * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? (childPlb * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (infantPlb * ROE).toFixed(2)
                                  : 0) :
                            (fare.paxType === "ADT"
                              ? ((plbType === "pct"
                                ? (adultBase - (iataType === "pct" ? adultBase * (adultIata / 100) : adultIata)) * (adultPlb / 100)
                                : (adultBase - (iataType === "pct" ? adultBase * (adultIata / 100) : adultIata)) * adultPlb) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((plbType === "pct"
                                  ? (childBase - (iataType === "pct" ? childBase * (childIata / 100) : childIata)) * (childPlb / 100)
                                  : (childBase - (iataType === "pct" ? childBase * (childIata / 100) : childIata)) * childPlb) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (infantPlb * ROE).toFixed(2)
                                  : 0)
                        }
                      </TableCell>
                      {mode === "importPnr" && <TableCell
                        align="center"
                        className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                      >
                        {(fare.paxType === "ADT"
                          ? (adultClientDropnet * ROE).toFixed(2)
                          : fare.paxType === "CHD"
                            ? (childClientDropnet * ROE).toFixed(2)
                            : fare.paxType === "INF"
                              ? (infantClientDropnet * ROE).toFixed(2)
                              : 0)
                        }
                      </TableCell>}
                    </TableRow>
                  </>
                ))
            ) : (
              <TableRow></TableRow>
            )}
            <TableRow className="itenary-passenger-table-item">
              <TableCell
                colSpan={12}
                align=""
                className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <span className="fs12">
                  <span className="colorRed">*</span>
                  Baggage Amount is averaged value of all added baggage of pax
                  to match single pax Fare
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
