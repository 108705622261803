import { ArrowForward } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';
import Button from '@mui/joy/Button';
import './PrevNextComponent.css'


export default function PrevNextComponent({ fetchPrevNextDayresults, search }) {
    const currentDate = new Date();
    const departDate = new Date(search.depart);
    const isPrevDisabled = currentDate.toDateString() === departDate.toDateString();
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button className="prevNextButton" color='#9f155a' variant="plain" disabled={isPrevDisabled} onClick={() => fetchPrevNextDayresults('prev')} startDecorator={<ArrowBack />}>Previous Day</Button>
                <Button className="prevNextButton" variant="plain" onClick={() => fetchPrevNextDayresults('next')} startDecorator={<ArrowForward />}>Next Day</Button>
            </div>
        </div>
    )
}