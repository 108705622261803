import { createTheme } from "@material-ui/core/styles";

const gfsPrimary = "#9f155a";
const gfsSecondary = "#f58336";

export default createTheme({
  palette: {
    common: {
      primary: gfsPrimary,
      secondary: gfsSecondary,
    },
    primary: {
      main: gfsPrimary,
    },
    secondary: {
      main: gfsSecondary,
    },
  }
});
