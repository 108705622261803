import getFare from "./getFare";
import getTotalFare from "./getTotalFare";

export default function getPricesFilter(flights) {
  let prices = [];
  flights.forEach((flight) => {
    prices.push(
      getTotalFare(
        flight.fareGroups[0],
        flight.adtNum,
        flight.chdNum,
        flight.infNum
      ).total.toFixed(2));
      //getFare(flight.fareGroups[0].fares[0]));
  });
  return [Math.min(...prices), Math.max(...prices), Math.max(...prices)];
}
