import React, { useState, useRef, useEffect } from "react";
import "./OfflineBooking.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import supplierList from "../../data/Supplier";
import axios from "axios";
import clientFilterOptions from "../../functions/clientFilterOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const statusList = ["true", "false"];

export default function SupplierCard({
  setsupplierName,
  setPCC,
  setPNR,
  setClientId,
  clientProfiles,
  pccList,
  loadPNRDetails,
  loadPCCDetails,
  clientId,
  supplierName,
  pcc,
  pnr,
  setClientAvailableBalance,
  clientAvailableBalance,
  clientBalanceSH,
  showLoader,
  setShowLoader,
  clientCurrency,
  setBookingType,
  bookingType
}) {
  const searchPnrSubmit = useRef();
  let tempClientProfiles = [];
  tempClientProfiles = structuredClone(removeDuplicates(clientProfiles));

  useEffect(() => {
    getClientBalance();
  }, [clientId]);

  async function getClientBalance() {
    if (clientId !== "") {
      await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/Transactions/GetAccountBanalce?clientId=" +
          clientId
        )
        .then((response) => {
          setClientAvailableBalance(response.data.balance);
        });
    } else {
      setClientAvailableBalance(0);
    }
  }
  function removeDuplicates(arr) {
    const filteredArr = arr.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }
  // const clientFilterOptions = (options, { inputValue }) => {
  //   const isInteger = /^\d+$/.test(inputValue);
  //   if (inputValue) {
  //     if (isInteger) {
  //       let temp;
  //       temp = options.filter(
  //         (option) => Number(option.id) === Number(inputValue)
  //       );
  //       return removeDuplicates(temp);
  //     } else {
  //       let temp = options.filter((option) =>
  //         option.clientName.toLowerCase().includes(inputValue.toLowerCase())
  //       );
  //       return temp ? removeDuplicates(temp) : [];
  //     }
  //   } else {
  //     return removeDuplicates(tempClientProfiles);
  //   }
  // };
  return (
    <ValidatorForm
      ref={searchPnrSubmit}
      onError={(errors) => console.log(errors)}
      onSubmit={() => loadPNRDetails()}
    >
      <div className="create-search-contents">
        <div>
          <FormControl>
            <div className="tripTypeDiv">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="fitBooking"
                row
                name="radio-buttons-group"
                style={{ paddingLeft: "10px" }}
              >
                <FormControlLabel
                  value="fitBooking"
                  onClick={() => { setBookingType((bookingType) => "fitBooking") }}
                  control={<Radio />}
                  label="FIT Booking"
                />
                <FormControlLabel
                  value="groupBooking"
                  onClick={() => { setBookingType((bookingType) => "groupBooking") }}
                  control={<Radio />}
                  label="Group Booking"
                />
              </RadioGroup>
            </div>{" "}
          </FormControl>
        </div>
        <div className="add-search-if-items-container">
          <div className="add-search-if-item flex-1-input margin-right-16">
            <Autocomplete
              id="Client select"
              value={
                clientProfiles !== null
                  ? clientProfiles.find((v) => v.id === clientId)
                  : ""
              }
              onChange={(event, newValue) => {
                setClientId(newValue ? newValue.id : "");
              }}
              options={clientProfiles || ""}
              getOptionLabel={(option) => `${option.clientName} (${option.id})`}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.clientName.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                  option.id.toString().includes(inputValue.trim())
                )
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label="Client"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["required"]}
                />
              )}
            />
          </div>
          <div className="add-search-if-item flex-1-input margin-right-16">
            <Autocomplete
              id="supplier"
              value={
                supplierName !== undefined
                  ? supplierList.find((v) => v.value === supplierName)
                  : ""
              }
              onChange={(event, newValue) => {
                setsupplierName(newValue ? newValue.value : "");
                loadPCCDetails(newValue ? newValue.value : "");
              }}
              options={supplierList}
              getOptionLabel={(option) =>
                option.value + " (" + option.label + ")"
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label="Supplier"
                  value={
                    supplierName !== undefined
                      ? supplierList.find((v) => v.value === supplierName)
                      : ""
                  }
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["required"]}
                />
              )}
            />
          </div>
          <div className="add-search-if-item flex-1-input margin-right-16">
            <Autocomplete
              id="Pseudo Code"
              value={
                pcc !== undefined ? pccList.find((v) => v.value === pcc) : ""
              }
              onChange={(event, newValue) => {
                setPCC(newValue ? newValue.value : "");
              }}
              options={pccList}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  value={
                    pcc !== undefined
                      ? pccList.find((v) => v.value === pcc)
                      : ""
                  }
                  label="Pseudo Code"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["required"]}
                />
              )}
            />
          </div>
          {/* <div className="add-search-if-item flex-1-input margin-right-16">
            <TextValidator
              variant="outlined"
              label="PNR"
              size="small"
              color="secondary"
              value={pnr}
              validators={["required", "matchRegexp:^[a-zA-Z0-9]+$"]}
              errorMessages={["required", "No special characters allowed"]}
              onChange={(event) => setPNR(event.target.value)}
            />
          </div> */}
        </div>
        <div className="text-start">
          <strong>
            Client Available Balance: {clientCurrency ? clientCurrency : " "}{" "}
            {clientAvailableBalance}
          </strong>
        </div>
      </div>
    </ValidatorForm>
  );
}
