import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
export default function ComissionParting({ flight }) {
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        Commission & Parting
                    </div>
                </div>
            </div>
            <Table>
                <thead className="mui-table-header">
                    <BootstrapTooltip1 title="IATA">
                        <th style={{ textAlign: 'center' }}>IATA</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="PLB">
                        <th style={{ textAlign: 'center' }}>PLB</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Dropnet">
                        <th style={{ textAlign: 'center' }}>Dropnet</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Booking Fee">
                        <th style={{ textAlign: 'center' }}>Booking Fee</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Client Tax IATA">
                        <th style={{ textAlign: 'center' }}>Client Tax IATA</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Client Tax PLB">
                        <th style={{ textAlign: 'center' }}>Client Tax PLB</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Client Tax Type">
                        <th style={{ textAlign: 'center' }}>Client Tax Type</th>
                    </BootstrapTooltip1>
                </thead>
                <tbody>
                    <tr>
                        <td align="center">{flight?.commissionParting?.iata}</td>
                        <td align="center">{flight?.commissionParting?.plb}</td>
                        <td align="center">{flight?.commissionParting?.dropnet}</td>
                        <td align="center">{flight?.commissionParting?.bookingFee}</td>
                        <td align="center">{flight?.commissionParting?.taxIATA}</td>
                        <td align="center">{flight?.commissionParting?.taxPLB}</td>
                        <td align="center">{flight?.commissionParting?.taxType}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}