import React, { useRef } from "react";
import "./AddBankInfoCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function AddBankInfoCard({name,setName}) {

  const BankInfoForm = useRef();

  return (
    <div className="add-bankInfo-card-container">
      <div className="add-bankInfo-card-title">BankInfo</div>
      <div className="create-bankInfo-contents-container">
        <div className="create-bankInfo-contents">
          <div className="add-bankInfo-if-items-container">
            {/* <div className="add-bankInfo-if-item margin-right-16">
              <div className="add-bankInfo-if-container">IF</div>
            </div> */}
            <div className="add-bankInfo-if-item flex-1-input  margin-right-16">
              {/* <ValidatorForm onError={(errors) => console.log(errors)} ref={BankInfoForm} onSubmit={ () => loadCategories() } >
                <TextValidator
                  variant="outlined"
                  label="Group Name"
                  size="small"
                  color="secondary"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["required"]}
                  required
                />
              </ValidatorForm> */}
            </div>
          </div>
        </div>
        <div className="add-bankInfo-button-container">
          <div className="add-bankInfo-button" onClick={() => BankInfoForm.current && BankInfoForm.current.submit()} >BankInfo</div>
        </div>
        {/* <div className="create-bankInfo-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
