import HTTPMethod from "http-method-enum";
import sendProtectedJSONRequest from "./sendProtectedJSONRequest";
import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";

export default function getSSRInfo(traceId, clientId, purchaseIds) {
  const isStaff = checkIfUserStaff();
  const headers = {
    "Content-Type": "application/json",
  };

  if (isStaff === true) {
    return axios.post(process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1/B2BSSRInfo",
      JSON.stringify({
        FlightRevalidationRq:
        {
          traceId: traceId,
          purchaseIds: purchaseIds
        },
        clientId: clientId
      }), { headers }
    ).then((response) => {
      if(response.status ===200){
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        }
        else {
          return response.data;
        }
      }else{
        return undefined;
      }
      
    }).catch((error) => {
      console.log(error);
    });
  }
  else {
    return axios.post(process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1/SSRInfo",
      JSON.stringify({
        traceId: traceId,
        purchaseIds: purchaseIds
      }), { headers }
    ).then((response) => {
      if(response.status==200){
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        }
        else {
          return response.data;
        }
      }else{
        return "Unable to fetch"
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
