export default function getTotalTripDuration(flightDetails) {
  let duration = 0;
  flightDetails.segGroups.forEach((segGroup) => {
    let startingTime = new Date(segGroup.segs[0].departureOn).getTime();
    let endingTime = new Date(
      segGroup.segs[segGroup.segs.length - 1].arrivalOn
    ).getTime();
    duration = duration + (endingTime - startingTime);
  });
  return duration;
}
