export default function sanitizePassengers(
  passengers,
  address,
  email,
  areaCode,
  mobile
) {
  let returnDetails = [];
  const passengerDetails = passengers;
  passengerDetails.forEach((element) => {
    // let extraServiceRefArrayList = [];
    // if(element.extraOutwardLuggage !== "")
    // {
    //   extraServiceRefArrayList.push({baggageRefNo:element.extraOutwardLuggage.split("|")[0], SegmentInfo: element.extraOutwardLuggage.split("|")[1]});
    // }

    // if(element.extraReturnLuggage !== "")
    // {
    //   extraServiceRefArrayList.push({baggageRefNo:element.extraReturnLuggage.split("|")[0], SegmentInfo: element.extraReturnLuggage.split("|")[1]});
    // }
    let array = [];

    if (
      element.extraOutwardLuggage &&
      element.extraOutwardLuggage.length !== 0
    ) {
      element.extraOutwardLuggage.forEach((item) => {
        array.push({
          baggageRefNo: item.freeText
            ? item.freeText
            : item.baggageRefNo
            ? item.baggageRefNo
            : "",
            mealsRefNo:"",
            SegmentInfo: item.cityPair
            ? item.cityPair
            : item.SegmentInfo
            ? item.SegmentInfo
            : "",
           
        });
      });
    }
    
    if (
      element.extraOutwardMeal &&
      element.extraOutwardMeal.length !== 0
    ){
      element.extraOutwardMeal.forEach((item) => {
        array.push({
          mealsRefNo: item.mealRefNo ? item.mealRefNo : "",
          SegmentInfo: item.cityPair
            ? item.cityPair
            : item.SegmentInfo
            ? item.SegmentInfo
            : "",
            baggageRefNo:""
        });
      });
    }

    returnDetails.push({
      title: element.title,
      firstName: element.firstName,
      lastName: element.lastName,
      email: email,
      dob: element.dob,
      genderType: element.gender === "Male" ? "M" : "F",
      paxType: element.paxType,
      areaCode: areaCode,
      mobile: mobile,
      mealPref: element.mealPreference,
      ktn: element.frequentFlyer,
      redressNo: element.tsaRedress,
      serviceReference: array,
      passportNumber: element.passportNumber,
      passengerNationality: element.passengerNationality,
      passportDOI: element.passportDOI,
      passportDOE: element.passportDOE,
      passportIssuedCountry: element.passportIssuedCountry,
      fema: element.fema,
      panCard: element.panCard,
    });
  });

  return returnDetails;
}
