import React from "react";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LabelIcon from "@mui/icons-material/Label";

export default function ContactDetails({ bookingDetails }) {
    return (
        <div className="flight-itenary">
            <div className='flight-itenary-title-container' style={{ textAlign: "center" }}>
                <p style={{ padding: "14px 24px", fontSize: '18px', fontWeight: 600, color: '#340f34', margin: 0 }}>
                    <span>Client Contact Details: </span>
                </p>
            </div>
            <div className="contact-details">
                <div className="contact-details-container">
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <AlternateEmailIcon fontSize="small"></AlternateEmailIcon>
                            </div>
                            <div className="contact-detail-text">
                                {bookingDetails &&
                                    bookingDetails?.flights &&
                                    bookingDetails?.flights[0] &&
                                    bookingDetails?.flights[0]?.passengers &&
                                    bookingDetails?.flights[0]?.passengers[0] &&
                                    bookingDetails?.flights[0]?.passengers[0]?.email

                                    ? bookingDetails?.flights[0]?.passengers[0]?.email
                                    : bookingDetails?.clientEmail ? bookingDetails?.clientEmail : ''}{" "}
                            </div>

                        </div>
                    </div>
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <PhoneIcon fontSize="small"></PhoneIcon>
                            </div>
                            <div className="contact-detail-text min-width-101">
                                {bookingDetails &&
                                    bookingDetails?.flights &&
                                    bookingDetails?.flights[0] &&
                                    bookingDetails?.flights[0]?.passengers &&
                                    bookingDetails?.flights[0]?.passengers[0] &&
                                    bookingDetails?.flights[0]?.passengers[0]?.mobile
                                    ? bookingDetails?.flights[0]?.passengers[0]?.mobile
                                    : bookingDetails?.clientContactNo ? bookingDetails?.clientContactNo : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flight-itenary-title-container' style={{ textAlign: "center" }}>
                <p style={{ padding: "14px 24px", fontSize: '18px', fontWeight: 600, color: '#340f34', margin: 0 }}>
                    <span>Contact Details: </span>
                </p>
            </div>
            <div className="contact-details">
                <div className="contact-details-container">
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <LocationOnRoundedIcon fontSize="small"></LocationOnRoundedIcon>
                            </div>
                            <div className="contact-detail-text">MUMBAI MH</div>
                        </div>
                    </div>
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <LabelIcon fontSize="small"></LabelIcon>
                            </div>
                            <div className="contact-detail-text">
                                www.getfares.com
                            </div>
                        </div>
                    </div>
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <AlternateEmailIcon fontSize="small"></AlternateEmailIcon>
                            </div>
                            <div className="contact-detail-text">
                                getfares@akbaroffshore.com
                            </div>
                        </div>
                    </div>
                    <div className="contact-detail-flex">
                        <div className="contact-detail-item">
                            <div className="contact-detail-icon contact-detail-opacity">
                                <PhoneIcon fontSize="small"></PhoneIcon>
                            </div>
                            <div className="contact-detail-text min-width-101">
                                +91 99941 91044
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}            