import React, {
  useEffect,
  useContext,
  useState,
  useReducer,
} from "react";
import Grid from "@material-ui/core/Grid";
import "./Searchbar.css";
import TripTypeRadioButtons from "../TripTypeRadioButtons/TripTypeRadioButtons";
import SearchContext from "../../contexts/Searchbar/SearchContext";
import PreferenceToggleButtons from "../PreferenceToggleButtons/PreferenceToggleButtons";
import BasicSearchBar from "../BasicSearchBar/BasicSearchBar";
import MulticitySearchBar from "../MulticitySearchBar/MulticitySearchBar";
import { useHistory } from "react-router";
import Switch from "@material-ui/core/Switch";
import Button from "@mui/material/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClassSelectMenu from "../ClassSelectMenu/ClassSelectMenu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import PeopleClassMenu from "../PeopleClassMenu/PeopleClassMenu";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { checkIfUserStaff } from "../../services/AuthService";
import CircularProgress from "@material-ui/core/CircularProgress";
import airlineDetails from "../../data/AirlineDetails";
import { Autocomplete } from "@mui/material";
import SearchItineraryParser from "../../providers/SearchItineraryParser/SearchItineraryParser";
import moment from "moment";
function SearchBar({ action, closeSearchDialog }) {
  const [search, setSearch] = useContext(SearchContext);
  const [directFlight, setDirectFlight] = useState(search.directFlights);
  const [brandedFares, setBrandedFares] = useState(search.brandedFares);
  const [itineraryText, setItineraryText] = useState("");
  const [includeAirlines, dispatchIncludeAirlines] = useReducer(
    reducerIncludeAirlines,
    search.includeAirlines
  );
  // const [excludeAirlines, dispatchExcludeAirlines] = useReducer(reducerExcludeAirlines, search.excludeAirlines)
  const [errorMessage, setErrorMessage] = useState("");
  const [clientProfiles, setclientProfiles] = useState(null);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [inputText, setInputText] = useState('');
  const [itineraryData, setItineraryData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pasteSearchEnabled, setPasteSearchEnabled] = useState(
    false
  )
  const history = useHistory();

  const [peopleClassMenuAnchorEl, setPeopleClassMenuAnchorEl] = useState(null);

  const [clientMenuAnchorEl, setClientMenuAnchorEl] = useState(null);
  let tempClientProfiles = [];
  function reducerIncludeAirlines(state, action) {
    switch (action.type) {
      case "includeAirlines":
        return action.newValue;
      default:
        return action.newValue;
    }
  }
  // function reducerExcludeAirlines(state, action) {

  //     switch (action.type) {
  //         case 'excludeAirlines':
  //             return action.newValue
  //         default:
  //             return action.newValue
  //     }
  // }
  const peopleClassMenuHandleClick = (event) => {
    setPeopleClassMenuAnchorEl(event.currentTarget);
  };

  const peopleClassMenuHandleClose = () => {
    setPeopleClassMenuAnchorEl(null);
  };

  const clientMenuHandleClick = (event) => {
    setClientMenuAnchorEl(event.currentTarget);
  };

  const clientMenuHandleClose = () => {
    setClientMenuAnchorEl(null);
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleDirectFlightChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.directFlights = event.target.checked;
    setSearch(existingSearch);
    setDirectFlight(event.target.checked);
  };
  const handlePasteSearchChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.pasteSearchEnabled = event.target.checked;
    setSearch((prevState) => ({ ...prevState, ...existingSearch }));
    // setDirectFlight(event.target.checked);
  };

  const handleBrandedFaresChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.brandedFares = event.target.checked;
    setSearch(existingSearch);
    setBrandedFares(event.target.checked);
  };

  useEffect(() => {

    let temp = JSON.parse(localStorage.getItem("lastSearch"));
    if (temp) {
      if (temp.type === 'RoundTrip' && !temp.return) {
        temp.return = moment(temp.depart).add(1, 'd')
      }
      setSearch(temp)
    } else {
      setSearch(search);
    }
    if (action && action === "modify") {
      temp ? localStorage.setItem("lastSearch", JSON.stringify(temp)) : localStorage.setItem("lastSearch", JSON.stringify(search));
    }
    if (checkIfUserStaff()) {
      getClients();
    }
  }, []);
  useEffect(() => {
    // console.log("searchbar search", search);
    localStorage.setItem("lastSearch", JSON.stringify(search));
    setPasteSearchEnabled((prevState) => search.pasteSearchEnabled)
    setIncludeAirlineDatafromSearch(search);
  }, [search]);

  useEffect(() => {
    console.log("includeAirlines", includeAirlines);
  }, [includeAirlines]);

  async function getClients() {

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {

        // added sort function to display client data in alphabetical order
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setclientProfiles(structuredClone(tempArray));
        tempClientProfiles = structuredClone(tempArray);
      });
  }
  const clientFilterOptions = (options, { inputValue }) => {
    const isInteger = /^\d+$/.test(inputValue);
    if (inputValue) {
      if (isInteger) {
        return options.filter(
          (option) => Number(option.id) === Number(inputValue)
        );
      } else {
        let temp = options.filter((option) =>
          option.clientName.toLowerCase().includes(inputValue.toLowerCase())
        );
        return temp ? temp : [];
      }
    } else {
      return tempClientProfiles;
    }
  };
  useEffect(() => {
    if (
      JSON.stringify(search.from) === JSON.stringify(search.to) ||
      checkDestinationsEquality()
    ) {
      setErrorMessage("From and To Airports cannot be same");
    } else if (checkEmptyFields()) {
      setErrorMessage("Fields cannot be empty");
    } else if (checkIfUserStaff() && search.clientId === "undefined") {
      setErrorMessage("Please select Client for search");
    } else if (
      search.type === "RoundTrip" &&
      ((search.depart === "" && search.return === "") ||
        search.depart === "" ||
        search.return === "" ||
        (search.depart === "null" && search.return === "null") ||
        search.depart === "null" ||
        search.return === "null")
    ) {
      setErrorMessage("Please select Departure and Return dates");
    } else {
      setErrorMessage("");
    }
  }, [search]);

  const checkDestinationsEquality = () => {
    let initialValue = false;
    if (search.destinations && search.destinations.length > 0) {
      search.destinations.map((destination) => {
        if (
          typeof destination.from !== "string" &&
          typeof destination.to !== "string" &&
          JSON.stringify(destination.from) === JSON.stringify(destination.to)
        ) {
          initialValue = true;
        }
      });
    }
    return initialValue;
  };
  const checkEmptyFields = () => {
    let initialValue = false;
    if (search.destinations && search.destinations.length > 0) {
      search.destinations.map((destination) => {
        if (
          (destination.from === "" &&
            destination.to &&
            Object.keys(destination.to).length > 0) ||
          (destination.to === "" &&
            destination.from &&
            Object.keys(destination.from).length > 0)
        ) {
          initialValue = true;
        }
      });
    }

    return initialValue;
  };

  const setClientId = (clientId) => {
    //console.log(clientId)
    const existingSearch = { ...search };
    existingSearch.clientId = clientId;
    setSearch(existingSearch);
  };
  const checkIfClient = () => {
    localStorage.setItem("lastSearch", JSON.stringify(search));
    // added if condition to handle bug{ without selecting client when user clicks on search it redirects to blank page}
    if (search.clientId && checkIfUserStaff() === true) {
      history.push(`/flight/search-results-new`);
    } else if (checkIfUserStaff() === false) {
      history.push(`/flight/search-results-new`);
    } else {
      alert("Kindly select a Client to Proceed");
    }
  };
  const handleSearchClick = () => {
    if (action && action === "modify") {
      closeSearchDialog();
    } else {
      checkIfClient();
    }
  };
  // useEffect(() => {
  //   if (action && action === "modify") {
  //     setIncludeAirlineDatafromSearch(search);
  //   }
  // }, [action, search]);
  const setIncludeAirlineDatafromSearch = async (data) => {
    if (data.includeCarrier !== undefined && data.includeCarrier !== "") {
      let list = [];
      data.includeCarrier
        .split(",")
        .map((air) => list.push(airlineDetails.find((el) => el.Code === air)));
      dispatchIncludeAirlines({ type: "includeAirlines", newValue: list });
    } else {
      dispatchIncludeAirlines({ type: "includeAirlines", newValue: [] });
    }
  };
  const setIncludeAirlineData = async (data) => {
    if (data) {
      let list = [];
      let string = "";
      data && data.length
        ? data.map((air) => {
          list.push(airlineDetails.find((el) => el.Code === air.Code));
          string.length === 0
            ? (string = air.Code)
            : (string = string.concat("," + air.Code));
        })
        : (string = "");
      const existingSearch = { ...search };
      existingSearch.includeCarrier = string;
      existingSearch.includeAirlines = list;
      setSearch(existingSearch);
      dispatchIncludeAirlines({ type: "includeAirlines", newValue: list });
    } else {
      const existingSearch = { ...search };
      existingSearch.includeAirlines = "";
      existingSearch.includeAirlines = [];
      setSearch(existingSearch);
      dispatchIncludeAirlines({ type: "includeAirlines", newValue: [] });
    }
  };
  const filterOptions = (options, { inputValue }) => {
    if (inputValue && inputValue.length <= 3) {
      return options.filter(
        (option) => option.Code.toLowerCase() === inputValue.toLowerCase()
      );
    } else {
      return options.filter((option) =>
        option.Name.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };


  return (
    <div>
      <SearchItineraryParser setModalIsOpen={setModalIsOpen} itineraryText={itineraryText} setItineraryText={setItineraryText} modalIsOpen={modalIsOpen} setInputText={setInputText} inputText={inputText} setItineraryData={setItineraryData} itineraryData={itineraryData} handleSearchClick={handleSearchClick} search={search} setSearch={setSearch} dispatchIncludeAirlines={dispatchIncludeAirlines} />
      <Grid container className="max-width">
        {!action && action !== "modify" && <Grid item xs={2}></Grid>}
        <Grid item xs={action && action === "modify" ? 12 : 8}>
          <div
            className={
              "search-bar-container " +
              (action && action === "modify" ? "pb-32" : "")
            }
          >
            <div className="horizontal-flex pointer">
              <div className="flex-display">
                <TripTypeRadioButtons search={search} setSearch={setSearch} />
                <div className="pax-dropdown ml-24">
                  <Popover
                    id="peopleClassMenu"
                    anchorEl={peopleClassMenuAnchorEl}
                    keepMounted
                    open={Boolean(peopleClassMenuAnchorEl)}
                    onClose={peopleClassMenuHandleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <PeopleClassMenu search={search} setSearch={setSearch} />
                  </Popover>
                  <div
                    className="pax-text"
                    onClick={peopleClassMenuHandleClick}
                  >
                    {search?.adult + search?.children + search?.infant}{" "}
                    {search.adult + search.children + search.infant > 1
                      ? "Travellers"
                      : "Traveller"}
                  </div>
                  <div
                    className="pax-icon"
                    onClick={peopleClassMenuHandleClick}
                  >
                    <ArrowDropDownIcon className="pax-icon-fill"></ArrowDropDownIcon>
                  </div>
                </div>
                <div className="ml-24">
                  <ClassSelectMenu search={search} setSearch={setSearch} />
                </div>
                {checkIfUserStaff() && (
                  <div className="pax-dropdown ml-24">
                    {clientProfiles ? (
                      <Popover
                        id="peopleClassMenu"
                        anchorEl={clientMenuAnchorEl}
                        keepMounted
                        open={Boolean(clientMenuAnchorEl)}
                        onClose={clientMenuHandleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Paper>
                          <Autocomplete
                            id="combo-box-demo"
                            value={
                              clientProfiles &&
                              (clientProfiles.find(
                                (v) => v.id === search.clientId
                              ) ||
                                null)
                            }
                            onChange={(event, newValue) => {
                              setClientId(newValue ? newValue.id : "");
                            }}
                            filterSelectedOptions
                            filterOptions={clientFilterOptions}
                            options={clientProfiles}
                            getOptionLabel={(option) => option.clientName}
                            style={{ width: 500, padding: "30px" }}
                            renderInput={(params) => (
                              <TextField
                                value={search.clientId}
                                {...params}
                                label="Select Client"
                                variant="outlined"
                              />
                            )}
                          />
                        </Paper>
                      </Popover>
                    ) : (
                      <CircularProgress size={20} />
                    )}

                    <div className="pax-text" onClick={clientMenuHandleClick}>

                      {search.clientId && clientProfiles
                        ? clientProfiles.find((v) => v.id === search.clientId).clientName

                        : "Select Client"}
                    </div>
                    <div className="pax-icon" onClick={clientMenuHandleClick}>
                      <ArrowDropDownIcon className="pax-icon-fill"></ArrowDropDownIcon>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-display">
                <div className="align-right">
                  <PreferenceToggleButtons
                    search={search}
                    setSearch={setSearch}
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div className="basic-search-bar-container">
              <BasicSearchBar search={search} setSearch={setSearch} />
              {search.type === "Multidestination" &&
                search.destinations.length > 0 && (
                  <div>
                    {search.destinations.map((destination) => (
                      <MulticitySearchBar
                        key={destination.id}
                        destination={destination}
                        search={search}
                        setSearch={setSearch}
                      />
                    ))}
                  </div>
                )}
            </div>
            <div className="flex-display">
              <div className="direct-flight-toggle">
                <FormControlLabel
                  className="pr-12"
                  control={
                    <Switch
                      checked={pasteSearchEnabled}
                      onChange={handlePasteSearchChange}
                    />
                  }
                  label="Paste Search Enabled"
                  labelPlacement="start"
                />
              </div>
              <div className="direct-flight-toggle">
                <FormControlLabel
                  className="pr-12"
                  control={
                    <Switch
                      checked={directFlight}
                      onChange={handleDirectFlightChange}
                    />
                  }
                  label="Direct Flights"
                  labelPlacement="start"
                />
              </div>
              <div className="direct-flight-toggle">
                <FormControlLabel
                  className="pr-12"
                  control={
                    <Switch
                      checked={brandedFares}
                      onChange={handleBrandedFaresChange}
                    />
                  }
                  label="Branded Fares"
                  labelPlacement="start"
                />
              </div>
              <div className="pax-dropdown ml-24">
                <Autocomplete
                  id="ex airline select"
                  multiple
                  className="airlineSelect"
                  style={{ maxWidth: "30vw", minWidth: "25vw" }}
                  limitTags={1}
                  value={includeAirlines && includeAirlines}
                  onChange={(event, value) => {
                    setIncludeAirlineData(value);
                  }}
                  filterOptions={filterOptions}
                  options={airlineDetails.filter(
                    (airline) => airline.Code !== "All"
                  )}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  filterSelectedOptions
                  getOptionDisabled={(option) => {
                    if (includeAirlines && includeAirlines.length) {
                      if (
                        includeAirlines.some(
                          (airline) => airline.Code === "All"
                        ) &&
                        option.Code !== "All"
                      ) {
                        return true;
                      } else if (
                        includeAirlines.some(
                          (airline) => airline.Code !== "All"
                        ) &&
                        option.Code === "All"
                      ) {
                        return true;
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Airline Prefrence"
                      value={includeAirlines}
                      variant="filled"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {errorMessage.length > 0 && (
              <div className="error-text">{errorMessage}</div>
            )}
            <div className="preference-container"></div>
            <Button variant="outlined" className="itienary-search-block " onClick={() => setModalIsOpen(true)}>Paste Itinerary Text</Button>

            <div
              className={
                action && action === "modify"
                  ? "modify-search-button"
                  : "search-button "
              }
            >
              <div
                id="searchButton"
                className={
                  "search-block " +
                  (errorMessage.length > 0
                    ? "disable-search-button"
                    : search.from === "From" ||
                      search.to === "To" ||
                      search.from === "" ||
                      search.to === "" ||
                      search.from === "null" ||
                      search.to === "null"
                      ? "disable-search-button"
                      : pasteSearchEnabled && (!itineraryText) ? "disable-search-button"
                        // : pasteSearchEnabled && (!itineraryText || search.itineraryText) ? "disable-search-button"
                        : search.from === search.to ? "disable-search-button"
                          : "")
                }
                onClick={handleSearchClick}
              >
                Search
              </div>
            </div>
          </div>
        </Grid>
        {!action && action !== "modify" && <Grid item xs={2}></Grid>}
      </Grid>
    </div>
  );
}

export default SearchBar;
