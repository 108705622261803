import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { checkIfUserStaff } from "../../services/AuthService";
import "./autoImps.css";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
export default function AutoImps() {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="impsPaymentTitleContainer">
      <div className="impsPaymentTitle">
        <AccountBalanceIcon
          className="icon-fill pointer"
          fontSize="medium"
        ></AccountBalanceIcon>
        <span className="impsPaymentHeading">AXIS BANK</span>
      </div>
      <div className="impsInfo">
        <p>
          now get auto account upload for IMPS/NEFT/RTGS Recharge, no need to
          send mail or deposit Request
        </p>
      </div>
      <div className="impsSteps">
        <div className="impsStepsHead">
          <span>Below are the steps to recharge via Online banking</span>
        </div>
        <ol style={{ listStyleType: "disc" }}>
          <li>
            Register the given account number as beneficiary in your Net banking
            login.
          </li>
          <li>
            Once this account gets activated you can do IMPS, NEFT or RTGS
            transaction to get auto upload Funds.
          </li>
          <li>
            Account fund upload Email confirmation will be sent on registered
            email address.
          </li>
        </ol>
      </div>
      <div className="impsAccountInfotable">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  Bank Holder Name
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  Akbar Offshore Pvt Ltd
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  Account Number
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  AOPLGFC
                  {user?.clientref_id.length === 5
                    ? user?.clientref_id
                    : user?.clientref_id.length === 4
                    ? "0" + user?.clientref_id
                    : user?.clientref_id.length === 3
                    ? "00" + user?.clientref_id
                    : user?.clientref_id.length === 2
                    ? "000" + user?.clientref_id
                    : user?.clientref_id.length === 1 &&
                      "0000" + user?.clientref_id}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  BankName
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">Axis Bank</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  IFSC Code
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">UTIB0CCH274</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  BranchAddress{" "}
                  {/* <span className="f600 pl-5 fs16 fontMono">
                  Centralised Collection Hub,Mumbai,Maharashtra
                </span> */}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  Centralised Collection Hub,Mumbai,Maharashtra
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="impsSteps">
        <div className="impsStepsHead">
          <span>Notes</span>
        </div>
        <ol style={{ listStyleType: "disc" }}>
          <li>
            The Virtual account is for only online transfer to INR bank account
          </li>
          <li>
            Overseas clients cannot do payment without swift code, above one is
            only for domestic transfers(Within India).
          </li>
        </ol>
      </div>
      {/* <table style={{ width: '100%' }}>
                        <tr>
                            <td style={{ width: '40%' }}></td>
                            <td className="terms">
                                <p>By Paying </p>
                                <p>I agree with <a>Booking Policies</a>,<a>Terms & Conditions</a>,<a>Privacy Policies</a></p>
                            </td>
                        </tr>
                    </table> */}
    </div>
  );
}
