const TimeZones = [
  {
    value: "-12.00",
    text: "[GMT-12] Baker Island Time",
  },

  {
    value: "-11.00",
    text: "[GMT-11] Niue , Samoa Standard Time",
  },

  {
    value: "-10.00",
    text: "[GMT-10] Hawaii-Aleutian , Cook Island Time",
  },

  {
    value: "-9.50",
    text: "[GMT-9:30] Marquesas Islands Time",
  },

  {
    value: "-9.00",
    text: "[GMT-9] Alaska , Gambier Island Time",
  },

  {
    value: "-8.00",
    text: "[GMT-8] USA , Pacific Standard Time",
  },

  {
    value: "-7.00",
    text: "[GMT-7] USA , Mountain Standard Time",
  },

  {
    value: "-6.00",
    text: "[GMT-6] USA , Central Standard Time",
  },

  {
    value: "-5.00",
    text: "[GMT-5] USA , Eastern Standard Time",
  },

  {
    value: "-4.50",
    text: "[GMT-4:30] Venezuelan Standard Time",
  },

  {
    value: "-4.00",
    text: "[GMT-4] Atlantic Standard Time",
  },

  {
    value: "-3.50",
    text: "[GMT-3:30] foundland Standard Time",
  },

  {
    value: "-3.00",
    text: "[GMT-3] Amazon , Central Greenland Time",
  },

  {
    value: "-2.00",
    text: "[GMT-2] Fernando de Noronha , South Georgia , the South Sandwich Islands Time",
  },

  {
    value: "-1.00",
    text: "[GMT-1] Azores , Cape Verde , Eastern Greenland Time",
  },

  {
    value: "0",
    text: "[GMT] Greenwich Mean Time",
  },

  {
    value: "1.00",
    text: "[GMT+1] Central European , West African Time",
  },

  {
    value: "2.00",
    text: "[GMT+2] Eastern European , Central African Time",
  },

  {
    value: "3.00",
    text: "[GMT+3] Moscow Standard , Eastern African Time",
  },

  {
    value: "3.50",
    text: "[GMT+3:30] Iran Standard Time",
  },

  {
    value: "4.00",
    text: "[GMT+4] Gulf , Samara Standard Time",
  },

  {
    value: "4.50",
    text: "[GMT+4:30] Afghanistan Time",
  },

  {
    value: "5.00",
    text: "[GMT+5] Pakistan, Yekaterinburg Standard Time",
  },

  {
    value: "5.5",
    text: "[GMT+5:30] Indian , Sri Lanka Standard Time",
  },

  {
    value: "5.75",
    text: "[GMT+5:45] Nepal Time",
  },

  {
    value: "6.00",
    text: "[GMT+6] Bangladesh , Bhutan , Novosibirsk Standard Time",
  },

  {
    value: "6.50",
    text: "[GMT+6:30] Cocos Islands , Myanmar Time",
  },

  {
    value: "7.00",
    text: "[GMT+7] Indochina , Krasnoyarsk Standard Time",
  },

  {
    value: "8.00",
    text: "[GMT+8] Chinese Standard , Australian Western , Irkutsk Standard Time",
  },

  {
    value: "8.75",
    text: "[GMT+8:45] Southeastern Western Australia Standard Time",
  },

  {
    value: "9.00",
    text: "[GMT+9] Japan , Korea , Chita Standard Time",
  },

  {
    value: "9.50",
    text: "[GMT+9:30] Australian Central Standard Time",
  },

  {
    value: "10.00",
    text: "[GMT+10] Australian Eastern , Vladivostok Standard Time",
  },

  {
    value: "10.50",
    text: "[GMT+10:30] Lord Howe Standard Time",
  },

  {
    value: "11.00",
    text: "[GMT+11] Solomon Island , Magadan Standard Time",
  },

  {
    value: "11.50",
    text: "[GMT+11:30] Norfolk Island Time",
  },

  {
    value: "12.00",
    text: "[GMT+12]  Zealand , Fiji , Kamchatka Standard Time",
  },

  {
    value: "12.75",
    text: "[GMT+12:45] Chatham Islands Time",
  },

  {
    value: "13.00",
    text: "[GMT+13] Tonga , Phoenix Islands Time",
  },

  {
    value: "14.00",
    text: "[GMT+14] Line Island Time",
  },
];

export default TimeZones;
