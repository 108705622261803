import React, { useState, useEffect, useRef } from "react";
import "./OperatingOND.css";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import useConfigurationTableCell from "../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../hooks/useConfigurationTableRow";
import { Container, Button, Grid, Divider } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function OperatingOND() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState();
  const [fileList, setFileList] = useState(null);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  
  // Ref object to reference the input element
  const inputFile = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setIsSuccess(false);
    // Checking if the file type is allowed or not
    const allowedTypes = ["text/csv"];
    if (!allowedTypes.includes(selectedFile?.type)) {
      setIsError(true);
      setErrorMsg("Only CSV File are allowed.");
      return;
    } else {
      let flie_str = event.target.files[0].name;
      let file_ext = flie_str.split(".", 2);
      setFileName(file_ext[1]);
    }

    setIsError(false);
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isError) return;
    setErrorMsg("");

    // Checking if the file has been selected
    if (!file) {
      setIsError(true);
      setErrorMsg("Please select a file.");
      return;
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("fileBelongsTo", "Vendor");
      formData.append("refId", "OND1001");
      formData.append("fileType", "Document");
      try {
        const res = await axios.post(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/AirlinesOperatingSector/GetAOSCSVFile/",
          formData
        );
        setAlert({
          open: true,
          message: "File Uploaded Successfully",
          severity: "success",
        });
        getFiles();
        setFile("");
        setFileName("");
        handleReset();
      } catch (ex) {
        setAlert({
          open: true,
          message: "File Upload Error",
          severity: "error",
        });
      }
    }

    setIsError(false);
    setIsSuccess(true);
  };

  useEffect(() => {
    getFiles();
  }, []);

  // Function to reset the input element
  const handleReset = () => {
    if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
    }
};

  async function getFiles() {
    let refId = "OND1001";
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
          "api/Configuration/AirlinesOperatingSector/GetAOSFileInfo/" +
          refId
      )
      .then((response) => {
        setFileList(response.data);
      });
  }

  return (
    <Container className="operating-ond-container">
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="operating-ond-heading">Operating OND</div>
          <Divider style={{ marginTop: "5px" }} />
          <div className="operating-ond-content">
            <div className="">
              <form onSubmit={handleSubmit}>
                <input type="file" accept=".csv"  ref={inputFile} onChange={handleFileChange} />
                <input
                  type="submit"
                  className="btn-primary"
                  value="Upload File"
                />
              </form>
            </div>
            {isError && <div className="error-text">{errorMsg}</div>}
            {/* {isSuccess && <div className="success-text">Valid File Type</div>} */}
          </div>
          <div className="">
            {fileList !== null ? (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell>Last Modified By</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          Airline Operating OND
                        </div>
                        <div className="table-cell-description">
                          {fileList.manageFiles.fileName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {fileList.manageFiles.fileType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-description">
                          Last Modified by:{" "}
                          <span style={{ color: "black" }}>
                            {fileList.modefiedBy}
                          </span>
                        </div>
                        <div className="table-cell-description">
                          Last Modified on:{" "}
                          {fileList.manageFiles.modified.replace("T", " , ")}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="right">
                        <div className="table-cell-title">
                          <Button
                            variant="contained"
                            size="small"
                            className="btn-secondary"
                            style={{
                              textTransform: "none",
                            }}
                            href={fileList.manageFiles.imagePath}
                            target="_blank"
                            download
                          >
                            Download
                          </Button>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        <div className="valid-text text-center">
                          There are no file, please upload file.
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
