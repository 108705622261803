import React, { useState, useEffect } from "react";
import "./SupplierCommissionBlockTable.css";
import Table from "@material-ui/core/Table";
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import cabinClassList from "../../../../data/CabinClass";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";
import SkeletonView from '../../../SkeletonView/SkeletonView';
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function SupplierCommissionBlockTable({
  openDrawer,
  setAction,
  commissionBlocks,
  name,
  setPage,
  page,
  pccList,
  loadCommissionBlocks,
  filters,
  filteredCommissionBlocks
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    loadCommissionBlocks();
  }, [page, rowsPerPage]);


  return (
    <div className='supplier-listing'>
      {commissionBlocks ? (
        commissionBlocks.length === 0 ? (
          <>
            <Alert severity='error'>No record found.</Alert>
          </>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper} className='table-paper-styles'>
              <Table className='table-background'>
                <TableHead>
                  <TableRow>
                    <TableCell size='auto-fill'>Supplier Ref ID</TableCell>
                    <TableCell>Pseudo Code</TableCell>
                    <TableCell>Airline</TableCell>
                    <TableCell>FareType</TableCell>
                    <TableCell>Cabin Class</TableCell>
                    <TableCell align='center' width='130'>Travel Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell width={750}>Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCommissionBlocks && filteredCommissionBlocks.length > 0 ? (
                    filteredCommissionBlocks
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((blocks) => (
                        <StyledTableRow
                          key={JSON.stringify(blocks.supplierCommissionBlocks)}
                          onClick={() => {
                            setAction("view");
                            openDrawer(blocks.supplierCommissionBlocks);
                          }}>
                          <StyledTableCell><div className='table-cell-title'>{blocks.supplierCommissionBlocks.id}</div></StyledTableCell>
                          <StyledTableCell>
                            <div className='table-cell-title'>{blocks.supplierCommissionBlocks.pccCode}</div>
                            <div className='table-cell-description'>
                              {pccList
                                .filter((id) =>
                                  id.value.startsWith(
                                    blocks.supplierCommissionBlocks.pccCode +
                                    ","
                                  )
                                )
                                .map(({ label }) => {
                                  return label?.match(/\(([^)]+)\)/)?.length > 1
                                    ? label?.match(/\(([^)]+)\)/)[1]
                                    : label?.match(/\(([^)]+)\)/)?.length === 1
                                      ? label?.match(/\(([^)]+)\)/)[0]
                                      : "";
                                })}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell><div className='table-cell-title'>{blocks.supplierCommissionBlocks.airline}</div></StyledTableCell>
                          <StyledTableCell><div className='table-cell-title'>{blocks.supplierCommissionBlocks.fareType}</div></StyledTableCell>
                          <StyledTableCell>
                            <div className='table-cell-title'>
                              {cabinClassList &&
                                blocks.supplierCommissionBlocks.cabinClass
                                  .split(",")
                                  .map((item, i) =>
                                    cabinClassList
                                      .filter((id) => id.value === item)
                                      .map(({ label }) => label)
                                  )
                                  .join(", ")}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component='th' scope='row' align='center'>
                            <div className='table-cell-title pointer'>
                              {blocks.supplierCommissionBlocks.travelType}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className='table-cell-title'>
                              {blocks.supplierCommissionBlocks.isActive ? (
                                <Chip
                                  label='Active'
                                  color='primary'
                                  size='small'
                                  style={{ backgroundColor: "green" }}
                                />
                              ) : (
                                <Chip
                                  label='In Active'
                                  color='primary'
                                  style={{ backgroundColor: "red" }}
                                />
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className='table-cell-description'>
                              Created by:{" "}
                              <span style={{ color: "black" }}>
                                {blocks.createdBy}
                              </span>
                            </div>
                            <div className='table-cell-description'>
                              Created on:{" "}
                              {blocks.supplierCommissionBlocks.created.replace(
                                "T",
                                " , "
                              )}
                            </div>
                            <div className='table-cell-description'>
                              Last Modified by:{" "}
                              <span style={{ color: "black" }}>
                                {blocks.modefiedBy}
                              </span>
                            </div>
                            <div className='table-cell-description'>
                              Last Modified on:{" "}
                              {blocks.supplierCommissionBlocks.modified.replace(
                                "T",
                                " , "
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                  ) : (
                    <StyledTableRow>
                      <TableCell colSpan={8} align="center">
                        No Results
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredCommissionBlocks.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />

          </React.Fragment>
        )
      ) : (
        <>
          <Row>
            <div className='col-12 text-center py-5 loader-cls'>
              <Box className='loader-box-content'>
                <div className='flt-main-heading py-3'>
                  <h3>Please Wait...</h3> We're fetching Supplier Commission
                  Block List...
                </div>
                <CircularProgress />
              </Box>
            </div>
          </Row>
        </>
      )}
    </div>
  );
}
