import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";

const getDetailedFareRule = async (traceId, additionalClientmarkup, clientId, purchaseIds, flag) => {
    if (flag === "viewBooking") {
        try {
            const response = await axios.get(
                process.env.REACT_APP_CORE_URL + 'Flights/Booking/GetBooking/v1/GetFareRule/' + traceId
            )
            return response.data;
        } catch (err) {
            console.error("Error in API call:", err.message);
            return null;
        }
    } else {
        let requestBody = {};
        if (checkIfUserStaff()) {
            requestBody = {
                FlightRevalidationRq: {
                    traceId: traceId,
                    purchaseIds: [purchaseIds],
                    additionalClientmarkup: additionalClientmarkup,
                },
                clientId: checkIfUserStaff() ? clientId : undefined,
            };
        } else {
            requestBody = {
                TraceId: traceId,
                purchaseIds: [purchaseIds],
                clientId: checkIfUserStaff() ? clientId : undefined,
            };
        }


        if (flag === "reviewbooking" && requestBody.FlightRevalidationRq) {
            requestBody.FlightRevalidationRq.isRevalidation = true;
        } else if (flag === "reviewbooking" && !checkIfUserStaff()) {
            requestBody.isRevalidation = true;
        }

        const headers = {
            "Content-Type": "application/json",
        };
        let URL = checkIfUserStaff() ? `${process.env.REACT_APP_CORE_URL}Flights/Revalidation/v1/B2BFareRule` : `${process.env.REACT_APP_CORE_URL}Flights/Revalidation/v1/FareRule`;
        try {
            const response = await axios.post(
                URL,
                requestBody,
                { headers }
            );

            return response.data;
        } catch (err) {
            console.error("Error in API call:", err.message);
            return null;
        }
    }
};

export default getDetailedFareRule;
