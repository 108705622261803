import React from "react";
import "./Filters.css";
import NumberOfStops from "../FilterComponents/NumberOfStops";
import FlightPrice from "../FilterComponents/FlightPrice";
import FareType from "../FilterComponents/FareType";
import ConnectingIn from "../FilterComponents/ConnectingIn";
import Airlines from "../FilterComponents/Airlines";
import DepartArrival from "../FilterComponents/DepartArrival";
import findAirportbyCode from "../../../functions/findAirportByCode";
import ItineraryMarkup from "../FilterComponents/ItineraryMarkup";
import { Button } from "@mui/joy";
import MonitorIcon from '@mui/icons-material/Monitor';
import OpenInNew from '@mui/icons-material/OpenInNew';

function Filters({
  search,
  setSearch,
  toggleDrawer,
  currencyCode,
  itineraryMarkup,
  setItineraryMarkup,
  markup,
  setMarkup,
}) {
  const openNewTab = () => {
    window.open(`${window.location.origin}/flight/search-results-new`, '_blank')
  }
  return (
    <div className="search-filters-container">
      <Button variant="outlined" className="defaultButton" size="sm" onClick={openNewTab} startDecorator={<OpenInNew />} >
        new tab
      </Button>
      {/* <Button startDecorator={<MonitorIcon />} >
        Open In New Window
      </Button> */}
      <div className="filter-item-container">
        <div className="filter-item-title">Stops</div>
        <div className="filter-item">
          <NumberOfStops
            search={search}
            setSearch={setSearch}
            toggleDrawer={toggleDrawer}
            currencyCode={currencyCode}
          />
        </div>
      </div>
      {search.filters.arrivalDepart &&
        search.filters.arrivalDepart.length > 0 &&
        search.filters.arrivalDepart.map((journey, index) => (
          <div
            className={"filter-item-container " + (index > 0 ? "mt-32" : "")}
            key={`filetr_Item_Container_` + index}
          >
            <div className="filter-item-title">
              {journey.type === "depart" ? "Departure from" : "Arrival at"}{" "}
              {findAirportbyCode(journey.city)}
            </div>
            <div className="filter-item  mt-24 ">
              <DepartArrival
                key={`DepartArrival_` + index}
                type="departure"
                journey={journey}
                search={search}
                setSearch={setSearch}
                journeyIndex={index}
                toggleDrawer={toggleDrawer}
              />
            </div>
          </div>
        ))}
      {search.filters.fareType.length > 0 && (
        <div className="filter-item-container  mt-32">
          <div className="filter-item-title">Fare Type</div>
          <div className="filter-item">
            <FareType
              search={search}
              setSearch={setSearch}
              toggleDrawer={toggleDrawer}
            />
          </div>
        </div>
      )}
      {search.filters.airlines.length > 0 &&
        search.filters.airlines.filter(
          (airline) => airline.airline !== undefined
        ).length > 0 && (
          <div className="filter-item-container">
            <div className="filter-item-title">Airlines</div>
            <div className="filter-item">
              <Airlines
                search={search}
                setSearch={setSearch}
                toggleDrawer={toggleDrawer}
                currencyCode={currencyCode}
              />
            </div>
          </div>
        )}
      {search.filters.price.length > 0 &&
        search.filters.price[0] !== search.filters.price[1] && (
          <div className="filter-item-container">
            <div className="filter-item-title">Price</div>
            <div className="filter-item mt-24 ml-8 mr-8">
              <FlightPrice
                search={search}
                setSearch={setSearch}
                toggleDrawer={toggleDrawer}
                currencyCode={currencyCode}
              />
            </div>
          </div>
        )}
      {search.filters.connectingIn.length > 0 && (
        <div className="filter-item-container">
          <div className="filter-item-title">Connecting In</div>
          <div className="filter-item">
            <ConnectingIn
              search={search}
              setSearch={setSearch}
              toggleDrawer={toggleDrawer}
            />
          </div>
        </div>
      )}
      <div className="filter-item-container border p-2">
        {/* <div className="filter-item-title">Markup - Itinerary<span style={{textDecoration:'underline',color:'blue'}} onClick={handleReset}>Reset</span></div> */}
        <div className="filter-item">
          <ItineraryMarkup
            itineraryMarkup={itineraryMarkup}
            setItineraryMarkup={setItineraryMarkup}
            markup={markup}
            setMarkup={setMarkup}
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
