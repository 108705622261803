const getMiniRulesData = (miniRulesResp, mr_type) => {
    const getMiniRuleRespData = [];
    if (miniRulesResp != undefined) {
        miniRulesResp.forEach(function (item) {
            if (getMiniRuleRespData.indexOf(item) < 0) {
                if (!getMiniRuleRespData.some((obj) => obj === item)) {
                    getMiniRuleRespData.push(item);
                }
            }
        });
    }

    const uniqueMiniRuleData = getMiniRuleRespData.filter((value, index) => {
        if (
            value.changeAllowed != false ||
            value.cancelAllowed != false ||
            value.canAmt != 0 ||
            value.exgAmt != 0
        ) {
            if (mr_type == "Cancellation") {
                if (value.cancelAllowed == true) {
                    const _value = JSON.stringify(value);
                    return (
                        index ===
                        getMiniRuleRespData.findIndex((obj) => {
                            return JSON.stringify(obj) === _value;
                        })
                    );
                }
            } else {
                if (value.changeAllowed == true) {
                    const _value = JSON.stringify(value);
                    return (
                        index ===
                        getMiniRuleRespData.findIndex((obj) => {
                            return JSON.stringify(obj) === _value;
                        })
                    );
                }
            }
        }
    });

    //console.log("uniqueMiniRuleData Len: "+uniqueMiniRuleData.length+" Type "+ mr_type)
    const removeDuplicateMiniruleInfo = [
        ...new Map(
            uniqueMiniRuleData.map((item) => [JSON.stringify(item), item])
        ).values(),
    ];
    // console.log("uniqueMiniRuleData", uniqueMiniRuleData);
    return removeDuplicateMiniruleInfo;
};


const filterAdultCancellation = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.cancelAllowed) {
        return miniRule;
    }
};
const filterChildCancellation = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.cancelAllowed) {
        return miniRule;
    }
};
const filterInfantCancellation = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.cancelAllowed) {
        return miniRule;
    }
};
const filterAdultChangeDate = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.changeAllowed) {
        return miniRule;
    }
};
const filterChildChangeDate = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.changeAllowed) {
        return miniRule;
    }
};
const filterInfantChangeDate = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.changeAllowed) {
        return miniRule;
    }
};

export { getMiniRulesData, filterAdultCancellation, filterChildCancellation, filterInfantCancellation, filterAdultChangeDate, filterChildChangeDate, filterInfantChangeDate }