import React, { useEffect, useState, Fragment } from "react";
import "./ReceiptDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp, Image } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { checkIfUserStaff } from "../../../services/AuthService";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import AntTabs from "../../../hooks/useTabsStyles";
import AntTab from "../../../hooks/useTabStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { shadows } from "@mui/system";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@material-ui/core/Divider";
import logo from "../../../assets/upi.png";
import upibarcode from "../../../assets/upibarcode.png";
import axios from "axios";
import { formatDate } from "../../../services/dateFormatter";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ReceiptDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
}) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [id, setId] = useState(0);
  const [mode, setMode] = useState("NEFT");
  const [bankMode, setBankMode] = useState("CC");
  const [clientId, setClientId] = useState(0);
  const [transactionDate, setTransactionDate] = useState();
  const [deposited, setDeposited] = useState("");
  const [transactedAmount, setTransactedAmount] = useState(0);
  const [transactedId, setTransactedId] = useState("");
  const [incentives, setIncentives] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState(0);

  const [accBooks, setAccBooks] = useState("");
  const [roe, setROE] = useState(0);
  const [bankCharges, setBankCharges] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [clientProfiles, setclientProfiles] = useState(null);

  const handleChange = (event) => {
    setMode(event.target.value);
  };
  const handleChangeBA = (event) => {
    setBankMode(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box className="ml-8 mr-8">
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  useEffect(() => {
    if (action === "edit" || action === "view") {
      setName(selectedItem.name);
      setDescription(selectedItem.description);
      setIsActive(selectedItem.isActive);
      setId(selectedItem.id);
    } else {
      setName("");
      setDescription("");
      setIsActive(true);
      setId(0);
    }
  }, [toggleDrawer, action]);

  //getClients();

  async function getClients() {
    let url = "";
    //let url = process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile?sorts=created";
    if (checkIfUserStaff()) {
      url =
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created";
    }

    try {
      await axios.get(url).then((response) => {
        setclientProfiles(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdate() {
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/Receipt/" +
            selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,
            name: name === undefined ? "" : name,
            // transactionDate: transactionDate ? (moment(transactionDate).set({ hour: 10, minute: 30, second: 0, millisecond: 0 })) : null,
            description: description === undefined ? "" : description,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/Receipt",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            name: name === undefined ? "" : name,
            description: description === undefined ? "" : description,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          handleSnackbarToggle();
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <AntTabs value={value} onChange={handleChangeTab}>
        <AntTab label="UPI" />
        <AntTab label="Direct Pay" />
        <AntTab label="Bank Details" />
        <AntTab label="Update Transaction" />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <div className="receipt-heading-container receipt-title-flex-container">
          <img
            alt="upi logo"
            style={{
              width: "160px",
              marginLeft: "24px",
              marginRight: "48px",
            }}
            src={logo}
          />
        </div>
        <div className="receipt-heading-container receipt-title-flex-container">
          <img
            alt="upi barcode"
            style={{
              width: "360px",
              marginLeft: "200px",
              marginRight: "48px",
            }}
            src={upibarcode}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="receipt-heading-container receipt-title-flex-container">
          <div>CC and Debit card payment</div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="receipt-heading-container receipt-title-flex-container">
          <div></div>
        </div>
        <div className="supplier-view-edit-drawer-input-container">
          <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
            <Grid spacing={1} item xs={8}>
              <Paper
                style={{ padding: "10px" }}
                variant="outlined"
                elevation={0}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "10px" }}>
                    <div className="search-heading">Bank Name: </div>
                    <Divider
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="search-heading">Bank Branch: </div>
                    <Divider
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="search-heading">Credit Card Number: </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          </div>
        </div>
        <div className="supplier-view-edit-drawer-input-container">
          <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
            <Grid spacing={1} item xs={8}>
              <Paper
                style={{ padding: "10px" }}
                variant="outlined"
                elevation={0}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "10px" }}>
                    <div className="search-heading">Bank Name: </div>
                    <Divider
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="search-heading">Bank Branch: </div>
                    <Divider
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    />
                    <div className="search-heading">Credit Card Number: </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="receipt-heading-container receipt-title-flex-container">
          <div>Update Transacted Information</div>
          {action === "view" && (
            <div className="receipt-heading-icons">
              <IconButton fontSize="small">
                <EditIcon
                  fontSize="small"
                  className="icon-blue"
                  onClick={() => setAction("edit")}
                ></EditIcon>
              </IconButton>
            </div>
          )}
        </div>
        <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={handleUpdate}
        >
          <div className="receipt-view-edit-drawer-container">
            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Autocomplete
                    id="Client select"
                    value={
                      clientProfiles !== null
                        ? clientProfiles.find((v) => v.id === clientId)
                        : ""
                    }
                    onChange={(event, newValue) => {
                      setClientId(newValue ? newValue.id : "");
                      setAccBooks(newValue ? newValue.ibossCountry : "");
                    }}
                    options={clientProfiles || ""}
                    getOptionLabel={(option) =>
                      clientProfiles !== null
                        ? option.clientName + " (" + option.id + ")"
                        : ""
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Client"
                        value={
                          clientProfiles !== null
                            ? clientProfiles.find((v) => v.id === clientId)
                            : ""
                        }
                        variant="outlined"
                        color="secondary"
                        size="small"
                        validators={["required"]}
                        errorMessages={["required"]}
                      />
                    )}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1"></div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">
                    Transaction Date
                  </div>
                  <div className="view-receipt-drawer-description ">
                    {transactionDate}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1"></div>
              )}
            </div>
            <RadioGroup
              row
              aria-label="Mode"
              name="Mode"
              value={mode}
              onChange={handleChange}
            >
              <FormControlLabel
                value="NEFT"
                control={<Radio />}
                label="NEFT / RTGS"
              />
              <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
              <FormControlLabel
                value="Cheque"
                control={<Radio />}
                label="Cheque"
              />
              <FormControlLabel
                value="Online"
                control={<Radio />}
                label="Online"
              />
            </RadioGroup>
            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <Fragment>
                    <KeyboardDatePicker
                      id="TransactedDate"
                      className="mt-0"
                      autoOk
                      margin="normal"
                      variant="inline"
                      inputVariant="outlined"
                      label="Transacted Date"
                      format="dd/MM/yyyy"
                      size="small"
                      value={
                        transactionDate !== undefined ? transactionDate : null
                      }
                      inputValue={
                        transactionDate !== undefined ? transactionDate : null
                      }
                      InputAdornmentProps={{
                        position: "start",
                        color: "primary",
                      }}
                      onChange={(date) => {
                        setTransactionDate(date);
                      }}
                      required
                    />
                  </Fragment>
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Deposited"
                    size="small"
                    color="secondary"
                    value={deposited}
                    onChange={(event) => setDeposited(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                    required
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">
                    Transaction Date
                  </div>
                  <div className="view-receipt-drawer-description ">
                    {transactionDate}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1">
                  <div className="view-receipt-drawer-title">Deposited</div>
                  <div className="view-receipt-drawer-description">
                    {deposited}
                  </div>
                </div>
              )}
            </div>
            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Transacted Amount"
                    size="small"
                    color="secondary"
                    value={transactedAmount}
                    onChange={(event) =>
                      setTransactedAmount(event.target.value)
                    }
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                    required
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Transacted ID"
                    size="small"
                    color="secondary"
                    value={transactedId}
                    onChange={(event) => setTransactedId(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                    required
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">
                    Transacted Amount
                  </div>
                  <div className="view-receipt-drawer-description ">
                    {transactedAmount}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1">
                  <div className="view-receipt-drawer-title">Transacted Id</div>
                  <div className="view-receipt-drawer-description">
                    {transactedId}
                  </div>
                </div>
              )}
            </div>
            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Incentives"
                    size="small"
                    color="secondary"
                    disabled
                    value={incentives}
                    onChange={(event) => setIncentives(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Remarks"
                    size="small"
                    color="secondary"
                    value={remarks}
                    onChange={(event) => setRemarks(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">Incentives</div>
                  <div className="view-receipt-drawer-description ">
                    {incentives}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1">
                  <div className="view-receipt-drawer-title">Remarks</div>
                  <div className="view-receipt-drawer-description ">
                    {remarks}
                  </div>
                </div>
              )}
            </div>
            <div className="supplier-view-edit-drawer-if-container">
              <div className="supplier-view-edit-drawer-if-line"></div>
              <div>Account Books</div>
              <div className="supplier-view-edit-drawer-if-line"></div>
            </div>

            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Account Books"
                    size="small"
                    color="secondary"
                    disabled
                    value={accBooks}
                    onChange={(event) => setAccBooks(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="ROE"
                    size="small"
                    color="secondary"
                    value={roe}
                    onChange={(event) => setROE(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">Account Books</div>
                  <div className="view-receipt-drawer-description ">
                    {accBooks}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1">
                  <div className="view-receipt-drawer-title">ROE</div>
                  <div className="view-receipt-drawer-description">{roe}</div>
                </div>
              )}
            </div>

            <div className="receipt-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Bank Charges"
                    size="small"
                    color="secondary"
                    value={bankCharges}
                    onChange={(event) => setBankCharges(event.target.value)}
                    disabled
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="receipt-view-edit-drawer-input config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Received Amount"
                    size="small"
                    color="secondary"
                    value={receivedAmount}
                    onChange={(event) => setReceivedAmount(event.target.value)}
                    validators={["required"]}
                    errorMessages={["This Field is Required."]}
                    required
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer margin-right-16 config-input-flex-1">
                  <div className="view-receipt-drawer-title">Bank Charges</div>
                  <div className="view-receipt-drawer-description ">
                    {bankCharges}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-receipt-drawer config-input-flex-1">
                  <div className="view-receipt-drawer-title">
                    Received Amount
                  </div>
                  <div className="view-receipt-drawer-description">
                    {receivedAmount}
                  </div>
                </div>
              )}
            </div>
          </div>
          {action !== "view" && (
            <div>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{
                  textTransform: "none",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginBottom: "25px",
                  width: "500px",
                }}
                type="submit"
              >
                {action === "edit" ? "Update" : "Create"}
              </Button>
            </div>
          )}
        </ValidatorForm>
      </TabPanel>
    </div>
  );
}
