import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Table, TableHead, TableCell, TableRow, TableBody, TableContainer, Paper, InputAdornment, OutlinedInput, IconButton, Chip, TablePagination } from '@material-ui/core';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './PassthroughConfigurationTable.css';

const PassthroughConfigurationTable = ({ setModal, passthroughConfiguration }) => {
    const [filterText, setFilterText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleFilterChange = (event) => {
        setFilterText(event.target.value.toLowerCase());
        setPage(0); // Reset page to 0 when filter changes
    };

    const filteredData = passthroughConfiguration.filter(row =>
        row.id == filterText ||
        row.applicableCountries.toLowerCase().includes(filterText) ||
        row.airlines.toLowerCase().includes(filterText) ||
        row.supplierPCC.toLowerCase().includes(filterText) ||
        row.cardType.toLowerCase().includes(filterText) ||
        row.originCountry.toLowerCase().includes(filterText) ||
        row.destinationCountry.toLowerCase().includes(filterText) ||
        (row.isActive ? 'active' : 'in-active').includes(filterText.toLowerCase())
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const editPassthroughConfiguration = (id) => {
        setModal({ status: true, action: "edit", data: passthroughConfiguration.find(item => item.id === id) });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        passthroughConfiguration && passthroughConfiguration.length > 0 ?
            <div className='vendor-listing' style={{ padding: "24px", paddingTop: "0px" }}>
                <TableContainer component={Paper} className="table-paper-styles">
                    <OutlinedInput
                        id="filter-text-box"
                        value={filterText}
                        onChange={handleFilterChange}
                        placeholder="Keyword Search by Applicable Countries, Airlines, Supplier PCC, Cards, Origin Country, Destination Country Or Status"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton>
                                    <SearchOutlinedIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        style={{ margin: '16px 0' }}
                    />
                    <Table className="table-background">
                        <TableHead>
                            <TableRow>
                                <TableCell>Rule ID</TableCell>
                                <TableCell>Applicable Countries</TableCell>
                                <TableCell>Airlines</TableCell>
                                <TableCell>Supplier PCC</TableCell>
                                <TableCell>Cards</TableCell>
                                <TableCell>Origin Country</TableCell>
                                <TableCell>Destination Country</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, index) => (
                                <TableRow key={index} onClick={() => editPassthroughConfiguration(row.id)} style={{ cursor: 'pointer' }}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.applicableCountries}</TableCell>
                                    <TableCell>{row.airlines}</TableCell>
                                    <TableCell>{row.supplierPCC}</TableCell>
                                    <TableCell>{row.cardType}</TableCell>
                                    <TableCell>{row.originCountry}</TableCell>
                                    <TableCell>{row.destinationCountry}</TableCell>
                                    <TableCell>
                                        {row.isActive ? (
                                            <Chip label="Active" color="primary" size="small" style={{ backgroundColor: "green", color: 'white' }} />
                                        ) : (
                                            <Chip label="In-Active" color="secondary" size="small" style={{ backgroundColor: "red", color: 'white' }} />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <div>Created by: <span style={{ color: "black" }}>{row.createdBy}</span></div>
                                            <div>Created on : {row.created.replace('T', " , ")}</div>
                                            <div>Last Modified by: <span style={{ color: "black" }}>{row.modifiedBy}</span></div>
                                            <div>Last Modified on : {row.modified.replace('T', " , ")}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </div>
            : null
    );
};

export default PassthroughConfigurationTable;
