import CryptoJS from 'crypto-js';

function encryptData(data) {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), 'superSecretKey').toString();
    return encrypted;
  } catch (error) {
    throw new Error('Encryption failed: ' + error.message);
  }
};
function decryptData(data) {
  return new Promise((resolve, reject) => {
    if (data) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, 'superSecretKey');
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
          resolve(JSON.parse(decryptedData));
        } else {
          reject(new Error('Decryption failed: Empty decrypted string'));
        }
      } catch (error) {
        reject(new Error('Decryption failed: ' + error.message));
      }
    } else {
      reject(new Error('No data provided for decryption'));
    }
  });
}

export { encryptData, decryptData }