import React, { useState } from "react";
import "./CategoryCard.css";
export default function ({ selected }) {
  return (
    <div
      className={`category-card-container mt-12 hover-fill ${
        selected ? "selected-border" : ""
      }`}
    >
      <div className="category-card-heading">Search - 2021</div>
      <div className="category-card-sub-heading mt-8">
        2021 US Best Category
      </div>
      <div className="category-person-date mt-12">
        <div>Satheesh Kumar</div>
        <div>May 18, 2021</div>
      </div>
    </div>
  );
}
