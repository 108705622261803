import React, { useEffect, useState } from "react";
import './DocumentUpload.css';
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import logo from "../../../assets/gf.png";
import CloseIcon from '@mui/icons-material/Close';
import agreementAED from './DocumentsToDownload/Agreement - AED - Prepaid Application 1 2.docx';
import agreementEUR from './DocumentsToDownload/Agreement - EUR -Prepaid Application 1 1.docx';
import agreementUSD from './DocumentsToDownload/Agreement - USD - Prepaid Application 1 1.docx';
import agreementINR from './DocumentsToDownload/Agreement - INR - Prepaid Application 1 1.docx';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import RiseLoading from "../../Loader/RiseLoader";
const styles = {
    container: {
        textAlign: 'center',
        marginTop: '200px',
        fontFamily: 'Arial, sans-serif',
    },
    checkmarkContainer: {
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: '#d9f9e1',
        width: '80px',
        height: '80px',
        textAlign: 'center',
        lineHeight: '80px',
        marginBottom: '20px',
    },
    checkmark: {
        fontSize: '40px',
        color: '#64c563',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px',
    },
    thankYouText: {
        fontSize: '14px',
        color: '#64c563',
        fontWeight: 'bold',
    },

    contactText: {
        fontSize: '14px',
        color: '#888',
    },

};

function DocumentUpload({ setDisplayHeader, setDisplayFooter, displayHeader, setDocUploadPopup, refId, billingCurrency,
    handleRefNoClick, viewVerificationDashboard }) {
    const { currency } = useParams();
    const { refno } = useParams();
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [showEmailSent, setShowEmailSent] = useState(false);
    const [showResMsg, setShowResMsg] = useState('');
    const [remarks, setRemarks] = useState('');
    const [showLoader, setShowLoader] = useState();
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [disableBtn, setDisableBtn] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setDisplayHeader(false);
        setDisplayFooter(false);
    }, [setDisplayHeader, setDisplayFooter]);

    const agreementFiles = {
        AED: agreementAED,
        USD: agreementUSD,
        EUR: agreementEUR,
        INR: agreementINR,
    };


    const handleFileChange = (e, type) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/jpeg'];
        const maxSize = 5 * 1024 * 1024;

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                alert("Only PDF or JPEG files are allowed.");
                return;
            }
            if (file.size > maxSize) {
                alert("File size exceeds 5MB.");
                return;
            }

            setUploadedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: file,
            }));
        }
    };


    const handleDownload = (event) => {
        event.preventDefault();
        const fileUrl = agreementFiles[currency ? currency : billingCurrency];
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileUrl.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("File not available for the selected currency.");
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const HandleSubmit = async () => {

        if (remarks && remarks.length <= 500) {
            setShowLoader(true);
            setShowResMsg("");

            const headers = {
                "Content-Type": "application/json",
            };

            const documents = await Promise.all(
                Object.keys(uploadedFiles).map(async (docType) => {
                    const file = uploadedFiles[docType];
                    const base64File = await convertFileToBase64(file);
                    return {
                        file: base64File,
                        fileName: file.name,
                        fileType: docType,
                    };
                })
            );

            await axios
                .post(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/LeadClients/UploadDocuments",
                    JSON.stringify({
                        documents: documents,
                        remarks: remarks,
                        refId: refno ? parseInt(refno) : parseInt(refId),
                    }),
                    { headers }
                )
                .then((res) => {
                    if (refno) {
                        setShowEmailSent(true);
                    }
                    else {
                        setShowEmailSent(false);
                        viewVerificationDashboard();
                    }
                    handleRefNoClick(refId);
                    setDocUploadPopup(false);
                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log('Error:', err);
                    setShowLoader(false);
                });
        }
        else {
            if (!remarks) {
                setShowResMsg("*The Comment or Query field must not be empty");
            }
            else {
                setShowResMsg("*The character limit for remarks has been exceeded");
            }
        }

    }

    const handleRemoveFile = (type) => {
        setUploadedFiles((prevFiles) => {
            const updatedFiles = { ...prevFiles };
            delete updatedFiles[type];
            return updatedFiles;
        });

        setFileInputKey(Date.now());
    };

    const handleCancelUpload = () => {
        setDocUploadPopup(false);
    }

    return (
        <>
            <div className="docUpload-heading"></div>
            {showEmailSent === false ? (
                <div className="docUpload-mainDiv">
                    <div className="d-flex justify-content-between">
                        <div className="mt-3 ms-2">
                            <div className="d-flex">
                                <div style={{ width: "45%" }}><strong>Application Contract:</strong></div>
                                <div>
                                    <label className="upload-doc-lbl" onClick={handleDownload}>Download</label>
                                    <label htmlFor="contract-upload" className="ms-3 upload-doc-lbl">
                                        Upload
                                        <input
                                            key={fileInputKey}
                                            type="file"
                                            id="contract-upload"
                                            style={{ display: "none" }}
                                            accept=".pdf, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'B2B Contract')}
                                        />
                                    </label>
                                    {uploadedFiles['B2B Contract'] && (
                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                            {uploadedFiles['B2B Contract'].name}
                                            <CloseIcon onClick={() => handleRemoveFile('B2B Contract')} style={{ cursor: "pointer" }} fontSize="small" />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div style={{ width: "250px" }}><strong>Agency Document Upload:</strong></div>
                                <div>
                                    <ul style={{ listStyleType: "none" }}>
                                        {(currency ? currency : billingCurrency) === "INR" ? (
                                            <>
                                                <li className="mb-2">
                                                    <label htmlFor="gst-upload" className="upload-doc-lbl">
                                                        GST Certificate
                                                        <input
                                                            key={fileInputKey}
                                                            type="file"
                                                            id="gst-upload"
                                                            style={{ display: "none" }}
                                                            accept=".pdf, .jpeg"
                                                            onChange={(e) => handleFileChange(e, 'GST Certificate')}
                                                        />
                                                    </label>
                                                    {uploadedFiles['GST Certificate'] && (
                                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                                            {uploadedFiles['GST Certificate'].name}
                                                            <CloseIcon onClick={() => handleRemoveFile('GST Certificate')} style={{ cursor: "pointer" }} fontSize="small" />
                                                        </span>
                                                    )}
                                                </li>
                                                <li className="mb-2">
                                                    <label htmlFor="pan-upload" className="upload-doc-lbl">
                                                        PAN Card
                                                        <input
                                                            key={fileInputKey}
                                                            type="file"
                                                            id="pan-upload"
                                                            style={{ display: "none" }}
                                                            accept=".pdf, .jpeg"
                                                            onChange={(e) => handleFileChange(e, 'PAN Card')}
                                                        />
                                                    </label>
                                                    {uploadedFiles['PAN Card'] && (
                                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                                            {uploadedFiles['PAN Card'].name}
                                                            <CloseIcon onClick={() => handleRemoveFile('PAN Card')} style={{ cursor: "pointer" }} fontSize="small" />
                                                        </span>
                                                    )}
                                                </li>
                                                <li>
                                                    <label htmlFor="aadhaar-upload" className="upload-doc-lbl">
                                                        Aadhar Card
                                                        <input
                                                            key={fileInputKey}
                                                            type="file"
                                                            id="aadhaar-upload"
                                                            style={{ display: "none" }}
                                                            accept=".pdf, .jpeg"
                                                            onChange={(e) => handleFileChange(e, 'Aadhar Card')}
                                                        />
                                                    </label>
                                                    {uploadedFiles['Aadhar Card'] && (
                                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                                            {uploadedFiles['Aadhar Card'].name}
                                                            <CloseIcon onClick={() => handleRemoveFile('Aadhar Card')} style={{ cursor: "pointer" }} fontSize="small" />
                                                        </span>
                                                    )}
                                                </li>
                                                <div className="mt-2 text-primary" style={{ fontSize: "12px" }}>*Only PDF and JPEG files are accepted, with a maximum size of 5MB</div>
                                            </>
                                        ) : (
                                            <>
                                                <li className="mb-2">
                                                    <label htmlFor="incorporation-upload" className="upload-doc-lbl">
                                                        Incorporation Certificate
                                                        <input
                                                            key={fileInputKey}
                                                            type="file"
                                                            id="incorporation-upload"
                                                            style={{ display: "none" }}
                                                            accept=".pdf, .jpeg"
                                                            onChange={(e) => handleFileChange(e, 'Incorporation Certificate')}
                                                        />
                                                    </label>
                                                    {uploadedFiles['Incorporation Certificate'] && (
                                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                                            {uploadedFiles['Incorporation Certificate'].name}
                                                            <CloseIcon onClick={() => handleRemoveFile('Incorporation Certificate')} style={{ cursor: "pointer" }} fontSize="small" />
                                                        </span>
                                                    )}
                                                </li>
                                                <li className="mb-2">
                                                    <label htmlFor="trade-upload" className="upload-doc-lbl">
                                                        Trade License
                                                        <input
                                                            key={fileInputKey}
                                                            type="file"
                                                            id="trade-upload"
                                                            style={{ display: "none" }}
                                                            accept=".pdf, .jpeg"
                                                            onChange={(e) => handleFileChange(e, 'Trade License')}
                                                        />
                                                    </label>
                                                    {uploadedFiles['Trade License'] && (
                                                        <span className="ms-2" style={{ fontSize: "12px" }}>
                                                            {uploadedFiles['Trade License'].name}
                                                            <CloseIcon onClick={() => handleRemoveFile('Trade License')} style={{ cursor: "pointer" }} fontSize="small" />
                                                        </span>
                                                    )}
                                                </li>
                                                <div className="mt-2 text-primary" style={{ fontSize: "12px" }}>*Only PDF and JPEG files are accepted, with a maximum size of 5MB</div>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div></div>
                        <div></div>
                        {currency && <div className="mt-3 ms-5">
                            <img
                                alt="company logo"
                                style={{
                                    width: "300px",
                                    marginLeft: "-30px",
                                }}
                                src={logo}
                            />
                        </div>}
                        <div></div>
                    </div>

                    <div className="mt-5 ms-2 d-flex">
                        <div style={{ width: "100%", height: "100px" }}>
                            <div>*Comment/Query or Feedback:</div>
                            <div><textarea style={{ width: "90%", height: "100px" }} onChange={(e) => setRemarks(e.target.value)}
                                placeholder="Maximum limit: 500 characters" /></div>
                        </div>
                        <div style={{ width: "40%" }}>
                            <p style={{ fontSize: "14px" }}>
                                Steps:<br></br>
                                1. Download Agreement copy , fill in the required details which are highlighted in the agreement and convert it to pdf for uploading.<br></br>
                                2. Agency documents upload must be in JPEG or PDF format.<br></br>
                                3. Maximum document size 5MB<br></br>
                            </p>
                        </div>
                    </div>
                    <div className="text-center">
                        <Button
                            className="srn-btn mt-5"
                            variant="outlined"
                            color="primary"
                            size="large"
                            type="button"
                            disabled={disableBtn}
                            onClick={HandleSubmit}
                        >
                            Submit
                        </Button>
                        {!currency && <Button
                            className="srn-btn mt-5 ms-2"
                            variant="outlined"
                            color="primary"
                            size="large"
                            type="button"
                            onClick={handleCancelUpload}
                        >
                            Cancel
                        </Button>}
                        <div className="m-2 text-danger">{showResMsg}</div>
                    </div>
                </div>) : (
                <div style={styles.container}>
                    <div style={styles.checkmarkContainer}>
                        <div style={styles.checkmark}>
                            &#10003;
                        </div>
                    </div>
                    <h1 style={styles.title}>Request successfully sent!</h1>
                    <p style={styles.thankYouText}>Thank you for your time</p>
                    <p style={styles.contactText}>We will contact you shortly at the following email address</p>
                </div>
            )}

            {showLoader && <RiseLoading />}
        </>
    );
}

export default DocumentUpload;
