import React, { useState } from "react";

import SearchContext from "../../contexts/Searchbar/SearchContext";
import PropTypes from "prop-types";
import PreferenceToggleData from "../../data/PreferenceToggleData";
import popularAirports from "../../data/PopularAirports";
import SortData from "../../data/SortData";
import classDetails from "../../data/ClassDetails";
import tripType from "../../data/TripType";
export default function Search({ children }) {
  const [search, setSearch] = useState({
    type: tripType[1],
    airlineNo: '',
    from: "From",
    to: "To",
    depart: new Date(),
    return: "",
    // return: new Date(new Date().setDate(new Date().getDate() + 2)),
    class: classDetails[0],
    adult: 1,
    children: 0,
    infant: 0,
    directFlights: false,
    brandedFares: true,
    includeCarrier: "",
    includeAirlines: [],
    preference: PreferenceToggleData[0],
    clientId: 0,
    filters: {
      stops: [],
      price: [],
      fareType: [],
      connectingIn: [],
      airlines: [],
      arrivalDepart: [],
    },
    sort: SortData,
    destinations: [],
  });

  return (
    <SearchContext.Provider value={[search, setSearch]}>
      {children}
    </SearchContext.Provider>
  );
}

Search.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
