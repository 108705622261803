import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import "./Pager.css";

export default function Pager(props) {
  let [selectedPage, setSelectedPage] = useState(props.currentPage);
  let totalPages = Math.ceil(props.resultCount / props.resultsPerPage);

  useEffect(
    (_) => {
      if (selectedPage !== 1) {
        props.setCurrentPage(selectedPage);
        props.setPageNav(true);
      }
    },
    [selectedPage, props]
  );

  function goToNextPage() {
    setSelectedPage(parseInt(selectedPage) + 1);
    // props.loadMyQueue(props.skip, "", props.q);
  }

  function goToPreviousPage() {
    setSelectedPage(parseInt(selectedPage) - 1);
  }
  function goToSelectionPage(e) {
    setSelectedPage(e.currentTarget.id);
    // props.loadMyQueue(props.skip, "", props.q);
  }
  var i = 0;
  var page_links = [];

  var minPage = 1;
  var maxPage = totalPages;

  if (selectedPage - minPage > 2) {
    minPage = selectedPage - 2;
  }

  if (maxPage - selectedPage > 2) {
    maxPage = parseInt(selectedPage) + 2;
  }

  for (i = minPage; i <= maxPage; i++) {
    if (i === parseInt(selectedPage)) {
      page_links.push(
        <li className="page-item active" key={i}>
          <Button
            variant="outlined"
            color="primary"
            disabled
            className="page-link"
          >
            {i}
          </Button>
        </li>
      );
    } else {
      page_links.push(
        <li className="page-item" key={i}>
          <Button
            variant="outlined"
            color="primary"
            className="page-link"
            id={i}
            onClick={(e) => goToSelectionPage(e)}
          >
            {i}
          </Button>
        </li>
      );
    }
  }

  var previousButton;
  if (parseInt(selectedPage) === 1) {
    previousButton = (
      <li className="page-item disabled" key="prev">
        <Button
          variant="outlined"
          color="primary"
          disabled
          className="page-link"
        >
          Previous
        </Button>
      </li>
    );
  } else {
    previousButton = (
      <li className="page-item" key="prev" onClick={goToPreviousPage}>
        <Button variant="outlined" color="primary" className="page-link">
          Previous
        </Button>
      </li>
    );
  }

  var nextButton;
  if (parseInt(selectedPage) === totalPages) {
    nextButton = (
      <li className="page-item disabled" key="next">
        <span className="page-link">Next</span>
      </li>
    );
  } else {
    nextButton = (
      <li className="page-item" key="next">
        <Button
          variant="outlined"
          color="primary"
          className="page-link"
          onClick={goToNextPage}
        >
          Next
        </Button>
      </li>
    );
  }

  return (
    <nav aria-label="..." className="pager">
      <ul className="pagination item">
        {previousButton}
        {page_links}
        {nextButton}
      </ul>
    </nav>
  );
}
