import React, { useState, useEffect } from "react";
import "./PendingQueueTable.css";
import ConfigurationMock from "../../../data/mock/ConfigurationMock";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import countries from "../../../data/Country";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function PendingQueueTable({
  openAddUserDrawer,
  setAction,
  pendingQueue,
  setPage,
  page,
  getClients,
  searchPendingQueueValue,
  search
}) {
  const history = useHistory();
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    getClients();
  };

  function handleViewPage(type,approvalConfigId,id){
    if(type === "Vendor")
    {
      history.push("/ViewVendor?vendorid="+approvalConfigId+"&pendingqueueid="+id)
    }
    else{
      history.push("/ViewClient?clientid="+approvalConfigId+"&pendingqueueid="+id)
    }
  }
  return (
    <div>
      {
        search ?   <div>
        {searchPendingQueueValue ? (
          searchPendingQueueValue.length === 0 ? (
            <Alert severity="error">
              There are No items found for your search.
            </Alert>
          ) : (
            <React.Fragment>
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>Approval Message</TableCell>
                      <TableCell>Approval Config</TableCell>
                      <TableCell>Approval Stage</TableCell>
                      <TableCell align="right">Requested By</TableCell>
                      <TableCell align="right">Previous Approver</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchPendingQueueValue.map((row) => (
                      <StyledTableRow
                        key={JSON.stringify(row)}
                        onClick={() => handleViewPage(row.pendingApprovalQueues.approvalConfigurationType,row.pendingApprovalQueues.approvalConfigId,row.pendingApprovalQueues.id)}
                      >
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title">
                            {row.pendingApprovalQueues.approvalMessage}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title">
                            {row.pendingApprovalQueues.approvalConfigurationType}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title">
                            {row.pendingApprovalQueues.approvalPermissionType}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="table-cell-title">{row.createdBy}</div>
                          <div className="table-cell-description">
                            date: {" "}
                            {moment(row.pendingApprovalQueues.created).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="table-cell-title">{row.modefiedBy}</div>
                          <div className="table-cell-description">
                            date: {" "}
                            {moment(row.pendingApprovalQueues.modified).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={50}
                rowsPerPage="10"
                page={page-1}
                onChangePage={(event, newPage) =>
                  handleChangePage(event, newPage)
                }
                //onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
              /> */}
            </React.Fragment>
          )
        ) : (
          // <CircularProgress style={{ marginLeft: "110px", marginTop: "100px" }} />
          <div className="searchText">
            <Alert >
               search to get results
            </Alert>
          </div>
        )}
      </div>
      :
      <div>
      {pendingQueue ? (
        pendingQueue.length === 0 ? (
          <Alert severity="error">
            There are No Pending items to approve.
          </Alert>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  <TableRow>
                    <TableCell>Approval Message</TableCell>
                    <TableCell>Approval Config</TableCell>
                    <TableCell>Approval Stage</TableCell>
                    <TableCell align="right">Requested By</TableCell>
                    <TableCell align="right">Previous Approver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingQueue.map((row) => (
                    <StyledTableRow
                      key={JSON.stringify(row)}
                      onClick={() => handleViewPage(row.pendingApprovalQueues.approvalConfigurationType,row.pendingApprovalQueues.approvalConfigId,row.pendingApprovalQueues.id)}
                    >
                      <StyledTableCell component="th" scope="row">
                        <div className="table-cell-title">
                          {row.pendingApprovalQueues.approvalMessage}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <div className="table-cell-title">
                          {row.pendingApprovalQueues.approvalConfigurationType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <div className="table-cell-title">
                          {row.pendingApprovalQueues.approvalPermissionType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                      <div className="table-cell-description">Created by: <span style={{color:"black"}}>{row.createdBy}</span></div>
                        <div className="table-cell-description">
                          Created on: {" "}
                          {row.pendingApprovalQueues.created.replace("T"," , ")}
                            
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                      <div className="table-cell-description">Last Modified by:  <span style={{color:"black"}}>{row.modefiedBy}</span></div>
                        <div className="table-cell-description">
                          Last Modified on: {" "}
                          {row.pendingApprovalQueues.modified.replace("T"," , ")}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={pendingQueue.length}
              rowsPerPage="10"
              page={page-1}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              //onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
            />
          </React.Fragment>
        )
      ) : (
        <CircularProgress style={{ marginLeft: "110px", marginTop: "100px" }} />
      )}
    </div>
      }
       
  
    </div>
  
  );
}
