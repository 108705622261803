import React from "react";
import findStationByCode from "../../../functions/findStationByCode";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import moment from "moment";
import QRCode from "react-qr-code";
import validateAndSanitizeDuration from "../../../functions/ValidateAndSanitizeDuration";
export default function RailSegmentDetails({ bookingDetails, legGroups, railIndex, handleRailcabinClassChange, handleRailPNRChange }) {

    return (
        <>
            {legGroups !== undefined && legGroups.length > 0 ? (
                legGroups.map((legGroup, legGroupIndex) => (
                    <div className="flight-itenary">
                        <div className="flight-itenary-header">
                            <div className="flight-itenary-title-container">
                                <div>
                                    {findStationByCode(legGroup.origin)} (
                                    {legGroup.origin})
                                </div>
                                <div className="flight-itenary-icon">
                                    <ArrowRightAltIcon></ArrowRightAltIcon>
                                </div>
                                <div>
                                    {findStationByCode(legGroup.destination)} (
                                    {legGroup.destination})
                                </div>
                            </div>
                            <div className="flight-itenary-date">
                                {moment(legGroup.departureOn).format(
                                    "Do MMMM YYYY"
                                )}
                            </div>
                        </div>
                        {legGroup.railSegments !== undefined &&
                            legGroup.railSegments.length > 0 ? (
                            <div>
                                <div className="flight-itenary-details">
                                    <div className="flight-itenary-details-item">
                                        <div className="flight-itenary-details-item-text">
                                            {legGroup.railSegments[0].classOfServiceName}
                                            {/* <Autocomplete
                                                        id="FOP-select"
                                                        className="cabinClass"
                                                        variant="outlined"
                                                        style={{
                                                            minWidth: "200px !important",
                                                            padding: "0px !important",
                                                        }}
                                                        value={
                                                            segment.cabinClass != ""
                                                                ? cabinOptions.find(
                                                                    (v) =>
                                                                        v.label ==
                                                                        segment.cabinClass
                                                                )
                                                                : null
                                                        }
                                                        onChange={(event, newValue) => {
                                                            handleRailcabinClassChange(
                                                                event,
                                                                newValue.label,
                                                                railIndex,
                                                                legGroupIndex,
                                                                segmentIndex
                                                            );
                                                        }}
                                                        options={cabinOptions}
                                                        getOptionLabel={(option) =>
                                                            option.label
                                                        }
                                                        renderInput={(params) => (
                                                            <TextValidator
                                                                {...params}
                                                                value={segment.cabinClass}
                                                                variant="filled"
                                                                color="secondary"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        )}
                                                    /> */}

                                        </div>
                                        <div className="flight-itenary-details-item-subtext">
                                            Class Of Service
                                        </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                        <div className="flight-itenary-details-item-text">
                                            {legGroup.railSegments[0].trainName}
                                        </div>
                                        <div className="flight-itenary-details-item-subtext">
                                            {legGroup.railSegments[0].trainName}{"-"}
                                            {legGroup.railSegments[0].trainNum}
                                        </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                        <div className="flight-itenary-details-item-text">
                                            {moment(legGroup.departureOn).format(
                                                "HH:mm"
                                            )}
                                        </div>
                                        <div className="flight-itenary-details-item-subtext">
                                            Depature
                                        </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                        <div className="flight-itenary-details-item-text">
                                            {validateAndSanitizeDuration(legGroup.totalDuration)}
                                        </div>
                                        <div className="flight-itenary-details-item-subtext">
                                            Duration
                                        </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                        <div className="flight-itenary-details-item-text">
                                            {moment(legGroup.arrivalOn).format(
                                                "HH:mm"
                                            )}
                                        </div>
                                        <div className="flight-itenary-details-item-subtext">
                                            Arrival
                                        </div>
                                    </div>
                                </div>
                                <div className="flight-confirmation-details">
                                    <div className="flight-confirmation-details-item">
                                        <div className="flight-confirmation-details-item-text">
                                            Origin
                                        </div>
                                        <div className="flight-confirmation-details-item-subtext">
                                            {findStationByCode(legGroups[0].origin)} -{" "}
                                            {legGroup.origin}
                                        </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                        <div className="flight-confirmation-details-item-text">
                                            Destination
                                        </div>
                                        <div className="flight-confirmation-details-item-subtext">
                                            {findStationByCode(
                                                legGroup.destination
                                            )}{" "}
                                            - {legGroup.destination}
                                        </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                        <div
                                            className="flight-confirmation-details-item-text"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            PNR
                                        </div>
                                        <div
                                            className="flight-confirmation-details-item-subtext"
                                        >
                                            {bookingDetails.pnr}
                                            {/* <TextValidator
                                                        type="text"
                                                        variant="outlined"
                                                        size="small"
                                                        required
                                                        color="secondary"
                                                        value={segment.pnr}
                                                        validators={[
                                                            "required",
                                                            "matchRegexp:^[a-zA-Z0-9]+$",
                                                        ]}
                                                        errorMessages={[
                                                            "required",
                                                            "No Special Characters Allowed",
                                                        ]}
                                                        onChange={(event) =>
                                                            handleRailPNRChange(
                                                                event,
                                                                railIndex,
                                                                legGroupIndex,
                                                                segmentIndex
                                                            )
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    /> */}
                                        </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                        <div className="flight-confirmation-details-item-text">
                                            Segment Status
                                        </div>
                                        <div className="flight-confirmation-details-item-subtext full-opacity">
                                            <div className="itenary-confirmtaion-tag success">
                                                {legGroup.railSegments[0].Status}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                        {bookingDetails?.railPNRDetails[railIndex].qrCode &&
                                            bookingDetails?.railPNRDetails[railIndex].qrCode.length && (
                                                <QRCode
                                                    value={bookingDetails?.railPNRDetails[railIndex].qrCode}
                                                    className="img-fluid"
                                                    size={120}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div></div>
                        )}
                    </div>
                ))
            ) : (
                <div></div>
            )}
        </>);
}