

export default function ItienarayHeader({
    bookingRefNo, headText }) {
    return (
        <>
            <div className="import-booking-itenary-container">
                <div>{headText}</div>
                <div className="flight-itenary-details-item">
                    <div className="flight-itenary-details-item-text">
                        {bookingRefNo}
                    </div>
                    <div className="flight-itenary-details-item-subtext">
                        PNR/Booking Reference
                    </div>
                </div>
                {/* <div className="itenary-status">
                              <div className="itenary-confirmtaion-tag">
                              {flight.currentStatus}
                              </div>
                              </div> */}
            </div>
        </>
    );
}