export const filterAndSortBaggage = (baggageData) => {

    if (baggageData && baggageData.length > 0) {
        // Filter data based on paxtype 'ADT'
        let adtBaggage = "";
        baggageData && baggageData.length
            ? (adtBaggage = baggageData.filter((item) => item.paxType === "ADT"))
            : (adtBaggage = "");

        // Find the lowest values for cabinBag and checkinbag
        const lowestCabinBag = Math.min(
            ...adtBaggage.map((item) =>
                item.cabinBag ? item.cabinBag.split("KG")[0] : ""
            )
        );
        const lowestCheckinBag = Math.min(
            ...adtBaggage.map((item) =>
                item.checkInBag ? item.checkInBag.split("KG")[0] : ""
            )
        );

        // Filter again to include only the entries with the lowest values
        const lowestBaggage = adtBaggage.sort(
            (item) =>
                (item.cabinBag && item.cabinBag.split("KG")[0] === lowestCabinBag) ||
                (item.checkInBag && item.checkInBag.split("KG")[0] === lowestCheckinBag)
        );
        // console.log("filtered baggage", adtBaggage);
        // console.log("lowestCheckinBag", lowestCheckinBag);
        // console.log("lowestCabinBag", lowestCabinBag);
        // console.log("lowestBaggage", lowestBaggage);
        return lowestBaggage[0];
    }

};