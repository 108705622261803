import axios from "axios";
import { setAuthHeader } from "../utils/axiosHeaders";

async function getToken(username, password) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const data =
    "grant_type=password&client_id=" +
    process.env.REACT_APP_CLIENTID +
    "&username=" +
    username +
    "&password=" +
    encodeURIComponent(password) +
    "&scope=" +
    process.env.REACT_APP_SCOPE;
  // console.log(data);
  await axios
    .post(process.env.REACT_APP_IDENTITY_SERVER + "connect/token", data, {
      headers,
    })
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => {
      console.log(error.response.data.error_description);
    });

  //setAuthHeader(response.data.access_token)
  return false;
}

function isUserHasPermission(permission) {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    user &&
    user["aof.permission"] &&
    user["aof.permission"].includes(permission)
  );
}

function isClientUserHasPermission(permission, context) {

  if (!checkIfUserStaff()) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user["aof.permission"]) {
      return false;
    }

    const permissions = JSON.parse(user["aof.permission"]);

    for (const model of permissions.ClientPermissionsModels) {
      if ((model.ClientPermissionType).toLowerCase() === "search&book" || (model.ClientPermissionType).toLowerCase() === "searchandbook") {
        for (const detail of model.SearchAndBookDetails) {
          if (detail.ClientPermissionType === context && detail.Details[permission] === true) {
            return true;
          }
        }
      }

      if (model.TransactionDetails && model.TransactionDetails[permission] === true) {
        return true;
      }

      if (model.PageProfileDetails && model.PageProfileDetails[permission] === true) {
        return true;
      }
    }

    return false;
  }
}





function getAllPermissions() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user["aof.permission"] && user["aof.permission"] !== null) {
    if (checkIfUserStaff()) {
      return user["aof.permission"];
    } else {
      const permissions = JSON.parse(user["aof.permission"]);
      const clientPermissions = permissions.ClientPermissionsModels.reduce((acc, curr) => {
        if (curr.SearchAndBookDetails) {
          for (const detail of curr.SearchAndBookDetails) {
            if (detail.Details) {
              const detailType = detail.ClientPermissionType.replace("bookDetail", "");
              Object.keys(detail.Details).forEach(key => {
                if (detail.Details[key] && !key.toLowerCase().includes("disabled")) {
                  acc.push(`${key}${detailType}`);
                }
              });
            }
          }
        }
        if (curr.TransactionDetails) {
          Object.keys(curr.TransactionDetails).forEach(key => {
            if (curr.TransactionDetails[key] && !key.toLowerCase().includes("disabled")) {
              acc.push(key);
            }
          });
        }
        if (curr.PageProfileDetails) {
          Object.keys(curr.PageProfileDetails).forEach(key => {
            if (curr.PageProfileDetails[key] && !key.toLowerCase().includes("disabled")) {
              acc.push(key);
            }
          });
        }
        return acc;
      }, []);
      return clientPermissions;
    }
  }
  return null;
}

function checkIfUserStaff() {
  const user = JSON.parse(localStorage.getItem('user'))
  return user && user.clientref_id === '0'
}

function GetClient() {
  const user = JSON.parse(localStorage.getItem('user'))
  return user && user.clientref_id === '0'
}
function CheckIsLoggedIn() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
}
function getCurrentClientID() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user && user.clientref_id;
}

function getCurrentRole() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user && user.role;
}

export {
  getToken,
  isUserHasPermission,
  getAllPermissions,
  checkIfUserStaff,
  getCurrentClientID,
  CheckIsLoggedIn,
  getCurrentRole,
  isClientUserHasPermission
};
