import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import clsx from "clsx";
import "./FilterComponents.css";
import findAirportbyCode from "../../../functions/findAirportByCode";

function ConnectingIn({ search, setSearch, toggleDrawer }) {
  const classes = useCheckboxStyles();

  const handleChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.filters.connectingIn.find(
      (item) => item.city === event.target.name
    ).checked = event.target.checked;
    toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className="number-of-stops-container mt-12 mb-16">
      <FormControl component="fieldset">
        <FormGroup>
          {search.filters.connectingIn &&
            search.filters.connectingIn.length > 0 &&
            search.filters.connectingIn.map((connectingIn, index) => (
              <div className="stops-item-container" key={index}>
                <div className="stop-item-title">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.root}
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                        checked={connectingIn.checked}
                        onChange={handleChange}
                        name={connectingIn.city}
                      />
                    }
                    label={findAirportbyCode(connectingIn.city) + " - " + connectingIn.city}
                  />
                </div>
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default ConnectingIn;
