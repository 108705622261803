import React, { useState, useEffect } from "react";
import "./MarkupGroupTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { OutlinedInput, InputAdornment } from "@material-ui/core";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function MarkupGroupTable({
  openDrawer,
  setAction,
  commissionGroups,
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchedVal, setSearchedVal] = useState("");

  return (
    <div className="markup-listing">
      <TableContainer component={Paper} className="table-paper-styles">
        <Table className="table-background">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5} className="py-2">
                <OutlinedInput
                  id="keyword-search"
                  onChange={(e) => {
                    setSearchedVal(e.target.value);
                    setPage(0);
                  }}
                  placeholder="Keyword Search by Group ID or Group Name or Status"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Group ID</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commissionGroups !== undefined && commissionGroups.length > 0 ? (
              commissionGroups.filter((group) => {
                if (searchedVal) {
                  if (
                    searchedVal.toString().toLowerCase() == "active" ||
                    searchedVal.toString().toLowerCase() == "inactive"
                  ) {
                    return (
                      group.markupGroup.status.toLowerCase() ==
                      searchedVal.toString().toLowerCase()
                    );
                  } else {
                    return (
                      group.markupGroup.name
                        ?.toLowerCase()
                        .includes(searchedVal.toString().toLowerCase()) ||
                      group.markupGroup.id == searchedVal
                    );
                  }
                } else {
                  return group;
                }
              }).length === 0 ? (
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      colSpan={5}
                    >
                      <Alert severity="error">No record found.</Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                commissionGroups
                  .filter((group) => {
                    if (searchedVal) {
                      if (
                        searchedVal.toString().toLowerCase() == "active" ||
                        searchedVal.toString().toLowerCase() == "inactive"
                      ) {
                        return (
                          group.markupGroup.status.toLowerCase() ==
                          searchedVal.toString().toLowerCase()
                        );
                      } else {
                        return (
                          group.markupGroup.name
                            ?.toLowerCase()
                            .includes(searchedVal.toString().toLowerCase()) ||
                          group.markupGroup.id == searchedVal
                        );
                      }
                    } else {
                      return group;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((group) => (
                    <StyledTableRow key={JSON.stringify(group.markupGroup)}>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {group.markupGroup.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <div
                          className="table-cell-title pointer"
                          onClick={() => {
                            setAction("view");
                            openDrawer(group.markupGroup);
                          }}
                        >
                          {group.markupGroup.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {group.markupGroup.description}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {group.markupGroup.isActive ? (
                            <Chip
                              label="active"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="in active"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-description">
                          Created by:{" "}
                          <span style={{ color: "black" }}>
                            {group.createdBy}
                          </span>
                        </div>
                        <div className="table-cell-description">
                          Created on:{" "}
                          {group.markupGroup.created.replace("T", " , ")}
                        </div>
                        <div className="table-cell-description">
                          Last Modified by:{" "}
                          <span style={{ color: "black" }}>
                            {group.modefiedBy}
                          </span>
                        </div>
                        <div className="table-cell-description">
                          Last Modified on:{" "}
                          {group.markupGroup.modified.replace("T", " , ")}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              )
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="50%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="70%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="800%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="500%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className="table-loading">
                    <Skeleton width="80%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          commissionGroups.filter((group) => {
            if (searchedVal) {
              if (
                searchedVal.toString().toLowerCase() == "active" ||
                searchedVal.toString().toLowerCase() == "inactive"
              ) {
                return (
                  group.markupGroup.status.toLowerCase() ==
                  searchedVal.toString().toLowerCase()
                );
              } else {
                return (
                  group.markupGroup.name
                    ?.toLowerCase()
                    .includes(searchedVal.toString().toLowerCase()) ||
                  group.markupGroup.id == searchedVal
                );
              }
            } else {
              return group;
            }
          }).length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
