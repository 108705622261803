import { Box, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Table } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import parse from 'html-react-parser';
import axios from "axios";
import { checkIfUserStaff } from "../../services/AuthService";
import RiseLoading from "../Loader/RiseLoader";


export default function ComissionAndTaxDetails({ flight, endClientName, endClientEmail, orderId }) {
    const [ticketModalopen, setTicketModalOpen] = useState(false);
    const [ticketImage, setTicketImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function getTicketImage(orderId) {
        setIsLoading(true);
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "Flights/Booking/GetBooking/v1/GetTicketImg/" +
                orderId
            )
            .then((response) => {
                let ticketData = response.data;
                setTicketModalOpen(true);
                if (ticketData[0].ticketImage) {
                    setTicketImage(ticketData[0].ticketImage);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setTicketImage(`<p>${e.response.data}</p>`);
                setIsLoading(false);
            })
    }

    const ticketPopupHandler = () => {
        getTicketImage(orderId);
    }

    if(isLoading){
        return(
           <RiseLoading isLoading={isLoading} />
        )
    }

    return (
        <div className="flight-itenary">
            <Box className="flight-itenary-header">
                <Box display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className="flight-itenary-title-container"
                    sx={{ flex: '1 1 100%' }}
                >
                    <div>
                        Commission And Tax Details
                    </div>
                    {checkIfUserStaff() &&
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={ticketPopupHandler}
                        >
                            View ticket image
                        </Button>
                    }
                </Box>
            </Box>
            {/* <Table> */}
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxIATA:</span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxIATA}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxPLB: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxPLB}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>VendorTaxType: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>TDS: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.tds}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>IsTDSApplicable: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.isTDSApplicable}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTax: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTax}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxName: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxType: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>ServiceTaxApplicableOn: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxApplicable}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>CaseId: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.caseId}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mco}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Parted: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoParted}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Charge: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>MCO Remark: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoRemark}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>PG Charge: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Corp/Retail: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight.isCorporate ? "Corporate" : "Retail"}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Client Name: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {endClientName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Client Email: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {endClientEmail}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Sales Representative: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.salesStaff ? flight?.salesStaff : '-'}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>Source Of Booking: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {
                                (flight?.info && flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    ? (flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    : '-'
                            }
                        </span>
                    </div>
                </div>
            </div>
            {/* </Table> */}
            <Dialog
                open={ticketModalopen}
                onClose={() => {
                    setTicketModalOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ticket Image"}
                </DialogTitle>
                <DialogContent>
                    <div className="custom-editor">
                        {parse(ticketImage)}
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
}