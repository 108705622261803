import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
export default function ROE({ flight }) {
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        ROE
                    </div>
                </div>
            </div>
            <Table>
                <thead className="mui-table-header">
                    <BootstrapTooltip1 title="Supplier Roe">
                        <th style={{ textAlign: 'center' }}>Supplier Roe</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Markup ROE">
                        <th style={{ textAlign: 'center' }}>Markup ROE</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="Client ROE">
                        <th style={{ textAlign: 'center' }}>Client ROE</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title="IBoss ROE">
                        <th style={{ textAlign: 'center' }}>IBoss ROE</th>
                    </BootstrapTooltip1>
                </thead>
                <tbody>
                    {
                        flight?.roes &&
                        <tr>
                            <td align="center">{flight?.roes?.supplierROE}</td>
                            <td align="center">{flight?.roes?.markupROE}</td>
                            <td align="center">{flight?.roes?.clientROE}</td>
                            <td align="center">{flight?.roes?.iBossROE}</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
}