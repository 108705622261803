import React, { useState, useEffect } from "react";
import "./PeopleClassMenu.css";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";

export default function PeopleClassMenu({ search, setSearch }) {
  const [disableAdd, setDisableAdd] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(search.class);

  useEffect(() => {
    if (search.adult + search.children + search.infant > 8) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
  }, [search]);

  const changeCount = (action, type) => {
    const existingSearch = { ...search };
    if (action === "add") {
      if (type === "adult" && existingSearch.adult < 9)
        existingSearch.adult += 1;
      if (type === "children" && existingSearch.children < 9)
        existingSearch.children += 1;
      if (type === "infant" && existingSearch.infant < 9)
        existingSearch.infant += 1;
    } else {
      if (type === "adult" && existingSearch.adult > 1)
        existingSearch.adult -= 1;
      if (type === "children" && existingSearch.children > 0)
        existingSearch.children -= 1;
      if (type === "infant" && existingSearch.infant > 0)
        existingSearch.infant -= 1;
    }
    if (existingSearch.adult < existingSearch.infant) {
      setErrorMessage("Number of infants cannot be more than adults");
    } else {
      setErrorMessage("");
      setSearch(existingSearch);
    }
  };

  return (
    <div className="people-class-menu-container">
      <div className="people-container bb-1">
        <div className="people-type-container">
          <div className="people-type-text">Adults</div>
          <div className="people-type-sub-text">(13y +)</div>
        </div>
        <div className="people-count-container">
          <Tooltip title="Remove">
            <div
              className={
                "count-button pointer " + (search.adult === 1 ? "disable" : "")
              }
              onClick={() => changeCount("remove", "adult")}
            >
              <RemoveCircleOutlineRoundedIcon />
            </div>
          </Tooltip>

          <div className="people-count pointer">{search.adult}</div>
          <Tooltip title="Add">
            <div
              className={
                "count-button pointer " + (disableAdd ? "disable" : "")
              }
              onClick={() => changeCount("add", "adult")}
            >
              <AddCircleOutlineRoundedIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="people-container bb-1">
        <div className="people-type-container">
          <div className="people-type-text">Children</div>
          <div className="people-type-sub-text">(2y - 12y )</div>
        </div>
        <div className="people-count-container">
          <Tooltip title="Remove">
            <div
              className={
                "count-button pointer " +
                (search.children === 0 ? "disable" : "")
              }
              onClick={() => changeCount("remove", "children")}
            >
              <RemoveCircleOutlineRoundedIcon />
            </div>
          </Tooltip>

          <div className="people-count">{search.children}</div>
          <Tooltip title="Add">
            <div
              className={
                "count-button pointer " + (disableAdd ? "disable" : "")
              }
              onClick={() => changeCount("add", "children")}
            >
              <AddCircleOutlineRoundedIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="people-container">
        <div className="people-type-container">
          <div className="people-type-text">Infants</div>
          <div className="people-type-sub-text">(below 24m)</div>
        </div>
        <div className="people-count-container">
          <Tooltip title="Remove">
            <div
              className={
                "count-button pointer " + (search.infant === 0 ? "disable" : "")
              }
              onClick={() => changeCount("remove", "infant")}
            >
              <RemoveCircleOutlineRoundedIcon />
            </div>
          </Tooltip>

          <div className="people-count">{search.infant}</div>
          <Tooltip title="Add">
            <div
              className={
                "count-button pointer " + (disableAdd ? "disable" : "")
              }
              onClick={() => changeCount("add", "infant")}
            >
              <AddCircleOutlineRoundedIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      {errorMessage.length > 0 && (
        <div className="error-text">{errorMessage}</div>
      )}
    </div>
  );
}
