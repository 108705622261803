import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import SearchBar from "../SearchBar/Searchbar";
import LogoText from "../../assets/Logo-Text.png";
import FlightRoundedIcon from "@material-ui/icons/FlightRounded";
import HotelRoundedIcon from "@material-ui/icons/HotelRounded";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import { useHistory } from "react-router-dom";
import {
  isUserHasPermission,
  isClientUserHasPermission,
  checkIfUserStaff,
  getCurrentRole,
} from "../../services/AuthService";

function LandingPage() {
  const history = useHistory();

  const [clientId, setClientId] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  //alert

  useEffect(() => {
    const userRole = getCurrentRole();

    if (!isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff() && userRole !== "ClientSuperAdmin") {
      history.push("/Unauthorized");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      setClientId(user["clientref_id"]);
    }

    if (!checkIfUserStaff()) {

      const hasDialogBeenShown = localStorage.getItem("dialogShown");

      if (hasDialogBeenShown) {
        setShowAlert(false);
      } else {
        setShowAlert(true);
        localStorage.setItem("dialogShown", "true");
      }

    }
  }, []);

  async function clientAlert() {
    setShowAlert(true);
  }

  return (
    <>
      <div className="landing-page-container">
        <div className="landing-page-gradient">
          <div className="search-toolbar">
            <SearchBar />
          </div>
        </div>
      </div>
      {/* {!checkIfUserStaff() && showAlert ? <ClientAlert /> : ""} */}
    </>
  );
}

export default LandingPage;
