import { loadCreditCardDetails } from "../services/apiService";

export async function loadCardDetails(cardInfoId) {
    if (cardInfoId !== 0) {
        let response = await loadCreditCardDetails();

        for (let item of response) {
            if (item.cardinfo.id === cardInfoId) {
                let string = item.cardinfo.cardNumber.toString();

                // Set card code based on card type
                let cardCode =
                    item.cardinfo.cardType === "American Express" ? "AX" :
                        item.cardinfo.cardType === "Visa" ? "VI" :
                            item.cardinfo.cardType === "MasterCard" ? "CA" :
                                item.cardinfo.cardType === "Diners Club" ? "DC" :
                                    item.cardinfo.cardType === "Discover" ? "DS" :
                                        item.cardinfo.cardType === "JCB" ? "JC" :
                                            item.cardinfo.cardType === "Maestro" ? "TO" :
                                                item.cardinfo.cardType === "UATP" ? "TP" : "";

                let lastFour = string.substr(string.length - 4);
                let fopText = cardCode + lastFour;

                return {
                    fopText,
                    selectedCard: item
                };
            }
        }
    } else {
        return { fopText: "Cash", selectedCard: null };
    }
}
