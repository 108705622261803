import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@material-ui/core";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import findStationByCode from "../../../functions/findStationByCode";

export default function RailFareDetailsSupplierTableComponent({
  bookingDetails,
  CalculateRailTotalFare,
  OnChangeCalculateRailTotalFare,
  railImportFareArray,
  currency,

}) {
  return (
    <>
      {" "}
      <div className="itenary-container-import booking-padding-top-40">
        <div>Supplier Fare Details ({currency ? currency : 'EUR'})</div>
      </div>
      <div className="itenary-passenger-details-import">
        <Table className="itenary-passenger-table ImportBookingFareDetails">
          <TableHead>
            {" "}
            <TableRow className="itenary-passenger-table-header">
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
              >
                Pax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Base Fare
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Tax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Extra
              </TableCell>
              {/* <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Service
              </TableCell> */}
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Service / MarkUp
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IssuanceFee
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                O.TAX
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                COMM
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight itenary-passenger-table-header-item-final"
              >
                <small>Vendor</small>
                <br />
                Incentive
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client  </small>
                <br />
                COMM
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client  </small>
                <br />
                Incentive
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="ImportBookingFareDetails ">
            {bookingDetails && bookingDetails?.railPNRDetails && bookingDetails?.railPNRDetails.length === 1 &&
              <>
                <TableRow>
                  <TableCell
                    colSpan={13}
                    style={{
                      backgroundColor: "#dedede",
                      padding: "0px",
                    }}
                  >
                    <div className="flight-itenary-table-title-container">
                      <div>
                        {findStationByCode(
                          bookingDetails?.railPNRDetails[0].legGroups[0].origin
                        )}
                      </div>
                      <div className="flight-itenary-icon">
                        <ArrowRightAltIcon></ArrowRightAltIcon>
                      </div>
                      <div>
                        {findStationByCode(
                          bookingDetails?.railPNRDetails[0].legGroups[0].destination
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                {bookingDetails?.railPNRDetails[0].railFares.length > 0 ? (
                  bookingDetails?.railPNRDetails[0].railFares
                    .sort(function (a, b) {
                      if (a.paxType < b.paxType) {
                        return -1;
                      }
                      if (a.paxType > b.paxType) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((fare) => (
                      <>
                        <TableRow className="itenary-passenger-table-item">
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                          >
                            {fare.paxType === "ADT"
                              ? "Adult"
                              : fare.paxType === "CHD"
                                ? "Child"
                                : fare.paxType === "INF"
                                  ? "Infant"
                                  : fare.paxType === 'SNR' ? 'Senior'
                                    : fare.paxType === 'YNG' ? 'Youth'
                                      : "Adult"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            <TextValidator
                              id={"outlined-number-Markup" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultBase
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childBase
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantBase :
                                      fare.paxType === 'SNR' ? railImportFareArray[0].seniorBase
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthBase
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "base", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            <TextValidator
                              id={"outlined-number-Markup" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultTax
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childTax
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantTax
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorTax
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthTax
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "tax", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80">
                            <TextValidator
                              id={"outlined-number-Extra" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultExtra
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childExtra
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantExtra
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorExtra
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthExtra
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "extra", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          {/* <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80">
                            <TextValidator
                              id={"outlined-number-Service" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultService
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childService
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantService
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorService
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthService
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "service", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                          >
                            <TextValidator
                              id={"outlined-number-Markup" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultMarkup
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childMarkup
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantMarkup
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorMarkup
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthMarkup
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "markup", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                            <TextValidator
                              id={
                                "outlined-number-vendorIssuanceFee" + fare.paxType
                              }
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultVendorIssuanceFee
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childVendorIssuanceFee
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantVendorIssuanceFee
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorVendorIssuanceFee
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthVendorIssuanceFee
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "vendorIssuanceFee", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                            <TextValidator
                              id={"outlined-number-vendorTax" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultVendorTax
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childVendorTax
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantVendorTax
                                      : fare.paxType === 'SNR'
                                        ? railImportFareArray[0].seniorVendorTax
                                        : fare.paxType === 'YNG'
                                          ? railImportFareArray[0].youthVendorTax
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "vendorTax", 0
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            {fare.paxType === "ADT"
                              ? railImportFareArray[0].adultVendorTotal
                              : fare.paxType === "CHD"
                                ? railImportFareArray[0].childVendorTotal
                                : fare.paxType === "INF"
                                  ? railImportFareArray[0].infantVendorTotal
                                  : fare.paxType === 'SNR' ? railImportFareArray[0].seniorVendorTotal
                                    : fare.paxType === 'YNG' ? railImportFareArray[0].youthVendorTotal
                                      : 0}
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                            <TextValidator
                              id={"outlined-number-VendorIata" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultVendorIata
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childVendorIata
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantVendorIata
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorVendorIata
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthVendorIata
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "vendorIata", 0
                                )
                              }
                              disabled={fare.paxType === "INF" ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                            <TextValidator
                              id={"outlined-number-VendorPlb" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultVendorPlb
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childVendorPlb
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantVendorPlb
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorVendorPlb
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthVendorPlb
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "vendorPlb", 0
                                )
                              }
                              disabled={fare.paxType === "INF" ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                            <TextValidator
                              id={"outlined-number-IATA" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultIata
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childIata
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantIata
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorIata
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthIata
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "iata", 0
                                )
                              }
                              disabled={fare.paxType === "INF" ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                            <TextValidator
                              id={"outlined-number-PLB" + fare.paxType}
                              type="number"
                              variant="outlined"
                              value={
                                fare.paxType === "ADT"
                                  ? railImportFareArray[0].adultPlb
                                  : fare.paxType === "CHD"
                                    ? railImportFareArray[0].childPlb
                                    : fare.paxType === "INF"
                                      ? railImportFareArray[0].infantPlb
                                      : fare.paxType === 'SNR' ? railImportFareArray[0].seniorPlb
                                        : fare.paxType === 'YNG' ? railImportFareArray[0].youthPlb
                                          : 0
                              }
                              // onBlur={() => CalculateRailTotalFare()}
                              onChange={(event) =>
                                OnChangeCalculateRailTotalFare(
                                  event,
                                  fare.paxType,
                                  "plb", 0
                                )
                              }
                              disabled={fare.paxType === "INF" ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                ) : (
                  <TableRow></TableRow>
                )}
              </>
            }
            {bookingDetails && bookingDetails?.railPNRDetails && bookingDetails?.railPNRDetails.length > 1 &&
              bookingDetails?.railPNRDetails.map((rail, railIndex) => (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={13}
                      style={{
                        backgroundColor: "#dedede",
                        padding: "0px",
                      }}
                    >
                      <div className="flight-itenary-table-title-container">
                        <div>
                          {findStationByCode(
                            rail.legGroups[0].origin
                          )}
                        </div>
                        <div className="flight-itenary-icon">
                          <ArrowRightAltIcon></ArrowRightAltIcon>
                        </div>
                        <div>
                          {findStationByCode(
                            rail.legGroups[0].destination
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  {rail.railFares.length > 0 ? (
                    rail.railFares
                      .sort(function (a, b) {
                        if (a.paxType < b.paxType) {
                          return -1;
                        }
                        if (a.paxType > b.paxType) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((fare) => (
                        <>
                          <TableRow className="itenary-passenger-table-item">
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                            >
                              {fare.paxType === "ADT"
                                ? "Adult"
                                : fare.paxType === "CHD"
                                  ? "Child"
                                  : fare.paxType === "INF"
                                    ? "Infant"
                                    : fare.paxType === 'SNR' ? 'Senior'
                                      : fare.paxType === 'YNG' ? 'Youth'
                                        : "Adult"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              <TextValidator
                                id={"outlined-number-Markup" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultBase
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childBase
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantBase :
                                        fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorBase
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthBase
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "base", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              <TextValidator
                                id={"outlined-number-Markup" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultTax
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childTax
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantTax
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorTax
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthTax
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "tax", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80">
                              <TextValidator
                                id={"outlined-number-Extra" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultExtra
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childExtra
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantExtra
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorExtra
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthExtra
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "extra", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            {/* <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80">
                              <TextValidator
                                id={"outlined-number-Service" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultService
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childService
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantService
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorService
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthService
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "service", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell> */}
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                            >
                              <TextValidator
                                id={"outlined-number-Markup" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultMarkup
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childMarkup
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantMarkup
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorMarkup
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthMarkup
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "markup", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                              <TextValidator
                                id={
                                  "outlined-number-vendorIssuanceFee" + fare.paxType
                                }
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultVendorIssuanceFee
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childVendorIssuanceFee
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantVendorIssuanceFee
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorVendorIssuanceFee
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthVendorIssuanceFee
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "vendorIssuanceFee", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                              <TextValidator
                                id={"outlined-number-vendorTax" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultVendorTax
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childVendorTax
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantVendorTax
                                        : fare.paxType === 'SNR'
                                          ? railImportFareArray[railIndex].seniorVendorTax
                                          : fare.paxType === 'YNG'
                                            ? railImportFareArray[railIndex].youthVendorTax
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "vendorTax", railIndex
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              {fare.paxType === "ADT"
                                ? (railImportFareArray[railIndex].adultVendorTotal)
                                : fare.paxType === "CHD"
                                  ? (railImportFareArray[railIndex].childVendorTotal)
                                  : fare.paxType === "INF"
                                    ? (railImportFareArray[railIndex].infantVendorTotal)
                                    : fare.paxType === 'SNR' ? (railImportFareArray[railIndex].seniorVendorTotal)
                                      : fare.paxType === 'YNG' ? (railImportFareArray[railIndex].youthVendorTotal)
                                        : 0}
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              <TextValidator
                                id={"outlined-number-VendorIata" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultVendorIata
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childVendorIata
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantVendorIata
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorVendorIata
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthVendorIata
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "vendorIata", railIndex
                                  )
                                }
                                disabled={fare.paxType === "INF" ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              <TextValidator
                                id={"outlined-number-VendorPlb" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultVendorPlb
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childVendorPlb
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantVendorPlb
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorVendorPlb
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthVendorPlb
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "vendorPlb", railIndex
                                  )
                                }
                                disabled={fare.paxType === "INF" ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              <TextValidator
                                id={"outlined-number-IATA" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultIata
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childIata
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantIata
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorIata
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthIata
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "iata", railIndex
                                  )
                                }
                                disabled={fare.paxType === "INF" ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              <TextValidator
                                id={"outlined-number-PLB" + fare.paxType}
                                type="number"
                                variant="outlined"
                                value={
                                  fare.paxType === "ADT"
                                    ? railImportFareArray[railIndex].adultPlb
                                    : fare.paxType === "CHD"
                                      ? railImportFareArray[railIndex].childPlb
                                      : fare.paxType === "INF"
                                        ? railImportFareArray[railIndex].infantPlb
                                        : fare.paxType === 'SNR' ? railImportFareArray[railIndex].seniorPlb
                                          : fare.paxType === 'YNG' ? railImportFareArray[railIndex].youthPlb
                                            : 0
                                }
                                // onBlur={() => CalculateRailTotalFare()}
                                onChange={(event) =>
                                  OnChangeCalculateRailTotalFare(
                                    event,
                                    fare.paxType,
                                    "plb", railIndex
                                  )
                                }
                                disabled={fare.paxType === "INF" ? true : false}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  ) : (
                    <TableRow></TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
