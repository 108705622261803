const mealPreferences = [
  "Any meal",
  "Asian Veg. Meal",
  "Inf/Baby Food",
  "Bland Meal",
  "Child Meal",
  "Diabetic Meal",
  "Gluten Free",
  "High Fiber Meal",
  "Hindu",
  "Kosher",
  "Low Calorie",
  "Low Carbohydrate",
  "Low Choles./Fat",
  "Low Protein",
  "Low Sodium",
  "Muslim Meal",
  "Nonlactose",
  "No Salt Meal",
  "Oriental",
  "Peanut Free Meal",
  "Low Purine Meal",
  "Raw Vegetarian",
  "Seafood Meal",
  "Spec Request",
  "Vegetarian",
  "Veg/Lacto-ovo",
];
export default mealPreferences;
