import React, { useState, useEffect } from "react";
import "./SupplierTable.css";
import Table from "@material-ui/core/Table";
import IconButton from "@mui/material/IconButton";

import { OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Alert from "@material-ui/lab/Alert";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function SupplierTable({ openDrawer, setAction, pccDetails }) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchedVal, setSearchedVal] = useState("");
  useEffect(() => {
    let string = 'inactive';
    let s = pccDetails.filter((row) => {
      return row.pccConfigs.status.toLowerCase().includes(string.toString().toLowerCase())
    })
  }, [pccDetails]);
  return (
    <div className="supplier-listing">
      <TableContainer component={Paper} className="table-paper-styles">
        <Table className="table-background">
          <TableHead>
            <TableRow>
              <TableCell colSpan={9} className="py-2">
                <OutlinedInput
                  id="keyword-search"
                  onChange={(e) => { setSearchedVal(e.target.value); setPage(0) }}
                  placeholder="Keyword Search by ID or PCC Code or IPCC or Supplier Name or Status"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>PCCCode</TableCell>
              <TableCell>IPCC</TableCell>
              <TableCell>SupplierName</TableCell>
              <TableCell>PointOfSale</TableCell>
              {/* <TableCell>Username</TableCell>
              <TableCell>TicketType</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pccDetails !== undefined && pccDetails.length > 0 ? (
              pccDetails.filter((row) => {
                if (searchedVal.toString().toLowerCase() === 'active' || searchedVal.toString().toLowerCase() === 'inactive') {
                  return row.pccConfigs.status.toLowerCase() === searchedVal.toString().toLowerCase()
                } else {
                  return row.pccConfigs.pccCode?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.pccConfigs.ipcc?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.pccConfigs.supplierName?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    // row.pccConfigs.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.pccConfigs.id === searchedVal;
                }
              }
              ).length === 0 ?
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      colSpan={9}
                      align="center"
                    >
                      <Alert severity="error">
                        No record found.
                      </Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
                :
                pccDetails.filter((row) => {
                  // return row.pccConfigs.pccCode?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                  // row.pccConfigs.ipcc?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                  // row.pccConfigs.supplierName?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                  // row.pccConfigs.id === searchedVal;
                  if (searchedVal.toString().toLowerCase() === 'active' || searchedVal.toString().toLowerCase() === 'inactive') {
                    return row.pccConfigs.status.toLowerCase() === searchedVal.toString().toLowerCase()
                  } else {
                    return row.pccConfigs.pccCode?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      row.pccConfigs.ipcc?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      row.pccConfigs.supplierName?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      // row.pccConfigs.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      row.pccConfigs.id === searchedVal;
                  }

                })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((pcc) => (
                    <StyledTableRow key={JSON.stringify(pcc.pccConfigs)}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <div className="table-cell-title">
                          {pcc.pccConfigs.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          setAction("view");
                          openDrawer(pcc.pccConfigs);
                        }}
                      >
                        <div className="table-cell-title pointer">
                          {pcc.pccConfigs.pccCode}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {pcc.pccConfigs.ipcc}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {pcc.pccConfigs.supplierName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {pcc.pccConfigs.pointOfSale}
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                      <div className="table-cell-title">
                        {pcc.pccConfigs.userName}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="table-cell-title">
                        {pcc.pccConfigs.ticketType}
                      </div>
                    </StyledTableCell> */}
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {pcc.pccConfigs.status === 'active' ? (
                            <Chip
                              label="Active"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="In Active"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell >
                        <div className="table-cell-description">Created by: <span style={{ color: "black" }}>{pcc.createdBy}</span> </div>
                        <div className="table-cell-description">
                          Created on: {pcc.pccConfigs.created.replace('T', " , ")}
                        </div>
                        <div className="table-cell-description">Last Modified by: <span style={{ color: "black" }}>{pcc.modefiedBy}</span></div>
                        <div className="table-cell-description">
                          Last Modified on: {pcc.pccConfigs.modified.replace('T', " , ")}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
            )
              : (
                <StyledTableRow>
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="40%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="40%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="40%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="50%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="70%" height={24} animation="wave" />
                  </div>
                </StyledTableCell> */}
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="800%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="500%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="table-loading">
                      <Skeleton width="70%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="table-loading">
                      <Skeleton width="80%" height={24} animation="wave" />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pccDetails.filter((row) => {
          if (searchedVal.toString().toLowerCase() === 'active' || searchedVal.toString().toLowerCase() === 'inactive') {
            return row.pccConfigs.status.toLowerCase() === searchedVal.toString().toLowerCase()
          } else {
            return row.pccConfigs.pccCode?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              row.pccConfigs.ipcc?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              row.pccConfigs.supplierName?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              // row.pccConfigs.status.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              row.pccConfigs.id === searchedVal;
          }
        }
        ).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
