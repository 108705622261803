import getConnectingAirports from "./getConnectingAirports";
import onlyUnique from "./onlyUnique";

export default function getConnectingInFilter(flights) {
  let connectingIn = [];
  flights.forEach((flight) => {
    flight.segGroups.forEach((segGroup) => {
      if (segGroup.segs.length > 1) {
        connectingIn = connectingIn.concat(
          getConnectingAirports(segGroup.segs)
        );
      }
    });
  });
  if (connectingIn.length > 0) {
    let unique = connectingIn.filter(onlyUnique);
    unique.forEach((item, index) => {
      unique[index] = {
        city: item,
        checked: false,
      };
    });
    return unique;
  } else {
    return [];
  }
}
