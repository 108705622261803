import React, { useEffect, useState } from "react";
import "./AddClientCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../assets/travel.svg";
export default function AddClientCard({ setClientID, setClientName, setClientEmail, setisLiveFilter, setisActiveFilter, isLiveFilter, isActiveFilter, getClients }) {
  return (
    <div className="add-supplier-card-container">
      <div className="add-supplier-card-title">Search Client</div>
      <div className="create-supplier-contents-container">
        <div className="create-supplier-contents">
          <div className="add-supplier-if-items-container">
            {/* <div className="add-supplier-if-item margin-right-16">
            <div className="add-supplier-if-container">IF</div>
          </div> */}
            <div className="minWidth20 add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Client ID"
                  size="small"
                  color="secondary"
                  onChange={(event) => setClientID(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="minWidth20 add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Client Name"
                  size="small"
                  color="secondary"
                  onChange={(event) => setClientName(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="minWidth20 add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Client/User Email"
                  size="small"
                  color="secondary"
                  onChange={(event) => setClientEmail(event.target.value)}
                />
              </ValidatorForm>
            </div>
            {/* <div className="minMax10 add-supplier-if-item flex-1-input margin-right-16">
              <input
                className="form-check-input"
                type="checkbox"
                name="languages"
                value={isActiveFilter}
                checked={isActiveFilter}
                id="flexCheckDefault"
                onChange={(event) => setisActiveFilter(event.target.checked)}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
                style={{ paddingLeft: '10px' }}
              >
                Is Active
              </label>
            </div>
            <div className="minMax10 add-supplier-if-item flex-1-input margin-right-16">
              <input
                className="form-check-input"
                type="checkbox"
                name="languages"
                value={isLiveFilter}
                checked={isLiveFilter}
                id="flexCheckDefault"
                onChange={(event) => setisLiveFilter(event.target.checked)}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
                style={{ paddingLeft: '10px' }}
              >
                Is Live
              </label>
            </div> */}
            <div className="add-configuration-button mt-0px pointer" onClick={() => getClients(true)} >Search Client</div>
          </div>
        </div>
      </div>
    </div>
  );
}
