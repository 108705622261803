import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { getMiniRulesData, filterAdultChangeDate, filterChildChangeDate, filterInfantChangeDate } from "../../functions/getMiniRulesData";
import DetailedFareRule from "./DetailedFareRule.js";
import getDetailedFareRule from "../../functions/getDetailedFareRule.js";
import RiseLoading from "../Loader/RiseLoader.js";


const DateChange = ({ miniRules, currentTicket, vendor, currencyCode, selectedItinerary, traceId, clientId, additionalClientmarkup, flag, purchaseId,
    adtCount, chdCount, infCount, refundable }) => {

    const [fareRulePopUp, setFareRulePopUp] = useState(false);
    const [flightResponsesData, setFlightResponsesData] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    let purchaseID = currentTicket && currentTicket?.fareGroups && currentTicket?.fareGroups?.length > 0 &&
        currentTicket?.fareGroups[selectedItinerary]?.purchaseId ? currentTicket?.fareGroups[selectedItinerary]?.purchaseId :
        purchaseId;
    let vendorCode = vendor ? vendor : currentTicket?.vendor;
    async function flightResponses(paxType) {
        try {
            setShowLoader(true);
            let flightResponses = await getDetailedFareRule(traceId, additionalClientmarkup, clientId, purchaseID, flag);
            let array = [];
            if (flightResponses) {
                flightResponses.segGroups.forEach(segGroup => {
                    segGroup.fareBasis.map(basis => {
                        if (basis.applicable.length === 3 && basis.applicable === paxType) {
                            let segs = { ...segGroup };
                            segs.fareBasis = segGroup.fareBasis.filter(b => b.applicable === paxType);
                            array.push(segs)
                        }
                        if (basis.applicable.length > 3) {
                            let types = basis.applicable.split(",")
                            if (types.some(type => type === paxType)) {
                                let segs = { ...segGroup };
                                segs.fareBasis = segGroup.fareBasis.filter(b => b.applicable.split(",").some(type => type === paxType));
                                array.push(segs)
                            }
                        }
                    })
                })
                const cleanedArray = removeDuplicates(array);
                setFlightResponsesData(cleanedArray);
                setFareRulePopUp(true);
                setShowLoader(false);
            } else {
                console.warn("No flight responses returned.");
                setShowLoader(false);
            }
        } catch (error) {
            setShowLoader(false);
            console.error("Error fetching flight responses:", error);
        }
    }
    const removeDuplicates = (array) => {
        const deduplicateFareBasis = (fareBasis) => {
            const seenKeys = new Set();
            return fareBasis.filter(item => {
                const key = `${item.code}-${item.airLine}-${item.applicable}`;
                if (seenKeys.has(key)) {
                    return false;
                }
                seenKeys.add(key);
                return true;
            });
        };
        array.forEach(item => {
            item.fareBasis = deduplicateFareBasis(item.fareBasis);
        });
        const seenCodesTopLevel = new Set();
        return array.filter(item => {
            const codesKey = item.fareBasis.map(fare => `${fare.code}-${fare.airLine}-${fare.applicable}`).join('-');
            if (seenCodesTopLevel.has(codesKey)) {
                return false;
            }
            seenCodesTopLevel.add(codesKey);
            return true;
        });
    };

    useEffect(() => {

        if (flightResponsesData && flightResponsesData.segGroups && flightResponsesData.segGroups.length > 0) {
            setFareRulePopUp(true);
        }
    }, [flightResponsesData]);


    const handleClickOpen = (paxType) => {

        setShowLoader(true);
        flightResponses(paxType);
    };

    const handleClose = () => {
        setFareRulePopUp(false);
        setFlightResponsesData(null);
    };

    return (
        <>
            {miniRules &&
                getMiniRulesData(
                    miniRules,
                    "Date Change"
                ).length === 0 ? (
                <div className="">

                    <table className="fareDetailsTable mb-3">
                        <tr>
                            <th className="top_headers">Pax</th>
                            <th className="top_headers">
                                Applicable For
                            </th>
                            <th className="top_headers">Amount</th>
                            {
                                ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                    currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                    currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                    currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                )
                                    ?
                                    <th className="top_headers">Detailed Fare Rule</th>
                                    : ''
                            }
                            <th className="top_headers">Remarks</th>
                        </tr>
                        <tr>
                            {adtCount >= 1 ? (
                                <>
                                    <td className="right">ADT</td>

                                    <td className="right">
                                        Before and After
                                    </td>

                                    <td className="right">-</td>
                                    {
                                        ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                            currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                            currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                            currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                        ) ?

                                            <td className="right" width="30%">
                                                <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("ADT")}>View</div>
                                            </td>
                                            : ''
                                    }
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>

                        <tr>
                            {chdCount >= 1 ? (
                                <>
                                    <td className="right">CHD</td>
                                    <td className="right">
                                        Before and After
                                    </td>
                                    <td className="right">-</td>
                                    {
                                        ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                            currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                            currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                            currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                        )
                                            ?

                                            <td className="right" width="30%">
                                                <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("CHD")}>View</div>
                                            </td>
                                            : ''
                                    }
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                        <tr>
                            {infCount >= 1 ? (
                                <>
                                    <td className="right">INF</td>

                                    <td className="right">
                                        Before and After
                                    </td>
                                    <td className="right">-</td>
                                    {
                                        ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                            currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                            currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                            currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                        )
                                            ?

                                            <td className="right" width="30%">
                                                <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("INF")}>View</div>
                                            </td>
                                            : ''
                                    }
                                    <td className="right">
                                        Non-Changeable
                                    </td>
                                </>
                            ) : (
                                ""
                            )}
                        </tr>
                    </table>
                    <Row className="mx-0 pb-2">
                        <div className="col-12 flt-highlight-label px-0 py-1">
                            <span className="badge flt-imp-badge-label mx-2">
                                Important Note
                            </span>
                            <ul className="mt-2">
                                <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                </li>
                                <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this
                                    if required.
                                </li>
                                <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based
                                    on the current rates.{" "}
                                </li>
                                <li className="flt-imp-msg">
                                    Most Restrictive Rule applicable as per Airline terms
                                </li>
                            </ul>
                        </div>
                    </Row>
                </div>
            ) : (
                <div>
                    {adtCount >= 1 ||
                        chdCount >= 1 ||
                        infCount >= 1 ? (
                        <div>
                            {miniRules &&
                                adtCount >= 1 &&
                                miniRules.length &&
                                miniRules.filter(filterAdultChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                {
                                                    ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                        currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                        currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                        currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                    )
                                                        ?
                                                        <th className="top_headers">Detailed Fare Rule</th>
                                                        : ''
                                                }
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {miniRules &&
                                                miniRules.length &&
                                                miniRules.filter(
                                                    filterAdultChangeDate
                                                ).length > 0 &&
                                                miniRules
                                                    .filter(filterAdultChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    miniRules &&
                                                                                    miniRules
                                                                                        .length &&
                                                                                    miniRules.filter(
                                                                                        filterAdultChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                ADT
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {adtCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                        {
                                                                            ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                                                currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                                                currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                                                currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                                            )
                                                                                ?

                                                                                <td className="right" width="30%">
                                                                                    <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("ADT")}>View</div>
                                                                                </td>
                                                                                : ''
                                                                        }

                                                                        {adtCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined &&
                                                                                    listItem?.remarks !==
                                                                                    ""
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined &&
                                                                                        listItem?.remarks ===
                                                                                        ""
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {chdCount >= 1 &&
                                miniRules &&
                                miniRules.length &&
                                miniRules.filter(filterChildChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                {
                                                    ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                        currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                        currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                        currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                    )
                                                        ?
                                                        <th className="top_headers">Detailed Fare Rule</th>
                                                        : ''
                                                }
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {miniRules &&
                                                miniRules.length &&
                                                miniRules.filter(
                                                    filterChildChangeDate
                                                ).length > 0 &&
                                                miniRules
                                                    .filter(filterChildChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    miniRules &&
                                                                                    miniRules
                                                                                        .length &&
                                                                                    miniRules.filter(
                                                                                        filterChildChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                CHD
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {chdCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {
                                                                            ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                                                currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                                                currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                                                currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                                            )
                                                                                ?

                                                                                <td className="right" width="30%">
                                                                                    <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("CHD")}>View</div>
                                                                                </td>
                                                                                : ''
                                                                        }
                                                                        {chdCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined &&
                                                                                    listItem?.remarks !==
                                                                                    ""
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined &&
                                                                                        listItem?.remarks ===
                                                                                        ""
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {miniRules &&
                                miniRules.length &&
                                infCount >= 1 &&
                                miniRules.filter(filterInfantChangeDate)
                                    .length > 0 ? (
                                <>
                                    <div className="">
                                        <table className="fareDetailsTable mb-3">
                                            <tr>
                                                <th className="top_headers">
                                                    Pax
                                                </th>
                                                <th className="top_headers">
                                                    Applicable For
                                                </th>
                                                <th className="top_headers">
                                                    Amount
                                                </th>
                                                {
                                                    ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                        currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                        currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                        currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                    )
                                                        ?
                                                        <th className="top_headers">Detailed Fare Rule</th>
                                                        : ''
                                                }
                                                <th className="top_headers">
                                                    Remarks
                                                </th>
                                            </tr>
                                            {miniRules &&
                                                miniRules.length &&
                                                miniRules.filter(
                                                    filterInfantChangeDate
                                                ).length > 0 &&
                                                miniRules
                                                    .filter(filterInfantChangeDate)
                                                    .map(
                                                        (listItem, index, element) =>
                                                            listItem.apply ? (
                                                                <tr>
                                                                    <>
                                                                        {index === 0 && (
                                                                            <th
                                                                                className="left_headers"
                                                                                width="10%"
                                                                                rowSpan={
                                                                                    miniRules &&
                                                                                    miniRules
                                                                                        .length &&
                                                                                    miniRules.filter(
                                                                                        filterInfantChangeDate
                                                                                    ).length
                                                                                }
                                                                            >
                                                                                INF
                                                                            </th>
                                                                        )}

                                                                        <td
                                                                            className="left_headers"
                                                                            width="20%"
                                                                        >
                                                                            {listItem.apply}
                                                                        </td>
                                                                        {infCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="10%"
                                                                            >
                                                                                {listItem?.remarks === "Non-Changeable" ?
                                                                                    "-" :
                                                                                    listItem?.exgAmt !==
                                                                                        undefined &&
                                                                                        listItem?.exgAmt !==
                                                                                        0
                                                                                        ? currencyCode +
                                                                                        "  " +
                                                                                        listItem?.exgAmt
                                                                                        : listItem?.exgAmt === 0 && listItem?.remarks
                                                                                            ? listItem?.remarks === "As per Airline Applicable" ? "-" : "0"
                                                                                            : "-"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                        {
                                                                            ((vendorCode === "1A" || vendorCode === "1G" || vendorCode === "1S") &&
                                                                                currentTicket.currentStatus !== "Booking_In_Process" && currentTicket.currentStatus !== "Booking_Failed" &&
                                                                                currentTicket.currentStatus !== "Booking_Rejected" && currentTicket.currentStatus !== "Payment_Failed" &&
                                                                                currentTicket.currentStatus !== "Hold_Cancelled" && currentTicket.currentStatus !== "Hold_Cancelled_Failed"
                                                                            )
                                                                                ?

                                                                                <td className="right" width="30%">
                                                                                    <div style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClickOpen("INF")}>View</div>
                                                                                </td>
                                                                                : ''
                                                                        }


                                                                        {infCount >=
                                                                            1 ? (
                                                                            <td
                                                                                className="right"
                                                                                width="30%"
                                                                            >
                                                                                {listItem?.remarks !==
                                                                                    undefined
                                                                                    ? listItem?.remarks
                                                                                    : listItem?.remarks !==
                                                                                        undefined
                                                                                        ? "NIL"
                                                                                        : "NIL"}
                                                                            </td>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )
                                                    )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {miniRules &&
                                miniRules.length &&
                                (miniRules.filter(filterAdultChangeDate)
                                    .length > 0 ||
                                    miniRules.filter(filterChildChangeDate)
                                        .length > 0 ||
                                    miniRules.filter(filterInfantChangeDate)
                                        .length > 0) ? (
                                <Row className="mx-0 pb-2">
                                    <div className="col-12 flt-highlight-label px-0 py-1">
                                        <span className="badge flt-imp-badge-label mx-2">
                                            Important Note
                                        </span>
                                        <ul className="mt-2">
                                            <li className="flt-imp-msg">
                                                Additional admin fee applicable.
                                            </li>
                                            <li className="flt-imp-msg">
                                                Please contact the operations team
                                                for further assistance and clarity
                                                on this if required.
                                            </li>
                                            <li className="flt-imp-msg">
                                                ROE (Rate of Exchange) is always
                                                based on the current rates.{" "}
                                            </li>
                                            <li className="flt-imp-msg">
                                                Most Restrictive Rule applicable as per Airline terms
                                            </li>
                                        </ul>
                                    </div>
                                </Row>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="">
                            <table className="fareDetailsTable mb-3">
                                <tr>
                                    <th className="top_headers"></th>
                                    {adtCount >= 1 ? (
                                        <td className="right">ADT</td>
                                    ) : (
                                        ""
                                    )}
                                    {chdCount >= 1 ? (
                                        <td className="right">CHD</td>
                                    ) : (
                                        ""
                                    )}
                                    {infCount >= 1 ? (
                                        <td className="right">INF</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        Before Trip
                                    </th>
                                    {adtCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {chdCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {infCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <th
                                        className="left_headers"
                                        width="175"
                                    >
                                        After Trip
                                    </th>
                                    {adtCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {chdCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                    {infCount >= 1 ? (
                                        <td className="right">
                                            Non Changeable
                                        </td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                            </table>
                            <Row className="mx-0 pb-2">
                                <div className="col-12 flt-highlight-label px-0 py-1">
                                    <span className="badge flt-imp-badge-label mx-2">
                                        Important Note
                                    </span>
                                    <ul className="mt-2">
                                        <li className="flt-imp-msg">
                                            Additional admin fee applicable.
                                        </li>
                                        <li className="flt-imp-msg">
                                            Please contact the operations team
                                            for further assistance and clarity
                                            on this if required.
                                        </li>
                                        <li className="flt-imp-msg">
                                            ROE (Rate of Exchange) is always
                                            based on the current rates.{" "}
                                        </li>
                                        <li className="flt-imp-msg">
                                            Most Restrictive Rule applicable as per Airline terms
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </div>
                    )}




                </div>
            )}

            <Dialog style={{ margin: '50px auto', maxWidth: '50vw' }}
                open={fareRulePopUp}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false} // Disable default maxWidth
                fullWidth={true} // Enable full width
            >

                <DialogContent style={{ maxWidth: '50vw' }}>
                    {fareRulePopUp && flightResponsesData && (
                        <DetailedFareRule
                            setFareRulePopUp={setFareRulePopUp}
                            flightResponses={flightResponsesData}

                        />
                    )}
                </DialogContent>
            </Dialog>
            {showLoader && <RiseLoading client={showLoader} />}
        </>
    )
}

export default DateChange