import React from "react";
import {
  Grid,
  Container,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import hero from "../../assets/hero.jpg";
import LuggageIcon from "@mui/icons-material/Luggage";
import feature from "../../assets/feature1.png";
import LockIcon from "@mui/icons-material/Lock";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManIcon from "@mui/icons-material/Man";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FlightIcon from "@mui/icons-material/Flight";
import RecommendIcon from "@mui/icons-material/Recommend";
import { Table, TableCell, TableHead } from "@mui/material";
import get2ltaly from "../../assets/get2ltaly.jpg";

function SignInHero() {
  const goToRegister = () => {
    window.open("https://register.getfares.com/", "_blank");
  };
  return (
    <React.Fragment>
      <div id="Home" style={{ backgroundColor: "white", padding: "0 1vw" }}>
        {/* <Container> */}
        <Grid container>
          <Grid item xs={7} style={{ margin: "3vw 0" }}>
            {/* <img alt="italy ad banner" src="{hero}" style={{ width: "100%" }}/> */}

            <p
              style={{
                fontSize: "16px",
                color: "#a0a9b1",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.8",
                marginTop: "0vw",
              }}
            >
              EXPLORE GETFARES TODAY
            </p>
            <h1
              style={{
                fontSize: "2.5rem",
                color: "#343a40",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.2",
                margin: "0px",
              }}
            >
              Get lowest fares for the same flights with{" "}
              <span style={{ color: "#9f155a" }}>GetFares</span>
            </h1>
            <p
              style={{
                color: "#a0a9b1",
                fontSize: "15px",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.6",
              }}
            >
              Our goal at GetFares is to save you considerable time and effort
              when consolidating multiple fares.
            </p>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                style={{
                  textTransform: "none",
                  width: "140px",
                  fontWeight: "600px",
                }}
                onClick={goToRegister}
              >
                Register Here
              </Button>
              <Button
                color="primary"
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "#FFBFDF",
                  width: "140px",
                  marginLeft: "10px",
                  fontWeight: "600px",
                }}
              >
                Learn More
              </Button>
            </div>
            <div className="get2ItalyBannerDiv" style={{ marginTop: "3vh" }}>
              <Table>
                <a
                  href="https://www.get2italy.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={get2ltaly}
                  />
                </a>
                {/* <TableBody>
                <TableCell>
                  <p>
                    Please scan the QR or click on the above image for
                    enquiries.
                  </p>                   
                  <img
                    className="G2IRetailQR"
                    src={G2IRetailQR}
                    alt="G2IRetailQR"
                  ></img>
                </TableCell>
              </TableBody> */}
              </Table>
            </div>
          </Grid>
          <Grid item xs={5}>
            <img
              alt="company logo"
              style={{
                width: "42vw",
              }}
              src={hero}
            />
          </Grid>
        </Grid>
        {/* </Container> */}
      </div>
      <div
        id="Features"
        style={{ backgroundColor: "#f8fafe", padding: "50px" }}
      >
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                Features
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Key features of the product
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <LockIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    How does single sign-on works?
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    The Trusted certificate been used to sign identity
                    information that is being sent from the identity provider to
                    the Getfares system.
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <AirplaneTicketIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Automation
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    Ticketing and post-ticketing have been integrated into one
                    system, such as ticketing and the ability to reissue,
                    cancel, or void a ticket.
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <LuggageIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Ancillary Airline products
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    Selling seats can be transformed into bundling several
                    products for a single price.
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <AccountBalanceWalletIcon
                        color="primary"
                        fontSize="large"
                      />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Payments Made Easy
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    The GetFares website accepts payments in several
                    currencies.Your account can be loaded in the currency of
                    your choice.
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <ChatBubbleIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    24/7 Support
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    Using AI/ML,GetFares is able to comprehend what people are
                    saying, respond appropriately, and assign an agent
                    accordingly.
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <RecommendIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Customer Friendly
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    Getfares is easy to use, simple, and trusted by customers.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div id="Solutions" style={{ backgroundColor: "white", padding: "50px" }}>
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                Solutions
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Travel Management made easy
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={6}>
                <div style={{ padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <TouchAppIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Control and visibility are at your fingertips
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    View expenses and reports from a user-friendly dashboard.
                  </p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img
                  alt="company logo"
                  style={{
                    width: "500px",
                    marginLeft: "100px",
                  }}
                  src={feature}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "50px" }}>
              <Grid item xs={6}>
                <img
                  alt="company logo"
                  style={{
                    width: "500px",
                  }}
                  src={feature}
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ padding: "1.8rem", marginLeft: "55px" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <ManageSearchIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    Travel management made easy
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                    Access actionable insights anytime, anywhere.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div style={{ backgroundColor: "#9f155a", padding: "50px" }}>
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "White",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Best Solutions for your Business
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              >
                With GetFares, customers can book flights and non-flight content
                online.
              </p>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <ManIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    5000 +
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                    Clients
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <FlightIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    600 +
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                    Airlines
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <PointOfSaleIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    30 +
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                    POS
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <CalendarTodayIcon
                      sx={{ color: "#FFBFDF", fontSize: 60 }}
                    />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    1978
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                    Since
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* <div
        id="testimonials"
        style={{ backgroundColor: "#f8fafe", padding: "50px" }}
      >
        <Box sx={{ justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <h5
              style={{
                fontSize: "14px",
                letterSpacing: "1px",
                color: "#9f155a",
                fontFamily: "Rubik,sans-serif",
                textTransform: "uppercase",
                lineHeight: "1.2",
                fontWeight: "500px",
                margin: "10px",
              }}
            >
              TESTIMONIAL
            </h5>
            <h4
              style={{
                fontSize: "1.5rem",
                color: "#343a40",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.2",
                fontWeight: "500px",
                margin: "0px",
                padding: "0px",
              }}
            >
              What our Clients say
            </h4>
            <p
              style={{
                color: "#a0a9b1",
                fontSize: "15px",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.6",
                margin: "10px",
              }}
            >
              It has been our pleasure to form excellent partnerships with many
              of our clients.
            </p>
          </div>
        </Box>
        <Box style={{ marginTop: "100px", padding: "0px 50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paper style={{ padding: "15px" }}>
                <div style={{ color: "#a0a9b1", fontSize: "15px" }}>
                  Our association with Getfares Team is very valuable to us.
                  Innoglobe Technologies benefits from the Getfares by
                  integrating it into our engine, thus allowing us to provide
                  seamless solutions globally. In addition, our 24/7 support
                  provides excellent service.Our team is eager to use their
                  technology in the future.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "50px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>
                        AH
                      </Avatar>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          alignItems: "flex-start",
                        }}
                      >
                        Ariful Haque
                      </div>
                      <div style={{ alignItems: "flex-end" }}>
                        Managing Director , Innoglobe
                      </div>
                    </div>
                  </div> */}
      {/* <div sx={{ alignItems: "flex-end" }}>
                    <Rating name="read-only" value={4} readOnly />
                  </div> */}
      {/* </div>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: "15px" }}>
                <div style={{ color: "#a0a9b1", fontSize: "15px" }}>
                  Our association with Getfares Team is very valuable to us.
                  Innoglobe Technologies benefits from the Getfares by
                  integrating it into our engine, thus allowing us to provide
                  seamless solutions globally. In addition, our 24/7 support
                  provides excellent service.Our team is eager to use their
                  technology in the future.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "50px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>
                        AH
                      </Avatar>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          alignItems: "flex-start",
                        }}
                      >
                        Ariful Haque
                      </div>
                      <div style={{ alignItems: "flex-end" }}>
                        Managing Director , Innoglobe
                      </div>
                    </div>
                  </div> */}
      {/* <div sx={{ alignItems: "flex-end" }}>
                    <Rating name="read-only" value={4} readOnly />
                  </div> */}
      {/* </div>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: "15px" }}>
                <div style={{ color: "#a0a9b1", fontSize: "15px" }}>
                  Our association with Getfares Team is very valuable to us.
                  Innoglobe Technologies benefits from the Getfares by
                  integrating it into our engine, thus allowing us to provide
                  seamless solutions globally. In addition, our 24/7 support
                  provides excellent service.Our team is eager to use their
                  technology in the future.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "50px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>
                        AH
                      </Avatar>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          alignItems: "flex-start",
                        }}
                      >
                        Ariful Haque
                      </div>
                      <div style={{ alignItems: "flex-end" }}>
                        Managing Director , Innoglobe
                      </div>
                    </div>
                  </div> */}
      {/* <div sx={{ alignItems: "flex-end" }}>
                    <Rating name="read-only" value={4} readOnly />
                  </div> */}
      {/* </div>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: "15px" }}>
                <div style={{ color: "#a0a9b1", fontSize: "15px" }}>
                  Our association with Getfares Team is very valuable to us.
                  Innoglobe Technologies benefits from the Getfares by
                  integrating it into our engine, thus allowing us to provide
                  seamless solutions globally. In addition, our 24/7 support
                  provides excellent service.Our team is eager to use their
                  technology in the future.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "50px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar sx={{ bgcolor: "gray", width: 56, height: 56 }}>
                        AH
                      </Avatar>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          alignItems: "flex-start",
                        }}
                      >
                        Ariful Haque
                      </div>
                      <div style={{ alignItems: "flex-end" }}>
                        Managing Director , Innoglobe
                      </div>
                    </div>
                  </div> */}
      {/* <div sx={{ alignItems: "flex-end" }}>
                    <Rating name="read-only" value={4} readOnly />
                  </div> */}
      {/* </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div> */}
      <div
        id="getstarted"
        style={{ backgroundColor: "white", padding: "50px" }}
      >
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                Register Here
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                An excellent journey begins here,
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              >
                The mission of our company is to help businesses achieve their
                goals.
              </p>
            </div>
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <TextField
                id="outlined-basic"
                label="entr your email here"
                variant="outlined"
                style={{ width: "500px" }}
              />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Button color="primary" variant="contained">
                Register Here
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default SignInHero;
