import getFare from "./getFare";

// export default function checkPricesFilter(item, search) {

//   let prices = [];
//   let totalPriceArr = [];

//   // deciding price array
//   if((search.filters.price).length === 3){
//     prices = [search.filters.price[0], search.filters.price[2]];
//   } else {
//     prices = [search.filters.price[3], search.filters.price[2]];
//   }

//   // getting total price array
//   item.fareGroups[0].fares.forEach((item2) => {
//     totalPriceArr.push(getFare(item2));
//   });

//   // sum of the array
//   let tempTotal = totalPriceArr.reduce((a, b) => a + b, 0);

//   if ( tempTotal >= prices[0] && tempTotal <= prices[1]) {
//     console.log(112233,"checkPricesFilter => ",true);
//     return true;
//   } else {
//     console.log(112233,"checkPricesFilter => ",false);
//     return false;
//   }



// }

export default function checkPricesFilter(item, search) {
  let prices = [search.filters.price[0], search.filters.price[2]];
  if (getFare(item.fareGroups[0].fares[0]) <= prices[1]) {
    return true;
  } else {
    return false;
  }
}
