import axios from "axios";

export default  function getClientBalance(getClientId) {
    return axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/Transactions/GetAccountBanalce?clientId=" +
        getClientId
      )
      .then((response) => {
        //console.log(response);
        return response.data;
      });
  }

  