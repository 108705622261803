import React, { Fragment, useEffect, useState } from "react";
import "./SearchCategoryConfigurationDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress, Box, Chip, IconButton } from "@material-ui/core";
import moment from "moment";
import countries from "../../../../data/Country";
import supplierList from "../../../../data/Supplier";
import cabinClassList from "../../../../data/CabinClass";
import TripTypeList from "../../../../data/TripType";
import airlineDetails from "../../../../data/AirlineDetails";
import airportDetails from "../../../../data/AirportDetails";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import { MultiSelect } from "react-multi-select-component";
import TextField from "@mui/material/TextField";
import { exclude } from "query-string";
import axios from "axios";
import { isUserHasPermission } from "../../../../services/AuthService";
import RiseLoading from "../../../Loader/RiseLoader";

const paxTypeList = ["JCB", "SEA", "VFR", "LBR"];
const statusList = ["true", "false"];
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

let AllTravelTypeList = TripTypeList;
AllTravelTypeList.unshift("All");

function getStylesMultiselect(value, cabinClassType, theme) {
  return {
    fontWeight:
      cabinClassType !== undefined && cabinClassType.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 175,
    },
  },
};
const SelectProps = {
  multiple: true,
};

export default function SearchCategoryConfigurationDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  pccList,
  catid,
  name,
}) {
  const theme = useTheme();

  const handleChangeAll = (event, setValue) => {
    const {
      target: { value },
    } = event;
    if (value.indexOf("All") !== -1) {
      const allArr = ["All"];
      setValue(allArr);
    } else {
      setValue(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };
  // const handleChangeAutoAll = (event, newValue, setValue) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   newValue.map(key => strVal !== "" ? console.log(strVal + "," + key.value) : console.log(key.value));
  //   if (newValue.indexOf("All") !== -1) {
  //     const allArr = ["All"];
  //     setValue(allArr);
  //   } else {
  //     setValue(
  //       // On autofill we get a the stringified value.
  //       typeof newValue === "string" ? newValue.split(",") : newValue
  //     );
  //   }
  // };

  const classes = useStyles();

  const [strVal, setStrVal] = useState("");
  const [id, setid] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [created, setcreated] = useState("");
  const [modifiedBy, setmodifiedBy] = useState("");
  const [modified, setmodified] = useState("");
  const [paxType, setpaxType] = useState([]);

  const [searchCategoryId, setsearchCategoryId] = useState(catid);
  const [supplierName, setsupplierName] = useState("");
  const [pcc, setpcc] = useState("");
  const [ipcc, setipcc] = useState("");

  const [travelType, settravelType] = useState([]);
  const [cabinClassType, setcabinClassType] = useState([]);
  const [remarks, setremarks] = useState("");
  const [airline, setairline] = useState([]);

  const [originAirport, setoriginAirport] = useState([]);
  const [destinationAirport, setdestinationAirport] = useState([]);
  const [exOriginAirport, setexOriginAirport] = useState([]);
  const [exDestinationAirport, setexDestinationAirport] = useState([]);

  const [originCountry, setoriginCountry] = useState([]);
  const [exOriginCountry, setexOriginCountry] = useState([]);
  const [destinationCountry, setdestinationCountry] = useState([]);
  const [exDestinationCountry, setexDestinationCountry] = useState([]);

  const [includeAirlines, setincludeAirlines] = useState([]);
  const [excludedAirlines, setexcludedAirlines] = useState([]);
  const [corporateCode, setcorporateCode] = useState("");
  const [accountCode, setaccountCode] = useState("");

  const [outputDirectSearch, setoutputDirectSearch] = useState(true);
  const [inputDirectSearch, setinputDirectSearch] = useState(true);
  const [isActive, setisActive] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [filteredcabinClass, setFilteredCabinClass] = useState(cabinClassList);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setid(selectedItem.id);
      setcreatedBy(selectedItem.createdBy);
      setcreated(selectedItem.created);
      setmodifiedBy(selectedItem.modifiedBy);
      setmodified(selectedItem.modified);

      setsearchCategoryId(selectedItem.searchCategoryId);
      setsupplierName(selectedItem.supplierNameType);
      setpcc(selectedItem.pcc + "," + selectedItem.supplierNameType);
      setipcc(selectedItem.ipcc);

      setremarks(selectedItem.remarks);

      if (
        selectedItem.travelType !== undefined &&
        selectedItem.travelType !== ""
      ) {
        let list = [];
        selectedItem.travelType
          .split(",")
          .map((trip) =>
            list.push(AllTravelTypeList.find((el) => el === trip))
          );
        settravelType(list);
      } else {
        settravelType([]);
      }
      if (
        selectedItem.cabinClassType !== undefined &&
        selectedItem.cabinClassType !== ""
      ) {
        let list = [];
        selectedItem.cabinClassType
          .split(",")
          .map((cabin) =>
            list.push(cabinClassList.find((el) => el.value === cabin))
          );
        setcabinClassType(list);
      } else {
        setcabinClassType([]);
      }

      if (selectedItem.airlines !== undefined && selectedItem.airlines !== "") {
        let list = [];
        selectedItem.airlines
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setairline(list);
      } else {
        setairline([]);
      }

      if (
        selectedItem.originAirport !== undefined &&
        selectedItem.originAirport !== ""
      ) {
        let list = [];
        selectedItem.originAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setoriginAirport(list);
      } else {
        setoriginAirport([]);
      }
      if (
        selectedItem.destinationAirport !== undefined &&
        selectedItem.destinationAirport !== ""
      ) {
        let list = [];
        selectedItem.destinationAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setdestinationAirport(list);
      } else {
        setdestinationAirport([]);
      }
      if (
        selectedItem.exOriginAirport !== undefined &&
        selectedItem.exOriginAirport !== ""
      ) {
        let list = [];
        selectedItem.exOriginAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setexOriginAirport(list);
      } else {
        setexOriginAirport([]);
      }
      if (
        selectedItem.exDestinationAirport !== undefined &&
        selectedItem.exDestinationAirport !== ""
      ) {
        let list = [];
        selectedItem.exDestinationAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setexDestinationAirport(list);
      } else {
        setexDestinationAirport([]);
      }

      if (
        selectedItem.originCountry !== undefined &&
        selectedItem.originCountry !== ""
      ) {
        let list = [];
        selectedItem.originCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setoriginCountry(list);
      } else {
        setoriginCountry([]);
      }
      if (
        selectedItem.destinationCountry !== undefined &&
        selectedItem.destinationCountry !== ""
      ) {
        let list = [];
        selectedItem.destinationCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setdestinationCountry(list);
      } else {
        setdestinationCountry([]);
      }
      if (
        selectedItem.exOriginCountry !== undefined &&
        selectedItem.exOriginCountry !== ""
      ) {
        let list = [];
        selectedItem.exOriginCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setexOriginCountry(list);
      } else {
        setexOriginCountry([]);
      }
      if (
        selectedItem.exDestinationCountry !== undefined &&
        selectedItem.exDestinationCountry !== ""
      ) {
        let list = [];
        selectedItem.exDestinationCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setexDestinationCountry(list);
      } else {
        setexDestinationCountry([]);
      }

      if (
        selectedItem.includeAirlines !== undefined &&
        selectedItem.includeAirlines !== ""
      ) {
        let list = [];
        selectedItem.includeAirlines
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setincludeAirlines(list);
      } else {
        setincludeAirlines([]);
      }
      if (
        selectedItem.excludedAirlines !== undefined &&
        selectedItem.excludedAirlines !== ""
      ) {
        let list = [];
        selectedItem.excludedAirlines
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setexcludedAirlines(list);
      } else {
        setexcludedAirlines([]);
      }

      if (selectedItem.paxType !== undefined && selectedItem.paxType !== "") {
        let list = [];
        selectedItem.paxType
          .split(",")
          .map((type) => list.push(paxTypeList.find((el) => el === type)));
        setpaxType(list);
      } else {
        setpaxType([]);
      }

      setcorporateCode(selectedItem.corporateCode);
      setaccountCode(selectedItem.accountCode);

      setoutputDirectSearch(selectedItem.outputDirectSearch.toString());
      setisActive(selectedItem.isActive.toString());
      getCabinClass();
    } else {
      setid("");
      setcreatedBy("");
      setcreated("");
      setmodifiedBy("");
      setmodified("");
      setsearchCategoryId(catid);
      setsupplierName("");
      setpcc("");
      setipcc("");

      settravelType([]);
      setcabinClassType([]);
      setremarks("");
      setairline([]);

      setoriginAirport([]);
      setdestinationAirport([]);
      setexOriginAirport([]);
      setexDestinationAirport([]);

      setoriginCountry([]);
      setexOriginCountry([]);
      setdestinationCountry([]);
      setexDestinationCountry([]);

      setincludeAirlines([]);
      setexcludedAirlines([]);
      setcorporateCode("");
      setaccountCode("");
      setpaxType([]);

      setoutputDirectSearch(true);
      setinputDirectSearch(true);
      setisActive(true);
      getCabinClass();
    }
  }, [toggleDrawer, action]);


  const getCabinClass = () => {
    if ((pccList !== undefined) && (action === "edit" || action === "new")) {
      if (["AirArabia", "Jazeera", "AkasaAir", "Flyadeal"].includes(supplierName) || pcc.includes("85NR")) {
        const filtered = cabinClassList.filter(option => option.label === "Economy");
        setFilteredCabinClass(filtered);
      } else {
        setFilteredCabinClass(cabinClassList);
      }
    } else if (action === "view") {
      setFilteredCabinClass(cabinClassList);
    }
  };

  useEffect(() => {
    getCabinClass();

  }, [pcc, action, supplierName]);

  const filterObjectArray = (arr, filterArr) =>
    arr.filter((el) => el.value === filterArr);


  const handleChange = (event, newValue) => {
    if (newValue.some(option => option.value === '0' || option.value === '6')) {
      newValue = newValue.filter(option => option.value === '0' || option.value === '6');
    }
    setcabinClassType(newValue);
    updateFilteredOptions(newValue);
  };

  const updateFilteredOptions = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === '0')) {
      setFilteredCabinClass(cabinClassList.filter(option => option.value === '0'));
    } else if (selectedOptions.some(option => option.value === '6')) {
      setFilteredCabinClass(cabinClassList.filter(option => option.value === '6'));
    } else {
      getCabinClass();
    }
  };

  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
      if (field === "value") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.value)
            : (strValue = strValue + "," + arr.value)
        );
      } else if (field === "Code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.Code)
            : (strValue = strValue + "," + arr.Code)
        );
      } else if (field === "city_code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.city_code)
            : (strValue = strValue + "," + arr.city_code)
        );
      } else if (field === "countrycode") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.code)
            : (strValue = strValue + "," + arr.code)
        );
      } else if (field === "raw") {
        arrayList.map((arr) =>
          strValue === "" ? (strValue = arr) : (strValue = strValue + "," + arr)
        );
      }
    }
    return strValue;
  };

  async function handleUpdate() {

    var Cabype = handleArrayToString(cabinClassType, "value")
    console.log(cabinClassType.label)
    if ((supplierName === "AirArabia" ||
      supplierName === "Jazeera" ||
      supplierName === "AkasaAir" ||
      supplierName === "Flyadeal" ||
      (pcc.includes("85NR"))) && Cabype !== "1") {

      return alert("Only Economy Cabin allowed for LCC")
    }
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/SearchCategoryConfiguration/" +
          selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy:
              selectedItem.modifiedBy === undefined
                ? ""
                : selectedItem.modifiedBy,
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,

            searchCategoryId:
              searchCategoryId === undefined ? "" : searchCategoryId,
            SupplierNameType: pcc === undefined ? "" : pcc.split(",")[1],
            pcc: pcc === undefined ? "" : pcc.split(",")[0],
            ipcc: pcc === undefined ? "" : pcc.split(",")[0],

            remarks: remarks === undefined ? "" : remarks,
            travelType: handleArrayToString(travelType, "raw"),
            cabinClassType: handleArrayToString(cabinClassType, "value"),
            airlines: handleArrayToString(airline, "Code"),

            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            exOriginAirport: handleArrayToString(exOriginAirport, "city_code"),
            exDestinationAirport: handleArrayToString(
              exDestinationAirport,
              "city_code"
            ),

            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
            exOriginCountry: handleArrayToString(
              exOriginCountry,
              "countrycode"
            ),
            exDestinationCountry: handleArrayToString(
              exDestinationCountry,
              "countrycode"
            ),

            includeAirlines: handleArrayToString(includeAirlines, "Code"),
            excludedAirlines: handleArrayToString(excludedAirlines, "Code"),

            corporateCode: corporateCode === undefined ? "" : corporateCode,
            accountCode: accountCode === undefined ? "" : accountCode,
            paxType: handleArrayToString(paxType, "raw"),

            outputDirectSearch:
              String(outputDirectSearch).toLowerCase() === "true"
                ? true
                : false,
            inputDirectSearch:
              String(inputDirectSearch).toLowerCase() === "true" ? true : false,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          //console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          //console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/SearchCategoryConfiguration",
          JSON.stringify({
            id: 0,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            searchCategoryId:
              searchCategoryId === undefined ? "" : searchCategoryId,
            SupplierNameType: pcc === undefined ? "" : pcc.split(",")[1],
            pcc: pcc === undefined ? "" : pcc.split(",")[0],
            ipcc: pcc === undefined ? "" : pcc.split(",")[0],

            remarks: remarks === undefined ? "" : remarks,
            travelType: handleArrayToString(travelType, "raw"),
            cabinClassType: handleArrayToString(cabinClassType, "value"),
            airlines: handleArrayToString(airline, "Code"),

            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            exOriginAirport: handleArrayToString(exOriginAirport, "city_code"),
            exDestinationAirport: handleArrayToString(
              exDestinationAirport,
              "city_code"
            ),

            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
            exOriginCountry: handleArrayToString(
              exOriginCountry,
              "countrycode"
            ),
            exDestinationCountry: handleArrayToString(
              exDestinationCountry,
              "countrycode"
            ),

            includeAirlines: handleArrayToString(includeAirlines, "Code"),
            excludedAirlines: handleArrayToString(excludedAirlines, "Code"),

            corporateCode: corporateCode === undefined ? "" : corporateCode,
            accountCode: accountCode === undefined ? "" : accountCode,
            paxType: handleArrayToString(paxType, "raw"),

            outputDirectSearch:
              String(outputDirectSearch).toLowerCase() === "true"
                ? true
                : false,
            inputDirectSearch:
              String(inputDirectSearch).toLowerCase() === "true" ? true : false,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          //console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          //console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="search-heading-container search-title-flex-container">
        <div>Search Category Configuration</div>
        {action === "view" && (
          <div className="search-heading-icons">
            <IconButton fontSize="small">
              <EditIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditIcon>
            </IconButton>
          </div>
        )}
      </div>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="search-view-edit-drawer-container">
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Search Category"
                  size="small"
                  color="secondary"
                  value={name}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="supplier select"
                  value={pccList.find((v) => v.value === pcc) || null}
                  onChange={(event, newValue) => {
                    setpcc(newValue ? newValue.value : "");
                    setsupplierName(newValue ? newValue.supplierName : "")
                  }}
                  options={pccList.filter(p => p.status === true)}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Pseudo Code"
                      value={pcc}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Search Category</div>
                <div className="view-search-drawer-description ">{name}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">PCC</div>
                <div className="view-search-drawer-description">
                  {pccList
                    .filter((id) => id.value === pcc)
                    .map(({ label }) => label)}
                  {/* {pcc} */}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Remarks"
                  size="small"
                  color="secondary"
                  value={remarks}
                  onChange={(event) => setremarks(event.target.value)}
                  required
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer  config-input-flex-1">
                <div className="view-search-drawer-title">Remarks</div>
                <div className="view-search-drawer-description ">{remarks}</div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="travel type select"
                  multiple
                  value={travelType && travelType}
                  onChange={(event, value) => {
                    settravelType(value);
                  }}
                  options={AllTravelTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Travel Type"
                      value={travelType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: travelType && travelType.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Travel Type</div>
                <div className="view-search-drawer-description">
                  {travelType !== undefined &&
                    travelType.length > 0 &&
                    travelType.map((trip) => <p>{trip}</p>)}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="cabin select"
                  multiple
                  value={cabinClassType && cabinClassType}
                  onChange={handleChange}
                  options={filteredcabinClass}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="CabinClass"
                      value={cabinClassType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: cabinClassType && cabinClassType.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">cabin Class</div>
                <div className="view-search-drawer-description">
                  {cabinClassType !== undefined &&
                    cabinClassType.length > 0 &&
                    cabinClassType.map((cabin) => <p>{cabin.label}</p>)}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="airline select"
                  multiple
                  value={airline && airline}
                  onChange={(event, value) => {
                    setairline(value);
                  }}
                  options={airlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Airline"
                      value={airline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: airline && airline.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Airline</div>
                <div className="view-search-drawer-description">
                  {airline !== undefined &&
                    airline.length > 0 &&
                    airline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-if-container">
            <div className="search-view-edit-drawer-if-line"></div>
            <div>Conditions</div>
            <div className="search-view-edit-drawer-if-line"></div>
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="originAirport select"
                  multiple
                  value={originAirport && originAirport}
                  onChange={(event, value) => {
                    setoriginAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Airport"
                      value={originAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: originAirport && originAirport.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Origin Airport</div>
                <div className="view-search-drawer-description">
                  {originAirport !== undefined &&
                    originAirport.length > 0 &&
                    originAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="destinationAirport select"
                  multiple
                  value={destinationAirport && destinationAirport}
                  onChange={(event, value) => {
                    setdestinationAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Airport"
                      value={destinationAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required:
                          destinationAirport && destinationAirport.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Airport
                </div>
                <div className="view-search-drawer-description">
                  {destinationAirport !== undefined &&
                    destinationAirport.length > 0 &&
                    destinationAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exoriginAirport select"
                  multiple
                  value={exOriginAirport && exOriginAirport}
                  onChange={(event, value) => {
                    setexOriginAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Origin Airport"
                      value={exOriginAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                    Exclude-Origin Airport
                </div>
                <div className="view-search-drawer-description">
                  {exOriginAirport !== undefined &&
                    exOriginAirport.length > 0 &&
                    exOriginAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exdestinationAirport select"
                  multiple
                  value={exDestinationAirport && exDestinationAirport}
                  onChange={(event, value) => {
                    setexDestinationAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Destination Airport"
                      value={exDestinationAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                Exclude-Destination Airport
                </div>
                <div className="view-search-drawer-description">
                  {exDestinationAirport !== undefined &&
                    exDestinationAirport.length > 0 &&
                    exDestinationAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="origincountry select"
                  multiple
                  value={originCountry && originCountry}
                  onChange={(event, value) => {
                    setoriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Country"
                      value={originCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: originCountry && originCountry.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Origin Country</div>
                <div className="view-search-drawer-description">
                  {originCountry !== undefined &&
                    originCountry.length > 0 &&
                    originCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="destinationcountry select"
                  multiple
                  value={destinationCountry && destinationCountry}
                  onChange={(event, value) => {
                    setdestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Country"
                      value={destinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required:
                          destinationCountry && destinationCountry.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Country
                </div>
                <div className="view-search-drawer-description">
                  {destinationCountry !== undefined &&
                    destinationCountry.length > 0 &&
                    destinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exorigincountry select"
                  multiple
                  value={exOriginCountry && exOriginCountry}
                  onChange={(event, value) => {
                    setexOriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Origin Country"
                      value={exOriginCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                Exclude-Origin Country
                </div>
                <div className="view-search-drawer-description">
                  {exOriginCountry !== undefined &&
                    exOriginCountry.length > 0 &&
                    exOriginCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exdestinationcountry select"
                  multiple
                  value={exDestinationCountry && exDestinationCountry}
                  onChange={(event, value) => {
                    setexDestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Destination Country"
                      value={exDestinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                Exclude-Destination Country
                </div>
                <div className="view-search-drawer-description">
                  {exDestinationCountry !== undefined &&
                    exDestinationCountry.length > 0 &&
                    exDestinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="includeairline select"
                  multiple
                  value={includeAirlines && includeAirlines}
                  onChange={(event, value) => {
                    setincludeAirlines(value);
                  }}
                  options={airlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Include Airlines"
                      value={includeAirlines}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required:
                          includeAirlines && includeAirlines.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Include Airlines</div>
                <div className="view-search-drawer-description">
                  {includeAirlines !== undefined &&
                    includeAirlines.length > 0 &&
                    includeAirlines.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="excludedAirlines select"
                  multiple
                  value={excludedAirlines && excludedAirlines}
                  onChange={(event, value) => {
                    setexcludedAirlines(value);
                  }}
                  options={airlineDetails.filter((item) => item.Code !== "All")}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude Airlines"
                      value={excludedAirlines}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Exclude Airlines
                </div>
                <div className="view-search-drawer-description">
                  {excludedAirlines !== undefined &&
                    excludedAirlines.length > 0 &&
                    excludedAirlines.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="CorporateCode"
                  size="small"
                  color="secondary"
                  value={corporateCode}
                  onChange={(event) => setcorporateCode(event.target.value)}
                />
                <p className="m0 fs12">
                  <span className="text-danger">
                    <b>*</b>
                  </span>{" "}
                  Pls use{" "}
                  <span className="text-success fs16">
                    <b>"~"</b>
                  </span>{" "}
                  instead of{" "}
                  <span className="text-danger ">
                    <b>Comma</b> ( <b className="fs16">,</b> )
                  </span>{" "}
                  for multiple Account codes
                </p>
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="AccountCode"
                  size="small"
                  color="secondary"
                  value={accountCode}
                  onChange={(event) => setaccountCode(event.target.value)}
                />
                <p className="m0 fs12">
                  <span className="text-danger">
                    <b>*</b>
                  </span>{" "}
                  Pls use{" "}
                  <span className="text-success fs16">
                    <b>"~"</b>
                  </span>{" "}
                  instead of{" "}
                  <span className="text-danger ">
                    <b>Comma</b> ( <b className="fs16">,</b> )
                  </span>{" "}
                  for multiple Account codes
                </p>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">CorporateCode</div>
                <div className="view-search-drawer-description">
                  {corporateCode}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">AccountCode</div>
                <div className="view-search-drawer-description">
                  {accountCode}
                </div>
              </div>
            )}
          </div>

          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Pax type select"
                  multiple
                  value={paxType && paxType}
                  onChange={(event, value) => {
                    setpaxType(value);
                  }}
                  options={paxTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Pax Type"
                      value={paxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Pax Type</div>
                <div className="view-search-drawer-description">
                  {paxType !== undefined &&
                    paxType.length > 0 &&
                    paxType.map((type) => <p>{type}</p>)}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1"></div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="directsearch-select"
                  value={
                    statusList.find((v) => v === outputDirectSearch) || null
                  }
                  onChange={(event, newValue) => {
                    setoutputDirectSearch(newValue ? newValue : "");
                  }}
                  options={statusList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Direct Search"
                      value={outputDirectSearch}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="status select"
                  value={statusList.find((v) => v === isActive) || null}
                  onChange={(event, newValue) => {
                    setisActive(newValue ? newValue : "");
                  }}
                  options={statusList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Status"
                      value={isActive}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Direct Search</div>
                <div className="view-search-drawer-description ">
                  {outputDirectSearch.toString()}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Status</div>
                <div className="view-search-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <RiseLoading Search={showLoader} />}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
