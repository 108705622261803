import "./ToolTip.css";

export default function Tooltip({ children, tooltipText, position = "top" }) {
  return (
    <div className='tooltip-trigger'>
      {children}
      <div className={`tooltip `}>{tooltipText}</div>
    </div>
  );
}
