import getStartingRates from "./getStartingRates";
export default function getNumberOfStopsFilter(flights) {
  let startingRates = getStartingRates(flights);

  return [
    {
      title: "Non Stop",
      startingFrom: startingRates.nonStopStartingRate,
      checked: false,
    },
    {
      title: "1 Stop",
      startingFrom: startingRates.oneStopStaringRate,
      checked: false,
    },
    {
      title: "2+ Stop",
      startingFrom: startingRates.moreStopStartingRate,
      checked: false,
    },
  ];
}
