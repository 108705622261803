import React, { useEffect, useState } from "react";
import "./MyQueueDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import airlineDetails from "../../../data/AirlineDetails";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function MyQueueDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  pccList,
  vendorList,
  clientList
}) {
  const classes = useStyles();

  const [queueName, setqueueName] = useState("");
  const [bookingStatus, setbookingStatus] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [airline, setairline] = useState("");
  const [pcc, setpcc] = useState("");
  const [vendorId, setvendorId] = useState(0);
  const [vendorName, setvendorName] = useState("");
  const [id, setId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [clientName, setClientName] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const bookingStatulist = [
    { "Text": "Booking_In_Process" },
    { "Text": "Booked" },
    { "Text": "Booking_Failed" },
    { "Text": "Ticketing_In_Process" },
    { "Text": "Ticketed" },
    { "Text": "Ticket_Failed" },
    { "Text": "Payment_In_Process" },
    { "Text": "Payment_Success" },
    { "Text": "Payment_Failed" },
    { "Text": "Booking_Cancelling_In_Process" },
    { "Text": "Booking_Cancelled" },
    { "Text": "Booking_Cancellation_Failed" },
    { "Text": "Voide_In_Process" },
    { "Text": "Voided" },
    { "Text": "Void_Failed" },
    { "Text": "Refund_Quote_In_Process" },
    { "Text": "Refund_Quoted" },
    { "Text": "Refund_Quoted_Accepted" },
    { "Text": "Refund_Quoted_Rejected" },
    { "Text": "Refund_In_Process" },
    { "Text": "Refunded" },
    { "Text": "Refund_Failed" },
    { "Text": "Exchange_Option_Requested" },
    { "Text": "Exchange_Option_Sent" },
    { "Text": "Exchange_Option_Accepted" },
    { "Text": "Exchange_Option_Rejected" },
    { "Text": "Exchange_In_Process" },
    { "Text": "Exchanged" },
    { "Text": "Exchange_Failed" },
    { "Text": "Exchange_Payment_In_Process" },
    { "Text": "Exchange_Payment_Success" },
    { "Text": "Exchange_Payment_Failed" }
  ]

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setqueueName(selectedItem.myBookingQueue.queueName);
      setbookingStatus(selectedItem.myBookingQueue.bookingStatus);
      setairline((selectedItem.myBookingQueue.airline === "" ? "All" : selectedItem.myBookingQueue.airline));
      setIsActive(selectedItem.myBookingQueue.isActive);
      setpcc((selectedItem.myBookingQueue.pcc === "" ? "All" : selectedItem.myBookingQueue.pcc));
      setId(selectedItem.myBookingQueue.id);
      setvendorId((selectedItem.vendor !== undefined ? selectedItem.vendor.id : "0"));
      setvendorName((selectedItem.vendor !== undefined ? selectedItem.vendor.vendorName : "All"));
      setClientId((selectedItem.myBookingQueue.clientId));
      setClientName((selectedItem.clientprofile !== undefined ? selectedItem.clientprofile.clientName : "All"));
    } else {
      setqueueName("");
      setbookingStatus("");
      setairline("");
      setIsActive(true);
      setpcc("");
      setvendorId("");
      setId(0);
      setClientId(0);
    }
  }, [toggleDrawer, action]);

  async function handleUpdate() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/MyBookingQueue/" +
          selectedItem.myBookingQueue.id,
          JSON.stringify({
            id: selectedItem.myBookingQueue.id,
            isActive: isActive,
            createdBy: selectedItem.myBookingQueue.createdBy,
            created: selectedItem.myBookingQueue.created,
            modifiedBy: "",
            modified: selectedItem.myBookingQueue.created,
            bookingStatus: bookingStatus === undefined ? "" : bookingStatus,
            queueName: queueName === undefined ? "" : queueName,
            pcc: pcc === undefined ? "" : pcc,
            airline: airline === undefined ? "" : airline,
            VendorId: vendorId === undefined ? "" : vendorId,
            ClientId: clientId
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/MyBookingQueue",
          JSON.stringify({
            id: 0,
            isActive: isActive,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            queueName: queueName === undefined ? "" : queueName,
            bookingStatus: bookingStatus === undefined ? "" : bookingStatus,
            pcc: pcc === undefined ? "" : pcc,
            airline: airline === undefined ? "" : airline,
            VendorId: vendorId === undefined ? "" : vendorId,
            ClientId: clientId
          }),
          {
            headers,
          }
        )
        .then((res) => {
          console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="search-heading-container search-title-flex-container">
        <div>My Booking Queue</div>
        {action === "view" && (
          <div className="search-heading-icons">
            <IconButton fontSize="small">
              <EditRoundedIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditRoundedIcon>
            </IconButton>
          </div>
        )}
      </div>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="search-view-edit-drawer-container">
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Queue Name"
                  size="small"
                  color="secondary"
                  value={queueName}
                  onChange={(event) => setqueueName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Queue Name</div>
                <div className="view-search-drawer-description ">
                  {queueName}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="booking status"
                  value={
                    bookingStatulist.find((v) => v.Text === bookingStatus) || null
                  }
                  onChange={(event, newValue) => {
                    setbookingStatus(newValue ? newValue.Text : "");
                  }}
                  options={bookingStatulist}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Text
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Booking Status"
                      value={bookingStatus}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      autoComplete="new-password"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Booking Status</div>
                <div className="view-search-drawer-description">
                  {bookingStatus}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="supplier select"
                  value={airlineDetails.find((v) => v.Code === airline) || null}
                  onChange={(event, newValue) => {
                    setairline(newValue ? newValue.Code : "");
                  }}
                  options={airlineDetails}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Name
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Airline"
                      value={airline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      autoComplete="new-password" // disable autocomplete and autofill
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Airline</div>
                <div className="view-search-drawer-description">{airline}</div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                {pccList && (
                  <Autocomplete
                    id="supplier select"
                    value={pccList.find((v) => v.value === pcc) || null}
                    onChange={(event, newValue) => {
                      setpcc(newValue ? newValue.value : "");
                    }}
                    options={pccList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Pseudo Code"
                        value={pcc}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        autoComplete="new-password" // disable autocomplete and autofill
                        inputProps={{
                          ...params.inputProps
                        }}
                      />
                    )}
                  />
                )}
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">pcc</div>
                <div className="view-search-drawer-description">{pcc}</div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Client Name"
                  value={clientList.find((v) => v.id === clientId) || null}
                  onChange={(event, newValue) => {
                    setClientId(newValue ? newValue.id : "0");
                  }}
                  options={clientList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Client Name"
                      value={clientId}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      autoComplete="new-password"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Client Name</div>
                <div className="view-search-drawer-description">
                  {clientName}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                {vendorList && (
                  <Autocomplete
                    id="search select"
                    value={vendorList.find((v) => v.id === vendorId) || null}
                    onChange={(event, newValue) => {
                      setvendorId(newValue ? newValue.id : "0");
                    }}
                    options={vendorList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Vendor"
                        value={vendorId}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        autoComplete="new-password" // disable autocomplete and autofill
                        inputProps={{
                          ...params.inputProps
                        }}
                      />
                    )}
                  />
                )}
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Vendor Name</div>
                <div className="view-search-drawer-description">
                  {vendorName}
                </div>
              </div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <div style={{ position: 'absolute', left: '50%', top: '50%' }}><CircularProgress /></div>}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
