import React, { useContext, useEffect } from "react";
import axios from "axios";
import SearchContext from "../contexts/Searchbar/SearchContext";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@material-ui/core";
import RiseLoading from "../components/Loader/RiseLoader";
import { useHistory } from "react-router";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../services/AuthService";
export default function QuoteCompletion() {
    const [search] = useContext(SearchContext);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
    useEffect(() => {
        localStorage.removeItem("reValTimer");
        let temp = localStorage.getItem("data");
        if (temp) {
            getReQuote();
        }
    }, [])
    const goBack = () => {
        const userRole = getCurrentRole();
        if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin") {
            history.push("/flight");
        }
        else {
            history.push("/Unauthorized");
        }
    }
    const getReQuote = () => {
        let activeSearch = { ...search };
        let reSearchData = JSON.parse(localStorage.getItem("data") ? localStorage.getItem("data") : "{}");
        console.log("reSearchData", reSearchData);
        setLoading(true);
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios.post(process.env.REACT_APP_CORE_URL + "Flights/Search/v1/AutoQuoteB2B",
                JSON.stringify(reSearchData), {
                headers,
            }).then((resp) => {
                let response = resp.data;
                console.log("autoQuote response", response);
                activeSearch.adult = response.flights[0].adtNum;
                activeSearch.children = response.flights[0].chdNum;
                activeSearch.infant = response.flights[0].infNum;
                localStorage.setItem("isReQuote", "true");
                history.push({
                    pathname: "/flight/review-booking",
                    state: {
                        activeSearch: activeSearch,
                        searchResults: response.flights,
                        traceId: response.traceId,
                        purchaseId: response.flights[0].fareGroups[0].purchaseId,
                        additionalClientmarkup: response.flights[0].fareGroups[0].clientMarkup,
                        clientId: localStorage.getItem("ClientId"),
                        hold: response.flights[0].isHold,
                    },
                })
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setErrorDialogOpen(true);
                console.log("autoQuote error", error);
            })
        } catch (error) {
            setLoading(false);
            setErrorDialogOpen(true);
            console.log(error);
        }
    }
    return (
        <div>
            {loading && (
                <RiseLoading isReviewBooking={true} />
            )}
            {errorDialogOpen &&
                <Dialog
                    open={errorDialogOpen}
                >
                    <DialogContent className="textCenter">
                        <Typography variant="body2">Error Occured while Retrieving the Itinerary </Typography>
                        <Typography variant="body2">{`Would you like to go back  ?`}</Typography>
                    </DialogContent>
                    <DialogActions className="sessionActionButtons">
                        <Button
                            onClick={goBack}
                            variant="contained"
                            style={{
                                color: "#fff",
                                backgroundColor: "red",
                                borderRadius: "10px",
                            }}
                        >
                            Go Back
                        </Button>
                    </DialogActions>
                </Dialog>

            }
        </div>
    )
}
