import airlineDetails from "../data/AirlineDetails";

export default function findAirlinebyCode(code) {
  if (code !== undefined) {
    const element = airlineDetails.find((element) => element.Code === code);
    return element !== undefined ? element.Name : "Airline";
  }
  else {
    return "";
  }
}
