import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

export default function SearchLoadingSkeleton() {
    return  <> 
                <Grid container className="skeleton-loader-styles">
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={3}>
                    <div className="mr-40">
                        <Skeleton width="50%" height={24} animation="wave" />
                        <Skeleton
                        variant="rect"
                        width="100%"
                        height={48}
                        className="skeleton-margin-top"
                        animation="wave"
                        />
                    </div>
                    <div className="mr-40 mt-32">
                        <div className="depart-arrival-container">
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                    <div className="ml-40">
                        <Skeleton width="100%" height={106} animation="wave" />
                        <Skeleton
                        width="75%"
                        height={24}
                        className="mt-32"
                        animation="wave"
                        />
                        <Skeleton width="50%" height={24} animation="wave" />
                    </div>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={3}>
                    <div className="mr-40">
                        <Skeleton width="50%" height={24} animation="wave" />
                        <Skeleton
                        variant="rect"
                        width="100%"
                        height={48}
                        className="skeleton-margin-top"
                        animation="wave"
                        />
                    </div>
                    <div className="mr-40 mt-32">
                        <div className="depart-arrival-container">
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                    <div className="ml-40">
                        <Skeleton width="100%" height={106} animation="wave" />
                        <Skeleton
                        width="75%"
                        height={24}
                        className="mt-32"
                        animation="wave"
                        />
                        <Skeleton width="50%" height={24} animation="wave" />
                    </div>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={3}>
                    <div className="mr-40">
                        <Skeleton width="50%" height={24} animation="wave" />
                        <Skeleton
                        variant="rect"
                        width="100%"
                        height={48}
                        className="skeleton-margin-top"
                        animation="wave"
                        />
                    </div>
                    <div className="mr-40 mt-32">
                        <div className="depart-arrival-container">
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                    <div className="ml-40">
                        <Skeleton width="100%" height={106} animation="wave" />
                        <Skeleton
                        width="75%"
                        height={24}
                        className="mt-32"
                        animation="wave"
                        />
                        <Skeleton width="50%" height={24} animation="wave" />
                    </div>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={3}>
                    <div className="mr-40">
                        <Skeleton width="50%" height={24} animation="wave" />
                        <Skeleton
                        variant="rect"
                        width="100%"
                        height={48}
                        className="skeleton-margin-top"
                        animation="wave"
                        />
                    </div>
                    <div className="mr-40 mt-32">
                        <div className="depart-arrival-container">
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        <div className="depart-arrival-icon skeleton-type-second">
                            <Skeleton width="100%" height={100} animation="wave" />
                        </div>
                        </div>
                    </div>
                    </Grid>
                    <Grid item xs={9}>
                    <div className="ml-40">
                        <Skeleton width="100%" height={106} animation="wave" />
                        <Skeleton
                        width="75%"
                        height={24}
                        className="mt-32"
                        animation="wave"
                        />
                        <Skeleton width="50%" height={24} animation="wave" />
                    </div>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                </Grid>
            </>
}