import AirportDetails from "../data/AirportDetails";
export default function findAirportbyCode(code) {
  if (code !== undefined) {
    const element = AirportDetails.find((element) => element.city_code === code);
    return element && element?.city;
  }
  else {
    return "";
  }
}
