import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@material-ui/core";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import findStationByCode from "../../../functions/findStationByCode";

export default function RailFareDetailsClientTableComponent({
  bookingDetails,
  railImportFareArray,
  currency,
  ROE,
}) {
  return (
    <>
      {" "}
      <div className="itenary-container-import booking-padding-top-40">
        <div>Client Fare Details ({bookingDetails?.railPNRDetails[0].currency})</div>
      </div>
      <div className="itenary-passenger-details-import">
        <Table className="itenary-passenger-table ImportBookingFareDetails">
          <TableHead>
            <TableRow className="itenary-passenger-table-header">
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
              >
                Pax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Base Fare
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Tax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Extra
              </TableCell>
              {/* <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Service
              </TableCell> */}

              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IssuanceFee
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                O.TAX
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                COMM
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight itenary-passenger-table-header-item-final"
              >
                <small>Vendor</small>
                <br />
                Incentive
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client </small>
                <br />
                COMM
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client </small>
                <br />
                Incentive
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="ImportBookingFareDetails ">
            {bookingDetails && bookingDetails?.railPNRDetails && bookingDetails?.railPNRDetails.length === 1 &&
              <>
                <TableRow>
                  <TableCell
                    colSpan={13}
                    style={{
                      backgroundColor: "#dedede",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    <div className="flight-itenary-table-title-container">
                      <div>
                        {findStationByCode(
                          bookingDetails?.railPNRDetails[0].legGroups[0].origin
                        )}
                      </div>
                      <div className="flight-itenary-icon">
                        <ArrowRightAltIcon></ArrowRightAltIcon>
                      </div>
                      <div>
                        {findStationByCode(
                          bookingDetails?.railPNRDetails[0].legGroups[0].destination
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                {bookingDetails?.railPNRDetails[0].railFares.length > 0 ? (
                  bookingDetails?.railPNRDetails[0].railFares
                    .sort(function (a, b) {
                      if (a.paxType < b.paxType) {
                        return -1;
                      }
                      if (a.paxType > b.paxType) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((fare) => (
                      <>
                        <TableRow className="itenary-passenger-table-item">
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                          >
                            {fare.paxType === "ADT"
                              ? "Adult"
                              : fare.paxType === "CHD"
                                ? "Child"
                                : fare.paxType === "INF"
                                  ? "Infant"
                                  : fare.paxType === "SNR"
                                    ? "Senior"
                                    : fare.paxType === "YNG"
                                      ? "Youth"
                                      : "Adult"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            {fare.paxType === "ADT"
                              ? ((Number(railImportFareArray[0].adultBase) + railImportFareArray[0].adultMarkup) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((Number(railImportFareArray[0].childBase) + railImportFareArray[0].childMarkup) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((Number(railImportFareArray[0].infantBase) + railImportFareArray[0].infantMarkup) * ROE).toFixed(
                                    2
                                  )
                                  : fare.paxType === "SNR"
                                    ? ((Number(railImportFareArray[0].seniorBase) + railImportFareArray[0].seniorMarkup) * ROE).toFixed(
                                      2
                                    )
                                    : fare.paxType === "YNG"
                                      ? ((Number(railImportFareArray[0].youthBase) + railImportFareArray[0].youthMarkup) * ROE).toFixed(
                                        2
                                      )
                                      : 0}
                            <br></br>
                            <span style={{ fontSize: "10px" }}>
                              (BaseFare + Markup)
                            </span>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            {fare.paxType === "ADT"
                              ? (Number(railImportFareArray[0].adultTax) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? (Number(railImportFareArray[0].childTax) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (Number(railImportFareArray[0].infantTax) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? (Number(railImportFareArray[0].seniorTax) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? (Number(railImportFareArray[0].youthTax) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                          >
                            {fare.paxType === "ADT"
                              ? (Number(railImportFareArray[0].adultExtra) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? (Number(railImportFareArray[0].childExtra) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (Number(railImportFareArray[0].infantExtra) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? (Number(railImportFareArray[0].seniorExtra) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? (Number(railImportFareArray[0].youthExtra) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                          >
                            {fare.paxType === "ADT"
                              ? (Number(railImportFareArray[0].adultService) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? (Number(railImportFareArray[0].childService) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (Number(railImportFareArray[0].infantService) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? (Number(railImportFareArray[0].seniorService) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? (Number(railImportFareArray[0].youthService) * ROE).toFixed(2)
                                      : 0}
                          </TableCell> */}


                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                          >
                            {fare.paxType === "ADT"
                              ? (Number(railImportFareArray[0].adultVendorIssuanceFee) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? (Number(railImportFareArray[0].childVendorIssuanceFee) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? (Number(railImportFareArray[0].infantVendorIssuanceFee) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? (Number(railImportFareArray[0].seniorVendorIssuanceFee) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? (Number(railImportFareArray[0].youthVendorIssuanceFee) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultVendorTax) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childVendorTax) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantVendorTax) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorVendorTax) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthVendorTax) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultTotal) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childTotal) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantTotal) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorTotal) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthTotal) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>

                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultVendorIata) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childVendorIata) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantVendorIata) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorVendorIata) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthVendorIata) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultVendorPlb) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childVendorPlb) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantVendorPlb) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorVendorPlb) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthVendorPlb) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultIata) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childIata) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantIata) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorIata) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthIata) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                          >
                            {fare.paxType === "ADT"
                              ? ((railImportFareArray[0].adultPlb) * ROE).toFixed(2)
                              : fare.paxType === "CHD"
                                ? ((railImportFareArray[0].childPlb) * ROE).toFixed(2)
                                : fare.paxType === "INF"
                                  ? ((railImportFareArray[0].infantPlb) * ROE).toFixed(2)
                                  : fare.paxType === "SNR"
                                    ? ((railImportFareArray[0].seniorPlb) * ROE).toFixed(2)
                                    : fare.paxType === "YNG"
                                      ? ((railImportFareArray[0].youthPlb) * ROE).toFixed(2)
                                      : 0}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                ) : (
                  <TableRow></TableRow>
                )}
              </>
            }
            {bookingDetails && bookingDetails?.railPNRDetails && bookingDetails?.railPNRDetails.length > 1 &&
              bookingDetails?.railPNRDetails.map((rail, railIndex) => (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={13}
                      style={{
                        backgroundColor: "#dedede",
                        padding: "0px",
                      }}
                    >
                      {" "}
                      <div className="flight-itenary-table-title-container">
                        <div>
                          {findStationByCode(
                            rail.legGroups[0].origin
                          )}
                        </div>
                        <div className="flight-itenary-icon">
                          <ArrowRightAltIcon></ArrowRightAltIcon>
                        </div>
                        <div>
                          {findStationByCode(
                            rail.legGroups[0].destination
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  {rail.railFares.length > 0 ? (
                    rail.railFares
                      .sort(function (a, b) {
                        if (a.paxType < b.paxType) {
                          return -1;
                        }
                        if (a.paxType > b.paxType) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((fare) => (
                        <>
                          <TableRow className="itenary-passenger-table-item">
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                            >
                              {fare.paxType === "ADT"
                                ? "Adult"
                                : fare.paxType === "CHD"
                                  ? "Child"
                                  : fare.paxType === "INF"
                                    ? "Infant"
                                    : fare.paxType === "SNR"
                                      ? "Senior"
                                      : fare.paxType === "YNG"
                                        ? "Youth"
                                        : "Adult"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              {fare.paxType === "ADT"
                                ? ((Number(railImportFareArray[railIndex].adultBase) + railImportFareArray[railIndex].adultMarkup) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((Number(railImportFareArray[railIndex].childBase) + railImportFareArray[railIndex].childMarkup) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((Number(railImportFareArray[railIndex].infantBase) + railImportFareArray[railIndex].infantMarkup) * ROE).toFixed(
                                      2
                                    )
                                    : fare.paxType === "SNR"
                                      ? ((Number(railImportFareArray[railIndex].seniorBase) + railImportFareArray[railIndex].seniorMarkup) * ROE).toFixed(
                                        2
                                      )
                                      : fare.paxType === "YNG"
                                        ? ((Number(railImportFareArray[railIndex].youthBase) + railImportFareArray[railIndex].youthMarkup) * ROE).toFixed(
                                          2
                                        )
                                        : 0}
                              <br></br>
                              <span style={{ fontSize: "10px" }}>
                                (BaseFare + Markup)
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              {fare.paxType === "ADT"
                                ? (Number(railImportFareArray[railIndex].adultTax) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? (Number(railImportFareArray[railIndex].childTax) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? (Number(railImportFareArray[railIndex].infantTax) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? (Number(railImportFareArray[railIndex].seniorTax) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? (Number(railImportFareArray[railIndex].youthTax) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                            >
                              {fare.paxType === "ADT"
                                ? (Number(railImportFareArray[railIndex].adultExtra) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? (Number(railImportFareArray[railIndex].childExtra) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? (Number(railImportFareArray[railIndex].infantExtra) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? (Number(railImportFareArray[railIndex].seniorExtra) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? (Number(railImportFareArray[railIndex].youthExtra) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                            >
                              {fare.paxType === "ADT"
                                ? (Number(railImportFareArray[railIndex].adultService) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? (Number(railImportFareArray[railIndex].childService) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? (Number(railImportFareArray[railIndex].infantService) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? (Number(railImportFareArray[railIndex].seniorService) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? (Number(railImportFareArray[railIndex].youthService) * ROE).toFixed(2)
                                        : 0}
                            </TableCell> */}


                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                            >
                              {fare.paxType === "ADT"
                                ? (Number(railImportFareArray[railIndex].adultVendorIssuanceFee) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? (Number(railImportFareArray[railIndex].childVendorIssuanceFee) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? (Number(railImportFareArray[railIndex].infantVendorIssuanceFee) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? (Number(railImportFareArray[railIndex].seniorVendorIssuanceFee) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? (Number(railImportFareArray[railIndex].youthVendorIssuanceFee) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultVendorTax) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childVendorTax) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantVendorTax) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorVendorTax) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthVendorTax) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultTotal) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childTotal) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantTotal) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorTotal) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthTotal) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>

                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultVendorIata) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childVendorIata) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantVendorIata) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorVendorIata) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthVendorIata) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultVendorPlb) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childVendorPlb) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantVendorPlb) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorVendorPlb) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthVendorPlb) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultIata) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childIata) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantIata) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorIata) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthIata) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {fare.paxType === "ADT"
                                ? ((railImportFareArray[railIndex].adultPlb) * ROE).toFixed(2)
                                : fare.paxType === "CHD"
                                  ? ((railImportFareArray[railIndex].childPlb) * ROE).toFixed(2)
                                  : fare.paxType === "INF"
                                    ? ((railImportFareArray[railIndex].infantPlb) * ROE).toFixed(2)
                                    : fare.paxType === "SNR"
                                      ? ((railImportFareArray[railIndex].seniorPlb) * ROE).toFixed(2)
                                      : fare.paxType === "YNG"
                                        ? ((railImportFareArray[railIndex].youthPlb) * ROE).toFixed(2)
                                        : 0}
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  ) : (
                    <TableRow></TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
