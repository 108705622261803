import React, { useState } from "react";
import BookingContext from "../../contexts/Booking/BookingContext";
import PropTypes from "prop-types";

export default function Booking({ children }) {
  const [booking, setBooking] = useState([]);
  return (
    <BookingContext.Provider value={[booking, setBooking]}>
      {children}
    </BookingContext.Provider>
  );
}
Booking.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
