import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
var types = [
    {
        maxLength: 15,
        code: "TP",
        expiry: "",
        name: "",
        number: "",
        issuer: "UATP"
    },
    {
        maxLength: 16,
        code: "VI",
        expiry: "",
        name: "",
        number: "",
        issuer: "Visa"
    },
    {
        maxLength: 16,
        code: "CA",
        expiry: "",
        name: "",
        number: "",
        issuer: "MasterCard"
    },
    {
        maxLength: 16,
        code: "DS",
        expiry: "",
        name: "",
        number: "",
        issuer: "Discover"
    }, {
        maxLength: 15,
        code: "AX",
        expiry: "",
        name: "",
        number: "",
        issuer: "American Express"
    }, {
        maxLength: 16,
        code: "DC",
        expiry: "",
        name: "",
        number: "",
        issuer: "Diners Club"
    }, {
        maxLength: 16,
        code: "JC",
        expiry: "",
        name: "",
        number: "",
        issuer: "JCB"
    }, {
        maxLength: 16,
        code: "TO",
        expiry: "",
        name: "",
        number: "",
        issuer: "Maestro"
    }
];



export default function AddCreditCardModal({ open, closeAddModal, handleSave, mode, selected }) {
    const cardForm = useRef();
    const [maxLength, setMaxLength] = useState(16);
    const [errorText, seterrorText] = useState();
    const [displayErrorText, setdisplayErrorText] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [expiry, setExpiry] = useState('');
    const [code, setCode] = useState('');
    const [issuer, setIssuer] = useState('');
    const [remarks, setRemarks] = useState('');
    const [checked, setChecked] = React.useState(true);
    const [state, setState] = React.useState({
        gilad: false,
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        setChecked(event.target.checked)
    };
    const formatExpirationDate = (value) => {
        const now = new Date();
        let until = new Date(now.getFullYear() + 15, now.getMonth());
        until = until.getFullYear()
        const clearValue = value.replace(/\D+/g, '')
        if (clearValue.length >= 3) {
            if (clearValue.slice(0, 2) > 12) {
                setdisplayErrorText(true);
                seterrorText('Kindly Enter Valid Expiration Date')
            } else if (clearValue.slice(2) <= new Date().getFullYear()) {
                setdisplayErrorText(true);
                seterrorText('Kindly Enter Valid Expiration Date')
            } else if (clearValue.slice(2) > until) {
                setdisplayErrorText(true);
                seterrorText('Kindly Enter Valid Expiration Date')
            } else {
                setdisplayErrorText(false);
                seterrorText('')
            }
            return (`${clearValue.slice(0, 2)}/${clearValue.slice(2)}`)
        }
    }
    useEffect(() => {
        if (mode !== 'Add') {
            setMaxLength(selected.cardinfo.maxLength)
            setCardNumber(selected.cardinfo.cardNumber)
            validateCardNumber(selected.cardinfo.cardNumber)
            setCardHolderName(selected.cardinfo.cardHolderName)
            setExpiry(selected.cardinfo.expDate)
            setCode(selected.cardinfo.code)
            setIssuer(selected.cardinfo.cardType)
            setChecked(selected.cardinfo.isActive)
            setRemarks(selected.cardinfo.remarks)
            setState({ ...state, ['gilad']: selected.cardinfo.isActive, });
        }
    }, [])
    useEffect(() => {

    }, [maxLength, code, expiry, cardHolderName, cardNumber, issuer, checked])

    function validateCardNumber(event) {
        let cardnumber = event;
        cardnumber = cardnumber.replace(/[ -]/g, '');
        var match = /^(?:([1]+[0-9]{14})|(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/.exec(cardnumber);
        setCardNumber(cardnumber);
        if (match) {
            for (var i = 1; i < match.length; i++) {
                if (match[i]) {
                    setMaxLength(types[i - 1].maxLength)
                    setCode(types[i - 1].code);
                    setIssuer(types[i - 1].issuer);
                    document.getElementById('notice').innerHTML = "Selected Card Type " + types[i - 1].issuer;
                    break;
                }
            }
        } else {
            document.getElementById('notice').innerHTML = '(invalid card number)';
        }
    }


    const closeModal = () => {
        closeAddModal();
        // setModalOpen(prevState => !prevState);
    };
    const handleSubmit = async (e) => {
        let temp = {
            code: code,
            ExpDate: expiry,
            CardHolderName: cardHolderName,
            cardNumber: cardNumber,
            CardType: issuer,
            isActive: checked,
            Remarks: remarks,
            Created: '',
            CreatedBy: ''
        }
        if (mode !== 'Add') {
            temp.id = selected.cardinfo.id ? selected.cardinfo.id : ''
            temp.Created = selected.cardinfo.created ? selected.cardinfo.created : ''
            temp.CreatedBy = selected.cardinfo.createdBy ? selected.cardinfo.createdBy : ''
        }
        handleSave(temp);
        closeAddModal();
    };

    return (
        <>
            <React.Fragment>
                <Modal
                    size="lg"
                    show={open}
                    // onHide={() => }
                    backdrop="static"
                    keyboard={false}
                    className="ticket-config-dialog"
                    centered
                >
                    <Box sx={{ ...style, width: 400 }}>


                        <div>
                            <Modal.Header>
                                <Modal.Title>

                                    {
                                        mode === 'Add' ?
                                            <span className="action-text">Add Credit Card</span>
                                            :
                                            <span className="action-text">Edit Credit Card </span>

                                    }


                                </Modal.Title>
                                <button type="button" className="btn-close pull-right modal_close" onClick={() => closeModal()}></button>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <div className="creditCard">
                                    <Cards
                                        cvc={cvc}
                                        expiry={expiry}
                                        focused={focus}
                                        name={name}
                                        number={number}
                                        callback={({ maxLength: length, issuer }, valid) => {
                                            console.log("issuer",issuer)
                                            // if (valid) {
                                                dispatch({ type: "ISSUER", payload: issuer });
                                            // }

                                            if (maxLength !== length) {
                                                issuer == 'amex' ? setMaxLength(length + 3) : setMaxLength(length);
                                            }
                                        }}
                                    />
                                </div> */}
                                {/* <ValidatorForm> */}
                                <ValidatorForm ref={cardForm} onSubmit={handleSubmit}>
                                    <div>
                                        {/* <Label>name:</Label> */}
                                        <Container>
                                            <Row className="cardModal-padd-1pt ">
                                                <Col sm={12} className="cardModal-padd-1pt ">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="name"
                                                        value={cardHolderName}
                                                        // pattern='[a-z A-Z-]+'
                                                        onChange={(event) => { setCardHolderName(event.target.value) }}
                                                        // onFocus={handleInputFocus}
                                                        required
                                                    />
                                                </Col>
                                                <Col sm={12} className="cardModal-padd-1pt ">
                                                    {/* {touchedInputs.cardNumber && erroredInputs.cardNumber && (
                                                        <span
                                                            list={["The card number is invalid"]}
                                                            size="tiny"
                                                            negative
                                                        />
                                                    )} */}
                                                    {/* <Label>Card Number:</Label> */}
                                                    <input
                                                        // {...getCardNumberProps({
                                                        //     onChange: handleInputChange,
                                                        //     value: number
                                                        // })}
                                                        name="number"
                                                        type='tel'
                                                        value={cardNumber}
                                                        style={{ width: '100% !important' }}
                                                        placeholder="Card Number"
                                                        onChange={(event) => { validateCardNumber(event.target.value) }}
                                                        // onFocus={handleInputFocus}
                                                        maxLength={maxLength}
                                                        pattern='[\d| ]{16,22}'
                                                        required
                                                    />
                                                    <p id="notice">(no card number entered)</p>
                                                </Col>
                                                <Col sm={12} className="cardModal-padd-1pt ">
                                                    {/* {touchedInputs.expiryDate && erroredInputs.expiryDate && (
                                                        <span
                                                            list={["The expiration date is invalid"]}
                                                            size="tiny"
                                                            negative
                                                        />
                                                    )} */}
                                                    {/* <Label>Expiry Date:</Label> */}
                                                    <input
                                                        // {...getExpiryDateProps({
                                                        //     onChange: handleExpiryChange,
                                                        //     value: expiry
                                                        // })}
                                                        style={{ width: '100% !important' }}
                                                        name="expiry"
                                                        type='tel'
                                                        // pattern='\d\d/\d\d\d\d'
                                                        placeholder="Expiry :MM/YYYY"
                                                        value={expiry}
                                                        onChange={(e) => { setExpiry(formatExpirationDate(e.target.value)) }}
                                                        // onFocus={handleInputFocus}
                                                        required
                                                        maxLength={7}
                                                    />
                                                    {
                                                        displayErrorText ? <span style={{ fontSize: '12px', color: 'red', paddingLeft: '10px' }}>{errorText}</span> : ""
                                                    }


                                                </Col>
                                                {/* <Col sm={6} className="cardModal-padd-1pt "> */}
                                                {/* {touchedInputs.cvc && erroredInputs.cvc && (
                                                            <span list={["CVC is invalid"]} size="tiny" negative />
                                                        )} */}
                                                {/* <Label>CVC:</Label> */}
                                                {/* <input
                                                            {...getCVCProps({ onChange: handleInputChange, value: cvc })}
                                                            name="cvc"
                                                            type='tel'
                                                            placeholder="CVC"
                                                            onFocus={handleInputFocus}
                                                            maxLength={4}
                                                            pattern='\d{4}'
                                                            required
                                                        /> */}
                                                {/* </Col> */}
                                                <Col sm={12} className="cardModal-padd-1pt ">
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                label={checked ? 'Disable Card' : 'Enable Card'}
                                                                control={
                                                                    <Switch checked={state.gilad} onChange={handleChange} name="gilad" />
                                                                }

                                                            />

                                                        </FormGroup>
                                                    </FormControl>

                                                </Col>
                                                <Col sm={12} className="cardModal-padd-1pt ">
                                                    <TextValidator
                                                        required={true}
                                                        label="Remarks"
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        onChange={(event) => { setRemarks(event.target.value) }}
                                                        value={remarks}
                                                        // validators={checked === false ? ["required"] : true}
                                                        errorMessages={["required"]}
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        name="remarks"
                                                        placeholder="Remarks"
                                                        value={remarks}
                                                        // pattern='[a-z A-Z-]+'
                                                        
                                                        // onFocus={handleInputFocus}
                                                        required
                                                    /> */}
                                                </Col>
                                            </Row>
                                        </Container>

                                    </div>

                                    {
                                        mode === 'Add' ?
                                            <Button
                                                className="modal_submit_btn"
                                                variant="outlined"
                                                color="primary"
                                                size="large"
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%"
                                                }}
                                                type="submit"
                                            // onClick={onSubmitClick}
                                            >
                                                Add
                                            </Button>
                                            :
                                            <Button
                                                className="modal_submit_btn"
                                                variant="outlined"
                                                color="primary"
                                                size="large"
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%"
                                                }}
                                                type="submit"
                                            // onClick={onSubmitClick}
                                            >
                                                Update
                                            </Button>
                                    }



                                </ValidatorForm>
                            </Modal.Body>
                        </div>
                    </Box>
                </Modal>
            </React.Fragment>
        </>
    );
}