import React from "react";
import "./Results.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import ResultsTableColumn from "../../../data/ResultsTableColumn";
import useTableHeaderStyles from "../../../hooks/useTableHeaderStyles";
import Row from "./Row/Row";
import checkStopsFilter from "../../../functions/checkStopsFilter";
import checkFareTypeFilter from "../../../functions/checkFareTypeFilter";
import checkAirlinesFilter from "../../../functions/checkAirlinesFilter";
import checkPricesFilter from "../../../functions/checkPricesFilter";
import checkConnectingInFilter from "../../../functions/checkConnectingInFilter";
import checkArrivalDepartFilter from "../../../functions/checkArrivalDepartFilter";
import SearchIcon from "@material-ui/icons/Search";
import findAirlinebyCode from "../../../functions/findAirlineByCode";
import filterRecommended from "../../../functions/filterRecommended";
function Results({
  traceId,  
  clientId,
  results,
  search,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  selectedTicket,
  setSelectedTicket,
  setDrawer,
}) {
  const classes = useTableHeaderStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  let currentResults = [];

  return (
    <div className="results-container">
      <TableContainer>
        <Table className="results-table">
          {/* <TableHead>
            <TableRow>
              {ResultsTableColumn.map((data) => (
                <TableCell
                  className="remove-vertical-parallel-padding"
                  align="center"
                  sortDirection={orderBy === data.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === data.id}
                    direction={orderBy === data.id ? order : "asc"}
                    onClick={createSortHandler(data.id)}
                  >
                    {data.title}
                    {orderBy === data.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead> */}
          <TableBody>
            {
              (currentResults = stableSort(
                results,
                getComparator(order, orderBy)
              )
                .filter((item) => searchInFilters(item, search))
                .map((result) => (
                  <Row
                    className="table-item-row"
                    traceId={traceId}
                    clientId={clientId}
                    result={result}
                    search={search}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    setDrawer={setDrawer}
                  />
                )))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {currentResults.length === 0 && (
        <div className="no-results-container">
          <div className="no-results-icon">
            <SearchIcon fontSize="large"></SearchIcon>
          </div>
          <div className="no-results-text">
            No Results Found for your Search
          </div>
        </div>
      )}
    </div>
  );
}

const searchInFilters = (item, search) => {
  return (
    checkStopsFilter(item, search) &&
    checkFareTypeFilter(item, search) &&
    checkAirlinesFilter(item, search) &&
    checkPricesFilter(item, search) &&
    checkConnectingInFilter(item, search) &&
    checkArrivalDepartFilter(item, search)
  );
};

const getTotalTripDuration = (segGroup) => {
  let totalDuration = 0;
  segGroup.forEach((segGroup) => {
    segGroup.segs.forEach((seg) => {
      totalDuration = totalDuration + seg.duration;
    });
  });
  return totalDuration;
};

function descendingComparator(a, b, orderBy) {
  if (orderBy === "airline") {
    if (findAirlinebyCode(b[orderBy]) < findAirlinebyCode(a[orderBy])) {
      return -1;
    }
    if (findAirlinebyCode(b[orderBy]) > findAirlinebyCode(a[orderBy])) {
      return 1;
    }
  } else if (orderBy === "depart") {
    if (
      b.segGroups[0].segs[0].departureOn < a.segGroups[0].segs[0].departureOn
    ) {
      return -1;
    }
    if (
      b.segGroups[0].segs[0].departureOn > a.segGroups[0].segs[0].departureOn
    ) {
      return 1;
    }
  } else if (orderBy === "totalDuration") {
    if (getTotalTripDuration(b.segGroups) < getTotalTripDuration(a.segGroups)) {
      return -1;
    }
    if (getTotalTripDuration(b.segGroups) > getTotalTripDuration(a.segGroups)) {
      return 1;
    }
  } else if (orderBy === "arrival") {
    if (
      b.segGroups[b.segGroups.length - 1].segs[
        b.segGroups[b.segGroups.length - 1].segs.length - 1
      ].arrivalOn <
      a.segGroups[a.segGroups.length - 1].segs[
        a.segGroups[a.segGroups.length - 1].segs.length - 1
      ].arrivalOn
    ) {
      return -1;
    }
    if (
      b.segGroups[b.segGroups.length - 1].segs[
        b.segGroups[b.segGroups.length - 1].segs.length - 1
      ].arrivalOn >
      a.segGroups[a.segGroups.length - 1].segs[
        a.segGroups[a.segGroups.length - 1].segs.length - 1
      ].arrivalOn
    ) {
      return 1;
    }
  } else if (orderBy === "price") {
    if (
      getTotalPrice(b.fareGroups[0].fares[0]) <
      getTotalPrice(a.fareGroups[0].fares[0])
    ) {
      return -1;
    }
    if (
      getTotalPrice(b.fareGroups[0].fares[0]) >
      getTotalPrice(a.fareGroups[0].fares[0])
    ) {
      return 1;
    }
  } else if (orderBy === "recommended") {
    if (JSON.stringify(filterRecommended(a, b)) === JSON.stringify(a)) {
      return 1;
    } else {
      return -1;
    }
  }
  return 0;
}

function getTotalPrice(fares) {
  let totalAmount = 0;
  totalAmount = fares.base + getTotalTax(fares.taxes);
  return totalAmount;
}

const getTotalTax = (taxes) => {
  let total = 0;
  taxes.forEach((item) => {
    total = total + item.amt;
  });
  return total;
};

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default Results;
