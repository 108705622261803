import React, { useEffect, useState } from "react";
import "../ConfigurationDrawer/ConfigurationDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function CategoryDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
}) {
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [checkbox, setCheckbox] = useState(true);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setCategoryTitle("Search - 2021");
      setCategoryDescription("2021 US Best Category");
    } else {
      setCategoryTitle("");
      setCategoryDescription("");
    }
  }, [toggleDrawer, action]);

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="configuration-heading-container configuration-title-flex-container">
        <div>Category</div>
        {action === "view" && (
          <div className="configuration-heading-icons">
            <IconButton fontSize="small">
              <EditIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditIcon>
            </IconButton>
          </div>
        )}
      </div>
      <div className="configuration-view-edit-drawer-container">
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Category Title"
                  size="small"
                  color="secondary"
                  value={categoryTitle}
                  onChange={(event) => {
                    // console.log(event.target.value);
                    setCategoryTitle(event.target.value);
                  }}
                />
              </ValidatorForm>
            </div>
          )}
          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Category Title
              </div>
              <div className="view-configuration-drawer-description ">
                {categoryTitle}
              </div>
            </div>
          )}
        </div>
        <div className="configuration-view-edit-drawer-input-container">
          {action !== "view" && (
            <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Category Description"
                  size="small"
                  color="secondary"
                  value={categoryDescription}
                  onChange={(event) =>
                    setCategoryDescription(event.target.value)
                  }
                />
              </ValidatorForm>
            </div>
          )}

          {action === "view" && (
            <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
              <div className="view-configuration-drawer-title">
                Category Description
              </div>
              <div className="view-configuration-drawer-description ">
                {categoryDescription}
              </div>
            </div>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox}
                  name="disabledCheck"
                  color="secondary"
                  onChange={(event) => setCheckbox(event.target.checked)}
                />
              }
              label="Disabled"
            />
          </FormGroup>
        </div>
      </div>

      {action !== "view" && (
        <div
          className="add-configuration-button-container-update-new"
          onClick={() => handleSnackbarToggle()}
        >
          <div className="add-configuration-button-container-update-new-button">
            {action === "edit" ? "Update" : "Create"}
          </div>
        </div>
      )}
    </div>
  );
}
