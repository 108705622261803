const StationDetails = [
  {
    country: "Italy",
    stationName: "Rapallo railway station",
    stationCode: "GE0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Udine",
    stationCode: "UDN",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Treviso Centrale",
    stationCode: "TVC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Trieste Centrale",
    stationCode: "TSC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Trento",
    stationCode: "TCN",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "San Donà-Jesolo",
    stationCode: "SDP",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Rovereto",
    stationCode: "RVR",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Trieste Airport",
    stationCode: "RHA",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Pordenone",
    stationCode: "PNE",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Portogruaro-Caorle",
    stationCode: "PGR",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Monfalcone",
    stationCode: "MNF",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Latisana-Lignano-Bibione",
    stationCode: "LTL",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Conegliano",
    stationCode: "CON",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Bolzano",
    stationCode: "BLZ",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Bergamo",
    stationCode: "BGM",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Rovigo",
    stationCode: "R__",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Aversa",
    stationCode: "AVR",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Venezia S.Lucia",
    stationCode: "VSL",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Ferrara",
    stationCode: "F__",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Venezia Mestre",
    stationCode: "VEM",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Venice ",
    stationCode: "VE0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Padua",
    stationCode: "PD_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Verona Porta Nuova",
    stationCode: "VPN",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Peschiera",
    stationCode: "PSY",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Desenzano",
    stationCode: "DSG",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Brescia",
    stationCode: "BSC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Villa San Giovanni",
    stationCode: "VSG",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Vallo della Lucania",
    stationCode: "VLH",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Vibo-Pizzo",
    stationCode: "VIP",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Vicenza",
    stationCode: "VIC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Voghera",
    stationCode: "V9_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Trani",
    stationCode: "TR_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Torino Porta Nuov",
    stationCode: "TOP",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Pavia railway station",
    stationCode: "TO0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Tortona",
    stationCode: "TF_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Sapri",
    stationCode: "SRI",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Florence S.M.Novella",
    stationCode: "SMN",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Scalea",
    stationCode: "SDC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Salerno",
    stationCode: "SAL",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Rosarno",
    stationCode: "RUT",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Roma Tiburtina",
    stationCode: "RTB",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Milano Rho Fiera",
    stationCode: "RRO",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Roma Termini",
    stationCode: "RMT",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Roma Termini railway station",
    stationCode: "RM0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Milano Rogoredo",
    stationCode: "RG_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Reggio Calabria",
    stationCode: "RCE",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Paola",
    stationCode: "PAR",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Pavia",
    stationCode: "P3_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Torino Porta Sus",
    stationCode: "OUE",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Napoli Afragola",
    stationCode: "NAF",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Napoli Centrale",
    stationCode: "NAC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Naples",
    stationCode: "NA0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Maratea",
    stationCode: "MRT",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Molfetta",
    stationCode: "ML_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Melzo railway station",
    stationCode: "MI0",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Milano Centrale",
    stationCode: "MC_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Lamezia Terme C",
    stationCode: "LON",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Genova Brignole",
    stationCode: "GB_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Genova Piazza Principe",
    stationCode: "G__",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Foggia",
    stationCode: "FG_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Caserta",
    stationCode: "CEA",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Barletta",
    stationCode: "BLT",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Bisceglie",
    stationCode: "BIG",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Benevento",
    stationCode: "BEN",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Bologna Centrale",
    stationCode: "BC_",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Bari Centrale",
    stationCode: "BAC",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Agropoli",
    stationCode: "AGR",
    region: "Europe",
  },
  {
    country: "Italy",
    stationName: "Reggio Emilia AV",
    stationCode: "AAV",
    region: "Europe",
  },
];
export default StationDetails;
