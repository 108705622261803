import React, { Fragment, useState } from "react";
import "../Results.css";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router";
import CollapsePanel from "./CollapsePanel";
import findAirportbyCode from "../../../../functions/findAirportByCode";
import findAirlinebyCode from "../../../../functions/findAirlineByCode";
import getTotalFare from "../../../../functions/getTotalFare";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import moment from "moment";
import {AirlineLogo} from "../../../../functions/airlineLogo";

function Row(props) {
  const {
    result,
    search,
    traceId,
    clientId,
    selectedTicket,
    setSelectedTicket,
    setDrawer,
  } = props;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const images = require.context("../../../../airline_logo", true);

  const getViaStops = (stops) => {
    let viaString = [];
    for (let stop of stops) {
      viaString.push(stop.origin);
      viaString.push(stop.destination);
    }
    viaString.shift();
    viaString.pop();

    for (const [index, value] of viaString.entries()) {
      let airportName = findAirportbyCode(value);
      viaString[index] = airportName;
    }

    return viaString.filter(onlyUnique).join().replace(/,/g, ", ");
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getDepartureTime = (item) => {
    let departure = item?.segs[0]?.departureOn;
    let temp = new Date(
      departure.includes("+00:00") ? departure.replace("+00:00", "") : departure
    );
    return moment(temp).format("HH:mm");
  };

  const getArrivalTime = (item) => {
    const segLength = item.segs.length;
    let departure = item.segs[segLength - 1].arrivalOn;
    let temp = new Date(
      departure.includes("+00:00") ? departure.replace("+00:00", "") : departure
    );
    return moment(temp).format("HH:mm");
  };

  const getTotalTripDuration = (item) => {
    let totalDuration = 0;
    const segs = item.segs;
    segs.forEach((seg) => {
      totalDuration = totalDuration + seg.duration;
    });
    return `${Math.floor(totalDuration / 60)} hrs ${totalDuration % 60} min`;
  };

  const getFare = () => {
    let taxes = 0;
    result.fareGroups[0].fares[0].taxes.forEach((tax) => {
      taxes = taxes + tax.amt;
    });
    return result.fareGroups[0].fares[0].base + taxes;
  };

  

  return (
    <Fragment>
      {Object.keys(result).length > 0 && (
        <TableRow
          className="pt-pb-32"
          onClick={() => {
            setSelectedTicket(result);
            setDrawer(true);
          }}
        >
          <Table
            className={`non-collapse ${
              JSON.stringify(result) === JSON.stringify(selectedTicket)
                ? "selected-ticket"
                : ""
            }`}
          >
            {result.segGroups.map((item, index) => (
              <TableRow key={JSON.stringify(item)} className="remove-border">
                <TableCell
                  align="left"
                  component="th"
                  scope="row"
                  className={`remove-border  cell-background left-radius vertical-bottom width-30
                    ${
                      result.segGroups.length === 1
                        ? "pt-32 pb-32"
                        : index === 0
                        ? "pt-32"
                        : index === result.segGroups.length - 1
                        ? "pb-32"
                        : ""
                    }`}
                >
                  <div className="justify-space-between">
                    <div>
                      <div className="airline-container">
                        <div className="flex-row-item text-bold font-16">
                          <div>
                            {getDepartureTime(result.segGroups[index])}{" "}
                          </div>
                          <div>&nbsp;-&nbsp;</div>
                          <div> {getArrivalTime(result.segGroups[index])}</div>
                        </div>
                      </div>
                      <div className="origin-destination-texts opacity-6 font-size-12">
                        {item.departureOn.split("T")[0]}
                      </div>
                      <div className="origin-destination-texts">
                        {findAirportbyCode(item.origin)} ({item.origin}) -{" "}
                        {findAirportbyCode(item.destination)} (
                        {item.destination})
                      </div>
                      <div className="airline-container mt-6">
                        <div className="airline-icon">
                          {result.airline !== undefined ? (
                            <AirlineLogo val={result.airline} h={24} w={24} />
                          ) : (
                            <FlightTakeoffIcon
                              className="primary-fill"
                              fontSize="small"
                            />
                          )}
                        </div>
                        <div className="airline-title ">
                          {" "}
                          {result.airline !== undefined
                            ? findAirlinebyCode(result.airline) +
                              " " +
                              result.airline
                            : "Airlines"}
                        </div>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  className={`remove-border cell-background width-25 align-baseline ${
                    result.segGroups.length === 1
                      ? "pt-32 pb-32"
                      : index === 0
                      ? "pt-32"
                      : index === result.segGroups.length - 1
                      ? "pb-32"
                      : ""
                  }`}
                >
                  <div className="airline-duration-container">
                    <div className="airline-total-duration-text">
                      {getTotalTripDuration(result.segGroups[index])} (
                      {result.segGroups[index].segs.length > 1
                        ? `${result.segGroups[index].segs.length - 1} stops`
                        : "Non-stop"}
                      {result.segGroups[index].segs.length > 1
                        ? ` via ${getViaStops(result.segGroups[index].segs)}`
                        : ""}
                      )
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  align="right"
                  className={`remove-border right-radius cell-background align-baseline  ${
                    result.segGroups.length === 1
                      ? "pt-32 pb-32"
                      : index === 0
                      ? "pt-32"
                      : index === result.segGroups.length - 1
                      ? "pb-32"
                      : ""
                  }`}
                >
                  {index === 0 && (
                    <div className="airline-price-container">
                      <div className="airline-price">
                        {result.currency}{" "}
                        {getTotalFare(
                          result.fareGroups[0],
                          search.adult,
                          search.children,
                          search.infant
                        ).total.toFixed(2)}
                      </div>
                      {/* <div className="airline-hint-text">
                        Avg Price per person
                      </div> */}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {/* <TableRow>
        <TableCell
          align="left"
          className="remove-border remove-top-padding remove-bottom-padding"
        >
          <div
            className="view-flight-details-container pointer"
            onClick={() => setOpen(!open)}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              className="airline-detail-expand-button"
            >
              {open ? (
                <KeyboardArrowUpRoundedIcon
                  fontSize="small"
                  className="airline-detail-expand-icon-fill"
                />
              ) : (
                <KeyboardArrowDownRoundedIcon
                  fontSize="small"
                  className="airline-detail-expand-icon-fill"
                />
              )}
            </IconButton>
            <div className="view-flight-details-text">Flight Details</div>
          </div>
        </TableCell>
        <TableCell className="remove-border remove-top-padding" />
        <TableCell className="remove-border remove-top-padding" />
        <TableCell className="remove-border remove-top-padding" />
        <TableCell align="right" className="remove-border remove-top-padding">
          <div
            className="airline-select-button-container pointer"
            onClick={() => {
              history.push({
                pathname: "/view-booking",
                state: {
                  activeSearch: search,
                  traceId,
                  purchaseId: result.fareGroups[0].purchaseId,
                },
              });
            }}
          >
            <div className="airline-select-button border-gradient border-gradient-purple hover-button-fill">
              Select
            </div>
          </div>
        </TableCell>
      </TableRow> */}
          {/* <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          colSpan={6}
        >
          <CollapsePanel
            result={result}
            open={open}
            segGroups={result.segGroups}
            fareGroups={result.fareGroups[0]}
          ></CollapsePanel>
        </TableCell>
      </TableRow> */}
        </TableRow>
      )}
    </Fragment>
  );
}
export default Row;
