import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select,
} from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { isUserHasPermission } from "../../../../services/AuthService";
import RiseLoading from "../../../Loader/RiseLoader";
import statusOptions from "../../../../data/statusOptions";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function L1CacheDrawer({
    toggleDrawer,
    selectedItem,
    action,
    setAction,
    handleSnackbarToggle,
    loadCategories,
}) {
    const classes = useStyles();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [expiryTime, setExpiryTime] = useState("");
    const [id, setId] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [isActive, setIsActive] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState([]);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (action === "edit" || action === "view") {
            setName(selectedItem.l1GroupName);
            setDescription(selectedItem.description);
            setExpiryTime(selectedItem.expiryTimeInSeconds);
            setIsActive(selectedItem.isActive ? "Active" : "In-Active");
            setId(selectedItem.id);
        } else {
            setName("");
            setDescription("");
            setExpiryTime("");
            setIsActive("Active");
            setId(0);
            setErrMsg("");
        }
    }, [toggleDrawer, action]);

    async function handleUpdate() {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };
        console.log('String(isActive).toLowerCase()', String(isActive).toLowerCase())
        if (action === "edit" || action === "view") {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/L1GroupCache/" +
                    selectedItem.id,
                    JSON.stringify({
                        id: selectedItem.id,
                        isActive: String(isActive).toLowerCase() === "active" ? true : false,
                        createdBy:
                            selectedItem.createdBy === undefined
                                ? ""
                                : selectedItem.createdBy,
                        created:
                            selectedItem.created === undefined ? "" : selectedItem.created,
                        modifiedBy: "",
                        modified:
                            selectedItem.created === undefined ? "" : selectedItem.created,
                        l1GroupName: name === undefined ? "" : name,
                        expiryTimeInSeconds: expiryTime,
                        description: description === undefined ? "" : description,
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    handleSnackbarToggle();
                    setShowLoader(false);
                    loadCategories();
                })
                .catch((err) => {
                    console.log(err);
                    handleSnackbarToggle();
                    setShowLoader(false);
                });
        } else {
            setShowLoader(true);
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/L1GroupCache",
                    JSON.stringify({
                        id: 0,
                        isActive: String(isActive).toLowerCase() === "active" ? true : false,
                        created: moment().format(),
                        modified: moment().format(),
                        l1GroupName: name === undefined ? "" : name,
                        expiryTimeInSeconds: parseInt(expiryTime),
                        description: description === undefined ? "" : description,
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    console.log(res);
                    handleSnackbarToggle();
                    setShowLoader(false);
                    loadCategories();
                    setErrMsg("");
                })
                .catch((err) => {
                    console.log(err);
                    // handleSnackbarToggle();
                    setShowLoader(false);
                    setErrMsg('*' + err.response.data.detail);
                });
        }
    }

    useEffect(() => {
        getClients();
        ValidatorForm.addValidationRule('isPositive', (value) => {
            if (value === '') return true;
            return parseInt(value, 10) > 0;
        });

        ValidatorForm.addValidationRule('isBetween300and1800', (value) => {
            if (value === '') return true;
            const number = parseInt(value, 10);
            return number >= 300 && number <= 1800;
        });

        return () => {

            ValidatorForm.removeValidationRule('isPositive');
            ValidatorForm.removeValidationRule('isBetween300and1800');
        };
    }, []);

    async function getClients() {
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/ClientProfile/GetClientList?sorts=created"
            )
            .then((response) => {
                console.log('response', response)
                setClientList(response.data);
            });
    }

    useEffect(() => {
        console.log('clientId', clientId)
    }, [clientId])


    return (
        <div className="ticket-drawer-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={toggleDrawer(false)}
                ></CloseIcon>
            </div>
            <div className="search-heading-container search-title-flex-container">
                <div>{action === "edit" ? "Edit" : action === "new" ? "Add" : ""} L1 Cache Group</div>
                {action === "view" && (
                    <div className="search-heading-icons">
                        {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
                        <IconButton fontSize="small">
                            <EditRoundedIcon
                                fontSize="small"
                                className="icon-blue"
                                onClick={() => setAction("edit")}
                            ></EditRoundedIcon>
                        </IconButton>
                        {/* ) : (
                            ""
                        )} */}
                    </div>
                )}
            </div>

            <ValidatorForm
                onError={(errors) => console.log(errors)}
                onSubmit={handleUpdate}
            >
                <div className="search-view-edit-drawer-container">
                    <div className="search-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Group Name"
                                    size="small"
                                    color="secondary"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    validators={[
                                        "required",
                                        "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                                    ]}
                                    errorMessages={[
                                        "required",
                                        "This field cannot be blank or contain only spaces",
                                    ]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Description"
                                    size="small"
                                    color="secondary"
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                    validators={[
                                        "required",
                                        "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                                    ]}
                                    errorMessages={[
                                        "required",
                                        "This field cannot be blank or contain only spaces",
                                    ]}
                                    required
                                />
                            </div>
                        )}

                        {action === "view" && (
                            <div className="view-search-drawer margin-right-16 config-input-flex-1">
                                <div className="view-search-drawer-title">Group Name</div>
                                <div className="view-search-drawer-description ">{name}</div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-search-drawer config-input-flex-1">
                                <div className="view-search-drawer-title">Description</div>
                                <div className="view-search-drawer-description">
                                    {description}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="search-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <TextValidator
                                    variant="outlined"
                                    label="Expiry Time (300 - 1800 seconds)"
                                    size="small"
                                    color="secondary"
                                    value={expiryTime}
                                    onChange={(event) => setExpiryTime(event.target.value)}
                                    validators={['required', 'matchRegexp:^[0-9]+$', 'isPositive', 'isBetween300and1800']}
                                    errorMessages={['This field is required.', 'Only numbers are allowed.', 'Value must be positive.', 'Value must be between 300 and 1800.'
                                    ]}
                                    required
                                />
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input config-input-flex-1">

                                <Autocomplete
                                    id="Status"
                                    value={isActive}
                                    onChange={(event, newValue) => setIsActive(newValue)}
                                    options={statusOptions}
                                    getOptionLabel={option => option}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Status"
                                            variant="outlined"
                                            color="secondary"
                                            required
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />

                                    )}
                                />

                            </div>
                        )}
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input config-input-flex-1"></div>
                        )}
                        {action === "view" && (
                            <div className="view-search-drawer margin-right-16 config-input-flex-1">
                                <div className="view-search-drawer-title">Expiry Time (in sec)</div>
                                <div className="view-search-drawer-description">
                                    {expiryTime.toString()}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-search-drawer margin-right-16 config-input-flex-1">
                                <div className="view-search-drawer-title">Status</div>
                                <div className="view-search-drawer-description">
                                    {isActive.toString()}
                                </div>
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-search-drawer config-input-flex-1"></div>
                        )}
                    </div>
                    {/* <div className="search-view-edit-drawer-input-container">

                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">

                                <FormControl fullWidth className="me-1 holdBooking-select">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        value={(clientId && clientId) || []}

                                        onChange={(event, value) => setClientId(value)}
                                        options={clientList}
                                        getOptionLabel={(option) => option.clientName}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Client"
                                                color="secondary"
                                                size="small"
                                            />
                                        )}
                                    />
                                </FormControl>

                            </div>
                        )}
                        {action !== "view" && (
                            <div className="search-view-edit-drawer-input config-input-flex-1"></div>
                        )}
                        {action === "view" && (
                            <div className="view-search-drawer margin-right-16 config-input-flex-1">
                                <div className="view-search-drawer-title">Client(s)</div>
                                <div className="view-search-drawer-description">
                                    {expiryTime.toString()}
                                </div>
                            </div>
                        )}

                    </div> */}
                </div>
                {action !== "view" && (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{
                                textTransform: "none",
                                marginLeft: "25px",
                                marginRight: "25px",
                                marginBottom: "25px",
                                width: "500px",
                            }}
                            type="submit"
                        >
                            {action === "edit" ? "Update" : "Create"}
                        </Button>
                        {showLoader && (
                            <RiseLoading Search={showLoader} />
                        )}
                        <div className="ms-5 t text-danger">{errMsg}</div>
                    </div>

                )}
            </ValidatorForm>
        </div>
    );
}
