import getFare from "./getFare";
import getNumberOfStops from "./getNumberOfStops";
import getTotalTripDuration from "./getTotalTripDuration";

export default function filterRecommended(firstFlight, secondFlight) {
  let firstFlightSum = 0;
  let secondFlightSum = 0;

  let firstFlightPrice = getFare(firstFlight.fareGroups[0].fares[0]);
  let secondFlightPrice = getFare(secondFlight.fareGroups[0].fares[0]);

  let firstFlightDuration = getTotalTripDuration(firstFlight);
  let secondFlightDuration = getTotalTripDuration(secondFlight);

  let firstFlightStops = getNumberOfStops(firstFlight);
  let secondFlightStops = getNumberOfStops(secondFlight);

  if (firstFlightPrice === secondFlightPrice) {
    firstFlightSum++;
    secondFlightSum++;
  } else if (firstFlightPrice > secondFlightPrice) {
    firstFlightPrice++;
  } else {
    secondFlightPrice++;
  }

  if (firstFlightDuration === secondFlightDuration) {
    firstFlightSum++;
    secondFlightSum++;
  } else if (firstFlightDuration > secondFlightDuration) {
    firstFlightPrice++;
  } else {
    secondFlightPrice++;
  }

  if (firstFlightStops === secondFlightStops) {
    firstFlightSum++;
    secondFlightSum++;
  } else if (firstFlightStops > secondFlightStops) {
    firstFlightPrice++;
  } else {
    secondFlightPrice++;
  }

  return firstFlightSum > secondFlightSum ? firstFlight : secondFlight;
}
