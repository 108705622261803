export default function getTotalFare(fareGroup, adult, children, infant) {
  let adultTotalPrice = 0;
  let adultBaseFare = 0;
  let adultTaxFare = 0;
  let childrenTotalPrice = 0;
  let childrenBaseFare = 0;
  let childrenTaxFare = 0;
  let infantTotalPrice = 0;
  let infantBaseFare = 0;
  let infantTaxFare = 0;

  fareGroup.fares.forEach((fare) => {
    if (fare.paxType === "INF") {
      infantBaseFare = fare.base * infant;
      infantTotalPrice = infantTotalPrice + fare.base;
      fare.taxes.forEach((item) => {
        infantTotalPrice = infantTotalPrice + item.amt;
        infantTaxFare = infantTaxFare + item.amt;
      });
    } else if (fare.paxType === "CHD") {
      childrenBaseFare = fare.base * children;
      childrenTotalPrice = childrenTotalPrice + fare.base;
      fare.taxes.forEach((item) => {
        childrenTotalPrice = childrenTotalPrice + item.amt;
        childrenTaxFare = childrenTaxFare + item.amt;
      });
    }
    else {
      adultBaseFare = fare.base * adult;
      adultTotalPrice = adultTotalPrice + fare.base;
      fare.taxes.forEach((item) => {
        adultTotalPrice = adultTotalPrice + item.amt;
        adultTaxFare = adultTaxFare + item.amt;
      });
    }
  });

  return {
    adultTotalPrice,
    childrenTotalPrice,
    infantTotalPrice,
    total:
      adultTotalPrice * adult +
      childrenTotalPrice * children +
      infantTotalPrice * infant,
    totalBaseFare: adultBaseFare + childrenBaseFare + infantBaseFare,
    totalTaxFare:
      adultTaxFare * adult +
      childrenTaxFare * children +
      infantTaxFare * infant,
  };
}
