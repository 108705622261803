import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { TextField, InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import TablePagination from "@material-ui/core/TablePagination";
import { CircularProgress } from "@material-ui/core";
import { Payment } from "@mui/icons-material";
import paymentStatus from "../../data/paymentStatus";
import PaymentStatus from "../../data/paymentStatus";
import Autocomplete from "@mui/material/Autocomplete";
// import './RechargeTransactions.css'
import clientFilterOptions from "../../functions/clientFilterOptions";
import { checkIfUserStaff } from "../../services/AuthService";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import getClientData from "../../functions/getClientData";
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

const RechargeTransactions = ({ paymentGatewayId }) => {
  const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [transactionID, setTransactionID] = useState("");
  const [status, setStatus] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [clientId, setClientId] = useState("");
  const [clientProfiles, setclientProfiles] = useState(null);
  const handleChangePage = (event, newPage, page, rowsPerPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (transactionData) {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = page * rowsPerPage;
      setTableData(transactionData.slice(startIndex, endIndex));
    }
  }, [transactionData, page]);

  const today = dayjs();

  const validateDateFilter = () => {
    if (toDate < fromDate) {
      alert("To Date should be greater than or equal to From Date");
    } else {
      setPage(1);
      getTransactionDetails();
    }
  };

  const getTransactionDetails = async () => {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    const formattedFromDate = fromDate
      ? dayjs(fromDate).format("YYYY-MM-DD")
      : null;
    const formattedToDate = toDate ? dayjs(toDate).format("YYYY-MM-DD") : null;
    const user = JSON.parse(localStorage.getItem("user"));
    let getClientId = user["clientref_id"];

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/PaymentGateway/GetPaymentTransaction",
        {
          FromDate: formattedFromDate,
          ToDate: formattedToDate,
          TransactionID: transactionID,
          TransactionStatus: status,
          ClientId: checkIfUserStaff() ? clientId.toString() : getClientId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setShowLoader(false);
        console.log(response.data);

        const sortedDetails =
          response.data &&
          response.data.sort((a, b) => {
            return new Date(b.transactionDate) - new Date(a.transactionDate);
          });

        setTransactionData(sortedDetails);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  };



  useEffect(() => {
    getTransactionDetails();

    if (checkIfUserStaff()) {
      getClientsProfiles();
    } else {
      // getClients();
    }
  }, []);

  async function getClientsProfiles() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created"
      )
      .then((response) => {
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setclientProfiles((clientProfiles) => tempArray.sort());
      });
  }
  const getClientNameById = (clientID) => {
    if (clientProfiles) {
      const profile = clientProfiles.find(profile => profile.id === Number(clientID));
      debugger;
      return profile ? profile.clientName : "";
    }
  };

  return (
    <Grid className="p-4 main-grid" container>
      <div className="ms-3">
        <h2 className="fw-bold">Transaction Details</h2>
      </div>
      <div className="d-flex justify-content-between ms-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="me-2">
            <DatePicker
              className="bg-light rounded"
              defaultValue={today}
              disableFuture
              onChange={(newValue) => {
                setFromDate(newValue.$d ? newValue.$d : null);
              }}
              format="DD/MM/YYYY"
              label="From Date"
            />
          </div>
          <div className="me-2">
            <DatePicker
              className="bg-light rounded me-2"
              defaultValue={today}
              minDate={dayjs(fromDate)}
              disableFuture
              onChange={(newValue) => {
                setToDate(newValue.$d ? newValue.$d : null);
              }}
              format="DD/MM/YYYY"
              label="To Date"
            />
          </div>
        </LocalizationProvider>

        <div className="me-2">
          <TextField
            sx={{ width: "200px" }}
            className="bg-light rounded"
            id="outlined-required"
            label="Order Reference"
            value={transactionID}
            onChange={(e) => setTransactionID(e.target.value.trim().replace(/[^a-zA-Z0-9]/g, ''))}
          />
        </div>

        {checkIfUserStaff() && <div className="me-2">
          <Autocomplete sx={{ width: "250px" }}
            id="combo-box-demo"
            value={
              clientProfiles &&
              (clientProfiles.find((v) => v.id === clientId) || null)
            }
            onChange={(event, newValue) => {
              setClientId(newValue ? newValue.id : "");
            }}
            filterSelectedOptions={true}
            filterOptions={clientFilterOptions}
            options={clientProfiles}
            getOptionLabel={(option) => option.clientName}
            renderInput={(params) => (
              <TextField
                className="bg-light rounded"
                value={clientId}
                {...params}
                label="Select Client"
                variant="outlined"
                color="secondary"
                size="small"
              />
            )}
          />
        </div>}

        <div className="me-2">
          <FormControl className="bg-light rounded" sx={{ width: "200px" }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={""}>Select Status</MenuItem>
              {Object.entries(PaymentStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* <div className=""> */}
        <Button
          className="rounded text-light"
          style={{ backgroundColor: "#9f155a", width: "100px" }}
          onClick={validateDateFilter}
          variant="contained"
        >
          Search
        </Button>
        {/* </div> */}
      </div>


      <Grid className="m-3 w-100">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#9f155a" }}>
              <TableRow>
                <TableCell className="text-white opacity-100" align="center">
                  Transaction Date
                </TableCell>
                {checkIfUserStaff() && <TableCell className="text-white opacity-100" align="center">
                  Client
                </TableCell>}
                <TableCell className="text-white opacity-100" align="center">
                  Order Reference
                </TableCell>
                <TableCell className="text-white opacity-100" align="center">
                  Currency
                </TableCell>
                <TableCell className="text-white opacity-100" align="center">
                  Amount
                </TableCell>
                <TableCell className="text-white opacity-100" align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.transactionDate}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {dayjs(row.transactionDate).format("DD-MM-YYYY")}
                  </TableCell>
                  {checkIfUserStaff() && <TableCell align="center">{getClientNameById(row.clientId) + ' (' + row.clientId + ')'}</TableCell>}
                  <TableCell align="center">{row.transactionID}</TableCell>
                  <TableCell align="center">{row.currency}</TableCell>
                  <TableCell align="center">
                    {parseFloat(row.totalAmount).toFixed(2)}
                    {
                        (checkIfUserStaff() || row?.paymentGateWay === 'WorldPay') && (
                        <BootstrapTooltip1
                          title={
                            Number(row.totalAmount) + " Collected Including  PG Charge (" + Number(row.pgCharge) + ")"
                          }
                        >
                          <InfoOutlinedIcon />
                        </BootstrapTooltip1>
                      )
                    }
                  </TableCell>
                  <TableCell align="center">{row.statusDesc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={transactionData?.length}
          rowsPerPage="10"
          page={page - 1}
          onChangePage={(event, newPage) =>
            handleChangePage(event, newPage, page, rowsPerPage)
          }
          ActionsComponent={TablePaginationActions}
        />

        {!showLoader && transactionData.length === 0 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                color: "grey",
              }}
            >
              <SearchIcon fontSize="large"></SearchIcon>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                color: "grey",
              }}
            >
              <span>No data found</span>
            </div>
          </>
        )}
        {showLoader && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              color: "grey",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default RechargeTransactions;

// https://sandboxtest.gfa-hub.com/api/Configuration/Transactions/GetAccountBanalce?clientId=22154
