import axios from "axios";

const getGfsDetails = (traceId) => {
    return axios
        .get(
            `${process.env.REACT_APP_CORE_URL}Flights/Booking/GetBooking/v1/GetGFSDetails/${traceId}`
        )
        .then((response) => {            
            return response.data;
        })
        .catch((error) => {            
            if (error.response) {
                return error.response.data;
            } else {
                return { message: "An unknown error occurred." };
            }
        });
};

export default getGfsDetails;
