import HTTPMethod from "http-method-enum";
import sendProtectedJSONRequest from "./sendProtectedJSONRequest";
import sanitizeOriginDestinations from "./sanitizeOriginDestinations";
import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";

export default function getSearchResults(search) {
  const isStaff = checkIfUserStaff();
  const headers = {
    "Content-Type": "application/json",
  };

  if (isStaff === true) {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Search/v1/B2B",
        JSON.stringify({
          flightSearchRq: {
            originDestinations: sanitizeOriginDestinations(search),
            adultCount: search.adult,
            childCount: search.children,
            infantCount: search.infant,
            cabinClass: search.class,
            cabinPreferenceType: "Preferred",
            stopOver: search.directFlights ? "DirectFlight" : "None",
            IsBrandFareEnable: search.brandedFares,
            includeCarrier: search.includeCarrier ? search.includeCarrier : "",
            airTravelType: search.type,
            includeBaggage: true,
            includeMiniRules: true,
            specialFareTypes: [search?.preference?.value],
            nationality: search.nationality,
          },
          clientId: search.clientId,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Search/v1",
        JSON.stringify({
          originDestinations: sanitizeOriginDestinations(search),
          adultCount: search.adult,
          childCount: search.children,
          infantCount: search.infant,
          cabinClass: search.class,
          cabinPreferenceType: "Preferred",
          stopOver: search.directFlights ? "DirectFlight" : "None",
          IsBrandFareEnable: search.brandedFares,
          includeCarrier: search.includeCarrier ? search.includeCarrier : "",
          airTravelType: search.type,
          includeBaggage: true,
          includeMiniRules: true,
          specialFareTypes: [search?.preference?.value],
          nationality: search.nationality,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
