import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  right: 1%;
  bottom: 16%;
  cursor: pointer;
  border-radius: 50px;
  background: #fff;
  color: rgb(0, 0, 0);
  padding: 5px;
  box-shadow: 0px 0px 14px 5px #00000069;
  z-index: 1;
`;
