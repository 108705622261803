const fareBasisType = [
    'Exact',
    'StartsWith',
    'EndsWith',
    //'2ndLetter',
    //'3rdLetter',
    //'4thLetter',
    //'5thLetter',
    //'6thLetter',
    //'7thLetter',
    //'8thLetter',
    'NthLetter',
    'Contains'
];

export default fareBasisType;