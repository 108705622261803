import React, { useState, useEffect } from "react";
import "./SortComponent.css";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import AccountBalanceWalletRoundedIcon from "@material-ui/icons/AccountBalanceWalletRounded";
import SortData from "../../../data/SortData";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SortFilter from "../../../data/SortFilter";
import filterRecommended from "../../../functions/filterRecommended";
import getPaxTotal from "../../../functions/getPaxTotal";
//import getTotalFare from "../../../functions/getTotalFareBooking";
import getTotalFare from "../../../functions/getTotalFare";
// import getTotalFare from ""

function SortComponent({
  setOrder,
  setOrderBy,
  results,
  orderBy,
  order,
  currencyCode,
}) {
  const [sortData, setSortData] = useState(SortData);
  const [currentSort, setCurrentSort] = useState(SortFilter[0]);
  const handleClick = (index) => {
    setOrder("asc");
    setOrderBy(sortData[index].name);
    setCurrentSort(
      SortFilter.filter(
        (item) => item.order === "asc" && item.orderBy === sortData[index].name
      )[0]
    );
    const existingSort = [...sortData];
    existingSort[index].checked = true;
    for (
      let initialIndex = 0;
      initialIndex < existingSort.length;
      initialIndex++
    ) {
      if (initialIndex === index) {
        continue;
      } else {
        existingSort[initialIndex].checked = false;
      }
    }
    setSortData(existingSort);
  };

  const getFare = (item) => {
    return getTotalFare(
      item.fareGroups[0],
      item.adtNum,
      item.chdNum,
      item.infNum
    ).total;
  };

  const setCheapest = () => {
    const existingSort = [...sortData];
    if (results.length > 0) {
      let cheapest = results.reduce(function (res, obj) {
        return getFare(res) < getFare(obj) ? res : obj;
      });
      existingSort[0].value = getFare(cheapest);
      setSortData(existingSort);
    }
  };

  const getTotalDuration = (segGroups) => {
    let totalDuration = 0;
    segGroups.forEach((item) => {
      item.segs.forEach((seg) => {
        totalDuration = totalDuration + seg.duration;
      });
    });
    return totalDuration;
  };

  const setShortest = () => {
    if (results.length > 0) {
      let cheapest = results.reduce(function (res, obj) {
        return getTotalDuration(obj.segGroups) < getTotalDuration(res.segGroups)
          ? obj
          : res;
      });
      const existingSort = [...sortData];
      existingSort[1].value = getFare(cheapest);
      setSortData(existingSort);
    }
  };

  const setRecommended = () => {
    const existingSort = [...sortData];
    if (results.length > 0) {
      let cheapest = results.reduce(function (res, obj) {
        return filterRecommended(obj, res);
      });
      existingSort[2].value = getFare(cheapest);
      setSortData(existingSort);
    }
  };

  const handleChange = (event) => {
    setCurrentSort(event.target.value);
    setOrder(event.target.value.order);
    setOrderBy(event.target.value.orderBy);
  };

  useEffect(() => {
    setCheapest();
    setShortest();
    setRecommended();
    setCurrentSort(
      SortFilter.filter(
        (item) => item.order === "asc" && item.orderBy === "recommended"
      )[0]
    );
  }, [results]);
  return (
    <div className='sort-component-container'>
      <div className='sort-component'>
        <div
          className={
            "sort-component-item br-sort hover-fill pointer " +
            (sortData[0].checked ? "highlighted" : "")
          }
          onClick={() => handleClick(0)}>
          <div className='sort-component-item-icon'>
            {!sortData[0].checked && (
              <AccountBalanceWalletOutlinedIcon
                fontSize='small'
                className='opacity-3'
              />
            )}
            {sortData[0].checked && (
              <AccountBalanceWalletRoundedIcon
                fontSize='small'
                className='filled-icon'
              />
            )}
          </div>
          <div className='sort-component-item-detail'>
            <div className='sort-component-item-title'>{sortData[0].title}</div>
            <div className='sort-component-item-value'>
              {currencyCode} {sortData[0].value.toFixed(2)}
            </div>
          </div>
        </div>
        <div
          className={
            "sort-component-item br-sort hover-fill pointer " +
            (sortData[1].checked ? "highlighted" : "")
          }
          onClick={() => handleClick(1)}>
          <div className='sort-component-item-icon'>
            {!sortData[1].checked && (
              <ScheduleRoundedIcon fontSize='small' className='opacity-3' />
            )}
            {sortData[1].checked && (
              <ScheduleRoundedIcon fontSize='small' className='filled-icon' />
            )}
          </div>
          <div className='sort-component-item-detail'>
            <div className='sort-component-item-title'>{sortData[1].title}</div>
            <div className='sort-component-item-value'>
              {currencyCode} {sortData[1].value.toFixed(2)}
            </div>
          </div>
        </div>
        <div
          className={
            "sort-component-item hover-fill pointer " +
            (sortData[2].checked ? "highlighted" : "")
          }
          onClick={() => handleClick(2)}>
          <div className='sort-component-item-icon'>
            {!sortData[2].checked && (
              <StarOutlineRoundedIcon fontSize='small' className='opacity-3' />
            )}
            {sortData[2].checked && (
              <StarRoundedIcon fontSize='small' className='filled-icon' />
            )}
          </div>
          <div className='sort-component-item-detail'>
            <div className='sort-component-item-title'>{sortData[2].title}</div>
            <div className='sort-component-item-value'>
              {currencyCode} {sortData[2].value.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className='sort-filter'>
        <div className='sort-component-item-title mr-8'>Sort By : </div>
        <div>
          <FormControl component='fieldset'>
            <Select
              value={currentSort}
              onChange={handleChange}
              autoWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}>
              {SortFilter.map((item, index) => (
                <MenuItem key={`sortFilter_` + index} value={item}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default SortComponent;
