import React, { useState, useEffect } from "react";
import "./Receipt.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import ReceiptTable from "./ReceiptTable/ReceiptTable";
import ReceiptDrawer from "./ReceiptDrawer/ReceiptDrawer";
import AddReceiptCard from "./AddReceiptCard/AddReceiptCard";
import { checkIfUserStaff } from "../../services/AuthService";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Receipt() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");

  // useEffect(() => {
  //   getClients();
  // }, []);

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="receipt-container">
      <div className="receipt-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="receipt-receipt-container">
              <div className="receipt-table-container">
                <div className="receipt-title-add-button-container">
                  <div className="receipt-heading">Receipt</div>
                  <div className="receipt-icon-button-container">
                    {/* <SearchIcon
                      fontSize="small"
                      className="receipt-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                    <div
                      className="add-receipt-button mt-0px"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add Receipt
                    </div>
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="receipt-card-container">
                    <AddReceiptCard
                      name={name}
                      setName={setName}
                    ></AddReceiptCard>
                  </div>
                </Collapse>
                <ReceiptTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  categories={categories}
                ></ReceiptTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <ReceiptDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
          ></ReceiptDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
