const SortFilter = [
  {
    title: "Price (Lowest)",
    order: "asc",
    orderBy: "price",
  },
  {
    title: "Price (Highest)",
    order: "desc",
    orderBy: "price",
  },
  {
    title: "Duration (Shortest)",
    order: "asc",
    orderBy: "totalDuration",
  },
  {
    title: "Duration (Longest)",
    order: "desc",
    orderBy: "totalDuration",
  },
  {
    title: "Airline (Ascending)",
    order: "asc",
    orderBy: "airline",
  },
  {
    title: "Airline (Descending)",
    order: "desc",
    orderBy: "airline",
  },
  {
    title: "Departure (Earlier)",
    order: "asc",
    orderBy: "depart",
  },
  {
    title: "Departure (Latest)",
    order: "desc",
    orderBy: "depart",
  },
  {
    title: "Arrival (Earlier)",
    order: "asc",
    orderBy: "arrival",
  },
  {
    title: "Arrival (Latest)",
    order: "desc",
    orderBy: "arrival",
  },
  {
    title: "Recommended",
    order: "asc",
    orderBy: "recommended",
  },
];
export default SortFilter;
