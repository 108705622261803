import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./FSHDetails.css";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Divider } from "@material-ui/core";
import RiseLoading from "../../../Loader/RiseLoader";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function FSHDetails() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [id, setId] = useState(parsed.id);
  const [fsh, setFsh] = useState([]);
  const [supplierLogs, setSupplierLogs] = useState([]);
  const [flightdetails, setFlightdetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    loadFlightSearchDetails();
  }, []);

  async function loadFlightSearchDetails() {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/FlightSearchHistory?id=" +
        id
      )
      .then((response) => {
        setFsh(response.data);
        setSupplierLogs(response.data.supplierLogs);
        loadResponseDetails(response.data);
        setShowLoader(false);
      });
  }

  async function loadResponseDetails(fsh) {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/FlightSearchHistory/GetSearchResults?url=" +
        fsh.searchDetailedResponseURL
      )
      .then((response) => {
        setFlightdetails(response.data.FlightDetails);
      });
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <div className="search-heading" style={{ marginBottom: "10px" }}>
        Flight Search Request Details
      </div>
      <div style={{ marginTop: "20px" }}>
        {showLoader && <RiseLoading FSH={showLoader} />}
        {fsh ? (
          <Paper style={{ padding: "40px" }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">Origin</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.origin} ({fsh.originCountry})
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Destination</div>
                  <div className="view-vendor-drawer-description">
                    {fsh.destination} ({fsh.destinationCountry})
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">Onward Date</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.onwardDate}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Return Date</div>
                  <div className="view-vendor-drawer-description">
                    {fsh.returnDate}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">TraceId</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.traceId}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">Pax Count</div>
                  <div className="view-vendor-drawer-description">
                    A{fsh.adultCount}-C{fsh.childCount}-I{fsh.infantCount}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">Cabin Class</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.cabinClass}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">stopOver</div>
                  <div className="view-vendor-drawer-description">
                    {fsh.stopOver}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">airTravelType</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.airTravelType}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">clientId</div>
                  <div className="view-vendor-drawer-description">
                    {fsh.clientId}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">userId</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.userId}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="view-vendor-drawer config-input-flex-1">
                  <div className="view-vendor-drawer-title">searchDate</div>
                  <div className="view-vendor-drawer-description">
                    {fsh?.searchDate?.split("T")[0]}{" "}
                    {fsh?.searchDate?.split("T")[1].split(".")[0]}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">responseTime</div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.responseTime}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">
                    numberOfResults
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.numberOfResults}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">
                    Client Request Logs
                  </div>
                  <div className="view-vendor-drawer-description ">
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        textTransform: "none",
                      }}
                      href={fsh.clientRequestURL}
                      target="_blank"
                      download
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">
                    Client Response Logs
                  </div>
                  <div className="view-vendor-drawer-description ">
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        textTransform: "none",
                      }}
                      href={fsh.clientResponseURL}
                      target="_blank"
                      download
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">
                    Is L1 Cache
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.isL1Cache ? "Yes" : "No"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className="view-vendor-drawer config-input-flex-1"
                  style={{ margin: "0px" }}
                >
                  <div className="view-vendor-drawer-title">
                  l1CacheTraceID
                  </div>
                  <div className="view-vendor-drawer-description ">
                    {fsh.l1CacheTraceID ? fsh.l1CacheTraceID : 'None'  }
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <CircularProgress
            style={{ marginLeft: "110px", marginTop: "100px" }}
          />
        )}
      </div>
      <div
        className="search-heading"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        Supplier Details
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Supplier</TableCell>
                <TableCell align="left">Pcc Code</TableCell>
                <TableCell align="left">Number of Results</TableCell>
                <TableCell align="left">Response Time(ms)</TableCell>
                <TableCell align="left">Is Success</TableCell>
                <TableCell align="left">Is Cache</TableCell>
                 <TableCell align="left">Is Cache From</TableCell>
                <TableCell align="left">Request Logs</TableCell>
                <TableCell align="left">Response Logs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierLogs ? (
                supplierLogs.map((row) => (
                  <StyledTableRow key={JSON.stringify(row)}>
                    <TableCell align="left">{row.supplier}</TableCell>
                    <TableCell align="left">{row.pcc}</TableCell>
                    <TableCell align="left">{row.numberOfResults}</TableCell>
                    <TableCell align="left">{row.responseTime}</TableCell>
                    <TableCell align="left">
                      {row.success ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.isCache ? "yes" : "No"} */}
                      {row.isCache ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.isCache ? "yes" : "No"} */}
                      {row.isCacheFrom ? row.isCacheFrom : 'None'}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          textTransform: "none",
                        }}
                        href={row.supplierRequestURL}
                        target="_blank"
                        download
                      >
                        Download
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          textTransform: "none",
                        }}
                        href={row.supplierResponseURL}
                        target="_blank"
                        download
                      >
                        Download
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <CircularProgress />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div
        className="search-heading"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        Search Response Details
      </div>
      {flightdetails.map((row) => (
        <Paper style={{ marginBottom: "20px", padding: "10px" }}>
          <Grid container spacing={3} style={{ textAlign: "center" }}>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">
                  Validating Airline
                </div>
                <div className="view-vendor-drawer-description ">
                  {row.VAirline}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">
                  Client Fare in ({row.ClientCurrency})
                </div>
                <div className="view-vendor-drawer-description ">
                  {row.TotalFare}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">Client ROE</div>
                <div className="view-vendor-drawer-description ">
                  {row.ClientROE}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">Markup ROE</div>
                <div className="view-vendor-drawer-description ">
                  {row.MarkupROE}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">Vendor</div>
                <div className="view-vendor-drawer-description ">
                  {row.SupplierName} - {row.PCCCode}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">Itinerary Key</div>
                <div className="view-vendor-drawer-description ">
                  {row.FlightUniqueKey}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "5px" }}>
              <div
                className="view-vendor-drawer config-input-flex-1"
                style={{ margin: "0px" }}
              >
                <div className="view-vendor-drawer-title">
                  Itinerary Active?
                </div>
                <div className="view-vendor-drawer-description ">
                  {row.IsActive ? "Yes" : "No"}
                </div>
              </div>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
          <div
            className="search-heading"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            Itinerary
          </div>
          {row.Grp.map((grp, index) => (
            <React.Fragment>
              {grp.FlightSegments.map((fs) => (
                <React.Fragment>
                  <Grid container spacing={3} style={{ textAlign: "center" }}>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Departure
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.O}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">Arrival</div>
                        <div className="view-vendor-drawer-description ">
                          {fs.D}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Departure Date
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.DesDate}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Arrival Date
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.ArrDate}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">Airline</div>
                        <div className="view-vendor-drawer-description ">
                          {fs.MarAirline}-{fs.FNum}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Op Airline
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.OpAirline}-{fs.OpFNum}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Journey Time
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.JrTime}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">Stops</div>
                        <div className="view-vendor-drawer-description ">
                          {fs.Stops ? fs.Stops : "NIL"}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "5px" }}>
                      <div
                        className="view-vendor-drawer config-input-flex-1"
                        style={{ margin: "0px" }}
                      >
                        <div className="view-vendor-drawer-title">
                          Equipment
                        </div>
                        <div className="view-vendor-drawer-description ">
                          {fs.Equip}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
              {index < row.Grp.length - 1 && (
                <Divider
                  style={{
                    marginLeft: "30px",
                    marginRight: "30px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              )}
            </React.Fragment>
          ))}
          <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
          <div
            className="search-heading"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            Fare
          </div>
          {row.FareFamilies.map((ff, index) => (
            <React.Fragment>
              <Grid container spacing={3} style={{ textAlign: "center" }}>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Fare Type</div>
                    <div className="view-vendor-drawer-description ">
                      {ff.FareType}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Currency</div>
                    <div className="view-vendor-drawer-description">
                      {ff.Currency}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">PurchaseType</div>
                    <div className="view-vendor-drawer-description">
                      {ff.PurchaseType}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">PurchaseId</div>
                    <div className="view-vendor-drawer-description">
                      {ff.PurchaseId}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">IsRefundable</div>
                    <div className="view-vendor-drawer-description">
                      {ff.IsRefundable ? "Yes" : "No"}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      SupplierComm Group Id
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.SupplierCommissionGroupId}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      SupplierComm Block Id
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.SupplierCommissionBlockId}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ textAlign: "center" }}>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">IATA</div>
                    <div className="view-vendor-drawer-description ">
                      {ff.IATA}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">PLB</div>
                    <div className="view-vendor-drawer-description">
                      {ff.PLB}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Markup</div>
                    <div className="view-vendor-drawer-description">
                      {ff.Markup}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      DisableInfantMarkup
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.DisableInfantMarkup
                        ? ff.DisableInfantMarkup.toString()
                        : ff.DisableInfantMarkup}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">TaxIATA</div>
                    <div className="view-vendor-drawer-description">
                      {ff.TaxIATA}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">TaxPLB</div>
                    <div className="view-vendor-drawer-description">
                      {ff.TaxPLB}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">TaxType</div>
                    <div className="view-vendor-drawer-description">
                      {ff.TaxType}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">DropNet</div>
                    <div className="view-vendor-drawer-description">
                      {ff.DropNet}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      VendorDropNet
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorDropNet}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">VendorIATA</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorIATA}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">VendorPLB</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorPLB}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      VendorTaxIATA
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorTaxIATA}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">VendorTaxPLB</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorTaxPLB}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ textAlign: "center" }}>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      Client Markup
                    </div>
                    <div className="view-vendor-drawer-description ">
                      {ff.CustomerMarkup}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      Configured Markup
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.ConfiguredCustomerMarkup}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Opportunity</div>
                    <div className="view-vendor-drawer-description">
                      {ff.Opportunity}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Opp From</div>
                    <div className="view-vendor-drawer-description">
                      {ff.OpportunityFrom}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Opp To</div>
                    <div className="view-vendor-drawer-description">
                      {ff.OpportunityTo}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">ApplicableOn</div>
                    <div className="view-vendor-drawer-description">
                      {ff.MarkupApplicableOn}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      ApplicableType
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.MarkupFeeApplicableType}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ textAlign: "center" }}>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      Client Issuance Fee
                    </div>
                    <div className="view-vendor-drawer-description ">
                      {ff.IssuanceFee}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      Vendor Issuance Fee
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorIssuanceFee}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Tax Name</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorServiceTaxName}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">Tax Value</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorServiceTax}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      Applicable Type
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorServiceTaxType}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">ApplicableOn</div>
                    <div className="view-vendor-drawer-description">
                      {ff.VendorServiceTaxApplicable ==
                        "CommissionAndServiceFee"
                        ? "Commission And ServiceFee"
                        : ff.VendorServiceTaxApplicable}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ margin: "5px" }}>
                  <div
                    className="view-vendor-drawer config-input-flex-1"
                    style={{ margin: "0px" }}
                  >
                    <div className="view-vendor-drawer-title">
                      ApplicableType
                    </div>
                    <div className="view-vendor-drawer-description">
                      {ff.MarkupFeeApplicableType}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div
                className="search-heading"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Client Fare
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Pax Type</TableCell>
                      <TableCell align="left">Base Fare</TableCell>
                      <TableCell align="left">Tax</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ff.CommissionedFares ? (
                      ff.CommissionedFares.map((cf) => (
                        <StyledTableRow key={JSON.stringify(cf)}>
                          <TableCell align="left">{cf.PaxType}</TableCell>
                          <TableCell align="left">{cf.Base}</TableCell>
                          <TableCell align="left">
                            Total :{cf.Tax}({" "}
                            {cf.Taxes.map((t) => (
                              <React.Fragment>
                                {t.Code}: {t.Amt}
                              </React.Fragment>
                            ))}
                            )
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(cf.Base + cf.Tax).toFixed(2)}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <CircularProgress />
                    )}
                    <TableRow>
                      <TableCell rowSpan={3} />
                      <TableCell
                        colSpan={2}
                        align="right"
                        style={{ fontSize: "1.5em" }}
                      >
                        Total
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "1.5em" }}>
                        {ff.CommissionedFares &&
                          ff.CommissionedFares.reduce(function (prev, current) {
                            return parseFloat(
                              prev + +current.Base + current.Tax
                            ).toFixed(2);
                          }, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                className="search-heading"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                Vendor Fare
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Pax Type</TableCell>
                      <TableCell align="left">Fare Desc</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Code</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ff.FlightFares ? (
                      ff.FlightFares.map((ffs) => (
                        <StyledTableRow key={JSON.stringify(ffs)}>
                          <TableCell align="left">{ffs.PaxType}</TableCell>
                          <TableCell align="left">
                            {ffs.FareDescription}
                          </TableCell>
                          <TableCell align="left">{ffs.FareTag}</TableCell>
                          <TableCell align="left">{ffs.FareCode}</TableCell>
                          <TableCell align="right">{ffs.Amount}</TableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <CircularProgress />
                    )}
                    <TableRow>
                      <TableCell rowSpan={3} />
                      <TableCell
                        colSpan={3}
                        align="right"
                        style={{ fontSize: "1.5em" }}
                      >
                        Total
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "1.5em" }}>
                        {ff.FlightFares &&
                          ff.FlightFares.reduce(function (prev, current) {
                            return prev + +current.Amount;
                          }, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">City Pair</TableCell>
                      <TableCell align="center">Cabin Class</TableCell>
                      <TableCell align="center">RBD</TableCell>
                      <TableCell align="center">Seat Remaining</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ff.SegmentInfos ? (
                      ff.SegmentInfos.map((sf) => (
                        <StyledTableRow key={JSON.stringify(sf)}>
                          <TableCell align="center">{sf.CityPair}</TableCell>
                          <TableCell align="center">{sf.CabinClass}</TableCell>
                          <TableCell align="center">
                            {sf.BookingClass}
                          </TableCell>
                          <TableCell align="center">
                            {sf.SeatRemaining}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <CircularProgress />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
        </Paper>
      ))}
    </Container>
  );
}

export default FSHDetails;
