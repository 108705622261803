import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

export default function FareDetailsClientTableComponent({
  fareRows,
  adultBase,
  adultTax,
  adultBaggage,
  childBase,
  childTax,
  childBaggage,
  infantBase,
  infantTax,
  infantBaggage,
  adultVendorIssuanceFee,
  childVendorIssuanceFee,
  infantVendorIssuanceFee,
  adultMarkup,
  childMarkup,
  infantMarkup,
  adultVendorTax,
  childVendorTax,
  infantVendorTax,
  adultTotal,
  childTotal,
  infantTotal,
  adultIata,
  childIata,
  infantIata,
  adultVendorIata,
  childVendorIata,
  infantVendorIata,
  adultPlb,
  childPlb,
  infantPlb,
  adultVendorPlb,
  childVendorPlb,
  infantVendorPlb,
  currency,
  ROE,
  CalculateTotalFare,
  OnChangeCalculateTotalFare,
}) {
  return (
    <>
      {" "}
      <div className="offline-itenary-container-import">
        <div>Client Fare Details ({currency})</div>
      </div>
      <div className="itenary-passenger-details-offline">
        <Table className="itenary-passenger-table ImportBookingFareDetails">
          <TableHead>
            <TableRow className="itenary-passenger-table-header">
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
              >
                Pax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Base Fare
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Tax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Baggage
              </TableCell>
              {/* <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                MarkUp
              </TableCell> */}

              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IssuanceFee
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                TAX
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IATA
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight itenary-passenger-table-header-item-final"
              >
                <small>Vendor</small>
                <br />
                PLB
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                IATA
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                PLB
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="ImportBookingFareDetails ">
            {fareRows !== undefined && fareRows.length > 0 ? (
              fareRows
                .sort(function (a, b) {
                  if (a.paxType < b.paxType) {
                    return -1;
                  }
                  if (a.paxType > b.paxType) {
                    return 1;
                  }
                  return 0;
                })
                .map((fare) => (
                  <TableRow className="itenary-passenger-table-item">
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                    >
                      {fare.paxType === "ADT"
                        ? "Adult"
                        : fare.paxType === "CHD"
                        ? "Child"
                        : fare.paxType === "INF"
                        ? "Infant"
                        : "Adult"}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                    >
                      {fare.paxType === "ADT"
                        ? ((adultBase + adultMarkup) * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? ((childBase + childMarkup) * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? ((infantBase + infantMarkup) * ROE).toFixed(2)
                        : 0}
                      <br></br>
                      <span style={{ fontSize: "10px" }}>
                        (BaseFare + Markup)
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                    >
                      {fare.paxType === "ADT"
                        ? (adultTax * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childTax * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantTax * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                    >
                      {fare.paxType === "ADT"
                        ? (adultBaggage * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childBaggage * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantBaggage * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                    >
                      <TextValidator
                        id={"outlined-number-Markup" + fare.paxType}
                        type="number"
                        variant="outlined"
                        value={
                          fare.paxType === "ADT"
                            ? adultMarkup
                            : fare.paxType === "CHD"
                            ? childMarkup
                            : fare.paxType === "INF"
                            ? infantMarkup
                            : 0
                        }
                        onBlur={() => CalculateTotalFare()}
                        onChange={(event) =>
                          OnChangeCalculateTotalFare(
                            event,
                            fare.paxType,
                            "markup"
                          )
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TableCell> */}

                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                    >
                      {fare.paxType === "ADT"
                        ? (adultVendorIssuanceFee * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childVendorIssuanceFee * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantVendorIssuanceFee * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center"
                    >
                      {fare.paxType === "ADT"
                        ? (adultVendorTax * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childVendorTax * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantVendorTax * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                    >
                      {fare.paxType === "ADT"
                        ? (adultTotal * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childTotal * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantTotal * ROE).toFixed(2)
                        : 0}
                    </TableCell>

                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                    >
                      {fare.paxType === "ADT"
                        ? (adultVendorIata * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childVendorIata * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantVendorIata * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                    >
                      {fare.paxType === "ADT"
                        ? (adultVendorPlb * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childVendorPlb * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantVendorPlb * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                    >
                      {fare.paxType === "ADT"
                        ? (adultIata * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childIata * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantIata * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                    >
                      {fare.paxType === "ADT"
                        ? (adultPlb * ROE).toFixed(2)
                        : fare.paxType === "CHD"
                        ? (childPlb * ROE).toFixed(2)
                        : fare.paxType === "INF"
                        ? (infantPlb * ROE).toFixed(2)
                        : 0}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow></TableRow>
            )}
            <TableRow className="itenary-passenger-table-item">
              <TableCell
                colSpan={12}
                align=""
                className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <span className="fs12">
                  <span className="colorRed">*</span>
                  Baggage Amount is averaged value of all added baggage of pax
                  to match single pax Fare
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
