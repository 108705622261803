// import { CSVLink } from "react-csv";
// import * as XLSX from "xlsx";
import * as ExcelJS from 'exceljs';

// const generateExcelFile = (data) => {
//   const ws = XLSX.utils.aoa_to_sheet(data);
//   const wb = XLSX.utils.book_new();
//   const merges = [
//     { s: { r: 1, c: 3 }, e: { r: 1, c: 16 } },
//     { s: { r: 2, c: 3 }, e: { r: 2, c: 16 } },
//   ];

//   ws["!merges"] = merges;
//   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
//   XLSX.writeFile(wb, "account_statement.xlsx");
// };

const generateExcelFile = async (data, length) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet('Sheet1');
  data.forEach((row, rowIndex) => {
    const x = ws.addRow(row)
    row.forEach((value, colIndex) => {
      const cell = ws.getCell(rowIndex + 1, colIndex + 1);
      cell.value = value;
    });
    if (rowIndex === 5) {
      x.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FCE4D6' }
      };
      x.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: colNumber === 1 ? { style: 'thin' } : undefined, // Add left border only for the first cell
          right: { style: 'thin' },
          // right: colNumber === x.cellCount ? { style: 'thin' } : undefined // Add right border only for the last cell
        };
      });
    }
  });
  if (!ws.getCell('D2').isMerged) {
    const cell = ws.getCell('D2');
    let value = cell.value;
    ws.getCell('D2').value = value;
    ws.mergeCells('D2:P2');
  }
  if (!ws.getCell('D3').isMerged) {
    const cell = ws.getCell('D3');
    let value = cell.value;
    ws.getCell('D3').value = value;
    ws.mergeCells('D3:P3');
  }
  if (!ws.getCell('T9').isMerged) {
    const cell = ws.getCell('T9');
    let value = cell.value;
    ws.getCell('T9').value = value;
    ws.mergeCells('T9:U9');
  }
  if (!ws.getCell('W9').isMerged) {
    const cell = ws.getCell('W9');
    let value = cell.value;
    ws.getCell('W9').value = value;
    ws.mergeCells('W9:X9');
  }
  if (!ws.getCell(`T${length + 12}`).isMerged) {
    const cell = ws.getCell(`T${length + 12}`);
    let value = cell.value;
    ws.getCell(`T${length + 12}`).value = value;
    ws.mergeCells(`T${length + 12}:U${length + 12}`);
  }
  if (!ws.getCell(`W${length + 12}`).isMerged) {
    const cell = ws.getCell(`W${length + 12}`);
    let value = cell.value;
    ws.getCell(`W${length + 12}`).value = value;
    ws.mergeCells(`W${length + 12}:X${length + 12}`);
  }
  if (!ws.getCell(`C${length + 17}`).isMerged) {
    const cell = ws.getCell(`C${length + 17}`);
    let value = cell.value;
    ws.getCell(`C${length + 17}`).value = value;
    ws.mergeCells(`C${length + 17}:D${length + 17}`);
  }
  if (!ws.getCell(`F${length + 17}`).isMerged) {
    const cell = ws.getCell(`F${length + 17}`);
    let value = cell.value;
    ws.getCell(`F${length + 17}`).value = value;
    ws.mergeCells(`F${length + 17}:G${length + 17}`);
  }
  if (!ws.getCell(`C19${length + 18}`).isMerged) {
    const cell = ws.getCell(`C19${length + 18}`);
    let value = cell.value;
    ws.getCell(`C19${length + 18}`).value = value;
    ws.mergeCells(`C${length + 18}:D${length + 18}`);
  }
  if (!ws.getCell(`F${length + 18}`).isMerged) {
    const cell = ws.getCell(`F${length + 18}`);
    let value = cell.value;
    ws.getCell(`F${length + 18}`).value = value;
    ws.mergeCells(`F${length + 18}:G${length + 18}`);
    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Account_Statement.xlsx';
    link.click();
    // await wb.xlsx.writeFile('account_statement.xlsx');
  };
}
export default generateExcelFile;
