import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    minWidth: 72,
    fontSize: 11,
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    fontFamily: "Lato",
    "&:hover": {
      color: "#a0155a",
      opacity: 1,
    },
    "&$selected": {
      color: "#a0155a",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "#a0155a",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
export default AntTab;
