import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function FareDetailsSupplierTableComponent({
  fareRows,
  passengers,
  isDisabled,
  addFareRows,
  deleteFareRows,
  CalculateTotalFare,
  OnChangeCalculateTotalFare,
  adultBase,
  adultTax,
  adultBaggage,
  childBase,
  childTax,
  childBaggage,
  infantBase,
  infantTax,
  infantBaggage,
  adultVendorIssuanceFee,
  childVendorIssuanceFee,
  infantVendorIssuanceFee,
  adultVendorTax,
  childVendorTax,
  infantVendorTax,
  adultVendorTotal,
  childVendorTotal,
  infantVendorTotal,
  adultIata,
  childIata,
  infantIata,
  adultVendorIata,
  childVendorIata,
  infantVendorIata,
  adultPlb,
  childPlb,
  infantPlb,
  adultVendorPlb,
  childVendorPlb,
  infantVendorPlb,
  currency,
  adultMarkup,
  childMarkup,
  infantMarkup,
}) {
  return (
    <>
      {" "}
      <div className="offline-itenary-container-import">
        <div className="addSegDiv">
          Supplier Fare Details ({currency})
          <Button
            variant="outlined"
            color="primary"
            className="addButton"
            startIcon={<AddIcon />}
            onClick={() => addFareRows()}
            disabled={passengers.length === 0 || fareRows.length >= [...new Set(passengers.map(p => p.paxType))].length}
          >
            Add Fare
          </Button>
        </div>
      </div>
      <div className="itenary-passenger-details-offline">
        <Table className="itenary-passenger-table ImportBookingFareDetails">
          <TableHead>
            {" "}
            <TableRow className="itenary-passenger-table-header">
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight s-no itenary-passenger-table-header-item-initial "
              >
                Pax
              </TableCell>

              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Base Fare
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight"
              >
                Tax
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                Baggage
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-80 import-header-item-font-weight"
              >
                MarkUp
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IssuanceFee
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                TAX
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Vendor</small>
                <br />
                IATA
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-2 import-header-item-font-weight itenary-passenger-table-header-item-final"
              >
                <small>Vendor</small>
                <br />
                PLB
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                IATA
              </TableCell>
              <TableCell
                align="center"
                className="itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <small>Client Parting </small>
                <br />
                PLB
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="ImportBookingFareDetails ">
            {fareRows !== undefined &&
              fareRows.length > 0 &&
              fareRows.map((fare, index) => (
                <TableRow className="itenary-passenger-table-item">
                  <TableCell
                    align="center"
                    className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                  >
                    {fare.paxType === "ADT"
                      ? "Adult"
                      : fare.paxType === "CHD"
                        ? "Child"
                        : fare.paxType === "INF"
                          ? "Infant"
                          : "Adult"}
                  </TableCell>

                  <TableCell
                    align="center"
                    className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                  >
                    <TextValidator
                      id={"outlined-number-Markup" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultBase
                          : fare.paxType === "CHD"
                            ? childBase
                            : fare.paxType === "INF"
                              ? infantBase
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(event, fare.paxType, "base")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                  >
                    <TextValidator
                      id={"outlined-number-Markup" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultTax
                          : fare.paxType === "CHD"
                            ? childTax
                            : fare.paxType === "INF"
                              ? infantTax
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(event, fare.paxType, "tax")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80">
                    <TextValidator
                      id={"outlined-number-Baggage" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultBaggage
                          : fare.paxType === "CHD"
                            ? childBaggage
                            : fare.paxType === "INF"
                              ? infantBaggage
                              : 0
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-80"
                  >
                    <TextValidator
                      id={"outlined-number-Markup" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultMarkup
                          : fare.paxType === "CHD"
                            ? childMarkup
                            : fare.paxType === "INF"
                              ? infantMarkup
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(
                          event,
                          fare.paxType,
                          "markup"
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                    <TextValidator
                      id={"outlined-number-vendorIssuanceFee" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultVendorIssuanceFee
                          : fare.paxType === "CHD"
                            ? childVendorIssuanceFee
                            : fare.paxType === "INF"
                              ? infantVendorIssuanceFee
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(
                          event,
                          fare.paxType,
                          "vendorIssuanceFee"
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-center">
                    <TextValidator
                      id={"outlined-number-vendorTax" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultVendorTax
                          : fare.paxType === "CHD"
                            ? childVendorTax
                            : fare.paxType === "INF"
                              ? infantVendorTax
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(
                          event,
                          fare.paxType,
                          "vendorTax"
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-2"
                  >
                    {fare.paxType === "ADT"
                      ? adultVendorTotal.toFixed(2)
                      : fare.paxType === "CHD"
                        ? childVendorTotal.toFixed(2)
                        : fare.paxType === "INF"
                          ? infantVendorTotal.toFixed(2)
                          : 0}
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                    <TextValidator
                      id={"outlined-number-VendorIata" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultVendorIata
                          : fare.paxType === "CHD"
                            ? childVendorIata
                            : fare.paxType === "INF"
                              ? infantVendorIata
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(
                          event,
                          fare.paxType,
                          "vendorIata"
                        )
                      }
                      disabled={fare.paxType === "INF" ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                    <TextValidator
                      id={"outlined-number-VendorPlb" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultVendorPlb
                          : fare.paxType === "CHD"
                            ? childVendorPlb
                            : fare.paxType === "INF"
                              ? infantVendorPlb
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(
                          event,
                          fare.paxType,
                          "vendorPlb"
                        )
                      }
                      disabled={fare.paxType === "INF" ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                    <TextValidator
                      id={"outlined-number-IATA" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultIata
                          : fare.paxType === "CHD"
                            ? childIata
                            : fare.paxType === "INF"
                              ? infantIata
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(event, fare.paxType, "iata")
                      }
                      disabled={fare.paxType === "INF" ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                    <TextValidator
                      id={"outlined-number-PLB" + fare.paxType}
                      type="number"
                      variant="outlined"
                      value={
                        fare.paxType === "ADT"
                          ? adultPlb
                          : fare.paxType === "CHD"
                            ? childPlb
                            : fare.paxType === "INF"
                              ? infantPlb
                              : 0
                      }
                      onBlur={() => CalculateTotalFare()}
                      onChange={(event) =>
                        OnChangeCalculateTotalFare(event, fare.paxType, "plb")
                      }
                      disabled={fare.paxType === "INF" ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </TableCell>
                  <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                    {fare.paxType !== "ADT" && (
                      <IconButton
                        color="error"
                        className="deleteIcon"
                        onClick={() => deleteFareRows(index)}
                        disabled={
                          fareRows.length !== index + 1 || fareRows.length === 1
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow className="itenary-passenger-table-item">
              <TableCell
                colSpan={12}
                align=""
                className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
              >
                <span className="fs12">
                  <span className="colorRed">*</span>
                  Baggage Amount is averaged value of all added baggage of pax
                  to match single pax Fare
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
