import React, { useRef } from "react";
import "./AddSearchCategoryCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";

export default function AddSearchCategoryCard({name,setName, loadCategories}) {

  const searchCategoryForm = useRef();

  return (
    <div className="add-search-card-container">
      <div className="add-search-card-title">Search Category</div>
      <div className="create-search-contents-container">
        <div className="create-search-contents">
          <div className="add-search-if-items-container">
            {/* <div className="add-search-if-item margin-right-16">
              <div className="add-search-if-container">IF</div>
            </div> */}
            <div className="add-search-if-item flex-1-input  margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)} ref={searchCategoryForm} onSubmit={ () => loadCategories() } >
                <TextValidator
                  variant="outlined"
                  label="Group Name"
                  size="small"
                  color="secondary"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["required"]}
                  required
                />
              </ValidatorForm>
            </div>
          </div>
        </div>
        <div className="add-search-button-container">
          <div className="add-search-button" onClick={() => searchCategoryForm.current && searchCategoryForm.current.submit()} >Search Category</div>
        </div>
        {/* <div className="create-search-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
