import React, { useState, Component } from "react";
import "./AddVendorCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";


export default function AddVendorCard({setVendorName, setContactEmail, loadVendor}) {
  
  return (
    <div className="add-vendor-card-container">
      <div className="add-vendor-card-title">Search Vendor</div>
      <div className="create-vendor-contents-container">
        <div className="create-vendor-contents">
          <div className="add-vendor-if-items-container">
            {/* <div className="add-vendor-if-item margin-right-16">
              <div className="add-vendor-if-container">IF</div>
            </div> */}
            <div className="add-vendor-if-item flex-1-input  margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Vendor Name"
                  size="small"
                  color="secondary"
                  //value={vendorName}
                  onChange={(event) => setVendorName(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-vendor-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Vendor Email"
                  size="small"
                  color="secondary"
                  //value={contactEmail}
                  onChange={(event) => setContactEmail(event.target.value)}
                />
              </ValidatorForm>
            </div>
          </div>
        </div>
        <div className="add-vendor-button-container">
          <div className="add-vendor-button" onClick={() => loadVendor()}>Search Vendor</div>
        </div>
        {/* <div className="create-vendor-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
