import React from "react";
import { useHistory } from "react-router-dom";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../services/AuthService";
import "./Unauthorizedtemplate.css";
import HomeIcon from '@mui/icons-material/Home';

function Unauthorizedtemplate() {
  const history = useHistory();

  const handleGoHomeClick = () => {
    const userRole = getCurrentRole();

    if (checkIfUserStaff() && window.location.origin === 'https://betaprd.getfares.com') {
      window.location.replace(`${window.location.origin}/SignIn`, "_parent");
    }
    else if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
      history.push("/flight");
    }
    else {
      history.push("/Unauthorized");
    }
  };

  return (
    <div className="unauthorized-container">
      <div className="unauthorized-box">
        <h1>Access Denied</h1>
        <p>Sorry, you don't have permission to access this page.</p>
        <button onClick={handleGoHomeClick} className="go-home-button">
          <HomeIcon className="home-icon" />
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default Unauthorizedtemplate;