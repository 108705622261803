import React from "react";
import Dialog from "@material-ui/core/Dialog";
import SearchBar from "../../SearchBar/Searchbar";
import "./ModifySearchDialog.css";
import useDialogAlignTop from "../../../hooks/useDialogAlignTop";
function ModifySearchDialog({ onClose, open, search, setSearch,handleModifySearch }) {
  const handleClose = () => {
    onClose();
  };

  const handleModify = () => {
    onClose(true);
    handleModifySearch();
  };

  const useStyles = useDialogAlignTop();

  return (
    <Dialog
      classes={{
        paper: useStyles.dialog,
      }}
      onClose={handleClose}
      aria-labelledby="modify-search-title"
      open={open}
    >
      <div className="modify-search-dialog-container">
        <SearchBar action="modify" closeSearchDialog={handleModify} />
      </div>
    </Dialog>
  );
}

export default ModifySearchDialog;
