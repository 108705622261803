// File: src/components/TicketQRCode.js

import React, { useEffect, useRef } from 'react';
import bwipjs from 'bwip-js';

const TicketQRCode = ({ passenger, bookingDetails }) => {
    const canvasRef = useRef(null);

    const formatTicketData = () => {
        const { firstName, lastName } = passenger;
        const { pnr } = bookingDetails;
        const { validatingAirline, segGroups } = bookingDetails;

        let barcodeData = `${lastName}/${firstName} ${pnr}`;

        segGroups.forEach((group) => {
            group.segments.forEach((segment) => {
                const { departureOn, flightNum, origin, destination } = segment;

                const departureDate = new Date(departureOn).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                });

                barcodeData += ` ${departureDate} ${validatingAirline} ${flightNum} ${origin} ${destination}`;
            });
        });

        return barcodeData;
    };

    useEffect(() => {
        const barcodeValue = formatTicketData();

        bwipjs.toCanvas(canvasRef.current, {
            bcid: 'pdf417',       // Barcode type
            text: barcodeValue,   // Text to encode
            scale: 1,             // 3x scaling factor
            height: 10,           // Bar height, in millimeters
            includetext: true,    // Show the text below the barcode
            textxalign: 'center', // Align text to the center
        }, (err) => {
            if (err) {
                console.error('Error generating barcode:', err);
            }
        });
    }, [bookingDetails, passenger]);

    return (
        // <div>
        <canvas ref={canvasRef} />
        // </div>
    );
};

export default TicketQRCode;
