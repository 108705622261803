import getPaxTotal from "./getPaxTotal";

export default function getTotalFare(fareGroup, adult, children, infant) {
  let adultTotalFare = 0;
  let childTotalFare = 0;
  let infantTotalFare = 0;
  fareGroup.forEach((item) => {
    if (item.paxType == "CHD") {
      childTotalFare = getPaxTotal(item, children);
    }
    else if (item.paxType == "INF") {
      infantTotalFare = getPaxTotal(item, infant);
    }
    else {
      adultTotalFare = getPaxTotal(item, adult);
    }
  });
  return adultTotalFare + childTotalFare + infantTotalFare;
}
