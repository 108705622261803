import React from "react";
import "./FlightDetails.css";
import Collapse from "@material-ui/core/Collapse";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import months from "../../../../data/Months";
import Days from "../../../../data/Days";
import findAirportbyCode from "../../../../functions/findAirportByCode";
import findAirlinebyCode from "../../../../functions/findAirlineByCode";
import getAirportByCode from "../../../../functions/getAirportByCode";
import getLayoverTime from "../../../../functions/getLayoverTime";
import WorkOutlineRoundedIcon from "@material-ui/icons/WorkOutlineRounded";
import moment from "moment";

export default function FlightDetails({ open, result, activeSearch }) {
  const getDeparture = (departure) => {
    let temp = new Date(
      departure.includes("+00:00") ? departure.replace("+00:00", "") : departure
    );
    return moment(temp).format("HH:mm");
  };

  const getArrival = (arrival) => {
    let temp = new Date(
      arrival.includes("+00:00") ? arrival.replace("+00:00", "") : arrival
    );
    return moment(temp).format("HH:mm");
  };

  const getDuration = (duration) => {
    return `${Math.floor(duration / 60)}hrs ${duration % 60}min`;
  };

  const getBaggageWeight = (baggages, origin, destination) => {
    let baggageWeights = "";
    const joinedPair = origin + destination;
    if (baggages != undefined) {
      const cityPairs = baggages
        .filter((item) => {
          return item.cityPair === joinedPair;
        })
        .sort(function (a, b) {
          if (a.paxType < b.paxType) {
            return -1;
          }
          if (a.paxType > b.paxType) {
            return 1;
          }
          return 0;
        });
      cityPairs.forEach((item, index) => {
        baggageWeights =
          baggageWeights +
          ` ${item.paxType} ` +
          ` ${item.checkInBag}${index !== cityPairs.length - 1 ? "," : " "}  `;
      });
    }
    return baggageWeights;
  };
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <div className="drawer-flight-details-container">
        {result.segGroups.length > 0 &&
          result.segGroups.map((segGroup, segGroupIndex) =>
            segGroup.segs.map((item, index) => (
              <div className={`drawer-departure-arrival-container`}>
                <div className={`drawer-departure-container`}>
                  <div className="drawer-departure-header">
                    <div className="drawer-departure-icon">
                      <FlightTakeoffIcon fontSize="small"></FlightTakeoffIcon>
                    </div>
                    <div className="drawer-departure-text">
                      {getDeparture(item.departureOn)} -{" "}
                      {findAirportbyCode(item.origin)}
                    </div>
                  </div>
                  <div className="drawer-airport-name opacity-6 font-size-12">
                    {item.departureOn.split("T")[0]}
                  </div>
                  <div className="drawer-airport-name">
                    {getAirportByCode(item.origin)} ({item.origin})
                  </div>
                  <div className="drawer-departure-content mb-32">
                    <div className="drawer-duration">
                      {getDuration(item.duration)}
                    </div>
                    <div className="drawer-flight mt-6">
                      {findAirlinebyCode(item.mrkAirline)} {item.eqpType}
                    </div>
                    <div className="drawer-flight mt-6">
                      {"Flight no: " + item.flightNum}
                    </div>
                    <div className="drawer-cabin mt-6">
                      {result.fareGroups[0].segInfos[index].cabinClass}
                    </div>
                    <div className="drawer-cabin luggage-flex mt-12">
                      <div className="luggage-icons">
                        <WorkOutlineRoundedIcon
                          className="luggage-icons"
                          fontSize="small"
                        ></WorkOutlineRoundedIcon>
                      </div>
                      <div className="luggage-margin">
                        {getBaggageWeight(
                          result.fareGroups[0].baggages,
                          item.origin,
                          item.destination
                        )}
                      </div>
                    </div>
                    <div className="drawer-cabin mt-6 operated-by-color">
                      {item.mrkAirline !== item.opAirline
                        ? `Operated by ${findAirlinebyCode(item.opAirline)}`
                        : ""}
                    </div>
                  </div>
                </div>
                <div
                  className={`drawer-departure-container mt-16 mb-24 ${
                    result.segGroups.length - 1 === segGroupIndex &&
                    segGroup.segs.length - 1 === index
                      ? ""
                      : "flight-detail-border-bottom"
                  }`}
                >
                  <div className="drawer-departure-header">
                    <div className="drawer-departure-icon">
                      <FlightLandIcon fontSize="small"></FlightLandIcon>
                    </div>
                    <div className="drawer-departure-text">
                      {getArrival(item.arrivalOn)} -{" "}
                      {findAirportbyCode(item.destination)}
                    </div>
                  </div>
                  <div className="drawer-airport-name opacity-6 font-size-12">
                    {item.arrivalOn.split("T")[0]}
                  </div>
                  <div className="drawer-airport-name">
                    {getAirportByCode(item.destination)} ({item.destination})
                  </div>
                </div>
                {segGroup.segs.length - 1 !== index && (
                  <div className="layover-flight-details">
                    Layover{" "}
                    {getLayoverTime(
                      segGroup.segs[index],
                      segGroup.segs[index + 1]
                    )}
                  </div>
                )}
              </div>
            ))
          )}
      </div>
    </Collapse>
  );
}
