import React, { useState, useEffect } from "react";
import PreferenceToggleData from "../../data/PreferenceToggleData";
import "./PreferenceToggleButton.css";

import Switch from "@material-ui/core/Switch";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import countries from "../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@mui/material/Alert";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { set } from "date-fns";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
export default function PreferenceToggleButtons({ search, setSearch }) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [acceptValue, setAcceptValue] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [alignment, setAlignment] = React.useState(search?.preference?.value);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setIsChecked(false)
      setOpen(false);
    }

    if (typeof onClose === "function") {
      setIsChecked(false)
      setOpen(false);
    }

    if (acceptValue) {
      handleAlignment()
    }
    setIsChecked(false)

    setOpen(false);
  };
  const handleChange = (event) => {
    if (event.target.value === "EthnicFare") {
      setOpen(true)
    } else {
      let PreferenceValue = event.target.value
      handleAlignment(PreferenceValue)
    }

  }
  const SpecialFare = () => {
    const existingSearch = { ...search };
    existingSearch.preference = { value: 'EthnicFare', label: 'Ethnic' };
    setSearch(existingSearch);
    setAlignment("EthnicFare");
    handleClose()
  }

  const handleAlignment = (PreferenceValue) => {
    if (PreferenceValue !== null) {
      //setPreference(event.target.value);

      const existingSearch = { ...search };
      for (const option of PreferenceToggleData) {
        if (option.value === PreferenceValue) {
          existingSearch.preference = option;
        }
      }
      setSearch(existingSearch);
      setAlignment(PreferenceValue);
    }
  };

  const setPreference = (value) => {
    const existingSearch = { ...search };
    existingSearch.preference.value = value;
    setSearch(existingSearch);

  };
  // useEffect(()=>{
  //   console.log(alignment)

  // })

  // function renderToggleButtons() {
  //   const preferenceToggleData = PreferenceToggleData;

  //   const list = [];

  //   for (const option of preferenceToggleData) {
  //     list.push(
  //       <MenuItem value={option.value}>
  //         {option.label.replace(/([A-Z])/g, " $1").trim()}
  //       </MenuItem>
  //     );
  //   }

  //   return list;
  // }

  // useEffect(() => {
  //   if (search?.preference?.value !== alignment) {
  //     setAlignment(search?.preference?.value);
  //   }
  // }, [search]);

  return (
    <div className="preference-type-select-width">
      <FormControl component="fieldset">
        <Select

          value={alignment}
          onChange={handleChange
          }
          autoWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            // changed horizontal value left to right in order to contain dropdown options within parent div
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            getContentAnchorEl: null,
          }}
        >
          {PreferenceToggleData.map((item) => (
            <MenuItem value={item.value}>
              {item.label.replace(/([A-Z])/g, " $1").trim()}
            </MenuItem>
          ))}
          {/* {renderToggleButtons()} */}
        </Select>
      </FormControl>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <DialogContent dividers={scroll === "paper"}>
          <div className="country-select-modal">
            <h3 className="warning-mode-h3">
              WARNING *ELIGIBILITY CHECK*
            </h3>
            <h4 className="warning-mode-h4">
              You have selected a fare that is designed for a specific type of
              Passenger.
            </h4>
            <p>
              Please be aware that the incorrect application of the fare could
              result in a Passenger's denied boarding by the airliner as well as
              the request for payment of a penalty and/or a fine, or the
              generation of ADM (Agency Debit Memo).
            </p>

            <h4 className="warning-mode-h4">
              By clicking that check box, you confirm that You:
            </h4>

            <p>1) Have carefully read the rules and regulations.</p>
            <p>2) Have understood the applicability of the fare.</p>
            <p>
              3) Exempt us from any liability in case airline denied boarding
              and/or applied penalties/fine to the Pax.
            </p>
            <p>
              4)  Will pay ADM within fourteen days without objection,in case any arises.
            </p>
          </div>
          <FormControlLabel
            required
            control={
              <Checkbox
                value={isChecked}

                onChange={(e) => { setIsChecked(e.target.checked) }


                }
              />
            }
            label="I Accept All Terms And Conditions"
          />
        </DialogContent>




        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!isChecked}

            onClick={SpecialFare}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
