import axios from 'axios';
import moment from "moment";


const headers = { "Content-Type": "application/json" }

async function ticketingConfigUpdate(data, reqObj) {
  reqObj["createdBy"] = data.createdBy;
  reqObj["created"] = data.created;
  reqObj["modifiedBy"] = data.modifiedBy;
  reqObj["modified"] = data.modified;
  const response = await axios.put(process.env.REACT_APP_CORE_URL + "api/Configuration/TicketingConfiguration/" + data.id, JSON.stringify(reqObj), { headers });

  return response.data;
}
async function ticketingConfigInsert(data, reqObj) {
  reqObj["createdBy"] = "";
  reqObj["created"] = moment().format();
  reqObj["modifiedBy"] = "";
  reqObj["modified"] = moment().format();
  const response = await axios.post(process.env.REACT_APP_CORE_URL + "api/Configuration/TicketingConfiguration", JSON.stringify(reqObj), { headers });
  return response.data;
}
async function loadCreditCardDetails() {
  const response = await axios.get(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/GetCardInfo");

  if (response.data && response.data.length) {
    return response.data;
  } else {
    return [];
  }
}
async function addCreditCard(data, mode) {
  let reqObj = {
    "CardHolderName": "",
    "CardNumber": "",
    "CardType": "",
    "ExpDate": "",
    "Cvc": "",
    "AddressLine1": "",
    "AddressLine2": "",
    "City": "",
    "State": "",
    "Zipcode": "",
    "Country": "",
    "Phone": "",
    "Email": ""
  };
  reqObj.CardHolderName = data.CardHolderName
  reqObj.cardNumber = data.cardNumber
  reqObj.ExpDate = data.ExpDate
  reqObj.CardType = data.CardType
  reqObj.isActive = data.isActive
  // dispatch({ type: 'card', newValue: temp });
  try {
    if (mode == "Edit") {
      reqObj.id = data.id
      let res = await axios.put(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/UpdateCardInfo/" + data.id, (reqObj), { headers });
      if (res) {
        //   await reload()
      }
    } else {
      let res = await axios.post(process.env.REACT_APP_CORE_URL + "api/Configuration/Account/AddCardInfo", (reqObj), { headers });
      if (res) {
        //   await reload()
      }
    }

  } catch (error) {
    ;
    console.log(error)
    if (mode == "Edit") {
    } else {
    }
  }
}
export {
  ticketingConfigInsert, ticketingConfigUpdate, loadCreditCardDetails, addCreditCard
}
