import getConnectingAirports from "./getConnectingAirports";

export default function checkConnectingInFilter(item, search) {
  let checkedConnectingIn = search.filters.connectingIn.filter(
    (connectingIn) => connectingIn.checked === true
  );
  let isAvailable = [];
  if (checkedConnectingIn.length > 0) {
    checkedConnectingIn.forEach((checkedCity) => {
      item.segGroups.forEach((segGroup) => {
        if (segGroup.segs.length > 1) {
          if (getConnectingAirports(segGroup.segs).includes(checkedCity.city)) {
            isAvailable.push("true");
          }
        } else {
          isAvailable.push("false");
        }
      });
    });
  } else {
    isAvailable.push("true");
  }

  if(isAvailable.includes("true")){
    return true;
  } else {
    return false;
  }
}
