import React, { useState, Component } from "react";
import "./AddMarkupBlockCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
export default function AddMarkupBlockCard(
  { setMarkupBlockID, setfareType, setVendorID, vendorID, vendorList, loadCommissionBlocks }) {

    let ID,vendor,type;
  const classes = useStyles();
 async function endSearch() {
    if (!ID && !type&& !vendor) {
     await setMarkupBlockID(''); 
     await setfareType('');
     await  setVendorID('');
     await loadCommissionBlocks(false);
    }
  }
  return (
    <div className="add-supplier-card-container">
      <div className="add-supplier-card-title">Search Markup Block</div>
      <div className="create-supplier-contents-container">
        <div className="create-supplier-contents">
          <div className="add-supplier-if-items-container">
            {/* <div className="add-supplier-if-item margin-right-16">
              <div className="add-supplier-if-container">IF</div>
            </div> */}
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Ref ID"
                  size="small"
                  color="secondary"
                  onChange={(event) => {
                    setMarkupBlockID(event.target.value);
                    ID=(event.target.value)
                    endSearch()}}
                />
              </ValidatorForm>
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Fare Type"
                  size="small"
                  color="secondary"
                  onChange={(event) => {
                    setfareType(event.target.value)
                    type=(event.target.value)
                    endSearch()
                  }}
                />
              </ValidatorForm>
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <Autocomplete
                  id="vendor select"
                  value={
                    vendorList.find((v) => v.id === vendorID) || null
                  }
                  onChange={(event, newValue) => {
                    setVendorID(newValue ? newValue.id : "0");
                    vendor=(newValue ? newValue.id : "")
                    endSearch()
                  }}
                  options={vendorList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor"
                      value={vendorID}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </ValidatorForm>
            </div>
            {/* <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Cabin class"
                  size="small"
                  color="secondary"
                  onChange={(event) => setcabinClass(event.target.value)}                  
                />
              </ValidatorForm>
            </div> */}
          </div>
        </div>
        <div className="add-supplier-button-container">
          <div className="add-supplier-button" onClick={() => loadCommissionBlocks(true)} >Search Markup Block</div>
        </div>
        {/* <div className="create-supplier-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
