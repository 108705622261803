import getLowestAirlineFare from "./getLowestAirlineFare";

export default function getAirlinesFilter(flights) {
  let airlines = {};
  let airlineFilter = [];
  let distinctAirlines = flights.filter((entry) => {
    if (airlines[entry.airline]) {
      return false;
    }
    airlines[entry.airline] = true;
    return true;
  });
  distinctAirlines.forEach((airline) => {
    airlineFilter.push({
      airline: airline.airline,
      startingFrom: getLowestAirlineFare(airline.airline, flights),
      checked: false,
    });
  });
  return airlineFilter;
}
