import { createContext, useRef, useState ,useEffect} from "react";

const SearchResponseContext = createContext();

export const FlightResponseProvider = ({ children }) => {
    
    
    const [searchResponse, setSearchResponse] = useState([]);



    const value = {
        setSearchResponse, searchResponse
    };

    return <SearchResponseContext.Provider value={value}>{children}</SearchResponseContext.Provider>

};


export default SearchResponseContext;
