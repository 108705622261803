import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}


export default function CreditCardsListModal({ creditCards, setPopupOpen, SetMode, setSelected }) {
  const classes = useStyles();
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {
        // (creditCards && creditCards.length) ?
        <div className="bankInfo-category-listing">
          <TableContainer component={Paper} className="table-paper-styles">
            <Table className="table-background">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Card Holder Name</TableCell>
                  <TableCell>Card Number</TableCell>
                  <TableCell>Expiry</TableCell>
                  <TableCell>Card Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditCards !== undefined && (
                  creditCards.length === 0 ?
                    <>
                      <StyledTableRow>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                          colSpan={12}
                        >
                          <Alert severity="error">
                            No record found.
                          </Alert>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                    :
                    creditCards
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((group) => (
                        <StyledTableRow key={JSON.stringify(group)}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                          >
                            <div className="table-cell-title pointer">
                              {group.cardinfo.id}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                          >
                            <div className="table-cell-title pointer">
                              {group.cardinfo.cardHolderName}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {group.cardinfo.cardNumber}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {group.cardinfo.expDate}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {group.cardinfo.cardType}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {group.cardinfo.isActive ? (
                                <Chip
                                  label="Active"
                                  color="primary"
                                  size="small"
                                  style={{ backgroundColor: "green" }}
                                />
                              ) : (
                                <Chip
                                  label="Disabled"
                                  color="primary"
                                  style={{ backgroundColor: "red" }}
                                />
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {group.cardinfo.remarks}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <div className="table-cell-description">
                              Modified By{" "}
                              {group.modefiedBy &&
                                <span style={{ color: "black" }}>{group.modefiedBy}</span>}
                            </div>
                            <div className="table-cell-description">
                              Modified At{" "}
                              {group.cardinfo.modified && <span style={{ color: "black" }}> {group.cardinfo.modified.split("T")[0]}</span>}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell className="pad0">
                            <Button variant="outlined" style={{ textTransform: 'capitalize' }} color="primary" onClick={() => {
                              setPopupOpen(true);
                              SetMode('Edit');
                              setSelected(group);
                            }}>
                              Edit
                            </Button>
                            {/* <div
                              className="add-cardInfo-button mt-0px"
                              onClick={() => {
                                setPopupOpen(true);
                                SetMode('Edit');
                                setSelected(group);
                              }}
                            >
                              Edit
                            </div> */}
                          </StyledTableCell>

                        </StyledTableRow>
                      ))
                )
                  // : (
                  // <StyledTableRow>
                  //   <StyledTableCell>
                  //     <div className="table-loading">
                  //       <Skeleton width="50%" height={24} animation="wave" />
                  //     </div>
                  //   </StyledTableCell>
                  //   <StyledTableCell>
                  //     <div className="table-loading">
                  //       <Skeleton width="70%" height={24} animation="wave" />
                  //     </div>
                  //   </StyledTableCell>
                  //   <StyledTableCell>
                  //     <div className="table-loading">
                  //       <Skeleton width="800%" height={24} animation="wave" />
                  //     </div>
                  //   </StyledTableCell>
                  //   <StyledTableCell>
                  //     <div className="table-loading">
                  //       <Skeleton width="500%" height={24} animation="wave" />
                  //     </div>
                  //   </StyledTableCell>
                  //   <StyledTableCell align="right">
                  //     <div className="table-loading">
                  //       <Skeleton width="80%" height={24} animation="wave" />
                  //     </div>
                  //   </StyledTableCell>
                  // </StyledTableRow>
                  // )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={creditCards.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, newPage) => handleChangePage(event, newPage)}
            onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      }
    </>
  )
};

