import React from "react";
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import SearchBar from "../../SearchBar/Searchbar";

export default function SearchBarModal({openSearchModal,setOpenSearchModal,handleModifySearch}) {

    const handleModify = () => {
        setOpenSearchModal(false);
        handleModifySearch();
    };

    return <>
            <Modal
                size="lg"
                show={ openSearchModal }
                onHide={ () => setOpenSearchModal(false) }
                centered
                enforceFocus={false}
            >
                <SearchBar action="modify" closeSearchDialog={handleModify} />
            </Modal>
    </>
}