export default function sanitizeAddress(address) {
  const addressDetails = address;
  return {
    addressName: address.address,
    state: address.state,
    postalCode: address.zip,
    countryName: address.country,
    city: address.city,
  };
}
