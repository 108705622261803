import React from "react";
import moment from "moment";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import findStationByCode from "../../../functions/findStationByCode";
export default function RailPassengerDetails({ bookingDetails, reissue,
    handleDOBFieldChange, selectDate, handleticketChange
}) {
    return (
        <>
            <div className="itenary-container-import booking-padding-top-40">
                <div>Passenger Details</div>
            </div>
            <div className="importBooking passenger-itenary-details itenary-passenger-details-baggage">
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="itenary-passenger-table"
                    >
                        <TableRow className="itenary-passenger-table-header">
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-initial "
                            >
                                Passenger(s)
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Pax
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Gender
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Date of Birth
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Ticket(s)
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Coach
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Seat
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Extra
                            </TableCell>
                        </TableRow>
                        {
                            bookingDetails?.railPNRDetails?.map((rail, railIndex) => (
                                <>
                                    <TableRow>
                                        <TableCell
                                            colSpan={12}
                                            style={{
                                                backgroundColor: "#dedede",
                                                padding: "0px",
                                            }}
                                        >
                                            {" "}
                                            <div className="flight-itenary-table-title-container">
                                                <div>
                                                    {findStationByCode(
                                                        rail.legGroups[0].origin
                                                    )}
                                                </div>
                                                <div className="flight-itenary-icon">
                                                    <ArrowRightAltIcon></ArrowRightAltIcon>
                                                </div>
                                                <div>
                                                    {findStationByCode(
                                                        rail.legGroups[0].destination
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {rail.passengers !== undefined &&
                                        rail.passengers.length > 0 ? (
                                        <>
                                            {rail.passengers
                                                .sort(function (a, b) {
                                                    if (a.paxType < b.paxType) {
                                                        return -1;
                                                    }
                                                    if (a.paxType > b.paxType) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                })
                                                .map((passenger, passengerIndex) => (
                                                    <TableRow className="itenary-passenger-table-item">
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.title} {passenger.firstName}{" "}
                                                            {passenger.lastName}
                                                        </TableCell>
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.paxType}
                                                        </TableCell>
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.genderType}
                                                        </TableCell>
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {/* {moment(passenger.dob).format(
                                                                "Do MMMM YYYY"
                                                            )} */}
                                                            <TextValidator
                                                                required
                                                                label="Date of birth"
                                                                placeholder=""
                                                                variant="outlined"
                                                                size="small"
                                                                color="secondary"
                                                                type="date"
                                                                value={passenger.dob}
                                                                validators={["required"]}
                                                                errorMessages={["required"]}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min:
                                                                            passenger.paxType === "SNR"
                                                                                ? (selectDate.minseniorLimit)
                                                                                : passenger.paxType === "CHD"
                                                                                    ? (selectDate.minchildLimit)
                                                                                    : passenger.paxType === "INF"
                                                                                        ? (selectDate.mininfantLimit)
                                                                                        : passenger.paxType === "ADT"
                                                                                            ? (selectDate.minadultLimit)
                                                                                            : passenger.paxType === "YNG"
                                                                                                ? (selectDate.minyouthLimit)
                                                                                                : (selectDate.today),
                                                                        max:
                                                                            passenger.paxType === "SNR"
                                                                                ? (selectDate.maxseniorLimit)
                                                                                : passenger.paxType === "CHD"
                                                                                    ? (selectDate.maxchildLimit)
                                                                                    : passenger.paxType === "INF"
                                                                                        ? (selectDate.maxinfantLimit)
                                                                                        : passenger.paxType === "ADT"
                                                                                            ? (selectDate.maxadultLimit)
                                                                                            : passenger.paxType === "YNG"
                                                                                                ? (selectDate.maxyouthLimit)
                                                                                                : (selectDate.today),
                                                                    },
                                                                }}
                                                                onChange={(event) =>
                                                                    handleDOBFieldChange(
                                                                        event.target.value,
                                                                        railIndex,
                                                                        passengerIndex
                                                                    )
                                                                }
                                                                onKeyDown={(e) => e.preventDefault()}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </TableCell>

                                                        {reissue ? (
                                                            <>
                                                                <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        size="small"
                                                                        color="secondary"
                                                                        value={passenger.ticket}
                                                                        onChange={(event) =>
                                                                            handleticketChange(
                                                                                event.target.value,
                                                                                railIndex,
                                                                                passengerIndex
                                                                            )
                                                                        }
                                                                        validators={["required"]}
                                                                        errorMessages={[
                                                                            "This Field is Required.",
                                                                        ]}
                                                                    />{" "}
                                                                </TableCell>
                                                            </>
                                                        ) : (
                                                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                                {passenger.ticket}
                                                            </TableCell>
                                                        )}
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.coachNo}
                                                        </TableCell>
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.seatNo}
                                                        </TableCell>
                                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                            {passenger.extra}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </>) : (
                                        <TableRow></TableRow>
                                    )}
                                </>
                            ))
                        }
                    </Table>
                </TableContainer>

            </div>

        </>
    );
}