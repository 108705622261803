import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
export default withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 220,
    boxShadow: "0px 0px 5px 0px #00000036",
    paddingBottom: "24px",
  },
}))(Tooltip);
