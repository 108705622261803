import React from 'react'
import Skeleton from "@material-ui/lab/Skeleton";
import useConfigurationTableCell from '../../hooks/useConfigurationTableCell';
import useConfigurationTableRow from '../../hooks/useConfigurationTableRow';

const SkeletonView = () => {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    return (
        <div>
            <StyledTableRow>
                <StyledTableCell>
                    <div className="table-loading">
                        <Skeleton width="50%" height={24} animation="wave" />
                    </div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-loading">
                        <Skeleton width="70%" height={24} animation="wave" />
                    </div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-loading">
                        <Skeleton width="800%" height={24} animation="wave" />
                    </div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-loading">
                        <Skeleton width="500%" height={24} animation="wave" />
                    </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                    <div className="table-loading">
                        <Skeleton width="80%" height={24} animation="wave" />
                    </div>
                </StyledTableCell>
            </StyledTableRow>
        </div>
    )
}

export default SkeletonView