import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./TicketingConfiguration.css";
import TicketingConfigurationTable from './TicketingConfigurationTable';
import axios from 'axios';
import { NotificationContainer } from 'react-notifications';
import Notify from '../../Notifications/Notifications';
import TicketConfigModal from "./TicketingConfigurationModal"
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import { loadCreditCardDetails } from "../../../services/apiService";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function TicketingConfiguration() {

  const [showLoader, setShowLoader] = useState(false);
  const [ticketingConfiguration, setticketingConfiguration] = useState([]);
  const [pccList, setPccList] = useState([]);
  const [modal, setModal] = useState({ status: false, action: "", data: "" });
  const history = useHistory();
  const [creditCardsList, setCreditCardsList] = useState([]);




  useEffect(() => {
    // if (isUserHasPermission('TicketingConfigurationRead')) {
    loadTicketingConfiguration();
    loadPCCDetails();
    // } else {
    //     history.push("/Unauthorized");
    // }

  }, []);

  // used to repload components
  const reload = () => {
    loadTicketingConfiguration();
    // loadPCCDetails();
  };

  // fetching ticketing configuration
  const loadTicketingConfiguration = async () => {
    try {
      setShowLoader(true);
      let result = await axios.get(process.env.REACT_APP_CORE_URL + "api/Configuration/TicketingConfiguration");
      setShowLoader(false);
      setticketingConfiguration(await loadCreditCards(result.data));
    } catch (error) {
      setShowLoader(false);
      Notify("error", "Unable to fetch ticketing configuration", "Ticketing Configuration");
    }
  }
  const loadCreditCards = async (ticketResult) => {
    try {
      let temp = [];
      let tempTicket = ticketResult
      setShowLoader(true);
      let result = await loadCreditCardDetails();

      if (result.length) {
        tempTicket.map((data, index) => {
          temp = result.filter(item => {
            if (item.cardinfo.isActive === true) {
              return item
            }
          });
          temp.map(item => {
            if (!item.cardinfo.cardCode) {
              item.cardinfo.code = item.cardinfo.cardType == 'American Express' ? 'AX' : item.cardinfo.cardType == 'Visa' ? 'VI' : item.cardinfo.cardType == 'MasterCard' ? 'CA' : item.cardinfo.cardType == 'Diners Club' ? 'DC' : item.cardinfo.cardType == 'Discover' ? 'DS' : item.cardinfo.cardType == 'JCB' ? 'JC' : item.cardinfo.cardType == 'Maestro' ? 'TO' : item.cardinfo.cardType == 'UATP' ? 'TP' : ''
            } else {
              item.cardinfo.code = item.cardinfo.cardCode
            }
          })
          temp.map(item => {
            if (data.ticketingConfigurations.cardInfoId === item.cardinfo.id) {
              tempTicket[index] = data;
              tempTicket[index].ticketingConfigurations = data.ticketingConfigurations;
              tempTicket[index].ticketingConfigurations.fop = data.ticketingConfigurations.fop;
              tempTicket[index].ticketingConfigurations.isActive = data.ticketingConfigurations.isActive;
              tempTicket[index].ticketingConfigurations.id = data.ticketingConfigurations.id;
              tempTicket[index].ticketingConfigurations.cardInfoId = data.ticketingConfigurations.cardInfoId;

            } else if (data.ticketingConfigurations.cardInfoId === 0) {
              tempTicket[index] = data;
              tempTicket[index].ticketingConfigurations = data.ticketingConfigurations;
              tempTicket[index].ticketingConfigurations.fop = 'Cash';
            }
          })
          setCreditCardsList((prev) => [...temp]);
        })
        setShowLoader(false)
        return tempTicket
      } else {
        setShowLoader(false)
        return tempTicket
      }


    } catch (error) {
      setShowLoader(false);
      Notify("error", "Unable to fetch ticketing configuration", "Ticketing Configuration");
    }
  }
  // fetching Pcc details
  const loadPCCDetails = async () => {
    let vendorArray = [];
    let tmpArray = [];
    try {
      setShowLoader(true);

      let result = await axios.get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor");
      for (var i = 0; i < result.data.length; i++) {
        vendorArray.push({ "id": result.data[i].bookingSwitches.id, "name": result.data[i].bookingSwitches.vendorName });
      }

      let subResult = await axios.get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig");
      for (var i = 0; i < subResult.data.length; i++) {
        if (vendorArray.some(item => item.id === subResult.data[i].pccConfigs.vendorId)) {
          tmpArray.push({ "value": subResult.data[i].pccConfigs.pccCode, "label": subResult.data[i].pccConfigs.pccCode + " (" + vendorArray.find(st => st.id === subResult.data[i].pccConfigs.vendorId).name + ")" });
        }
        else {
          tmpArray.push({ "value": subResult.data[i].pccConfigs.pccCode, "label": subResult.data[i].pccConfigs.pccCode });
        }
      }
      setShowLoader(false);
      setPccList(tmpArray);
    } catch (error) {
      setShowLoader(false);
      Notify("error", "Unable to fetch pcc configuration", "Ticketing Configuration");
    }
  }

  const handleAddTicketConfig = () => {
    setModal({ status: true, action: "add", data: "" });
  }

  return (
    <>
      <div className="ticketingConfiguration-container">
        {ticketingConfiguration &&
          ticketingConfiguration.length === 0 &&
          pccList.length === 0 ? (
          <></>
        ) : (
          <>
            <div className="row ticketing-row">
              <div className="col-sm-12 ticketing-header">
                <div className="ticketing-header-text">
                  Ticketing Configuration
                </div>
                {/* {isUserHasPermission("TicketingConfigurationWrite") ? ( */}
                <button
                  className="btn add-new-config"
                  onClick={() => handleAddTicketConfig()}
                >
                  <AddCircleOutlineIcon />
                  &nbsp; Add New Config
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
            <div className="row ticketing-row">
              <TicketingConfigurationTable
                ticketingConfiguration={ticketingConfiguration}
                setModal={setModal}
              />
            </div>
            <TicketConfigModal
              pccList={pccList}
              status={modal.status}
              action={modal.action}
              data={modal.data}
              setModal={setModal}
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              reload={reload}
              creditCardsList={creditCardsList}
            />
          </>
        )}
      </div>
      {/* <RiseLoading isLoading={ showLoader } /> */}
      <NotificationContainer />
    </>
  );
}
