import axios from "axios";
async function getTransactionDetails(paymentId) {
    return await axios
        .get(
            process.env.REACT_APP_CORE_URL +
            "api/Configuration/PaymentGateway/GetPaymentResponseByPaymentId/" +
            paymentId
        )
        .then((response) => {
            return response.data;
        });
}
export default getTransactionDetails;