import React, { useEffect, useState } from "react";
import "./VendorTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { Chip } from "@material-ui/core";
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Alert from "@material-ui/lab/Alert";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function VendorTable({
  openDrawer,
  setAction,
  bookingSwitches,
  selectedFilter
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    
    setPage(0);
  }, [selectedFilter])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchedVal, setSearchedVal] = useState("");

  return (
    <div className="vendor-listing">
      <TableContainer component={Paper} className="table-paper-styles">
        <Table className="table-background">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} className="py-2">
                <OutlinedInput
                  id="keyword-search"
                  onChange={(e) => { setSearchedVal(e.target.value); setPage(0) }}
                  placeholder="Keyword Search by Vendor ID or Vendor Name or Contact Email or Status"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor ID</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Contact Email</TableCell>
              <TableCell>CurrencyCode</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>VendorFee</TableCell>
              <TableCell>After WorkingHoursFee</TableCell> */}
              <TableCell >Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingSwitches !== undefined && bookingSwitches.length > 0 ? (
              bookingSwitches.filter((row) => {
                if (searchedVal.toString().toLowerCase() == 'active' || searchedVal.toString().toLowerCase() == 'inactive') {
                  return row.bookingSwitches.status.toLowerCase() == searchedVal.toString().toLowerCase()
                } else {
                  return row.bookingSwitches.vendorName?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.bookingSwitches.contactEmail?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row.bookingSwitches.id == searchedVal;
                }

              }
              ).length === 0 ?
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      colSpan={6}
                      align="center"
                    >
                      <Alert severity="error">
                        No record found.
                      </Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
                :
                bookingSwitches
                  .filter((row) => {
                    if (searchedVal.toString().toLowerCase() == 'active' || searchedVal.toString().toLowerCase() == 'inactive') {
                      return row.bookingSwitches.status.toLowerCase() == searchedVal.toString().toLowerCase()
                    } else {
                      return row.bookingSwitches.vendorName?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                        row.bookingSwitches.contactEmail?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                        row.bookingSwitches.id == searchedVal;
                    }
                  }
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vendor) => (
                    <StyledTableRow key={JSON.stringify(vendor.bookingSwitches)}>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {vendor.bookingSwitches.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          setAction("view");
                          openDrawer(vendor.bookingSwitches);
                        }}
                      >
                        <div className="table-cell-title pointer">
                          {vendor.bookingSwitches.vendorName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {vendor.bookingSwitches.contactEmail}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {vendor.bookingSwitches.currencyCode}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-cell-title">
                          {vendor.bookingSwitches.isActive ? (
                            <Chip
                              label="Active"
                              color="primary"
                              size="small"
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label="In Active"
                              color="primary"
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                      <div className="table-cell-title">
                        {vendor.bookingSwitches.vendorFee}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="table-cell-title">
                        {vendor.bookingSwitches.afterWorkingHoursFee}
                      </div>
                    </StyledTableCell> */}
                      <StyledTableCell >
                        <div className="table-cell-description">Created by: <span style={{ color: "black" }}>{vendor.createdBy}</span></div>
                        <div className="table-cell-description">
                          Created on:{" "}
                          {vendor.bookingSwitches.created.replace('T', " , ")}
                        </div>
                        <div className="table-cell-description">Last Modified by: <span style={{ color: "black" }}>{vendor.modefiedBy}</span></div>
                        <div className="table-cell-description">
                          Last Modified on:{" "}
                          {vendor.bookingSwitches.modified.replace('T', " , ")}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="50%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="800%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="500%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="70%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div className="table-loading">
                    <Skeleton width="70%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className="table-loading">
                    <Skeleton width="80%" height={24} animation="wave" />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={bookingSwitches.filter((row) => {
          if (searchedVal.toString().toLowerCase() == 'active' || searchedVal.toString().toLowerCase() == 'inactive') {
            return row.bookingSwitches.status.toLowerCase() == searchedVal.toString().toLowerCase()
          } else {
            return row.bookingSwitches.vendorName?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              row.bookingSwitches.contactEmail?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              row.bookingSwitches.id == searchedVal;
          }
        }
        ).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
