const ConfigurationMock = [
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
  {
    originCountry: {
      country: "India",
      airports: "All Airports",
    },
    destinationCountry: {
      country: "United States",
      airports: "All Airports",
    },
    class: {
      cabin: "Economy",
      type: "Direct Search",
    },
    provider: {
      supplier: "Sabre",
      pcc: "U4JR",
    },
    inclusion: "B8, B9, BA, BB, BC",
    exclusion: "B8, B9, BA, BB, BC",
    owner: "Satheesh Kumar",
    created: new Date(),
    modified: new Date(),
  },
];
export default ConfigurationMock;
