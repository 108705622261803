import React, { useState, useEffect } from "react";
import "./MarkupBlockTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import cabinClassList from "../../../../data/CabinClass";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function MarkupBlockTable({ openDrawer, setAction, commissionBlocks, name,
    setPage, page, vendorList, loadCommissionBlocks, initialLoad, setInitialLoad }) {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event, newPage) => {
        setInitialLoad(false);
        setPage(newPage + 1);
    };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  // };


    useEffect(() => {
        if (!initialLoad) {
            loadCommissionBlocks();
        }
    }, [page])

  return (
    <div className="supplier-listing">
      {commissionBlocks ? (
        commissionBlocks.length === 0 ? (
          <>
            <Alert severity="error">No record found.</Alert>
          </>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  <TableRow>
                    <TableCell>Ref ID</TableCell>
                    <TableCell>Markup Group</TableCell>
                    <TableCell>Airline</TableCell>
                    <TableCell>FareType</TableCell>
                    <TableCell>Cabin Class</TableCell>
                    <TableCell width="200px">Vendor Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissionBlocks && commissionBlocks.length > 0 ? (
                    commissionBlocks.map((blocks) => (
                      <StyledTableRow
                        key={JSON.stringify(blocks.markupBlock)}
                        onClick={() => {
                          setAction("view");
                          openDrawer(blocks.markupBlock);
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title">
                            {blocks.markupBlock.id}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title">{name}</div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {blocks.markupBlock.airline}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {blocks.markupBlock.fareType}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {cabinClassList &&
                            cabinClassList
                              .filter(
                                (id) =>
                                  id.label === blocks.markupBlock.cabinClass
                              )
                              .map((row) => (
                                <div className="table-cell-title">
                                  {row.label}
                                </div>
                              ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {vendorList &&
                            vendorList
                              .filter(
                                (list) =>
                                  list.id === blocks.markupBlock.vendorId
                              )
                              .map((row) => (
                                <div className="table-cell-title">
                                  {row.name}
                                </div>
                              ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-title">
                            {blocks.markupBlock.isActive ? (
                              <Chip
                                label="active"
                                color="primary"
                                size="small"
                                style={{ backgroundColor: "green" }}
                              />
                            ) : (
                              <Chip
                                label="in active"
                                color="primary"
                                style={{ backgroundColor: "red" }}
                              />
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="table-cell-description">
                            Created by:{" "}
                            <span style={{ color: "black" }}>
                              {blocks.createdBy}
                            </span>
                          </div>
                          <div className="table-cell-description">
                            Created on:{" "}
                            {blocks.markupBlock.created.replace("T", " , ")}
                          </div>
                          <div className="table-cell-description">
                            Last Modified by:{" "}
                            <span style={{ color: "black" }}>
                              {blocks.modefiedBy}
                            </span>
                          </div>
                          <div className="table-cell-description">
                            Last Modified on:{" "}
                            {blocks.markupBlock.modified.replace("T", " , ")}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="50%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="70%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="800%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="500%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="70%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="table-loading">
                          <Skeleton width="80%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={commissionBlocks[0].count}
              rowsPerPage="25"
              page={page - 1}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              //onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
              ActionsComponent={TablePaginationActions}
            />
          </React.Fragment>
        )
      ) : (
        <>
          <Row>
            <div className="col-12 text-center py-5 loader-cls">
              <Box className="loader-box-content">
                <div className="flt-main-heading py-3">
                  <h3>Please Wait...</h3> We're fetching Markup Block List...
                </div>
                <CircularProgress />
              </Box>
            </div>
          </Row>
        </>
        //  <CircularProgress style={{ marginLeft: "110px", marginTop: "100px" }} />
      )}
    </div>
  );
}
