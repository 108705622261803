import React, { useState, useEffect } from "react";
import "./SortComponent.css";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../../services/AuthService";
const SortComponent = ({
  setOrder,
  setOrderBy,
  searchResults,
  setContextFlights,
  stableSort,
  getComparator,
  showNetfare,
  setShowNetFare,
  setHasMore,
  setPage,
}) => {
  const [sortType, setSortType] = useState("price");
  const [sortOrder, setSortOrder] = useState("asc");
  const [userRole, setUserRole] = useState("");

  const setSort = (sortType, sortOrder) => {
    setOrder(sortOrder);
    setOrderBy(sortType);
    setSortType(sortType);
    setSortOrder(sortOrder);

    const sortedResponse = stableSort(
      searchResults,
      getComparator(sortOrder, sortType)
    );

    const initialFlights = sortedResponse.slice(0, 40);
    setContextFlights((prevState) => [...initialFlights]);

    const hasMoreData = sortedResponse.length > initialFlights.length;

    setHasMore(hasMoreData);
    setPage(1);
  };

  useEffect(() => {
    setOrder("asc");
    setOrderBy("price");
    setUserRole(getCurrentRole());
  }, []);
  useEffect(() => {
  }, [sortOrder, sortType]);

  const handleShowNetFaresClick = () => {
    showNetfare === false ? setShowNetFare(true) : setShowNetFare(false);
  };


  return (
    <div className='sort-component-container-new'>
      <ButtonGroup variant='outlined' aria-label='outlined button group'>
        <Button
          size='small'
          variant='text'
          key={"Airline"}
          onClick={() => {
            setSort(
              "airline",
              sortType !== "airline"
                ? "asc"
                : sortOrder === "asc"
                  ? "desc"
                  : "asc"
            );
          }}
          endIcon={
            sortType === "airline" && sortOrder === "asc" ? (
              <ArrowDownwardIcon />
            ) : sortType === "airline" && sortOrder === "desc" ? (
              <ArrowUpwardIcon />
            ) : (
              ""
            )
          }>
          Airline
        </Button>
        <Button
          size='small'
          variant='text'
          key={"Departure"}
          onClick={() => {
            setSort(
              "depart",
              sortType !== "depart"
                ? "asc"
                : sortOrder === "asc"
                  ? "desc"
                  : "asc"
            );
          }}
          endIcon={
            sortType === "depart" && sortOrder === "asc" ? (
              <ArrowDownwardIcon />
            ) : sortType === "depart" && sortOrder === "desc" ? (
              <ArrowUpwardIcon />
            ) : (
              ""
            )
          }>
          Departure
        </Button>
        <Button
          size='small'
          variant='text'
          key={"Duration"}
          onClick={() => {
            setSort(
              "totalDuration",
              sortType !== "totalDuration"
                ? "asc"
                : sortOrder === "asc"
                  ? "desc"
                  : "asc"
            );
          }}
          endIcon={
            sortType === "totalDuration" && sortOrder === "asc" ? (
              <ArrowDownwardIcon />
            ) : sortType === "totalDuration" && sortOrder === "desc" ? (
              <ArrowUpwardIcon />
            ) : (
              ""
            )
          }>
          Duration
        </Button>
        <Button
          size='small'
          variant='text'
          key={"Arrival"}
          onClick={() => {
            setSort(
              "arrival",
              sortType !== "arrival"
                ? "asc"
                : sortOrder === "asc"
                  ? "desc"
                  : "asc"
            );
          }}
          endIcon={
            sortType === "arrival" && sortOrder === "asc" ? (
              <ArrowDownwardIcon />
            ) : sortType === "arrival" && sortOrder === "desc" ? (
              <ArrowUpwardIcon />
            ) : (
              ""
            )
          }>
          Arrival
        </Button>
        <Button
          size='small'
          variant='text'
          key={"price"}
          onClick={() => {
            setSort(
              "price",
              sortType !== "price"
                ? "asc"
                : sortOrder === "asc"
                  ? "desc"
                  : "asc"
            );
          }}
          endIcon={
            sortType === "price" && sortOrder === "asc" ? (
              <ArrowDownwardIcon />
            ) : sortType === "price" && sortOrder === "desc" ? (
              <ArrowUpwardIcon />
            ) : (
              ""
            )
          }>
          Price
        </Button>
        {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) && (
          <Button
            variant="text"
            onClick={() => {
              setShowNetFare(handleShowNetFaresClick);
            }}
            endIcon={
              showNetfare === false ? (
                <AddCircleOutlineOutlinedIcon />
              ) : (
                <RemoveCircleOutlineOutlinedIcon />
              )
            }
          ></Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default SortComponent;
