import React from "react";
import "./Footer.css";
import Grid from "@mui/material/Grid";
import { Container } from "@material-ui/core";
import logo from "../../assets/getfares.png";
import cert from "../../assets/iafcert.png";
import { Link } from "react-router-dom";
import Terms from "./Terms";

function Footer() {
  return (
    <React.Fragment>
      <div className='footer-container'>
        <Container>
          <Grid container>
            <Grid item xs={3}>
              <div className='footer-content'>
                <div className='footer-content-item footer-item-heading'>
                  Company
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  <Link
                    className='text-link'
                    to={{
                      pathname: "https://www.akbaroffshore.com/about.html",
                    }}>
                    About Us
                  </Link>
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  <Link className='text-link' target='_blank' to='/Terms'>
                    Terms of Use
                  </Link>
                </div>
                <div className='footer-content-item'>
                  <span
                    style={{ display: "block", opacity: "1" }}
                    className='footer-item-heading'>
                    ISO 27001 : 2022 Certified
                  </span>
                  <img
                    style={{
                      width: "10vw",
                    }}
                    alt='certification'
                    src={cert}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className='footer-content'>
                <div className='footer-content-item footer-item-heading'>
                  Products and Pricing
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  <Link
                    className='text-link'
                    to={{ pathname: "https://www.akbaroffshore.com/" }}
                    target='_blank'>
                    Products
                  </Link>
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Pricing
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Optimise your costs
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className='footer-content'>
                <div className='footer-content-item footer-item-heading'>
                  Support
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Solution Architectures
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Operations
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Customer relationship
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className='footer-content'>
                <div className='footer-content-item footer-item-heading'>
                  Resources
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Product Traning
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Documentation
                </div>
                <div className='footer-content-item footer-item-content-text'>
                  Blog
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className='footer-bottom-content'>
        <Container>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className='footer-left-container'>
              <img
                alt='company logo'
                style={{
                  width: "170px",
                  marginLeft: "-30px",
                }}
                src={logo}
              />
            </div>
            <div className='footer-right-container'>
              <div className='footer-right-container-text'>
                <Link
                  className='text-link'
                  to={{
                    pathname: "https://www.akbaroffshore.com/careers.html",
                  }}
                  target='_blank'>
                  Careers
                </Link>
              </div>
              <div className='footer-right-container-text'>
                <Link
                  className='text-link'
                  to={{
                    pathname:
                      "https://privacy.getfares.com",
                  }}
                  target='_blank'>
                  Privacy Policy
                </Link>
              </div>
              <div className='footer-right-container-text'>
                <Link
                  className='text-link'
                  to={{
                    pathname: "https://www.akbaroffshore.com/contact.html",
                  }}
                  target='_blank'>
                  {" "}
                  Contact Us
                </Link>
              </div>
              <div className='footer-right-container-text'>
                Terms and Conditions
              </div>
              <div className='footer-right-container-text'>
                Copyright © Get Fares 2024 All Rights Reserved.
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Footer;
