import React from "react";
function getCurrencySymbol(currencyCode) {
  let currency = currencyCode ? currencyCode : ''
  switch (currency) {
    case "USD":
      return <span className="currencySymbol">&#36;</span>;
    case "INR":
      return <span className="currencySymbol">&#8377;</span>;
    case "GBP":
      return <span className="currencySymbol">&pound;;</span>;
    case "EUR":
      return <span className="currencySymbol">&euro;</span>;
    case "AED":
      return <span className="currencySymbol">د. إ</span>;
    case "IDR":
      return <span className="currencySymbol">&#82;&#112;</span>;
    case "TRY":
      return <span className="currencySymbol">&#8378;</span>;
    case "PHP":
      return <span className="currencySymbol">&#8369;</span>;
    case "SAR":
      return <span className="currencySymbol">&#65020;</span>;
    default:
      return <span className="currencySymbol">{currencyCode}</span>;
  }
}

function CurrencyCodeComponent({ currencyCode }) {
  return <React.Fragment>{getCurrencySymbol(currencyCode)}</React.Fragment>;
}
export default CurrencyCodeComponent;
