import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

const LoadingStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "99999",
    background: "#00000060",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
};

export default function UserAlerts({ isSessionExpired }) {
    const history = useHistory();

    const logoutfn = async () => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/SignIn");
    };

    return isSessionExpired ? (
        <div
            className="col-12 text-center py-5"
            style={LoadingStyle}
        >
            <Box className="loader-box-content">
                <div className="flt-main-heading py-3">
                    <h3>Session Inactive</h3>
                    <div>Unable to continue as you are logged in a different browser</div>
                </div>
                <div><Button className="srn-btn" onClick={logoutfn}>Ok</Button></div>
            </Box>
        </div>
    ) : ""
}
