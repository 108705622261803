import React, { useState, Component } from "react";
import "./AddSupplierCommissionBlockCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import fareTypeList from "../../../../data/FareType";
import { Button, TextField } from "@material-ui/core";
import airlineDetails from "../../../../data/AirlineDetails";
import cabinClassList from "../../../../data/CabinClass";
import TripTypeList from "../../../../data/TripType";


export default function AddSupplierCommissionBlockCard({ filterCommissionBlocks, filters, setFilters, ClearFilters, setPage }) {

  const noneArr = [{ Code: "None", Name: "None" }];
  const extendedAirlineDetails = [...airlineDetails, ...noneArr];
  const statusList = ["Active", "In Active"];

  const updateFormState = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };

  return (
    <div className="scb add-supplier-card-container">
      {/* <div className="add-supplier-card-title">Search Supplier</div> */}
      <div className="create-supplier-contents-container">
        <div className="create-supplier-contents">
          <div className="add-supplier-if-items-container">
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <TextField
                variant="outlined"
                label="Supplier Ref ID"
                color="secondary"
                className="border border-1 rounded"
                value={filters.supplierID}
                onChange={(e) => updateFormState("supplierID", e.target.value)}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <TextField
                variant="outlined"
                label="Pseudo Code"
                color="secondary"
                className="border border-1 rounded"
                value={filters.pccCode}
                onChange={(e) => updateFormState("pccCode", e.target.value)}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="airline select"
                multiple
                value={filters.airline && filters.airline}
                onChange={(e, value) => updateFormState("airline", value)}
                options={extendedAirlineDetails}
                autoHighlight
                getOptionLabel={(option) => option?.Code === "All" ? "All" : option?.Name + " (" + option?.Code + ")"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Airline"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={filters.airline}
                    className="border border-1 scb-custom-dropdown-height rounded"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="faretype select"
                multiple
                value={filters.fareType && filters.fareType}
                onChange={(e, value) => updateFormState("fareType", value)}
                options={fareTypeList}
                autoHighlight
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={filters.fareType}
                    label="Fare Type"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="border border-1 scb-custom-dropdown-height rounded"
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>
          </div>
          <div className="add-supplier-if-items-container">
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="cabin select"
                multiple
                value={filters.cabinClass && filters.cabinClass}
                onChange={(e, value) => updateFormState("cabinClass", value)}
                options={cabinClassList}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={filters.cabinClass}
                    label="Cabin Class"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="border border-1 scb-custom-dropdown-height rounded"
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="traveltype select"
                multiple
                value={filters.travelType && filters.travelType}
                onChange={(e, value) => updateFormState("travelType", value)}
                options={TripTypeList}
                autoHighlight
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={filters.travelType}
                    label="Travel Type"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="border border-1 scb-custom-dropdown-height rounded"
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="status-select"
                value={filters.isActive}
                onChange={(e, value) => updateFormState("isActive", value)}
                options={statusList}
                autoHighlight
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="border border-1 scb-custom-dropdown-height rounded"
                  />
                )}
              />
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Button className="srn-btn" onClick={() => { filterCommissionBlocks(); setPage(1) }}>Search</Button>
              <Button className="srn-btn ms-3" onClick={() => ClearFilters()}>Clear</Button>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <Button className="srn-btn" onClick={() => loadCommissionBlocks(true)}>Search</Button>
        </div> */}
      </div>
    </div>
  );
}
