import getFare from "../../functions/getFare";
import getTotalFare from "../../functions/getTotalFare";


export const checkFareTypeFilter = (item, search) => {
    let fareFilters = search.filters.fareType;
    if (fareFilters.length > 0 && fareFilters[0].checked) {
        let isAvailable = [];
        item.fareGroups.forEach(fare => {
            fare.refundable ? isAvailable.push("true") : isAvailable.push("false");
        });
        return isAvailable.length ? isAvailable.includes("true") ? true : false : false;
    } else {
        return true;
    }
}

export const checkPricesFilter = (item, search) => {
    
    let prices = [];
    let totalPriceArr = [];

    if((search.filters.price).length === 3){
      //console.log("Default: "+(search.filters.price).length+" -- "+search.filters.price[0]+" -- "+search.filters.price[2])
      prices = [search.filters.price[0], search.filters.price[2]];
    } else {
      prices = [search.filters.price[3], search.filters.price[2]];
    }

    //let prices = [search.filters.price[0], search.filters.price[2]];
    //console.log("Price Low: "+prices[0] +" Price High: "+prices[1])
    let isAvailable = [];
    item.fareGroups.forEach(fareItem => {
      totalPriceArr.push(getTotalFare(
        fareItem,
        item.adtNum,
        item.chdNum,
        item.infNum
      ).total.toFixed(2));

      getTotalFare(
        fareItem,
        item.adtNum,
        item.chdNum,
        item.infNum
      ).total.toFixed(2) >= prices[0] && getTotalFare(
        fareItem,
        item.adtNum,
        item.chdNum,
        item.infNum
      ).total.toFixed(2) <= prices[1] ? isAvailable.push("true") : isAvailable.push("false");
    });
    //console.log("Total Length: "+totalPriceArr)
    return isAvailable.length ? isAvailable.includes("true") ? true : false : false;
}
