import React, { useState } from "react";
import "./Dashboard.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import { Container, Button, Grid, Divider, Paper } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Chart from "react-apexcharts";

// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
//import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

function Dashboard() {
  const [startDate, setStartDate] = useState(Moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(Moment());
  const [dateRange, setDateRange] = useState({
    currentWeek:
      Moment().subtract("days", 7).format("D MMMM YYYY") +
      " - " +
      Moment().format("D MMMM YYYY"),
    prevWeek:
      Moment().subtract("days", 15).format("D MMMM") +
      " - " +
      Moment().subtract("days", 8).format("D MMMM YYYY"),
  });

  const range = {
    Today: [Moment(), Moment()],
    Yesterday: [Moment().subtract(1, "days"), Moment().subtract(1, "days")],
    "Last 7 Days": [Moment().subtract(6, "days"), Moment()],
    "Last 30 Days": [Moment().subtract(29, "days"), Moment()],
    "This Month": [Moment().startOf("month"), Moment().endOf("month")],
    "Last Month": [
      Moment().subtract(1, "month").startOf("month"),
      Moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      Moment().subtract(1, "year").startOf("year"),
      Moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleDateApplyEvent = (event, picker) => {
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    var gap = endDate.diff(startDate, "days");

    var currentWeek =
      startDate.format("D MMMM YYYY") + " - " + endDate.format("D MMMM YYYY");
    var prevWeek =
      Moment(startDate).subtract("days", gap).format("D MMMM") +
      " - " +
      Moment(startDate).subtract("days", 1).format("D MMMM YYYY");

    setDateRange({ currentWeek: currentWeek, prevWeek: prevWeek });
  };

  let donut = {
    options: {
      chartOptions: {
        labels: ["India", "United States", "Itely"],
        colors: ["#F45336", "#E91E63", "#0C27B0"],
      },
      fill: {
        colors: ["#F45336", "#E91E63", "#0C27B0"],
      },
      colors: ["#F45336", "#E91E63", "#0C27B0"],
    },
    series: [4559.25, 2559.25, 559.25],
    labels: ["India", "United States", "Itely"],
    chartOptions: {
      labels: ["India", "United States", "Itely"],
      colors: ["#9f155a", "#E91E63", "#0C27B0"],
    },
  };

  let profitData = {
    options: {
      chartOptions: {
        labels: ["India", "United States", "Itely"],
      },
    },
    series: [1559.25, 2559.25, 559.25],
    labels: ["India", "United States", "Itely"],
    chartOptions: {
      labels: ["India", "United States", "Itely"],
    },
  };

  let areachart = {
    series: [
      {
        name: "India",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "United States",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "Italy",
        data: [1, 10, 30, 5, 34, 32, 10],
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  let barchartValues = {
    series: [
      {
        name: "Search",
        type: "column",
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
      },
      {
        name: "Revalidation",
        type: "column",
        data: [231, 421, 351, 271, 143, 221, 117, 131, 122, 221, 121, 116],
      },
      {
        name: "Book",
        type: "line",
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
      },
      labels: [
        "01 Jan 2001",
        "02 Jan 2001",
        "03 Jan 2001",
        "04 Jan 2001",
        "05 Jan 2001",
        "06 Jan 2001",
        "07 Jan 2001",
        "08 Jan 2001",
        "09 Jan 2001",
        "10 Jan 2001",
        "11 Jan 2001",
        "12 Jan 2001",
      ],
      xaxis: {
        type: "datetime",
      },
    },
  };

  let treemapCityPairs = {
    series: [
      {
        data: [
          {
            x: "DEL-DXB",
            y: 218,
          },
          {
            x: "DEL-BLR",
            y: 149,
          },
          {
            x: "BOM-DXB",
            y: 184,
          },
          {
            x: "LHR-BLR",
            y: 55,
          },
          {
            x: "JFK-SIN",
            y: 84,
          },
          {
            x: "LHR-JFK",
            y: 31,
          },
          {
            x: "SIN-DEL",
            y: 70,
          },
          {
            x: "BOM-HYD",
            y: 30,
          },
          {
            x: "BOM-DEL",
            y: 10,
          },
          {
            x: "MAA-HYD",
            y: 5,
          },
        ],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
    },
  };

  let barAirlines = {
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "British Airways",
          "Air India",
          "Indego",
          "Go Air",
          "Emirites",
          "Air Canada",
          "SpiceJet",
          "United Airliines",
          "Chaina Airlines",
          "American Airlines",
        ],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  return (
    <Container style={{ marginTop: "15px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="search-heading">My Dashboard</div>
          <Divider style={{ marginTop: "5px" }} />
        </Grid>
        <Grid item xs={12}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            ranges={range}
            onApply={handleDateApplyEvent}
          >
            <Button variant="contained" color="primary">
              {dateRange.currentWeek}
            </Button>
          </DateRangePicker>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading">Total Sales</div>
            <div className="total-sales-number">$64,559.25</div>
            <div>
              <ArrowUpwardIcon />
              25% compared to last week
            </div>
            <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Sales</div>
                <div className="total-sales-number-country">$4,559.25</div>
                <div>India</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Sales</div>
                <div className="total-sales-number-country">$2,559.25</div>
                <div>United States</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Sales</div>
                <div className="total-sales-number-country">$559.25</div>
                <div>Italy</div>
              </div>
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Chart
                options={donut.chartOptions}
                series={donut.series}
                type="pie"
                width="380"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading">Total Profit</div>
            <div className="total-sales-number">$4,559.25</div>
            <div>
              <ArrowUpwardIcon />
              5% compared to last week
            </div>
            <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Profit</div>
                <div className="total-sales-number-country">$4,559.25</div>
                <div>India</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Profit</div>
                <div className="total-sales-number-country">$559.25</div>
                <div>United States</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Profit</div>
                <div className="total-sales-number-country">$59.25</div>
                <div>Italy</div>
              </div>
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Chart
                options={profitData}
                series={profitData.series}
                type="pie"
                width="380"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading" style={{ marginBottom: "10px" }}>
              Total Look to Book
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Search</div>
                <div className="total-sales-number-country">35,543,232</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Revalidation</div>
                <div className="total-sales-number-country">32,245</div>
              </div>
              <div style={{ padding: "10px" }}>
                <div className="search-heading">Total Bookings</div>
                <div className="total-sales-number-country">1,493</div>
              </div>
            </div>
            <Chart
              options={barchartValues.options}
              series={barchartValues.series}
              type="line"
              height={250}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading" style={{ marginBottom: "10px" }}>
              Top Searched City Pairs
            </div>
            <Chart
              options={treemapCityPairs.options}
              series={treemapCityPairs.series}
              type="treemap"
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading" style={{ marginBottom: "10px" }}>
              TOP Performed Airlines
            </div>
            <Chart
              options={barAirlines.options}
              series={barAirlines.series}
              type="bar"
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "10px" }} variant="outlined" elevation={0}>
            <div className="search-heading" style={{ marginBottom: "30px" }}>
              Total Sales Trends
            </div>
            <Chart
              options={areachart.options}
              series={areachart.series}
              type="area"
              height={250}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
