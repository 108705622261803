import React from "react";
import "./RecoDetails.css";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="ml-8 mr-8">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function RecoDetails({ toggleDrawer, selectedItem }) {
  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="vendor-heading-container vendor-title-flex-container">
        <div>Reconcilliation Details</div>
      </div>
      {selectedItem && (
        <React.Fragment>
          <div className="vendor-view-edit-drawer-container">
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title1">Client Details</div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title1">Vendor Details</div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Adult Fare</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.clientSingleAdtBaseFare} (BaseFare) + {selectedItem.clientSingleAdtTax} (Tax)
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Adult Fare</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorSingleAdtBaseFare} (BaseFare) + {selectedItem.vendorSingleAdtTax} (Tax)
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Child Fare</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.clientSingleChdBaseFare} (BaseFare) + {selectedItem.clientSingleChdTax} (Tax)
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Child Fare</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorSingleChdBaseFare} (BaseFare) + {selectedItem.vendorSingleChdTax} (Tax)
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Infant Fare</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.clientSingleInfBaseFare} (BaseFare) + {selectedItem.clientSingleInfTax} (Tax)
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Infant Fare</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorSingleInfBaseFare} (BaseFare) + {selectedItem.vendorSingleInfTax} (Tax)
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Total</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.clientTotal}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Total</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorTotal}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client IATA</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.iata}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor IATA</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorIATA}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client PLB</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.plb}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor PLB</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorPLB}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Markup</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.markup}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Tax IATA</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.taxIATA}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Tax IATA</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorTaxIATA}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Tax PLB</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.taxPLB}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Tax PLB</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.vendorTaxPLB}
                </div>
              </div>
            </div>
            <div className="vendor-view-edit-drawer-input-container">
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Client Tax Applicable Type</div>
                <div className="view-vendor-drawer-description ">
                {selectedItem.taxType}
                </div>
              </div>
              <div className="view-vendor-drawer margin-right-16 config-input-flex-1">
                <div className="view-vendor-drawer-title">Vendor Tax Applicable Type</div>
                <div className="view-vendor-drawer-description ">
                  {selectedItem.taxType}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
