import moment from "moment";
export default function sanitizeOriginDestinations(search) {
  // console.log("Default Date:"+search.depart)
  // console.log("New Convert as per Format Date:"+(moment(new Date(search.depart)).format()).split("T")[0])
  // console.log("Old Code toISOString: "+search.depart.toISOString())
  if (search.type === "OneWay") {
    return [
      {
        departureDateTime: (moment(new Date(search.depart)).format()).split("T")[0],
        origin: search.from.city_code,
        destination: search.to.city_code,
      },
    ];
  } else if (search.type === "RoundTrip") {
    return [
      {
        //departureDateTime: search.depart.toISOString(),
        departureDateTime: (moment(new Date(search.depart)).format()).split("T")[0],
        origin: search.from.city_code,
        destination: search.to.city_code,
      },
      {
        //departureDateTime: search.return.toISOString(),
        departureDateTime: (moment(new Date(search.return)).format()).split("T")[0],
        origin: search.to.city_code,
        destination: search.from.city_code,
      },
    ];
  } else if (search.type === "Multidestination") {
    const originDestinations = [];
    originDestinations.push({
      //departureDateTime: search.depart.toISOString(),
      departureDateTime: (moment(new Date(search.depart)).format()).split("T")[0],
      origin: search.from.city_code,
      destination: search.to.city_code,
    });
    for (const destination of search.destinations) {
      originDestinations.push({
        //departureDateTime: destination.depart.toISOString(),
        departureDateTime: (moment(new Date(destination.depart)).format()).split("T")[0],
        origin: destination.from.city_code,
        destination: destination.to.city_code,
      });
    }
    return originDestinations;
  }
}
