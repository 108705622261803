import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Container, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import logo from "../../../assets/Logo-Text.png";
import Applicant from "../../../assets/Applicant.png";
import "./RegisterClient.css";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { useDropzone } from "react-dropzone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "../../../data/Country";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PasswordStrengthBar from "react-password-strength-bar";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../../services/AuthService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MustContainItem = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["must-line"];
    if (meetsReq) classArr.push("cross-out");
    return classArr.join(" ");
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className="must-text">{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "100px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    marginLeft: "10px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function RegisterClient() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [country, setcountry] = useState("");
  const [region, setRegion] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [adhar, setAdhar] = useState("");
  const [ownerCert, setOwnerCert] = useState("");
  const [filesList, setFilesList] = useState(null);
  const [registering, setRegistering] = useState(false);
  const [passwordvalidation, setPasswordValidation] = useState("");
  const [matchconfirmpasswordvalidation, setMatchConfirmPasswordValidation] =
    useState("");
  const [action, setAction] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isImportBtnDisabled, setisImportBtnDisabled] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [isTAC, setTAC] = useState(false);
  const [clientId, setClientId] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [documentType, setdocumentType] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const [eye, seteye] = useState(false);
  // checks all validations are true
  const [allNotValid, setAllNotValid] = useState(true);

  // labels and state boolean corresponding to each validation
  const mustContainData = [
    ["An uppercase letter (A-Z)", containsUL],
    ["A lowercase letter (a-z)", containsLL],
    ["A number (0-9)", containsN],
    ["A special character (!@#$)", containsSC],
    ["At least 8 characters", contains8C],
    ["Passwords match", passwordMatch],
  ];

  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() !== password) setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    if (password.toUpperCase() !== password) setContainsLL(true);
    else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);

    // has 8 characters
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);

    // passwords match
    if (password !== "" && password === confirmPassword) setPasswordMatch(true);
    else setPasswordMatch(false);

    // all validations passed
    if (
      containsUL &&
      containsLL &&
      containsN &&
      containsSC &&
      contains8C &&
      passwordMatch &&
      isTAC
    ) {
      setAllNotValid(false);
    } else {
      setAllNotValid(true);
    }
  };

  useEffect(() => {
    //Loading Necessory Data.
    const parsed = queryString.parse(location.search);
    // console.log(parsed);
    setEmail(parsed.email);
    setToken(decodeURIComponent(parsed.token));
    setClientId(parsed.clientId);
    setisImportBtnDisabled(false);
  }, []);

  useEffect(() => {
    validatePassword();
  }, [confirmPassword, isTAC]);
  const handleSubmit = () => {
    if (step === 3) {
      //create client
      setRegistering(true);
      handleRegister();
    } else {
      setStep(step + 1);
    }
  };
  const eyeToggle = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      seteye(true);
    } else {
      setPasswordType("password");
      seteye(false);
    }
  };
  const handlePreviousSubmit = () => {
    setStep(step - 1);
  };

  async function handleRegister() {
    setisImportBtnDisabled(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/ManageUser/AddNewClientUserAndConfirmEmail/",
        JSON.stringify({
          title: "Mr",
          firstName: firstName,
          lastName: lastName,
          email: email,
          emailConfirmationToken: token,
          password: password,
          companyName: companyname,
          region: region,
          country: country,
          state: state,
          city: city,
          pinCode: pincode,
          pan: pan,
          gst: gst,
          adhar: adhar,
          ownerCert: ownerCert,
          address1: address1,
          address2: address2,
          ClientId: clientId,
          phoneNumber: phoneNumber,
        }),
        {
          headers,
        }
      )
      .then(async (res) => {
        // console.log(res.data);
        await setRegistering(true);
        await setAlert({
          open: true,
          message: "Client is registered successfully",
          // message: "Client’s user registered",
          severity: "success",
        });
        await setisImportBtnDisabled(false);
        setTimeout(() => {
          const userRole = getCurrentRole();
          if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin") {
            history.push("/flight");
          }
          else {
            history.push("/Unauthorized");
          }
        }, 5000);
      })
      .catch((err) => {
        // console.log(err);
        const errMsg = err.response.data.detail;
        setAlert({
          open: true,
          message: `${errMsg}`,
          severity: "error",
        });
        setisImportBtnDisabled(false);
      });
  }

  useEffect(() => {
    validatePassword();
  }, [password, confirmPassword]);
  const toLogin = () => {
    history.push("/SignIn");
  };
  // const validatePassword = () => {
  //   let passerr = false;
  //   let confirmpasserr = false;
  //   // has uppercase letter
  //   if (password.toLowerCase() === password) {
  //     passerr = true;
  //   }
  //   else if (password.toUpperCase() === password) { // has lowercase letter
  //     passerr = true;
  //   }
  //   else if (!/\d/.test(password)) { // has number
  //     passerr = true;
  //   }
  //   else if (!/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) {// has special character
  //     passerr = true;
  //   }
  //   else if (password.length <= 8) { // has 8 characters
  //     passerr = true;
  //   }

  //   if (password !== "" && password !== confirmPassword) {// passwords match
  //     confirmpasserr = true;
  //   }

  //   if (password !== "" && passerr)
  //     setPasswordValidation("Password not in correct format");
  //   else
  //     setPasswordValidation("");

  //   if (confirmpasserr)
  //     setMatchConfirmPasswordValidation("Password and Confirm Password does not match.");
  //   else
  //     setMatchConfirmPasswordValidation("");
  // };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFilesList(acceptedFiles);
    // console.log(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container style={{ marginTop: "50px" }}>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {/* <img
              alt="company logo"
              style={{
                width: "200px",
                marginBottom: "50px",
              }}
              src={logo}
            /> */}
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <div style={{ padding: "20px", marginTop: "80px" }}>
            <Typography variant="h6" style={{ fontWeight: "600" }}>
              Register with Getfares to get cheapest ever fares...
            </Typography>
            <p>
              its a very quick process, fill the simple form and the account
              manager from Getfares will contact you. if you need support,please
              write to support@getfares.com .
            </p>
            <div style={{ textAlign: "center" }}>
              <img
                alt="company logo"
                style={{
                  width: "250px",
                }}
                src={Applicant}
              />
            </div>
          </div>
          {/* <Divider orientation="vertical" style={{ height: "700px" }} /> */}
        </Grid>
        <Grid item xs={6} className="mb-5">
          <div style={{ marginLeft: "20px" }}>
            <Typography variant="h5" style={{ marginBottom: "20px" }}>
              Sign Up to Getfares
            </Typography>
            <ValidatorForm
              onError={(errors) => console.log(errors)}
              onSubmit={handleSubmit}
            >
              {step === 1 && (
                <React.Fragment>
                  <div className="client-client-input-group">
                    <label className="field-heading">
                      Email Address/User Name
                    </label>
                    <div className="help-texts">
                      {/* This will be used as admin user's user id.  */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Email Address"
                      style={{ width: "350px" }}
                      value={email}
                      disabled
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Phone Number</label>
                    <div className="help-texts">
                      {/* mobile or landline where we can contact you. */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Phone Number"
                      style={{ width: "350px" }}
                      value={phoneNumber}
                      onChange={(event) => setphoneNumber(event.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">First Name</label>
                    <div className="help-texts">
                      {/* first name of the admin user */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="First Name"
                      style={{ width: "350px" }}
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Last Name</label>
                    <div className="help-texts">
                      {/* last name of the admin user */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Last Name"
                      style={{ width: "350px" }}
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Password</label>
                    <div className="help-texts">
                      {/* please enter the stront password, password rule here */}
                    </div>
                    {/* <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">Password Rule</Typography>
                          <ul>
                            <li>An uppercase letter (A-Z)</li>
                            <li>A lowercase letter (a-z)</li>
                            <li>A number (0-9)</li>
                            <li>A special character (!@#$)</li>
                            <li>At least 8 characters</li>
                            <li>Passwords match</li>
                          </ul>
                        </React.Fragment>
                      }
                    >
                      <TextValidator
                        type="password"
                        id="standard-basic"
                        variant="outlined"
                        // placeholder="Password"
                        style={{ width: "350px" }}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        validators={["required"]}
                        errorMessages={["This Field is Required."]}
                        autoComplete="new-password"
                      />
                    </HtmlTooltip> */}
                    {/* <div className="invalid">{passwordvalidation}</div> */}
                    <TextValidator
                      variant="outlined"
                      margin="normal"
                      size="small"
                      // label="Password"
                      color="primary"
                      fullWidth
                      // type="password"
                      type={passwordType}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      onKeyUp={validatePassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={eyeToggle}
                              onMouseDown={eyeToggle}
                            >
                              {eye ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <PasswordStrengthBar minLength="4" password={password} />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Confirm Password</label>
                    <div className="help-texts">
                      {/* this should be same as password, so you make sure you
                      remember what you enter. */}
                    </div>
                    <TextValidator
                      type="password"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      // label="Confirm Password"
                      color="primary"
                      fullWidth
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      onKeyUp={validatePassword}
                    />
                    {/* <h4>Password Checklist:</h4> */}
                    <div className="must-container cfb">
                      {mustContainData.map((data) => (
                        <MustContainItem data={data} />
                      ))}
                    </div>

                    {/* <TextValidator
                      type="password"
                      id="standard-basic"
                      variant="outlined"
                      placeholder="Confirm Password"
                      style={{ width: "350px" }}
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      autoComplete="new-password"
                    /> */}
                    {/* <div className="invalid">{matchconfirmpasswordvalidation}</div> */}
                  </div>
                </React.Fragment>
              )}
              {step === 2 && (
                <React.Fragment>
                  <div className="client-input-group">
                    <label className="field-heading">Company Name</label>
                    <div className="help-texts">
                      {/* full name of the company as mentioned on document */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Client Name"
                      style={{ width: "350px" }}
                      value={companyname}
                      onChange={(event) => setCompanyname(event.target.value)}
                      autoComplete="off"
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Region</label>
                    <div className="help-texts">
                      {/* specific region where this client belongs to */}
                    </div>
                    <FormControl
                      variant="outlined"
                      size="small"
                      margin="normal"
                      validators={["required"]}
                      errorMessages={["This Field is Required."]}
                      style={{
                        width: "350px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {/* <InputLabel id="demo-simple-select-outlined-label">
                        Region
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={region}
                        onChange={(event) => setRegion(event.target.value)}
                        label="Region"
                      >
                        <MenuItem value={"APAC"}>APAC</MenuItem>
                        <MenuItem value={"AMEA"}>AMEA</MenuItem>
                        <MenuItem value={"Middle-East"}>Middle-East</MenuItem>
                        <MenuItem value={"Africa"}>Africa</MenuItem>
                        <MenuItem value={"AMER"}>AMER</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Country</label>
                    <div className="help-texts">
                      {/* Please select country, this will be used for many legal
                      verification */}
                    </div>
                    <Autocomplete
                      id="country-select-demo"
                      value={countries.find((v) => v.code === country) || null}
                      onChange={(event, newValue) => {
                        setcountry(newValue ? newValue.code : "");
                      }}
                      options={countries}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      autoComplete="new-password"
                      getOptionLabel={(option) =>
                        option.label + " (" + option.code + ")"
                      }
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.label} ({option.code})
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          // label="Country"
                          value={country}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          style={{ width: "350px" }}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          validators={["required"]}
                          errorMessages={["This Field is Required."]}
                        />
                      )}
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Address1</label>
                    <div className="help-texts">
                      {/* first line of the address */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Address"
                      style={{ width: "350px" }}
                      value={address1}
                      onChange={(event) => setAddress1(event.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Address2</label>
                    <div className="help-texts">
                      {/* second line of the address */}
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Address 2"
                      style={{ width: "350px" }}
                      value={address2}
                      onChange={(event) => setAddress2(event.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">City</label>
                    <div className="help-texts">{/* City information */}</div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="City"
                      style={{ width: "350px" }}
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                      autoComplete="new-password"
                    />
                    <div className="client-input-group">
                      <label className="field-heading">State</label>
                      <div className="help-texts">
                        {/* State information */}
                      </div>
                      <TextValidator
                        id="standard-basic"
                        variant="outlined"
                        // placeholder="State"
                        style={{ width: "350px" }}
                        value={state}
                        onChange={(event) => setState(event.target.value)}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="client-input-group">
                      <label className="field-heading">Pin code</label>
                      <div className="help-texts">
                        {/* pincode of the city */}
                      </div>
                      <TextValidator
                        id="standard-basic"
                        variant="outlined"
                        // placeholder="Pin code"
                        style={{ width: "350px" }}
                        value={pincode}
                        onChange={(event) => setPincode(event.target.value)}
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {step === 3 && (
                <React.Fragment>
                  <div className="client-input-group">
                    <label className="field-heading">Pan Card Number</label>
                    <div className="help-text">this is for indian clients</div>
                    {/* <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Pan Card Number"
                      style={{ width: "350px" }}
                      value={pan}
                      onChange={(event) => setPan(event.target.value)}
                      autoComplete="off"
                    /> */}
                    <TextValidator
                      variant="outlined"
                      style={{ width: "350px" }}
                      value={pan}
                      onChange={(event) => setPan(event.target.value)}
                      // required={isPassMandate}
                      validators={["matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$"]}
                      errorMessages={[
                        "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                      ]}
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">GST number</label>
                    <div className="help-text">
                      This field is mandatory for indian clients
                    </div>
                    {/* <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="GST number"
                      style={{ width: "350px" }}
                      value={gst}
                      onChange={(event) => setGst(event.target.value)}
                      autoComplete="off"
                    /> */}
                    <TextValidator
                      variant="outlined"
                      style={{ width: "350px" }}
                      value={gst}
                      onChange={(event) => setGst(event.target.value)}
                      // required={isPassMandate}
                      validators={[
                        "matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                      ]}
                      errorMessages={[
                        "Not in valid format - 1st 2 letters should be in Numbers, Next 5 letters should be in Uppercase , next 4 letters should be in Numbers, Next Letter should be in Uppercase, 13th,15th letter should be in number or in Uppercase , 14th letter Should be capital",
                      ]}
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">Aadhar Card Number</label>
                    <div className="help-text">
                      Aadhar card number of the one of the owner of the company,
                      this is required for indian clients.
                    </div>
                    {/* <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Adhar Card Number" ^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$”
                      style={{ width: "350px" }}
                      value={adhar}
                      onChange={(event) => setAdhar(event.target.value)}
                      autoComplete="off"
                    /> */}
                    <TextValidator
                      variant="outlined"
                      style={{ width: "350px" }}
                      value={adhar}
                      onChange={(event) => setAdhar(event.target.value)}
                      // required={isPassMandate}
                      validators={[
                        "matchRegexp:^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                      ]}
                      errorMessages={[
                        "Only 12 Numerical Values Allowed,No Alphabets or special characters allowed",
                      ]}
                    />
                  </div>
                  <div className="client-input-group">
                    <label className="field-heading">
                      Shop Rent/Ownership Certificate
                    </label>
                    <div className="help-text">
                      please give number of the ownership certificate or shop
                      rent details
                    </div>
                    <TextValidator
                      id="standard-basic"
                      variant="outlined"
                      // placeholder="Shop Rent/Ownership Certificate"
                      style={{ width: "350px" }}
                      value={ownerCert}
                      onChange={(event) => setOwnerCert(event.target.value)}
                      autoComplete="off"
                    // validators={["matchRegexp:/\S/"]}
                    // errorMessages={["No white Spaces allowed"]}
                    />
                  </div>
                </React.Fragment>
              )}
              {/* {step === 4 && (
                <React.Fragment>
                  <section className="container">
                    <label className="field-heading">
                      Upload required documents
                    </label>
                    <div className="help-text" style={{ width: "50%" }}>
                      these documents will be used for verification from account
                      manager, please upload correct documents.
                    </div>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div className="drag-drop-container">
                          <PublishTwoToneIcon style={{ fontSize: "5rem" }} />
                          <p>
                            Drag 'n' drop all files here, or click to select,
                            you can select multiple files at once to upload more
                            files
                          </p>
                        </div>
                      )}
                    </div>
                    <aside>
                      <h4>Uploaded Files</h4>
                      <ul>
                        {filesList &&
                          filesList.map((file) => (
                            <li key={file.path}>
                              {file.path} - {file.size} bytes
                            </li>
                          ))}
                      </ul>
                    </aside>
                  </section>
                </React.Fragment>
              )} */}
              <div style={{ display: "flex", marginTop: "5vh" }}>
                {step > 1 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePreviousSubmit}
                    disabled={isImportBtnDisabled}
                    style={{ marginRight: "10px", textTransform: "none" }}
                  >
                    Previous Step
                  </Button>
                )}
                {step !== 3 && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    // onClick={handleSubmit}
                    style={{ textTransform: "none", width: "200px" }}
                  >
                    Continue (Step {step} of {totalSteps})
                  </Button>
                )}
                {step === 3 && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isImportBtnDisabled}
                    style={{ textTransform: "none", width: "200px" }}
                  >
                    Submit Client Details
                  </Button>
                )}
              </div>
            </ValidatorForm>
            <div style={{ marginTop: "3vh" }}>
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  toLogin();
                }}
              >
                Sign In to an Existing GetFares Account
              </Link>
              {/* <Link href="#" variant="body2" >
                <span onClick={toLogin()}> Sign In to an Existing GetFares Account</span>
              </Link> */}
            </div>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default RegisterClient;
