import React, { useEffect, useState } from "react";
import getPaxTotal from "../../../functions/getPaxTotal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";

import RailSegmentDetails from "./RailSegmentDetails";
import RailPassengerDetails from "./RailPassengerDetails";
import RailFareDetailsClientTableComponent from "./RailFareDetailsClientTableComponent";
import RailFareDetailsSupplierTableComponent from "./RailFareDetailsSupplierTableComponent";

export default function RailImportBooking({ bookingDetails,
    handleRailcabinClassChange, handleRailPNRChange, reissue, selectedProductType,
    handleticketChange, handleDOBFieldChange, handleSnackbarToggle, toggleDrawer,
    clientROE, setClientTotalWithClientROE, setClientTotal, setVendorTotal
    , setVendorTotalWithClientROE, setVendorIataType, setiataType, setPlbType, setvendorPlbType,
    iataType, plbType, setPassengerTemplate, selectedItem, setSelectedItem, action, setAction,
    drawer, setDrawer, clientCurrency, setClientCurrency, showLoader,
    setShowLoader, vendorDetail, setVendorDetail, selectDate, railImportFareArray, setrailImportFareArray,
    CalculateRailTotalFare, OnChangeCalculateRailTotalFare
}) {

    console.log(bookingDetails, "bookingDetails");

    return (
        <div >
            {bookingDetails && bookingDetails?.railPNRDetails !== undefined &&
                bookingDetails?.railPNRDetails?.length > 0 ? (
                <>
                    {bookingDetails?.railPNRDetails?.map((rail, railIndex) => (
                        <>
                            {rail.legGroups !== undefined && rail.legGroups.length > 0 ? (
                                <RailSegmentDetails legGroups={rail.legGroups} bookingDetails={bookingDetails} handleRailcabinClassChange={handleRailcabinClassChange} handleRailPNRChange={handleRailPNRChange} railIndex={railIndex} />
                            ) : (
                                <div></div>
                            )}
                        </>)
                    )}
                    <RailPassengerDetails selectedProductType={selectedProductType} bookingDetails={bookingDetails}
                        reissue={reissue} handleticketChange={handleticketChange}
                        handleDOBFieldChange={handleDOBFieldChange}
                        selectedItem={selectedItem} drawer={drawer} toggleDrawer={toggleDrawer} setPassengerTemplate={setPassengerTemplate} action={action}
                        handleSnackbarToggle={handleSnackbarToggle} selectDate={selectDate}
                        setDrawer={setDrawer} setShowLoader={setShowLoader}
                        showLoader={showLoader} />
                    <RailFareDetailsSupplierTableComponent
                        bookingDetails={bookingDetails}
                        CalculateRailTotalFare={CalculateRailTotalFare}
                        OnChangeCalculateRailTotalFare={
                            OnChangeCalculateRailTotalFare
                        }
                        railImportFareArray={railImportFareArray}
                        currency={
                            vendorDetail && vendorDetail.currencyCode
                                ? vendorDetail.currencyCode
                                : ""
                        }
                    />
                    <RailFareDetailsClientTableComponent
                        bookingDetails={bookingDetails}
                        railImportFareArray={railImportFareArray}
                        currency={
                            clientCurrency ? clientCurrency : bookingDetails?.railPNRDetails[0].currency
                        }
                        ROE={clientROE ? clientROE : 1}
                    />
                </>
            ) : ''}
        </div>
    );
}
