import getDepartArrival from "../data/mock/ArrivalMock";

export default function getArrivalDepartFilters(search) {
  let arrivalDepart = [];
  if (search.type === "OneWay") {
    arrivalDepart.push({
      type: "depart",
      city: search.from.city_code,
      departArrival: getDepartArrival(),
    });
    arrivalDepart.push({
      type: "arrival",
      city: search.to.city_code,
      departArrival: getDepartArrival(),
    });
  } else if (search.type === "RoundTrip") {
    arrivalDepart.push({
      type: "depart",
      city: search.from.city_code,
      departArrival: getDepartArrival(),
    });
    arrivalDepart.push({
      type: "arrival",
      city: search.to.city_code,
      departArrival: getDepartArrival(),
    });
    arrivalDepart.push({
      type: "depart",
      city: search.to.city_code,
      departArrival: getDepartArrival(),
    });
    arrivalDepart.push({
      type: "arrival",
      city: search.from.city_code,
      departArrival: getDepartArrival(),
    });
  } else if (search.type === "Multidestination") {
    arrivalDepart.push({
      type: "depart",
      city: search.from.city_code,
      departArrival: getDepartArrival(),
    });
    arrivalDepart.push({
      type: "arrival",
      city: search.to.city_code,
      departArrival: getDepartArrival(),
    });
    search.destinations.forEach((destination) => {
      arrivalDepart.push({
        type: "depart",
        city: destination.from.city_code,
        departArrival: getDepartArrival(),
      });
      arrivalDepart.push({
        type: "arrival",
        city: destination.to.city_code,
        departArrival: getDepartArrival(),
      });
    });
  }
  return arrivalDepart;
}
