import React, { useState, Component } from "react";
import "./AddSupplierCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";

export default function AddSupplierCard({setPCCCode, setIPcc, setSupplierName, loadPCCs}) {

  return (
    <div className="add-supplier-card-container">
      <div className="add-supplier-card-title">Search Supplier</div>
      <div className="create-supplier-contents-container">
        <div className="create-supplier-contents">
          <div className="add-supplier-if-items-container">
            {/* <div className="add-supplier-if-item margin-right-16">
              <div className="add-supplier-if-container">IF</div>
            </div> */}
            <div className="add-supplier-if-item flex-1-input  margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="PCC Code"
                  size="small"
                  color="secondary"
                  onChange={(event) => setPCCCode(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="IPCC"
                  size="small"
                  color="secondary"
                  onChange={(event) => setIPcc(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label="Supplier Name"
                  size="small"
                  color="secondary"
                  onChange={(event) => setSupplierName(event.target.value)}                  
                />
              </ValidatorForm>
            </div>
          </div>
        </div>
        <div className="add-supplier-button-container">
          <div className="add-supplier-button" onClick={() => loadPCCs()} >Search PCC</div>
        </div>
        {/* <div className="create-supplier-image">
          <img src={Travel} height="100%"></img>
        </div> */}
      </div>
    </div>
  );
}
