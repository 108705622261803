import React, { useState, useEffect } from 'react'
import './PassengerProfile.css'
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import countries from '../../data/Country';
import TextField from "@material-ui/core/TextField";
import { Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import airlineDetails from '../../data/AirlineDetails';
import axios from 'axios';
import dayjs from "dayjs";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

const PassengerDrawer = ({ setShowDrawer, action, setAction, setIsLoading, clientID, getPassengerData, passengerData, selectedPassenger }) => {

    const [formError, setformError] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        firstName: "",
        lastName: "",
        dob: "",
        nationality: "",
        areaCode: "",
        mobile: "",
        email: "",
        telephone: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        passportNumber: "",
        issuingCountry: "",
        placeOfIssue: "",
        dateOfIssue: "",
        expiryDate: "",
        createdBy: "",
        created: "",
        id: ""
    });
    const [flyer, setFlyer] = useState([{}]);

    const titles = ["Mr", "Mrs", "Ms", "Mstr", "Miss"];

    const addFlyerRow = () => {
        let temp = structuredClone(flyer);
        temp.push({
            airline: null,
            frequentFlyerNumber: null
        });
        setFlyer((flyer) => temp);
    };


    const deleteFlyerRow = async (index, flyId) => {

        if (flyId) {
            setIsLoading(true);

            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/PassengerProfile/Flyer/" + flyId
                )
                .then((response) => {
                    console.log("Deleted flyer", response.data);
                    let temp = structuredClone(flyer);
                    temp.splice(index, 1);
                    setFlyer((flyer) => temp);
                    setIsLoading(false);
                    getPassengerData();
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                });
        }
        else {
            let temp = structuredClone(flyer);
            temp.splice(index, 1);
            setFlyer((flyer) => temp);
        }


    };


    const handleFlyerEnter = (index, field, value, id) => {

        let temp = structuredClone(flyer);
        let fly = structuredClone(temp[index]);
        if (field === "airline") {
            fly[field] = structuredClone(value.Name);
        }
        else {
            fly[field] = structuredClone(value);
        }

        if (action === "edit") {
            fly["id"] = id;
            fly["passengerProfileId"] = selectedPassenger[0].passengerProfile.id;
        }

        temp[index] = structuredClone(fly);
        setFlyer((flyer) => structuredClone(temp));
    };



    const handleFormChange = (newValue, field) => {

        if (field == "nationality" || field == "issuingCountry" || field == "country") {
            setFormData({
                ...formData,
                [field]: newValue ? newValue?.code : ""

            });
        }
        else if (field == "areaCode") {
            setFormData({
                ...formData,
                [field]: newValue ? newValue.phone : ""

            });
        }
        else {
            setFormData({
                ...formData,
                [field]: newValue

            });
        }

    };

    useEffect(() => {

        if (action !== "add") {
            console.log("Edit", selectedPassenger);

            setFormData({
                title: selectedPassenger[0].passengerProfile.title,
                firstName: selectedPassenger[0].passengerProfile.firstName,
                lastName: selectedPassenger[0].passengerProfile.lastName,
                dob: selectedPassenger[0].passengerProfile.dob,
                nationality: selectedPassenger[0].passengerProfile.passengerNationality,
                areaCode: selectedPassenger[0].passengerProfile.areaCode,
                mobile: selectedPassenger[0].passengerProfile.mobile,
                email: selectedPassenger[0].passengerProfile.email,
                telephone: selectedPassenger[0].passengerProfile.telePhone,
                country: selectedPassenger[0].passengerProfile.country,
                state: selectedPassenger[0].passengerProfile.state,
                city: selectedPassenger[0].passengerProfile.city,
                pincode: selectedPassenger[0].passengerProfile.pinCode,
                passportNumber: selectedPassenger[0].passengerProfile.passportNumber,
                issuingCountry: selectedPassenger[0].passengerProfile.passportIssuedCountry,
                placeOfIssue: "", //selectedPassenger[0].passengerProfile.placeOfIssue
                dateOfIssue: selectedPassenger[0].passengerProfile.passportDOI,
                expiryDate: selectedPassenger[0].passengerProfile.passportDOE,
                createdBy: selectedPassenger[0].passengerProfile.createdBy,
                created: selectedPassenger[0].passengerProfile.created,
                id: selectedPassenger[0].passengerProfile.id
            });
            setFlyer(selectedPassenger[0].passengerProfile.frequentFlyer);

        }
        else if (action === "add") {
            setFormData({
                title: "",
                firstName: "",
                lastName: "",
                dob: "",
                nationality: "",
                areaCode: "",
                mobile: "",
                email: "",
                telephone: "",
                country: "",
                state: "",
                city: "",
                pincode: "",
                passportNumber: "",
                issuingCountry: "",
                placeOfIssue: "",
                dateOfIssue: "",
                expiryDate: "",
                createdBy: "",
                created: "",
                id: ""
            });
            setFlyer([{}]);

        }
    }, [action])



    async function handleUpdate() {

        setIsLoading(true);
        const headers = {
            "Content-Type": "application/json",
        };
        if (action === "edit" || action === "view") {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/PassengerProfile/" + formData.id,
                    JSON.stringify({
                        id: formData.id,
                        isActive: true,
                        createdBy: formData.createdBy,
                        created: formData.created,
                        clientId: parseInt(clientID),
                        title: formData.title,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        dob: formData.dob,
                        genderType: (formData.title === "Mr" || formData.title === "Mstr") ? "M" : "F",
                        frequentFlyer: flyer,
                        areaCode: formData.areaCode,
                        mobile: formData.mobile,
                        telePhone: formData.telephone,
                        email: formData.email,
                        country: formData.country,
                        state: formData.state,
                        city: formData.city,
                        pinCode: formData.pincode,
                        passportNumber: formData.passportNumber,
                        passengerNationality: formData.nationality,
                        passportDOI: formData.dateOfIssue === "" ? null : formData.dateOfIssue,
                        passportDOE: formData.expiryDate === "" ? null : formData.expiryDate,
                        passportIssuedCountry: formData.issuingCountry

                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    setShowDrawer(false);
                    setIsLoading(false);
                    getPassengerData();
                })
                .catch((err) => {
                    console.log(err);

                    setIsLoading(false);
                });
        } else {

            setIsLoading(true);
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/PassengerProfile",
                    JSON.stringify({
                        isActive: true,
                        clientId: parseInt(clientID),
                        title: formData.title,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        dob: formData.dob,
                        genderType: (formData.title === "Mr" || formData.title === "Mstr") ? "M" : "F",
                        frequentFlyer: flyer,
                        areaCode: formData.areaCode.phone,
                        mobile: formData.mobile,
                        telePhone: formData.telephone,
                        email: formData.email,
                        country: formData.country,
                        state: formData.state,
                        city: formData.city,
                        pinCode: formData.pincode,
                        passportNumber: formData.passportNumber,
                        passengerNationality: formData.nationality,
                        passportDOI: formData.dateOfIssue === "" ? null : formData.dateOfIssue,
                        passportDOE: formData.expiryDate === "" ? null : formData.expiryDate,
                        passportIssuedCountry: formData.country
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    console.log(res);
                    setIsLoading(false);
                    getPassengerData();
                    setShowDrawer(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    }


    return (

        <div className="ticket-drawer-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={() => setShowDrawer(false)}
                ></CloseIcon>
            </div>
            {action !== "view" &&
                <>
                    <div className="vendor-heading-container vendor-title-flex-container div-bottom-border pb-3">
                        <div>Add New Profile</div>
                    </div>
                    <ValidatorForm
                        onSubmit={() => handleUpdate()}
                        onError={(errors) => console.log(errors)}
                    >

                        <div className="passenger-drawer-form">
                            <div className='vendor-title-flex-container'>Traveller Details</div>
                            <div className="vendor-view-edit-drawer-input-container div-bottom-border pb-5">


                                <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                                    <Autocomplete
                                        id="timezone-select-demo"
                                        value={formData.title}
                                        onChange={(event, newValue) => handleFormChange(newValue, "title")}
                                        required
                                        options={titles}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                label="Title"
                                                value={formData.title}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                                validators={action == "add" ? ["required"] : []}
                                                errorMessages={action == "add" ? ["This field is required"] : []}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="First Name"
                                        size="small"
                                        required
                                        color="primary"
                                        value={formData.firstName}
                                        onChange={(e) => handleFormChange(e.target.value, "firstName")}

                                        validators={['matchRegexp:^[a-zA-Z ]+$']}
                                        errorMessages={["Only Alphabets are allowed"]} />
                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Last Name"
                                        size="small"
                                        color="primary"
                                        value={formData.lastName}
                                        onChange={(e) => handleFormChange(e.target.value, "lastName")}

                                        validators={['matchRegexp:^[a-zA-Z ]+$']}
                                        errorMessages={["Only Alphabets are allowed"]}
                                    />
                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="bg-light me-1 rounded"
                                            // value={formData.dob}
                                            required
                                            value={formData.dob ? dayjs(formData.dob) : dayjs()}
                                            onChange={(newValue) => {
                                                handleFormChange(newValue.$d ? newValue.$d : null, "dob");
                                            }}
                                            disableFuture
                                            format="DD/MM/YYYY"
                                            label="Date Of Birth"
                                        />

                                    </LocalizationProvider>
                                </div>
                                <div className="vendor-view-edit-drawer-input  config-input-flex-1">
                                    <Autocomplete
                                        id="nationality-select"
                                        value={countries.find(v => v.code === formData.nationality) || null}
                                        onChange={(event, newValue) => handleFormChange(newValue, "nationality")}
                                        options={countries.filter(item => item.code !== 'All')}
                                        getOptionLabel={(option) => option.label + " (" + option.code + ")"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Nationality"
                                                variant="outlined"
                                                required
                                                color="primary"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>


                            </div>

                            <div className="vendor-title-flex-container mt-4">Traveller Contact Details</div>
                            <div className="vendor-view-edit-drawer-input-container">

                                <div className="vendor-view-edit-drawer-input  config-input-flex-1 me-3">
                                    <div className='d-flex'>
                                        <Autocomplete
                                            width="auto"
                                            id="area-code-select"
                                            options={countries.filter((item) => item.code !== "All")}
                                            getOptionLabel={(option) => option ? "+" + option.phone : ""}
                                            value={countries.find((v) => v.phone === formData?.areaCode) || null}
                                            onChange={(event, newValue) => handleFormChange(newValue, "areaCode")}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Code"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />


                                        <TextValidator
                                            variant="outlined"
                                            label="Mobile Number"
                                            size="small"
                                            color="primary"
                                            value={formData.mobile}
                                            onChange={(e) => handleFormChange(e.target.value, "mobile")}

                                            validators={['matchRegexp:^[0-9 ]+$']}
                                            errorMessages={["Only numbers are allowed"]}
                                        />
                                    </div>

                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <ValidatorForm>
                                        <TextValidator
                                            variant="outlined"
                                            label="Email"
                                            size="small"
                                            required
                                            color="primary"
                                            name="email"
                                            value={formData.email}
                                            onChange={(e) => handleFormChange(e.target.value, "email")}
                                            validators={['required', 'isEmail']}
                                            errorMessages={["This field is required", "Email is not valid"]}
                                        />
                                    </ValidatorForm>

                                </div>
                                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Telephone"
                                        size="small"
                                        color="primary"
                                        value={formData.telephone}
                                        onChange={(e) => handleFormChange(e.target.value, "telephone")}

                                        validators={['matchRegexp:^[0-9 ]+$']}
                                        errorMessages={["Only numbers are allowed"]}
                                    />

                                </div>

                            </div>
                            <div className="vendor-view-edit-drawer-input-container div-bottom-border pb-5">

                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="nationality-select"
                                        value={countries.find(v => v.code === formData.country) || null}
                                        onChange={(event, newValue) => handleFormChange(newValue, "country")}
                                        options={countries.filter(item => item.code !== 'All')}
                                        getOptionLabel={(option) => option.label + " (" + option.code + ")"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                variant="outlined"
                                                required
                                                color="primary"
                                                size="small"
                                            />
                                        )}
                                    />

                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="State"
                                        size="small"
                                        color="primary"
                                        value={formData.state}
                                        onChange={(e) => handleFormChange(e.target.value, "state")}
                                        validators={['required']}
                                        errorMessages={["This field is required"]}
                                    />
                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="City"
                                        size="small"
                                        color="primary"
                                        value={formData.city}
                                        onChange={(e) => handleFormChange(e.target.value, "city")}
                                        validators={['required']}
                                        errorMessages={["This field is required"]}
                                    />
                                </div>
                                <div className="vendor-view-edit-drawer-input  config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Pincode"
                                        size="small"
                                        color="primary"
                                        value={formData.pincode}
                                        onChange={(e) => handleFormChange(e.target.value, "pincode")}
                                        validators={['required']}
                                        errorMessages={["This field is required"]}
                                    />
                                </div>

                            </div>

                            <div className='vendor-title-flex-container mt-4'>Passport Details</div>
                            <div className="vendor-view-edit-drawer-input-container div-bottom-border pb-5">

                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Passport Number"
                                        size="small"
                                        color="primary"
                                        value={formData.passportNumber}
                                        onChange={(e) => handleFormChange(e.target.value, "passportNumber")}

                                        validators={["matchRegexp:^[a-zA-Z0-9]+$"]}
                                        errorMessages={["No special characters allowed"]}

                                    />
                                </div>
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <Autocomplete
                                        id="nationality-select"
                                        value={countries.find(v => v.code === formData.issuingCountry) || null}
                                        onChange={(event, newValue) => handleFormChange(newValue, "issuingCountry")}
                                        options={countries.filter(item => item.code !== 'All')}
                                        getOptionLabel={(option) => option.label + " (" + option.code + ")"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Issuing Country"
                                                variant="outlined"
                                                required
                                                color="primary"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                {/* <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <TextValidator
                                        variant="outlined"
                                        label="Place Of Issue"
                                        size="small"
                                        color="primary"
                                        value={formData.placeOfIssue}
                                        onChange={(e) => handleFormChange(e.target.value, "placeOfIssue")}

                                        validators={['matchRegexp:^[a-zA-Z ]+$']}
                                        errorMessages={["Only Alphabets are allowed"]} />
                                </div>
                                 */}
                                <div className="vendor-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="bg-light me-1 rounded"
                                            value={formData.dateOfIssue ? dayjs(formData.dateOfIssue) : dayjs()}
                                            onChange={(newValue) => {
                                                handleFormChange(newValue.$d ? newValue.$d : null, "dateOfIssue");
                                            }}

                                            format="DD/MM/YYYY"
                                            label="Date Of Issue"
                                        />

                                    </LocalizationProvider>
                                </div>
                                <div className="vendor-view-edit-drawer-input config-input-flex-1">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="bg-light me-1 rounded"
                                            value={formData.expiryDate ? dayjs(formData.expiryDate) : dayjs()}
                                            onChange={(newValue) => {
                                                handleFormChange(newValue.$d ? newValue.$d : null, "expiryDate");
                                            }}

                                            format="DD/MM/YYYY"
                                            label="Expiry Date"
                                        />

                                    </LocalizationProvider>
                                </div>


                            </div>

                            <div className='vendor-title-flex-container d-flex align-items-center mt-4'>
                                <div> Frequent Flyer</div>
                                <div className="" style={{ marginLeft: '20rem' }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="addButton"
                                        onClick={() => {
                                            addFlyerRow();
                                        }}
                                    >
                                        + Add Flyer
                                    </Button>
                                </div>
                            </div>
                            <div className="vendor-view-edit-drawer-input-container div-bottom-border pb-5">

                                <div>
                                    <div>
                                        {flyer &&
                                            flyer.length > 0 &&
                                            flyer.map((fly, index) => (
                                                <div key={index} className="d-flex align-items-center mb-3">
                                                    <div className="me-3 w-100" style={{ minWidth: "15rem" }}>
                                                        <Autocomplete
                                                            id={`timezone-select-demo-${index}`}
                                                            value={airlineDetails.find((v) => v.Name === fly.airline) || null}
                                                            onChange={(event, newValue) =>
                                                                handleFlyerEnter(index, "airline", newValue, fly.id)
                                                            }
                                                            options={airlineDetails}
                                                            getOptionLabel={(option) =>
                                                                option.Code === "All"
                                                                    ? "All"
                                                                    : option.Name + " (" + option.Code + ")"
                                                            }
                                                            renderInput={(params) => (
                                                                <TextValidator
                                                                    {...params}
                                                                    label="Airline Name"
                                                                    variant="outlined"

                                                                    color="secondary"
                                                                    size="small"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "new-password",
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="w-100" style={{ minWidth: "15rem" }}>
                                                        <TextValidator
                                                            variant="outlined"
                                                            label="Frequent Flyer"
                                                            size="small"
                                                            color="secondary"
                                                            value={fly.frequentFlyerNumber}
                                                            onChange={(event) =>
                                                                handleFlyerEnter(
                                                                    index,
                                                                    "frequentFlyerNumber",
                                                                    event.target.value, fly.id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <IconButton
                                                            color="error"
                                                            className="deleteIcon"
                                                            onClick={() => {
                                                                deleteFlyerRow(index, fly.id);
                                                            }}
                                                            aria-label="delete"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>

                                </div>
                            </div>



                        </div>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                type="submit"
                                style={{
                                    textTransform: "none",
                                    marginLeft: "25px",
                                    marginRight: "25px",
                                    marginBottom: "25px",
                                    width: "500px",
                                }}
                            // onClick={() => handleUpdate()}
                            >
                                {action === "edit" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </ValidatorForm >
                </>
            }
            {action === "view" &&
                <div className='border border-1 m-2'>
                    <div className="vendor-heading-container vendor-title-flex-container div-bottom-border pb-3">
                        <div>View Passenger</div>
                    </div>
                    <ValidatorForm
                        // onSubmit={handleUpdate}
                        onError={(errors) => console.log(errors)}
                    >

                        <div className="passenger-drawer-form">
                            <div className='vendor-title-flex-container profile-div-bottom-border fs-5'>{formData.title + '. ' + formData.firstName + " " + formData.lastName}</div>
                            <div className="vendor-view-edit-drawer-input-container profile-div-bottom-border pb-5">
                                <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                                    <div className='pb-2' style={{ fontSize: "1rem", fontWeight: "500", color: "grey" }}>Traveller Details</div>
                                    <div className='d-flex align-items-center'>

                                        <div className='' style={{ width: "12.5rem" }}>

                                            <div ><AccountCircleRoundedIcon className='fs-1' style={{ width: "100px", color: "grey" }} /></div>
                                        </div>
                                        <div className='' style={{ width: "20rem" }}>
                                            <div style={{ fontWeight: "500" }}>{formData.title + '. ' + formData.firstName + " " + formData.lastName}</div>
                                            <div style={{ fontSize: "13px" }}><CallIcon className='fs-5 me-1' /><span className='view-configuration-drawer-title'>{formData.mobile}</span></div>
                                            <div style={{ fontSize: "13px" }}><EmailIcon className='fs-5 me-1' /><span className='view-configuration-drawer-title'>{formData.email}</span></div>
                                        </div>
                                        <div onClick={() => setAction("edit")} className='d-flex align-items-center' style={{ cursor: "pointer" }}>
                                            <div><EditOutlinedIcon style={{ color: "grey" }} /></div>
                                            <div className='ms-2 view-configuration-drawer-description' style={{ textDecoration: "underline" }}>Edit Profile</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="view-configuration-drawer margin-right-16 config-input-flex-1 profile-div-bottom-border pb-5">
                                <div className="vendor-view-edit-drawer-input margin-right-16  config-input-flex-1">
                                    <div className='pt-3 pb-2' style={{ fontSize: "1rem", fontWeight: "500", color: "grey" }}>Passport Details</div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className=''>
                                            <div className='view-configuration-drawer-title passenger-font-size-500'>Passport Number</div>
                                            <div className='text-center view-configuration-drawer-description'>{formData.passportNumber}</div>
                                        </div>
                                        <div className=''>
                                            <div className='view-configuration-drawer-title passenger-font-size-500'>Issuing Country</div>
                                            <div className='text-center view-configuration-drawer-description'>{formData.issuingCountry}</div>
                                        </div>
                                        {/* <div className='' style={{ width: "20rem" }}>
                                            <div>Place Of Issue</div>
                                            <div>{formData.placeOfIssue}</div>
                                        </div> */}
                                        <div className=''>
                                            <div className=' view-configuration-drawer-title passenger-font-size-500'>Date Of Issue</div>
                                            <div className='text-center view-configuration-drawer-description'>{moment(formData.dateOfIssue).format("DD/MM/YYYY")}</div>
                                        </div>
                                        <div className=''>
                                            <div className='view-configuration-drawer-title passenger-font-size-500'>Date Of Expiry</div>
                                            <div className='text-center view-configuration-drawer-description'>{moment(formData.expiryDate).format("DD/MM/YYYY")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='vendor-title-flex-container d-flex align-items-center mt-4'>
                                <div style={{ fontSize: "1rem", fontWeight: "500", color: "grey" }}> Frequent Flyer</div>
                            </div>
                            <div className="vendor-view-edit-drawer-input-container div-bottom-border pb-5">
                                <div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="view-configuration-drawer-title" style={{ minWidth: "15rem" }}>
                                            <strong>Airline</strong>

                                        </div>
                                        <div className="view-configuration-drawer-title" style={{ minWidth: "15rem" }}>
                                            <strong>Flyer Number</strong>

                                        </div>

                                        <div></div>
                                    </div>


                                    {flyer &&
                                        flyer.length > 0 &&
                                        flyer.map((fly, index) => (
                                            <div key={index} className="d-flex align-items-center mb-3">
                                                <div className="w-100  view-configuration-drawer-description" style={{ minWidth: "15rem" }}>
                                                    <div>{fly.airline}</div>
                                                </div>
                                                <div className="w-100  view-configuration-drawer-description" style={{ minWidth: "15rem" }}>
                                                    <div>{fly.frequentFlyerNumber}</div>
                                                </div>

                                                {action !== "view" && (<div>
                                                    <IconButton
                                                        color="error"
                                                        className="deleteIcon"
                                                        onClick={() => {
                                                            deleteFlyerRow(index, fly.id);
                                                        }}
                                                        aria-label="delete"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>)}
                                            </div>
                                        ))}
                                </div>
                            </div>




                        </div>
                        <div>

                        </div>
                    </ValidatorForm >
                </div>
            }
            {
                formError ?
                    <div style={{ width: '100%', textAlign: 'center' }
                    } >
                        <span style={{ fontSize: '16px', color: 'red', paddingLeft: '10px' }}>*Please fill all required fields</span>
                    </div >
                    : ""
            }
        </div >
    )
}

export default PassengerDrawer