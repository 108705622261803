import React, { Fragment, useState, useEffect, useRef } from "react";
import Container from "@material-ui/core/Container";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { formatDate } from "../../services/dateFormatter";
import SearchIcon from "@mui/icons-material/Search";
import RiseLoading from "../Loader/RiseLoader";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ClientBalanceReport() {
  const [DateFrom, setDateFrom] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const csvLink = useRef();
  const [exportresults, setExportResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let myqueueExportExcel = () => {
    setIsLoading(true);
    const filters = [
      {
        field: "DateTo",
        value: moment(DateFrom).format("YYYY-MM-DD") + "T23:59:00.000Z",
        displayvalue: DateFrom,
      },
    ];
    const body = { q: "", top: 5000, skip: 0, filters: filters };
    const headers = { "Content-Type": "application/json" };
    axios
      .post(
        process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue/GetClientBalance",
        body,
        { headers }
      )
      .then((response) => {
        var resultlist = [];
        if (
          response &&
          response.data &&
          response.data.length > 0
        ) {
          response.data.forEach((item, index) => {
            let SalesData = {
              Date: moment(item.createdOnDate).format("MM-DD-YYYY HH:mm"),
              "Client Name": item.clientName,
              "Client Id": item.clientId,
              "Iboss Country": item.clientIboss,
              "Payment Type": item.paymentType,
              Currency: item.currency,
              "Closing Balance": item.closingBalance,
            };
            resultlist.push(SalesData);
          });
          setExportResults(resultlist);
          setTimeout(() => {
            setIsLoading(false);
            csvLink.current.link.click();
            setIsLoading((isLoading) => false);
          }, 3000);
        } else {
          setErrorMessage("No Transactions Found...");
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <Container className="ClientBalanceReport">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="trans-head-container">
        <div className="trans-heading">Client Balance Report</div>
      </div>
      <div className="trans-client-container">
        <div className="trans-client-container-label">Date</div>
        <div className="trans-client-container-item2">
          <React.Fragment>
            <KeyboardDatePicker
              id="DateFrom"
              autoOk
              margin="normal"
              maxDate={new Date()}
              variant="inline"
              format="dd/MM/yyyy"
              size="small"
              value={DateFrom !== undefined ? formatDate(DateFrom) : null}
              inputValue={DateFrom !== undefined ? formatDate(DateFrom) : null}
              InputAdornmentProps={{
                position: "start",
                color: "primary",
              }}
              onChange={(date) => {
                setDateFrom(date ? date : null);
              }}
              required
            />
          </React.Fragment>
        </div>

        <button
          className="trans-client-container-search-button"
          onClick={() => myqueueExportExcel()}
          disabled={!DateFrom}
        >
          <p style={{ margin: 0 }}>
            <SearchIcon />
            Export Data
          </p>
        </button>
        <CSVLink
          className="hidden"
          ref={csvLink}
          target="_blank"
          data={exportresults}
          filename="ClientBalanceReport"
        />
        {isLoading && <RiseLoading excelExport={isLoading} />}
      </div>
    </Container>
  );
}
