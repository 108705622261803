import React, { } from "react";
import moment from "moment";
import AddPassengerDrawer from "../addPassengerDrawer";
import Drawer from "@material-ui/core/Drawer";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function FlightPassengerDetails({ selectedProductType, passengers, reissue, handleticketChange, flightIndex,
    selectedItem, setPassengerTemplate,
    baggages, flightTicketNumber
}) {

    return (
        <>
            <div className="itenary-container-import booking-padding-top-40">
                <div>Passenger Details</div>
                {/* <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{
                              textTransform: "none",
                            }}
                            // onClick={() => addPassenger(flightIndex)}
                            onClick={async () => {
                              await setAction("add");
                              await openAddDrawer(
                                flightIndex,
                                passengers.length
                              );
                            }}
                          >
                            Add
                          </Button> */}
            </div>
            <div className="importBooking passenger-itenary-details itenary-passenger-details-baggage">
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="itenary-passenger-table"
                    >
                        <TableRow className="itenary-passenger-table-header">
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-initial "
                            >
                                Passenger(s)
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Pax
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Gender
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Date of Birth
                            </TableCell>
                            <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                            >
                                Ticket(s)
                            </TableCell>

                            {/* <TableCell
                                align="left"
                                className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-final"
                              ></TableCell> */}
                        </TableRow>
                        {passengers !== undefined &&
                            passengers.length > 0 ? (
                            passengers
                                .sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                        return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .map((passenger, passengerIndex) => (
                                    <TableRow className="itenary-passenger-table-item">
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                            {passenger.title} {passenger.firstName}{" "}
                                            {passenger.lastName}
                                        </TableCell>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                            {passenger.paxType}
                                        </TableCell>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                            {passenger.genderType}
                                        </TableCell>
                                        <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                            {moment(passenger.dob).format(
                                                "Do MMMM YYYY"
                                            )}
                                        </TableCell>

                                        {reissue ? (
                                            <>
                                                <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                    <TextValidator
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        value={flightTicketNumber[`${flightIndex}-${passengerIndex}`]}
                                                        onChange={(event) =>
                                                            handleticketChange(
                                                                event.target.value,

                                                                flightIndex,
                                                                passengerIndex
                                                            )
                                                        }
                                                        validators={["required", 'matchRegexp:^[0-9A-Za-z/]*$']}
                                                        errorMessages={["This field is required", "Only alphanumeric characters and '/' are allowed"]}
                                                    />{" "}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                                {passenger.ticketNumber}
                                            </TableCell>
                                        )}

                                        {/* <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                      <IconButton
                                        color="primary"
                                        className="editIcon"
                                        onClick={async () => {
                                          await setAction("edit");
                                          await openEditDrawer(
                                            flightIndex,
                                            passengerIndex
                                          );
                                        }}
                                        aria-label="edit"
                                      >
                                        <Edit />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        className="deleteIcon"
                                        onClick={async () => {
                                          await setAction("delete");
                                          await deletePassenger(
                                            flightIndex,
                                            passengerIndex
                                          );
                                        }}
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell> */}
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow></TableRow>
                        )}
                    </Table>
                </TableContainer>
                {/* <Drawer
                    anchor="right"
                    open={drawer}
                    onClose={toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    variant="persistent"
                    className="drawer-width"
                    transitionDuration={{ enter: 500, exit: 500 }}
                >
                    <div className="drawer-min-width-50">
                        <AddPassengerDrawer
                            selectedItem={selectedItem}
                            toggleDrawer={toggleDrawer}
                            setPassengerTemplate={setPassengerTemplate}
                            // action={action}
                            handleSnackbarToggle={handleSnackbarToggle}
                            selectDate={selectDate}
                            setDrawer={setDrawer}
                            setShowLoader={setShowLoader}
                            showLoader={showLoader}
                            productType={selectedProductType}
                        ></AddPassengerDrawer>
                    </div>
                </Drawer> */}
            </div>
            {selectedProductType === 'Flight' && baggages && baggages.length > 0 ? (
                <div className="itenary-container-import booking-padding-top-40">
                    <div>Baggage</div>
                </div>
            ) : (
                ""
            )}
            {selectedProductType === 'Flight' && baggages && baggages.length > 0 ? (
                <div className="itenary-passenger-details-baggage">
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Passenger Type</TableCell>
                                    <TableCell align="left">
                                        Passenger Name
                                    </TableCell>
                                    <TableCell align="left">
                                        CheckIn Bag
                                    </TableCell>
                                    <TableCell align="left">
                                        Cabin Bag
                                    </TableCell>
                                    <TableCell align="left">
                                        City Pair
                                    </TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {baggages
                                    // .filter((item) => item.amount !== 0)
                                    .map((row) => (
                                        <TableRow
                                            key={row.paxName}
                                            sx={{
                                                "&:last-child TableCell, &:last-child TableCell":
                                                {
                                                    border: 0,
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="TableCell"
                                                scope="row"
                                            >
                                                {row.paxType}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.paxName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.checkInBag
                                                    ? row.checkInBag
                                                    : "NIL"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.cabinBag ? row.cabinBag : "NIL"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.cityPair ? row.cityPair : "NIL"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.amount && row.amount > 0
                                                    ? row.amount
                                                    : row.amount === 0
                                                        ? 0
                                                        : "NIL"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <div></div>
            )}
        </>
    )
}