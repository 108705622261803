import StationDetails from "../data/StationDetails";
export default function findStationByCode(code) {
  if (code !== undefined) {
    const element = StationDetails.find(
      (element) => element.stationCode === code
    );
    return element && element?.stationName;
  } else {
    return "";
  }
}
