import React, { useEffect, useState } from "react";
import "./SearchCategoryDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../../data/TimeZones";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { isUserHasPermission } from "../../../../services/AuthService";
import RiseLoading from "../../../Loader/RiseLoader";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SearchCategoryDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
}) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [id, setId] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setName(selectedItem.name);
      setDescription(selectedItem.description);
      setIsActive(selectedItem.isActive);
      setId(selectedItem.id);
    } else {
      setName("");
      setDescription("");
      setIsActive(true);
      setId(0);
    }
  }, [toggleDrawer, action]);

  async function handleUpdate() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/SearchCategory/" +
            selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,
            name: name === undefined ? "" : name,
            description: description === undefined ? "" : description,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/SearchCategory",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            name: name === undefined ? "" : name,
            description: description === undefined ? "" : description,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="search-heading-container search-title-flex-container">
        <div>Search Category</div>
        {action === "view" && (
          <div className="search-heading-icons">
            <IconButton fontSize="small">
              <EditIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditIcon>
            </IconButton>
          </div>
        )}
      </div>
      {action === "view" && (
        <div className="configuration-heading-container configuration-title-flex-container">
          <Button
            size="small"
            color="secondary"
            variant="contained"
            style={{
              textTransform: "none",
              marginLeft: "auto",
            }}
            component={Link}
            to={"/SearchCategoryConfiguration/" + id + "/" + name}
          >
            Manage Configuration
          </Button>
        </div>
      )}
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="search-view-edit-drawer-container">
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Group Name"
                  size="small"
                  color="secondary"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                  required
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Description"
                  size="small"
                  color="secondary"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                  required
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Group Name</div>
                <div className="view-search-drawer-description ">{name}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Description</div>
                <div className="view-search-drawer-description">
                  {description}
                </div>
              </div>
            )}
          </div>

          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Status
                  </InputLabel>
                  <NativeSelect
                    variant="outlined"
                    label="Status"
                    size="small"
                    color="secondary"
                    value={isActive}
                    onChange={(event) => setIsActive(event.target.value)}
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </NativeSelect>
                </FormControl>
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Status</div>
                <div className="view-search-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1"></div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <RiseLoading Search={showLoader} />}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
