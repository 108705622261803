import AirportDetails from "../data/AirportDetails";
export default function findAirportNameByCode(code) {
    if(code !== undefined)
    {
        const element = AirportDetails.find((element) => element.city_code === code);
        return element && element?.airport_name;
    }
    else{
        return "";
    }
}
