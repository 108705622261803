import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./AgentMarkup.css";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import airlineDetails from "../../../data/AirlineDetails";
import axios from "axios";
import RiseLoading from "../../Loader/RiseLoader";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@mui/material/InputAdornment";

export default function AgentMarkup() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRadio, setSelectedRadio] = React.useState("all");
    const [allAirlineMarkup, setAllAirlineMarkup] = React.useState({
        markupvalue: '',
        markupvaluetype: "bfperc",
    });

    const [savedEachAirlineMarkup, setSavedEachAirlineMarkup] = React.useState(
        []
    );
    const [saveAll, setSaveAll] = React.useState(false);
    const [editingIndex, setEditingIndex] = React.useState("-1");
    const [alleditingIndex, setAlleditingIndex] = React.useState(false);

    const [clientID, setClientID] = useState(0);
    const [markupID, setMarkupID] = useState(0);
    const [eachMarkupID, setEachMarkupID] = useState(0);

    const [eachMarkupValueType, setEachMarkupValueType] = useState(0);
    const [eachMarkupValue, setEachMarkupValue] = useState('');
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        setClientID(user["clientref_id"]);
    }, []);

    useEffect(() => {
        if (clientID > 0) {
            getMarkupData();
        }
    }, [clientID]);

    const handleChange = (event) => {
        setSelectedRadio(event.target.value);
    };

    const handleAllAirlineMarkupChange = (field, newValue) => {
        debugger;
        if (field === "markupvalue" && newValue !== "") {
            let regex = /^\d+(\.\d+)?$/
            if (regex.test(newValue)) {
                setAllAirlineMarkup((prevMarkup) => ({
                    ...prevMarkup,
                    [field]: newValue,
                }));
            }
        } else {
            setAllAirlineMarkup((prevMarkup) => ({
                ...prevMarkup,
                [field]: newValue,
            }));

        }


    };

    const handleEachMarkupValue = (newValue) => {
        debugger;
        if (newValue !== "") {
            let regex = /^\d+(\.\d+)?$/
            if (regex.test(newValue)) {
                setEachMarkupValue(newValue);
            }
        }
        else {
            setEachMarkupValue(newValue);
        }

    };

    const saveAllAirlineMarkup = async () => {
        debugger;
        if (allAirlineMarkup.markupvalue !== "") {
            try {
                let response = await saveMarkup(0, "All");
                if (response.status === 200 || response.status === 204) {
                    getMarkupData();
                    setSaveAll(true);
                    setAlleditingIndex(true);
                } else {
                    setSaveAll(false);
                }
            } catch (error) {
                console.error("Error", error);
                setSaveAll(false);
            }
        } else {
            alert("Please enter a markup value before saving.");
        }
    };

    const saveEachAirlineMarkup = async (index, flcode) => {
        debugger;
        try {
            let response = await saveMarkup(index, flcode);
            if (response.status === 200 || response.status === 204) {
                debugger;
                getMarkupData();
                setEditingIndex("-1");
                if (eachMarkupID === 0) {
                    setEachMarkupID(response.data.id);
                }
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    useEffect(() => {
        console.log("savedEachAirlineMarkup", savedEachAirlineMarkup);
    }, [savedEachAirlineMarkup]);

    const saveMarkup = async (index, MarkupType) => {
        const headers = {
            "Content-Type": "application/json",
        };
        const payload = {
            ClientId: parseFloat(clientID),
            Airline: MarkupType,
            MarkupValueType:
                MarkupType === "All"
                    ? allAirlineMarkup.markupvaluetype === "bfperc"
                        ? 0
                        : 1
                    : eachMarkupValueType === "bfperc"
                        ? 0
                        : 1,
            MarkupValue:
                MarkupType === "All"
                    ? parseFloat(allAirlineMarkup.markupvalue)
                    : parseFloat(eachMarkupValue),
            isActive: true,
        };

        if (MarkupType === "All") {
            const endpoint = markupID === 0 ? "" : `/${markupID}`;
            const method = markupID === 0 ? "post" : "put";

            try {
                const response = await axios[method](
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/ClientMarkup" +
                    endpoint,
                    {
                        ...payload,
                        Id: markupID,
                        createdBy: "a2dac2ad-4f28-4c98-aa5e-8e61c2fd9c80",
                        created: "2024-03-19T12:05:51.8867629Z",
                    },
                    {
                        headers: headers,
                    }
                );

                if (response.status === 200) {
                    setMarkupID(response.data.id);
                }
                return response;
            } catch (error) {
                console.error("Error", error);
                return error;
            }
        } else {
            const markupIdToUpdate = eachMarkupID !== 0 ? `/${eachMarkupID}` : "";
            const methodToUpdate = eachMarkupID !== 0 ? "put" : "post";

            try {
                const response = await axios[methodToUpdate](
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/ClientMarkup" +
                    markupIdToUpdate,
                    {
                        ...payload,
                        Id: eachMarkupID,
                        createdBy: "a2dac2ad-4f28-4c98-aa5e-8e61c2fd9c80",
                        created: "2024-03-19T12:05:51.8867629Z",
                    },
                    {
                        headers: headers,
                    }
                );

                if (response.status === 200) {
                    setEachMarkupID(response.data.id);
                }
                return response;
            } catch (error) {
                console.error("Error", error);
                return error;
            }
        }
    };

    const getMarkupData = async () => {
        setIsLoading(true);
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/ClientMarkup?filters=clientId==" +
                clientID
            )
            .then((response) => {
                console.log("getmarkup", response.data);

                const allAirlineMarkupData = response.data.find(
                    (item) => item.clientMarkup.airline === "All"
                );

                const eachAirlineMarkupData = response.data.filter(
                    (item) => item.clientMarkup.airline !== "All"
                );

                if (allAirlineMarkupData) {
                    setMarkupID(allAirlineMarkupData.clientMarkup.id);
                    setAllAirlineMarkup({
                        markupvalue: allAirlineMarkupData.clientMarkup.markupValue,
                        markupvaluetype:
                            allAirlineMarkupData.clientMarkup.markupValueType === "Percentage"
                                ? "bfperc"
                                : "bfvalue",
                    });
                }

                const updatedSavedEachAirlineMarkup = {};
                eachAirlineMarkupData.forEach((item) => {
                    updatedSavedEachAirlineMarkup[item.clientMarkup.airline] = {
                        markupvalue: item.clientMarkup.markupValue,
                        markupvaluetype:
                            item.clientMarkup.markupValueType === "Percentage"
                                ? "bfperc"
                                : "bfvalue",
                        markupid: item.clientMarkup.id,
                    };
                });
                setSavedEachAirlineMarkup(updatedSavedEachAirlineMarkup);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleEditClick = (flightCode) => {
        if (flightCode !== "All") {
            setEditingIndex(flightCode);

            const selectedAirlineMarkup = savedEachAirlineMarkup[flightCode];

            if (selectedAirlineMarkup) {
                setEachMarkupValueType(
                    selectedAirlineMarkup?.markupvaluetype || "bfperc"
                );
                setEachMarkupValue(selectedAirlineMarkup?.markupvalue || '');
                setEachMarkupID(selectedAirlineMarkup?.markupid || 0);
            } else {
                setEachMarkupValueType(allAirlineMarkup.markupvaluetype || "bfperc");
                setEachMarkupValue(allAirlineMarkup.markupvalue || '');
                setEachMarkupID(0);
            }
        } else {
            setAlleditingIndex(false);
        }
    };

    const images = require.context("../../../airline_logo", true);

    const AirlineLogo = (details) => {
        let imgSrc;
        try {
            imgSrc = images(`./${details?.val}.webp`);
        } catch (err) {
            console.log(err);
        }

        return (
            <>
                {imgSrc !== undefined ? (
                    <img className="br-4" height={24} width={24} src={imgSrc} />
                ) : (
                    ""
                )}
            </>
        );
    };

    useEffect(() => {
        if (markupID != 0) {
            setSaveAll(true);
            setAlleditingIndex(true);
        }
    }, [markupID]);

    return (
        <>
            <div
                className="m-4 d-flex"
                style={{ borderBottom: "2px solid rgb(61 60 60 / 12%)" }}
            >
                <BadgeOutlinedIcon />
                <div className="ms-2" style={{ fontSize: "20px", fontWeight: "600" }}>
                    Mark-Up
                </div>
            </div>

            <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="d-flex align-items-center"
            >
                <div className="m-3">
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={selectedRadio}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All Airline"
                            />
                            <FormControlLabel
                                value="each"
                                control={<Radio />}
                                label="Each Airline"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div>
                    {selectedRadio == "each" && (
                        <>
                            <TextField
                                className="bg-light rounded ms-5"
                                variant="outlined"
                                placeholder="Search for an Airline or Airline Code"
                                sx={{ minWidth: "400px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </>
                    )}
                </div>
            </Box>

            {selectedRadio === "all" && (
                <Grid
                    className="align-items-center"
                    container
                    spacing={3}
                    style={{
                        backgroundColor: "#93939330",
                        marginTop: "0px",
                        paddingBottom: "10px",
                    }}
                >
                    <Grid item xs>
                        <div className="ms-3">All Airline</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="d-flex flex-column">
                            <div>Booking Markup</div>

                            {alleditingIndex && (
                                <div className="text-bold" style={{ fontSize: "18px" }}>
                                    {allAirlineMarkup.markupvalue !== undefined
                                        ? allAirlineMarkup.markupvaluetype === "bfperc"
                                            ? parseFloat(allAirlineMarkup.markupvalue).toFixed(2) +
                                            " % of Base Fare"
                                            : parseFloat(allAirlineMarkup.markupvalue).toFixed(2)
                                        : "No Markup"}
                                </div>
                            )}
                            {!alleditingIndex && (
                                <div className="d-flex">
                                    <div className="me-2">
                                        <FormControl
                                            className="border bg-light"
                                            fullWidth
                                            sx={{ minWidth: 150 }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={allAirlineMarkup.markupvaluetype}
                                                onChange={(e) =>
                                                    handleAllAirlineMarkupChange(
                                                        "markupvaluetype",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value="bfperc">% Base fare</MenuItem>
                                                <MenuItem value="bfvalue">Value</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <TextField
                                            value={allAirlineMarkup.markupvalue}
                                            id="outlined-basic"
                                            className="bg-light"
                                            variant="outlined"
                                            type="number"
                                            onChange={(e) =>
                                                handleAllAirlineMarkupChange(
                                                    "markupvalue",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className="">
                            <Button
                                variant={!alleditingIndex ? "contained" : "outlined"}
                                color={!alleditingIndex ? "success" : "primary"}
                                onClick={
                                    !alleditingIndex
                                        ? saveAllAirlineMarkup
                                        : () => handleEditClick("All")
                                }
                            >
                                {!alleditingIndex ? "Save" : "Edit"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}

            {selectedRadio === "each" && (
                <>
                    {airlineDetails
                        .slice(1)
                        .filter(
                            (flight) =>
                                flight.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                flight.Code.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((flight, index) => (
                            <Grid
                                className="align-items-center each-airline-div  bg-light mt-1"
                                container
                                spacing={3}
                                key={index}
                            >
                                <Grid item xs>
                                    <div className="d-flex align-items-center ms-3">
                                        <div className="me-2">
                                            <AirlineLogo val={flight.Code} />
                                        </div>
                                        <div className="">{flight.Code} - {flight.Name}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <div className="d-flex flex-column">
                                            <div
                                                className=""
                                                style={{ fontSize: "13px", color: "gray" }}
                                            >
                                                Booking Markup
                                            </div>

                                            {editingIndex !== flight.Code && (
                                                <div className="text-bold" style={{ fontSize: "18px" }}>
                                                    {savedEachAirlineMarkup[flight.Code]?.markupvalue !==
                                                        undefined
                                                        ? savedEachAirlineMarkup[flight.Code]
                                                            ?.markupvaluetype === "bfperc"
                                                            ? parseFloat(
                                                                savedEachAirlineMarkup[flight.Code]
                                                                    ?.markupvalue
                                                            ).toFixed(2) + " % of Base Fare"
                                                            : parseFloat(
                                                                savedEachAirlineMarkup[flight.Code]
                                                                    ?.markupvalue
                                                            ).toFixed(2)
                                                        : allAirlineMarkup.markupvalue
                                                            ? allAirlineMarkup.markupvaluetype === "bfperc"
                                                                ? parseFloat(
                                                                    allAirlineMarkup.markupvalue
                                                                ).toFixed(2) + " % of Base Fare"
                                                                : parseFloat(
                                                                    allAirlineMarkup.markupvalue
                                                                ).toFixed(2)
                                                            : "No Markup"}
                                                </div>
                                            )}

                                            {editingIndex === flight.Code && (
                                                <div className="d-flex">
                                                    <div className="me-2">
                                                        <FormControl
                                                            className="rounded border border-dark"
                                                            fullWidth
                                                            sx={{ minWidth: 150 }}
                                                        >
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id=""
                                                                value={eachMarkupValueType}
                                                                onChange={(e) =>
                                                                    setEachMarkupValueType(e.target.value)
                                                                }
                                                            >
                                                                <MenuItem value="bfperc">% Base fare</MenuItem>
                                                                <MenuItem value="bfvalue">Value</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div>
                                                        <TextField
                                                            value={eachMarkupValue}
                                                            id="outlined-basic"
                                                            className="rounded border border-dark"
                                                            variant="outlined"
                                                            type="number"
                                                            onChange={(e) => handleEachMarkupValue(e.target.value)
                                                                // setEachMarkupValue(e.target.value.replace(/^\d+(\.\d+)?$/, ""))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    {editingIndex !== flight.Code && (
                                        <div className="text-end me-5">
                                            <Button
                                                variant={saveAll ? "outlined" : "contained"}
                                                color={saveAll ? "primary" : "success"}
                                                // style={{ color: "gray", textDecoration: "underline" }}
                                                onClick={() => handleEditClick(flight.Code)}
                                            >
                                                {saveAll ? "Edit" : "Add"}
                                            </Button>
                                        </div>
                                    )}
                                    {editingIndex === flight.Code && (
                                        <div className="">
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() =>
                                                    saveEachAirlineMarkup(index, flight.Code)
                                                }
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                className="ms-2"
                                                variant="text"
                                                style={{ color: "gray", textDecoration: "underline" }}
                                                onClick={() => setEditingIndex("-1")}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                </>
            )}
            {isLoading && <RiseLoading MarkupGroup={isLoading} />}
        </>
    );
}
