import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const ItineraryMarkup = ({
  itineraryMarkup,
  setItineraryMarkup,
  markup,
  setMarkup,
}) => {
  const handleCalculate = () => {
    setMarkup(markup);
    setItineraryMarkup(markup);
  };

  const handleReset = () => {
    setMarkup(0);
    setItineraryMarkup(0);
  };

  return (
    <>
      <div className="filter-item-title d-flex justify-content-between align-items-center">
        <div>Markup - Itinerary</div>
        <div
          style={{
            textDecoration: "underline",
            color: "blue",
            fontSize: "13px",
            cursor: "pointer",
          }}
          onClick={handleReset}
        >
          Reset
        </div>
      </div>
      <div className="d-flex align-items-center mt-2">
        <div>
          <TextField
            id="outlined-basic"
            className="bg-light"
            variant="outlined"
            type="number"
            placeholder="Amount"
            value={markup}
            onChange={(e) => setMarkup(e.target.value)}
          />
        </div>
        <div className="ms-2">
          <Button
            variant="contained"
            onClick={handleCalculate}
            style={{
              backgroundColor: "#9f155a",
            }}
          >
            Calculate
          </Button>
        </div>
      </div>
    </>
  );
};

export default ItineraryMarkup;
