import React,{useEffect} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { setAuthHeader } from "../utils/axiosHeaders";

function ProtectedRoute({ children, component: Component, ...rest }) {
  //const user = useSelector((state) => state.IsLoggedIn.value);
  var user = localStorage.getItem('token')

  useEffect(() => {
    user = localStorage.getItem('token')
    
    if(user !== null){
      setAuthHeader(user);
    }
    return () => {
      
    }
  }, [user])

  return user
    ? (<Route {...rest} component={Component} />)
    : (<Redirect to="/SignIn" />)
}

export default ProtectedRoute