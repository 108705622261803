export default function getNumberOfStops(flightDetails) {
  let stops = 0;
  flightDetails.segGroups.forEach((segGroup) => {
    segGroup.segs.forEach((seg, index) => {
      if (index !== 0) {
        stops++;
      }
    });
  });
  return stops;
}
