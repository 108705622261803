const clientFilterOptions = (options, { inputValue }) => {
    const isInteger = /^\d+$/.test(inputValue);
    return options.filter(option => {
        if (inputValue) {
            if ((inputValue.length > 3 && inputValue.length <= 5) && isInteger) {
                return option.id === parseInt(inputValue);
            } else if (inputValue.length >= 5) {
                const inputPattern = new RegExp(`^${inputValue}`, "i");
                return inputPattern.test(option.clientName);
            }
        } else {
            return true;
        }
    });

};
export default clientFilterOptions