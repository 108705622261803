import axios from "axios";
async function getClientData(getClientId) {
    let filter = "id==" + getClientId;
    const response = await axios
        .get(
            process.env.REACT_APP_CORE_URL +
            "api/Configuration/ClientProfile?sorts=created&filters=" +
            filter
        )
    return response;
}

export default getClientData;