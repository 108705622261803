const Currencies = [
  {
      "name": "Afghan afghani",
      "code": "AFN"
  },
  {
      "name": "European euro",
      "code": "EUR"
  },
  {
      "name": "Albanian lek",
      "code": "ALL"
  },
  {
      "name": "Algerian dinar",
      "code": "DZD"
  },
  {
      "name": "United States dollar",
      "code": "USD"
  },
  {
      "name": "Angolan kwanza",
      "code": "AOA"
  },
  {
      "name": "East Caribbean dollar",
      "code": "XCD"
  },
  {
      "name": "Argentine peso",
      "code": "ARS"
  },
  {
      "name": "Armenian dram",
      "code": "AMD"
  },
  {
      "name": "Aruban florin",
      "code": "AWG"
  },
  {
      "name": "Saint Helena pound",
      "code": "SHP"
  },
  {
      "name": "Australian dollar",
      "code": "AUD"
  },
  {
      "name": "Azerbaijan manat",
      "code": "AZN"
  },
  {
      "name": "Bahamian dollar",
      "code": "BSD"
  },
  {
      "name": "Bahraini dinar",
      "code": "BHD"
  },
  {
      "name": "Bangladeshi taka",
      "code": "BDT"
  },
  {
      "name": "Barbadian dollar",
      "code": "BBD"
  },
  {
      "name": "Belarusian ruble",
      "code": "BYN"
  },
  {
      "name": "Belize dollar",
      "code": "BZD"
  },
  {
      "name": "West African CFA franc",
      "code": "XOF"
  },
  {
      "name": "Bermudian dollar",
      "code": "BMD"
  },
  {
      "name": "Bhutanese ngultrum",
      "code": "BTN"
  },
  {
      "name": "Bolivian boliviano",
      "code": "BOB"
  },
  {
      "name": "Bosnia and Herzegovina convertible mark",
      "code": "BAM"
  },
  {
      "name": "Botswana pula",
      "code": "BWP"
  },
  {
      "name": "Brazilian real",
      "code": "BRL"
  },
  {
      "name": "Brunei dollar",
      "code": "BND"
  },
  {
      "name": "Bulgarian lev",
      "code": "BGN"
  },
  {
      "name": "Burundi franc",
      "code": "BIF"
  },
  {
      "name": "Cabo Verdean escudo",
      "code": "CVE"
  },
  {
      "name": "Cambodian riel",
      "code": "KHR"
  },
  {
      "name": "Central African CFA franc",
      "code": "XAF"
  },
  {
      "name": "Canadian dollar",
      "code": "CAD"
  },
  {
      "name": "Cayman Islands dollar",
      "code": "KYD"
  },
  {
      "name": "New Zealand dollar",
      "code": "NZD"
  },
  {
      "name": "Chilean peso",
      "code": "CLP"
  },
  {
      "name": "Chinese Yuan Renminbi",
      "code": "CNY"
  },
  {
      "name": "Colombian peso",
      "code": "COP"
  },
  {
      "name": "Comorian franc",
      "code": "KMF"
  },
  {
      "name": "Congolese franc",
      "code": "CDF"
  },
  {
      "name": "Cook Islands dollar",
      "code": "none"
  },
  {
      "name": "Costa Rican colon",
      "code": "CRC"
  },
  {
      "name": "Croatian kuna",
      "code": "HRK"
  },
  {
      "name": "Cuban peso",
      "code": "CUP"
  },
  {
      "name": "Netherlands Antillean guilder",
      "code": "ANG"
  },
  {
      "name": "Czech koruna",
      "code": "CZK"
  },
  {
      "name": "Danish krone",
      "code": "DKK"
  },
  {
      "name": "Djiboutian franc",
      "code": "DJF"
  },
  {
      "name": "Dominican peso",
      "code": "DOP"
  },
  {
      "name": "Egyptian pound",
      "code": "EGP"
  },
  {
      "name": "Eritrean nakfa",
      "code": "ERN"
  },
  {
      "name": "Swazi lilangeni",
      "code": "SZL"
  },
  {
      "name": "Ethiopian birr",
      "code": "ETB"
  },
  {
      "name": "Falkland Islands pound",
      "code": "FKP"
  },
  {
      "name": "Faroese krona",
      "code": "none"
  },
  {
      "name": "Fijian dollar",
      "code": "FJD"
  },
  {
      "name": "CFP franc",
      "code": "XPF"
  },
  {
      "name": "Gambian dalasi",
      "code": "GMD"
  },
  {
      "name": "Georgian lari",
      "code": "GEL"
  },
  {
      "name": "Ghanaian cedi",
      "code": "GHS"
  },
  {
      "name": "Gibraltar pound",
      "code": "GIP"
  },
  {
      "name": "Guatemalan quetzal",
      "code": "GTQ"
  },
  {
      "name": "Guernsey Pound",
      "code": "GGP"
  },
  {
      "name": "Guinean franc",
      "code": "GNF"
  },
  {
      "name": "Guyanese dollar",
      "code": "GYD"
  },
  {
      "name": "Haitian gourde",
      "code": "HTG"
  },
  {
      "name": "Honduran lempira",
      "code": "HNL"
  },
  {
      "name": "Hong Kong dollar",
      "code": "HKD"
  },
  {
      "name": "Hungarian forint",
      "code": "HUF"
  },
  {
      "name": "Icelandic krona",
      "code": "ISK"
  },
  {
      "name": "Indian rupee",
      "code": "INR"
  },
  {
      "name": "Indonesian rupiah",
      "code": "IDR"
  },
  {
      "name": "SDR (Special Drawing Right)",
      "code": "XDR"
  },
  {
      "name": "Iranian rial",
      "code": "IRR"
  },
  {
      "name": "Iraqi dinar",
      "code": "IQD"
  },
  {
      "name": "Manx pound",
      "code": "IMP"
  },
  {
      "name": "Israeli new shekel",
      "code": "ILS"
  },
  {
      "name": "Jamaican dollar",
      "code": "JMD"
  },
  {
      "name": "Japanese yen",
      "code": "JPY"
  },
  {
      "name": "Jersey pound",
      "code": "JEP"
  },
  {
      "name": "Jordanian dinar",
      "code": "JOD"
  },
  {
      "name": "Kazakhstani tenge",
      "code": "KZT"
  },
  {
      "name": "Kenyan shilling",
      "code": "KES"
  },
  {
      "name": "Kuwaiti dinar",
      "code": "KWD"
  },
  {
      "name": "Kyrgyzstani som",
      "code": "KGS"
  },
  {
      "name": "Lao kip",
      "code": "LAK"
  },
  {
      "name": "Lebanese pound",
      "code": "LBP"
  },
  {
      "name": "Lesotho loti",
      "code": "LSL"
  },
  {
      "name": "Liberian dollar",
      "code": "LRD"
  },
  {
      "name": "Libyan dinar",
      "code": "LYD"
  },
  {
      "name": "Swiss franc",
      "code": "CHF"
  },
  {
      "name": "Macanese pataca",
      "code": "MOP"
  },
  {
      "name": "Malagasy ariary",
      "code": "MGA"
  },
  {
      "name": "Malawian kwacha",
      "code": "MWK"
  },
  {
      "name": "Malaysian ringgit",
      "code": "MYR"
  },
  {
      "name": "Maldivian rufiyaa",
      "code": "MVR"
  },
  {
      "name": "Mauritanian ouguiya",
      "code": "MRU"
  },
  {
      "name": "Mauritian rupee",
      "code": "MUR"
  },
  {
      "name": "Mexican peso",
      "code": "MXN"
  },
  {
      "name": "Moldovan leu",
      "code": "MDL"
  },
  {
      "name": "Mongolian tugrik",
      "code": "MNT"
  },
  {
      "name": "Moroccan dirham",
      "code": "MAD"
  },
  {
      "name": "Mozambican metical",
      "code": "MZN"
  },
  {
      "name": "Myanmar kyat",
      "code": "MMK"
  },
  {
      "name": "Namibian dollar",
      "code": "NAD"
  },
  {
      "name": "Nepalese rupee",
      "code": "NPR"
  },
  {
      "name": "Nicaraguan cordoba",
      "code": "NIO"
  },
  {
      "name": "Nigerian naira",
      "code": "NGN"
  },
  {
      "name": "North Korean won",
      "code": "KPW"
  },
  {
      "name": "Macedonian denar",
      "code": "MKD"
  },
  {
      "name": "Norwegian krone",
      "code": "NOK"
  },
  {
      "name": "Omani rial",
      "code": "OMR"
  },
  {
      "name": "Pakistani rupee",
      "code": "PKR"
  },
  {
      "name": "Papua New Guinean kina",
      "code": "PGK"
  },
  {
      "name": "Paraguayan guarani",
      "code": "PYG"
  },
  {
      "name": "Peruvian sol",
      "code": "PEN"
  },
  {
      "name": "Philippine peso",
      "code": "PHP"
  },
  {
      "name": "Polish zloty",
      "code": "PLN"
  },
  {
      "name": "Qatari riyal",
      "code": "QAR"
  },
  {
      "name": "Romanian leu",
      "code": "RON"
  },
  {
      "name": "Russian ruble",
      "code": "RUB"
  },
  {
      "name": "Rwandan franc",
      "code": "RWF"
  },
  {
      "name": "Samoan tala",
      "code": "WST"
  },
  {
      "name": "Sao Tome and Principe dobra",
      "code": "STN"
  },
  {
      "name": "Saudi Arabian riyal",
      "code": "SAR"
  },
  {
      "name": "Serbian dinar",
      "code": "RSD"
  },
  {
      "name": "Seychellois rupee",
      "code": "SCR"
  },
  {
      "name": "Sierra Leonean leone",
      "code": "SLL"
  },
  {
      "name": "Singapore dollar",
      "code": "SGD"
  },
  {
      "name": "Solomon Islands dollar",
      "code": "SBD"
  },
  {
      "name": "Somali shilling",
      "code": "SOS"
  },
  {
      "name": "South African rand",
      "code": "ZAR"
  },
  {
      "name": "Pound sterling",
      "code": "GBP"
  },
  {
      "name": "South Korean won",
      "code": "KRW"
  },
  {
      "name": "South Sudanese pound",
      "code": "SSP"
  },
  {
      "name": "Sri Lankan rupee",
      "code": "LKR"
  },
  {
      "name": "Sudanese pound",
      "code": "SDG"
  },
  {
      "name": "Surinamese dollar",
      "code": "SRD"
  },
  {
      "name": "Swedish krona",
      "code": "SEK"
  },
  {
      "name": "Syrian pound",
      "code": "SYP"
  },
  {
      "name": "New Taiwan dollar",
      "code": "TWD"
  },
  {
      "name": "Tajikistani somoni",
      "code": "TJS"
  },
  {
      "name": "Tanzanian shilling",
      "code": "TZS"
  },
  {
      "name": "Thai baht",
      "code": "THB"
  },
  {
      "name": "Tongan pa’anga",
      "code": "TOP"
  },
  {
      "name": "Trinidad and Tobago dollar",
      "code": "TTD"
  },
  {
      "name": "Tunisian dinar",
      "code": "TND"
  },
  {
      "name": "Turkish lira",
      "code": "TRY"
  },
  {
      "name": "Turkmen manat",
      "code": "TMT"
  },
  {
      "name": "Ugandan shilling",
      "code": "UGX"
  },
  {
      "name": "Ukrainian hryvnia",
      "code": "UAH"
  },
  {
      "name": "UAE dirham",
      "code": "AED"
  },
  {
      "name": "Uruguayan peso",
      "code": "UYU"
  },
  {
      "name": "Uzbekistani som",
      "code": "UZS"
  },
  {
      "name": "Vanuatu vatu",
      "code": "VUV"
  },
  {
      "name": "Venezuelan bolivar",
      "code": "VES"
  },
  {
      "name": "Vietnamese dong",
      "code": "VND"
  },
  {
      "name": "Yemeni rial",
      "code": "YER"
  },
  {
      "name": "Zambian kwacha",
      "code": "ZMW"
  }
];

export default Currencies;