const PreferenceToggleData = [
    { value: 'GeneralFare', label: 'General Fare' },
    { value: 'EthnicFare', label: 'Ethnic Fare' },

    // { value : "NET", label : "Negotiated Fare" },
    // { value : "PUB", label : "Publish Fare" },
    // { value : "JOB", label : "Corporate Fare" },
    // { value : "ITX", label : "Individual Inclusive Tour PSGR Fare" },
    // { value : "VFR", label : "Visit Friends / Relatives Fare" },
    // { value : "TBF", label : "Tour Based Fare" },
    // { value : "VFR", label : "Ethnic Fare" },
    // { value : "LBR", label : "Labour Fare" },
    // { value : "SEA", label : "Seamean Fare" },
    // { value : "STU", label : "Student Fare" },
    // { value : "EMI", label : "Emigrant Fare" },
    // { value : "WEB", label : "Internet Fare" },
    // { value : "YCR", label : "Youth Fare" },
    // { value : "SRC", label : "Senior Citizen Fare" },
    // { value : "PIL", label : "Pilgrim Fare" },
    // { value : "MIS", label : "Missionary Fare" },
    // { value: 'MED', label: 'Medical Treatment Fare' },
    // { value: 'INS', label: 'Infant with Seat Fare' },
    // { value: 'CMP', label: 'Campanion Fare' },
    // { value: 'IIT', label: 'Individual Inclusive Tour Adult Fare' },
    // { value: 'LCC', label: 'LCC Fare' },
    // { value: 'NDC', label: 'NDC Fare' }

];

export default PreferenceToggleData;
