import React, { useState, useCallback, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Container, Divider } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import "./ResetPassword.css";
import ChangePassword from "../../assets/ChangePassword.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import PasswordStrengthBar from "react-password-strength-bar";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@mui/material/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "100px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    margin: "10px",
    fontSize: theme.typography.pxToRem(11),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MustContainItem = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["must-line"];
    if (meetsReq) classArr.push("cross-out");
    return classArr.join(" ");
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className="must-text">{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};
function ResetPassword() {
  const classes = useStyles();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isImportBtnDisabled, setisImportBtnDisabled] = useState(false);
  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [isTAC, setTAC] = useState(false);
  const [clientId, setClientId] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  const [eye, seteye] = useState(true);
  // checks all validations are true
  const [allNotValid, setAllNotValid] = useState(true);
  const history = useHistory();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    //Loading Necessory Data.
    setisImportBtnDisabled(false);
    const parsed = queryString.parse(location.search);
    setEmail(parsed.email);
    setToken(decodeURIComponent(parsed.token));
  }, []);

  const handleSubmit = () => {
    if (password !== undefined && confirmPassword !== undefined) {
      setConfirmPasswordError("");
      const strongRegEx = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!password.match(strongRegEx)) {
        setConfirmPasswordError("New password does not match");
      } else if (password !== confirmPassword) {
        setConfirmPasswordError(
          "New password and confirm password does not match."
        );
      } else {
        setConfirmPasswordError("");
        setisImportBtnDisabled(true);
        handleResetPassword();
      }
    }
  };
  const mustContainData = [
    ["An uppercase letter (A-Z)", containsUL],
    ["A lowercase letter (a-z)", containsLL],
    ["A number (0-9)", containsN],
    ["A special character (!@#$)", containsSC],
    ["At least 8 characters", contains8C],
    ["Passwords match", passwordMatch],
  ];
  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() !== password) setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    if (password.toUpperCase() !== password) setContainsLL(true);
    else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);

    // has 8 characters
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);

    // passwords match
    if (password !== "" && password === confirmPassword) setPasswordMatch(true);
    else setPasswordMatch(false);

    // all validations passed
    if (
      containsUL &&
      containsLL &&
      containsN &&
      containsSC &&
      contains8C &&
      passwordMatch &&
      isTAC
    ) {
      setAllNotValid(false);
    } else {
      setAllNotValid(true);
    }
  };
  async function handleResetPassword() {
    // console.log(JSON.stringify({
    //     email: email,
    //     resetPasswordToken: token,
    //     password: password,
    //   }))
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_IDENTITY_SERVER +
          "ids/ManageUser/UpdateUserPassword/",
        JSON.stringify({
          email: email,
          resetPasswordToken: token,
          password: password,
        }),
        {
          headers,
        }
      )
      .then(async (res) => {
        // console.log(res.data);
        await setAlert({
          open: true,
          message: "Password reset successful. Please login with new password.",
          severity: "success",
        });
        setTimeout(navigateToLogin, 6000);
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: err.response.data.detail,
          severity: "error",
        });
      });
  }
  function navigateToLogin() {
    history.push("/SignIn");
  }
  const eyeToggle = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      seteye(false);
    } else {
      setPasswordType("password");
      seteye(true);
    }
  };
  useEffect(() => {
    validatePassword();
  }, [confirmPassword, isTAC]);
  // history.push({ pathname: "/SignIn"})

  return (
    <Container style={{ marginTop: "50px" }}>
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setAlert({ ...alert, open: false });
        }}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => {
            setAlert({ ...alert, open: false });
          }}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <div style={{ padding: "20px", marginTop: "10px" }}>
            <div style={{ textAlign: "center" }}>
              <img
                alt="ChangePassword Icon"
                style={{
                  width: "350px",
                }}
                src={ChangePassword}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={5} className="reset-pass-section mb-5">
          <div style={{ margin: "20px" }}>
            <Typography variant="h5" style={{ marginBottom: "20px" }}>
              Change Your Password
            </Typography>
            <React.Fragment>
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <div className="user-input-group py-3">
                  <TextValidator
                    variant="outlined"
                    label="Email Address"
                    size="small"
                    color="secondary"
                    value={email}
                    validators={["required"]}
                    disabled={true}
                    errorMessages={["This Field is Required."]}
                  />
                </div>
                {/*<div className="user-input-group py-2">
                                 <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                        <Typography color="inherit">Password Rule</Typography>
                                        <ul>
                                            <li>An uppercase letter (A-Z)</li>
                                            <li>A lowercase letter (a-z)</li>
                                            <li>A number (0-9)</li>
                                            <li>A special character (!@#$)</li>
                                            <li>At least 8 characters</li>
                                            <li>Passwords match</li>
                                        </ul>
                                        </React.Fragment>
                                    }
                                    >
                                    <TextValidator
                                        type="password"
                                        variant="outlined"
                                        label="New Password"
                                        size="small"
                                        color="secondary"
                                        value={password}
                                        onChange={(event) => setNewPassword(event.target.value)}
                                        validators={["required"]}
                                        errorMessages={["This Field is Required."]}
                                    />
                                    </HtmlTooltip>
                                </div>
                                <div className="user-input-group py-3">
                                    <TextValidator
                                        type="password"
                                        variant="outlined"
                                        label="Confirm Password"
                                        size="small"
                                        color="secondary"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        validators={["required"]}
                                        errorMessages={["This Field is Required."]}
                                    />
                                    <div className="text-danger">{confirmPasswordError}</div>
                                </div> */}
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  size="small"
                  label="Password"
                  color="primary"
                  fullWidth
                  // type="password"
                  type={passwordType}
                  value={password}
                  onChange={(event) => setNewPassword(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                  onKeyUp={validatePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={eyeToggle} onMouseDown={eyeToggle}>
                          {eye ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordStrengthBar minLength="4" password={password} />
                <TextValidator
                  type="password"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  label="Confirm Password"
                  color="primary"
                  fullWidth
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                  onKeyUp={validatePassword}
                />
                {/* <h4>Password Checklist:</h4> */}
                <div className="must-container cfb">
                  {mustContainData.map((data) => (
                    <MustContainItem data={data} />
                  ))}
                </div>
                <div className="text-center">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{
                      textTransform: "none",
                      marginLeft: "25px",
                      marginRight: "25px",
                      marginTop: "10px",
                      width: "50%",
                    }}
                    onClick={() => handleSubmit()}
                    disabled={isImportBtnDisabled}
                  >
                    Reset Password
                  </Button>
                </div>
              </ValidatorForm>
            </React.Fragment>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Container>
  );
}

export default ResetPassword;
