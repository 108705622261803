import React, { useState, useEffect } from "react";
import "./PendingApprovalQueue.css";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import Drawer from "@material-ui/core/Drawer";

import SearchPendingQueue from "./SearchPendingQueue/SearchPendingQueue";
import PendingQueueTable from "./PendingQueueTable/PendingQueueTable";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PendingApprovalQueue() {
  let { id } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [addUserdrawer, setaddUserdrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [action, setAction] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [pendingQueue, setpendingQueue] = useState(null);
  const [searchPendingQueueValue, setsearchPendingQueueValue] = useState(null);
  const [clientList, setclientList] = useState(null);
  const [search, setsearch] = useState(false);
  const [page, setPage] = React.useState(1);
  

  useEffect(() => {
    //Loading Necessory Data.
    getClients();
  }, []);

  async function getClients() {
      await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/ClientProfile/GetClientList"
        )
        .then((response) => {
          setclientList(response.data);
          // console.log('client data', clientList);
        });
        await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/PendingApprovalQueue/GetByUser?sorts=created&page=" +
          page +
          "&pageSize=10"
        )
        .then((response) => {
          
          // console.log(response.data);
          setpendingQueue(response.data);
        });
   
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
   setsearch(!search);


  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const toggleAddUserDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setaddUserdrawer(value);
  };

  const openAddUserDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setaddUserdrawer(true);
  };
 
  // async function searchClients(supplierName, approvalStage) {
  //   setsearch(true);
  //   let clientResult, approvalResult;
  //   console.log(supplierName, approvalStage);
  //   console.log(pendingQueue);

  //   if (approvalStage) {
  //     let searchArray = approvalStage.trim().split(" ");
  //     let re = new RegExp(searchArray.join("|"), "i");
  //     approvalResult = pendingQueue.filter(obj =>
  //       re.test(obj.pendingApprovalQueues.approvalPermissionType) ||
  //       re.test(obj.pendingApprovalQueues.approvalConfigurationType)
  //     );
  //     console.log(approvalResult);
  //   }
  //   if (supplierName) {
  //     let searchArray = supplierName.trim().split(" ");
  //     let re = new RegExp(searchArray.join("|"), "i");
  //     clientResult = clientList.filter(obj =>
  //       re.test(obj.clientName)
  //     );
  //     console.log(clientResult);
  //   }
  //   if (approvalStage && supplierName) {
  //     let tempArray = [];
  //     approvalResult.map(item => {
  //       clientResult.map(client => {
  //         if (client.id == item.pendingApprovalQueues.approvalConfigId) {
  //           tempArray.push(item);
  //         }
  //       })
  //     })
  //     if (tempArray.length) {
  //       setsearchPendingQueueValue(tempArray);
  //       console.log("search", search);
  //       console.log("searchPendingQueueValue", searchPendingQueueValue)
  //     }else{
  //       setsearchPendingQueueValue([]);
  //     }
  //     } else if (supplierName) {
  //     let tempArray = [];
  //     pendingQueue.map(item => {
  //       clientResult.map(client => {
  //         if (client.id == item.pendingApprovalQueues.approvalConfigId) {
  //           tempArray.push(item);
  //         }
  //       })
  //     })
  //     if (tempArray.length) {
  //       setsearchPendingQueueValue(tempArray);
  //       console.log("search", search);
  //       console.log("searchPendingQueueValue", searchPendingQueueValue)
  //     }else{
  //       setsearchPendingQueueValue([]);
  //     }
  //   } else {
  //     setsearchPendingQueueValue(approvalResult);
  //     console.log("search", search);
  //     console.log("searchPendingQueueValue", searchPendingQueueValue)
  //   }
  // }

  async function searchClients(supplierName, approvalStage) {
    setsearch(true);
    let approvalResult;
    let searchArray = approvalStage.trim().split(" ");
    let re = new RegExp(searchArray.join("|"), "i");
    approvalResult = pendingQueue.filter(obj =>
      (re.test(obj.pendingApprovalQueues.approvalPermissionType) ||
        re.test(obj.pendingApprovalQueues.approvalConfigurationType))
      && obj.pendingApprovalQueues.approvalMessage.toLowerCase().includes(supplierName.trim().toLowerCase())
    );
    setsearchPendingQueueValue(approvalResult);
  }
  // async function searchClients(supplierName, approvalStage) {
  //   setsearch(true);
  //   let clientResult, approvalResult;
  //   // console.log(supplierName, approvalStage);
  //   // console.log(pendingQueue);

  //   if (approvalStage) {
  //     let searchArray = approvalStage.trim().split(" ");
  //     let re = new RegExp(searchArray.join("|"), "i");
  //     approvalResult = pendingQueue.filter(obj =>
  //       re.test(obj.pendingApprovalQueues.approvalPermissionType) ||
  //       re.test(obj.pendingApprovalQueues.approvalConfigurationType)
  //     );
  //     // console.log(approvalResult);
  //   }
  //   if (supplierName) {
  //     let searchArray = supplierName.trim().split(" ");
  //     let re = new RegExp(searchArray.join("|"), "i");
  //     clientResult = clientList.filter(obj =>
  //       re.test(obj.clientName)
  //     );
  //     // console.log(clientResult);
  //   }
  //   if (approvalStage, supplierName) {
  //     let tempArray = [];
  //     approvalResult.map(item => {
  //       clientResult.map(client => {
  //         if (client.id == item.pendingApprovalQueues.approvalConfigId) {
  //           tempArray.push(item);
  //         }
  //       })
  //     })
  //     if (tempArray.length) {
        
  //       setsearchPendingQueueValue(tempArray);
  //       // console.log("search", search);
  //       // console.log("searchPendingQueueValue", searchPendingQueueValue)
  //     }
  //   } else {
     
  //     setsearchPendingQueueValue(approvalResult);
  //     // console.log("search", search);
  //     // console.log("searchPendingQueueValue", searchPendingQueueValue)
  //   }
  // }
 

  return (
    <div className="configuration-container">
      <div className="configuration-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-configuration-container">
              <div className="configuration-table-container">
                <div className="configuration-title-add-button-container">
                  <div className="configuration-heading">
                    Pending Approval Queue
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="configuration-card-container">
                    <SearchPendingQueue
                      searchClients={searchClients}
                    />
                  </div>
                </Collapse>
                
                <PendingQueueTable
                  openAddUserDrawer={openAddUserDrawer}
                  setAction={setAction}
                  pendingQueue={pendingQueue}
                  setPage={setPage}
                  page={page}
                  getClients={getClients}
                  searchPendingQueueValue={searchPendingQueueValue}
                  search={search}
                ></PendingQueueTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          Display vendor here
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PendingApprovalQueue;
