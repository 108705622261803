import React, { useState, useEffect, useReducer } from "react";
import "../Results.css";
import months from "../../../../data/Months";
import Days from "../../../../data/Days";
import findAirportbyCode from "../../../../functions/findAirportByCode";
import findAirlinebyCode from "../../../../functions/findAirlineByCode";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
/**********New Addon */
import { Row, Col, Nav, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import getLayoverTime from "../../../../functions/getLayoverTime";
import findAirportNameByCode from "../../../../functions/findAirportNameByCode";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "../../../ToolTip/ToolTip";
import convertTo24HourFormat from "../../../../functions/convertTo24HourFormat";
import { AirlineLogoForSearch } from "../../../../functions/airlineLogo";
import { OnlyAirlineLogo } from "../../../../functions/airlineLogo";
export default function Tickets({ result, segGroups, fareGroups }) {
  const getDeparture = (departure) => {
    let temp = departure.includes("+00:00")
      ? departure.replace("+00:00", "")
      : departure;
    return moment(temp).format("HH:mm");
  };

  const getArrival = (arrival) => {
    let temp = new Date(
      arrival.includes("+00:00") ? arrival.replace("+00:00", "") : arrival
    );
    return moment(temp).format("HH:mm");
  };

  const getDuration = (duration) => {
    return `${Math.floor(duration / 60)}hrs ${duration % 60}min`;
  };

  const images = require.context("../../../../airline_logo", true);


  /************ New Addon */
  const getDifferenceInDays = (dateA, dateB) => {
    const date1 = new Date(
        dateA.includes("+00:00") ? dateA.replace("+00:00", "") : dateA
      ),
      date2 = new Date(
        dateB.includes("+00:00") ? dateB.replace("+00:00", "") : dateB
      );
    const diffInMs = Math.abs(date1 - date2);
    const getDiffInDay = Math.round(diffInMs / (1000 * 60 * 60 * 24));
    let getDiffInDay_msg = "";
    if (getDiffInDay !== "" || getDiffInDay !== 0) {
      if (getDiffInDay === 1) {
        getDiffInDay_msg = "Arrives next day";
      } else if (getDiffInDay > 1) {
        getDiffInDay_msg = "Arrives " + getDiffInDay + " days later";
      }
    }
    return getDiffInDay_msg;
  };


  return (
    <div>
      {result.segGroups.length > 0 &&
        result.segGroups.map((segGroup, segGroupIndex) => (
          <section className="col-12 flt-bkg-itinerary-section">
            {/* <Row className="mx-0 pb-2">
                      <div className="col-12 flt-bkg-highlight-label px-0">
                          <span className="badge flt-bkg-imp-badge-label mx-2">Important</span>
                          <span className="flt-bkg-imp-msg">You will have to change terminals while travelling</span>
                      </div>
                  </Row> */}
            <Row className="mx-0 pb-2 flt-bkg-heading-bg px-2">
              <div className="col-12 flt-bkg-main-heading px-2">
                {findAirportbyCode(segGroup.origin)} {" → "}
                {findAirportbyCode(segGroup.destination)}{" "}
                {/* <small className="px-1">|</small>
                <small className="px-1">
                  {
                    moment(new Date(segGroup.departureOn))
                      .format("ddd,Do MMM  YYYY")
                  }
                </small> */}
              </div>
            </Row>
            {segGroup.segs.map((item, index) => (
              <Row className="flt-bkg-information-panel mx-0 pt-3 pb-2">
                <div className="col-2">
                  <Row>
                    <div className="col-4 flt-ic px-2">
                      <OnlyAirlineLogo val={item.mrkAirline} />
                    </div>
                    <div className="col-8 flt-info px-3">
                      <div className="flt-name">
                        {findAirlinebyCode(item.mrkAirline)}
                      </div>
                      <div className="flt-number">
                        {item.mrkAirline + "-" + item.flightNum}
                      </div>

                      {fareGroups.segInfos.map((seg, indexInfo) =>
                        seg.cityPair === item.origin + item.destination ? (
                          <div className="flt-route-cls">{seg.cabinClass}</div>
                        ) : (
                          ""
                        )
                      )}
                      {item.mrkAirline !== item.opAirline &&
                      item.opAirline !== "" ? (
                        <>
                          <div className="flt-operated-by">
                            Operated by <br />
                            {findAirlinebyCode(item.opAirline)}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                </div>
                <div className="col-10">
                  <Row>
                    {fareGroups.baggages &&
                    fareGroups.baggages.filter(
                      (i) =>
                        i.paxType === "ADT" &&
                        i.cityPair === item.origin + item.destination
                    ).length ? (
                      fareGroups.baggages
                        .filter(
                          (i) =>
                            i.paxType === "ADT" &&
                            i.cityPair === item.origin + item.destination
                        )
                        // .slice(0,1)
                        .map((bag) =>
                          // bag.cityPair === item.origin + item.destination
                          (bag.checkInBag &&
                            bag.checkInBag !== "Nil" &&
                            bag.checkInBag !== "0PC" &&
                            bag.checkInBag !== "0KG") ||
                          (bag.cabinBag &&
                            bag.cabinBag !== "Nil" &&
                            bag.cabinBag !== "0PC" &&
                            bag.cabinBag !== "0KG") ? (
                            <>
                              {" "}
                              <div className="col-4 flt-departure-info px-1">
                                <div className="flt-airport-code pb-1">
                                  <span className="flt-origin">
                                    {item.origin}
                                  </span>
                                  <span className="flt-time">
                                    {getDeparture(
                                      item.departureOn.includes("+00:00")
                                        ? item.departureOn.replace("+00:00", "")
                                        : item.departureOn
                                    )}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  {moment(
                                    new Date(
                                      item.departureOn.includes("+00:00")
                                        ? item.departureOn.replace("+00:00", "")
                                        : item.departureOn
                                    )
                                  ).format("ddd,Do MMM  YYYY")}
                                </div>
                                <div className="flt-airport-nm">
                                  {findAirportNameByCode(item.origin)},{" "}
                                  {findAirportbyCode(item.origin)}
                                  {item.depTerminal ? (
                                    <>
                                      <br />
                                      Terminal {item.depTerminal}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-2 flt-duration-info text-center px-1">
                                <AccessTimeIcon />
                                <div className="flt-duration py-1">
                                  {getDuration(item.duration)}
                                </div>
                                {item.eqpType ? (
                                  <div className="flt-number">
                                    {"EQP" + "-" + item.eqpType}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-4 flt-arrival-info px-1">
                                <div className="flt-airport-code pb-1">
                                  <span className="flt-time">
                                    {getDeparture(
                                      item.arrivalOn.includes("+00:00")
                                        ? item.arrivalOn.replace("+00:00", "")
                                        : item.arrivalOn
                                    )}
                                  </span>

                                  <span className="flt-destination">
                                    {item.destination}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  <span className="badge flt-bkg-highlight-badge">
                                    {getDifferenceInDays(
                                      moment(
                                        new Date(
                                          segGroup.segs[0].departureOn.includes(
                                            "+00:00"
                                          )
                                            ? segGroup.segs[0].departureOn.replace(
                                                "+00:00",
                                                ""
                                              )
                                            : segGroup.segs[0].departureOn
                                        )
                                      ).format("YYYY-MM-DD"),

                                      moment(
                                        new Date(
                                          segGroup.segs[
                                            segGroup.segs.length - 1
                                          ].arrivalOn.includes("+00:00")
                                            ? segGroup.segs[0].departureOn.replace(
                                                "+00:00",
                                                ""
                                              )
                                            : segGroup.segs[0].departureOn
                                        )
                                      ).format("YYYY-MM-DD")
                                    )}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  {moment(
                                    new Date(
                                      item.arrivalOn.includes("+00:00")
                                        ? item.arrivalOn.replace("+00:00", "")
                                        : item.arrivalOn
                                    )
                                  ).format("ddd,Do MMM  YYYY")}
                                </div>
                                <div className="flt-airport-nm">
                                  {findAirportNameByCode(item.destination)},{" "}
                                  {findAirportbyCode(item.destination)}
                                  {item.arrTerminal ? (
                                    <>
                                      <br />
                                      Terminal {item.arrTerminal}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-2 ">
                                <div className="flight-confirmation-details-item">
                                  {bag.checkInBag &&
                                    bag.checkInBag !== "Nil" &&
                                    bag.checkInBag !== "0PC" &&
                                    bag.checkInBag !== "0KG" && (
                                      <>
                                        <div className="flight-confirmation-details-item-text">
                                          Check-In{" "}
                                          <Tooltip tooltipText={bag.checkInBag}>
                                            <InfoOutlinedIcon
                                              style={{
                                                fontSize: "12px",
                                                color: "#db2a5f",
                                              }}
                                            />
                                          </Tooltip>{" "}
                                        </div>
                                        <div className="flight-confirmation-details-item-subtext ">
                                          <p
                                            style={{ display: "block" }}
                                            className="textElipsis"
                                          >
                                            {bag.checkInBag}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  {bag.cabinBag &&
                                    bag.cabinBag !== "Nil" &&
                                    bag.cabinBag !== "0PC" &&
                                    bag.cabinBag !== "0KG" && (
                                      <>
                                        <div className="flight-confirmation-details-item-text">
                                          Cabin{" "}
                                          <Tooltip tooltipText={bag.cabinBag}>
                                            <InfoOutlinedIcon
                                              style={{
                                                fontSize: "12px",
                                                color: "#db2a5f",
                                              }}
                                            />
                                          </Tooltip>{" "}
                                        </div>
                                        <div className="flight-confirmation-details-item-subtext">
                                          <p
                                            style={{ display: "block" }}
                                            className="textElipsis"
                                          >
                                            {bag.cabinBag}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-5 flt-departure-info px-1">
                                <div className="flt-airport-code pb-1">
                                  <span className="flt-origin">
                                    {item.origin}
                                  </span>
                                  <span className="flt-time">
                                    {getDeparture(
                                      item.departureOn.includes("+00:00")
                                        ? item.departureOn.replace("+00:00", "")
                                        : item.departureOn
                                    )}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  {moment(
                                    new Date(
                                      item.departureOn.includes("+00:00")
                                        ? item.departureOn.replace("+00:00", "")
                                        : item.departureOn
                                    )
                                  ).format("ddd,Do MMM  YYYY")}
                                </div>
                                <div className="flt-airport-nm">
                                  {findAirportNameByCode(item.origin)},{" "}
                                  {findAirportbyCode(item.origin)}
                                  {item.depTerminal ? (
                                    <>
                                      <br />
                                      Terminal {item.depTerminal}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-2 flt-duration-info text-center px-1">
                                <AccessTimeIcon />
                                <div className="flt-duration py-1">
                                  {getDuration(item.duration)}
                                </div>
                                {item.eqpType ? (
                                  <div className="flt-number">
                                    {"EQP" + "-" + item.eqpType}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-5 flt-arrival-info px-1">
                                <div className="flt-airport-code pb-1">
                                  <span className="flt-time">
                                    {getDeparture(
                                      item.arrivalOn.includes("+00:00")
                                        ? item.arrivalOn.replace("+00:00", "")
                                        : item.arrivalOn
                                    )}
                                  </span>

                                  <span className="flt-destination">
                                    {item.destination}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  <span className="badge flt-bkg-highlight-badge">
                                    {getDifferenceInDays(
                                      moment(
                                        new Date(
                                          segGroup.segs[0].departureOn.includes(
                                            "+00:00"
                                          )
                                            ? segGroup.segs[0].departureOn.replace(
                                                "+00:00",
                                                ""
                                              )
                                            : segGroup.segs[0].departureOn
                                        )
                                      ).format("YYYY-MM-DD"),

                                      moment(
                                        new Date(
                                          segGroup.segs[
                                            segGroup.segs.length - 1
                                          ].arrivalOn.includes("+00:00")
                                            ? segGroup.segs[0].departureOn.replace(
                                                "+00:00",
                                                ""
                                              )
                                            : segGroup.segs[0].departureOn
                                        )
                                      ).format("YYYY-MM-DD")
                                    )}
                                  </span>
                                </div>
                                <div className="flt-date">
                                  {moment(
                                    new Date(
                                      item.arrivalOn.includes("+00:00")
                                        ? item.arrivalOn.replace("+00:00", "")
                                        : item.arrivalOn
                                    )
                                  ).format("ddd,Do MMM  YYYY")}
                                </div>
                                <div className="flt-airport-nm">
                                  {findAirportNameByCode(item.destination)},{" "}
                                  {findAirportbyCode(item.destination)}
                                  {item.arrTerminal ? (
                                    <>
                                      <br />
                                      Terminal {item.arrTerminal}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        )
                    ) : (
                      <>
                        <div className="col-5 flt-departure-info px-1">
                          <div className="flt-airport-code pb-1">
                            <span className="flt-origin">{item.origin}</span>
                            <span className="flt-time">
                              {getDeparture(
                                item.departureOn.includes("+00:00")
                                  ? item.departureOn.replace("+00:00", "")
                                  : item.departureOn
                              )}
                            </span>
                          </div>
                          <div className="flt-date">
                            {moment(
                              new Date(
                                item.departureOn.includes("+00:00")
                                  ? item.departureOn.replace("+00:00", "")
                                  : item.departureOn
                              )
                            ).format("ddd,Do MMM  YYYY")}
                          </div>
                          <div className="flt-airport-nm">
                            {findAirportNameByCode(item.origin)},{" "}
                            {findAirportbyCode(item.origin)}
                            {item.depTerminal ? (
                              <>
                                <br />
                                Terminal {item.depTerminal}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-2 flt-duration-info text-center px-1">
                          <AccessTimeIcon />
                          <div className="flt-duration py-1">
                            {getDuration(item.duration)}
                          </div>
                          {item.eqpType ? (
                            <div className="flt-number">
                              {"EQP" + "-" + item.eqpType}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-5 flt-arrival-info px-1">
                          <div className="flt-airport-code pb-1">
                            <span className="flt-time">
                              {getDeparture(
                                item.arrivalOn.includes("+00:00")
                                  ? item.arrivalOn.replace("+00:00", "")
                                  : item.arrivalOn
                              )}
                            </span>

                            <span className="flt-destination">
                              {item.destination}
                            </span>
                          </div>
                          <div className="flt-date">
                            <span className="badge flt-bkg-highlight-badge">
                              {getDifferenceInDays(
                                moment(
                                  new Date(
                                    segGroup.segs[0].departureOn.includes(
                                      "+00:00"
                                    )
                                      ? segGroup.segs[0].departureOn.replace(
                                          "+00:00",
                                          ""
                                        )
                                      : segGroup.segs[0].departureOn
                                  )
                                ).format("YYYY-MM-DD"),

                                moment(
                                  new Date(
                                    segGroup.segs[
                                      segGroup.segs.length - 1
                                    ].arrivalOn.includes("+00:00")
                                      ? segGroup.segs[
                                          segGroup.segs.length - 1
                                        ].arrivalOn.replace("+00:00", "")
                                      : segGroup.segs[segGroup.segs.length - 1]
                                          .arrivalOn
                                  )
                                ).format("YYYY-MM-DD")
                              )}
                            </span>
                          </div>
                          <div className="flt-date">
                            {moment(
                              new Date(
                                item.arrivalOn.includes("+00:00")
                                  ? item.arrivalOn.replace("+00:00", "")
                                  : item.arrivalOn
                              )
                            ).format("ddd,Do MMM  YYYY")}
                          </div>
                          <div className="flt-airport-nm">
                            {findAirportNameByCode(item.destination)},{" "}
                            {findAirportbyCode(item.destination)}
                            {item.arrTerminal ? (
                              <>
                                <br />
                                Terminal {item.arrTerminal}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Row>
                </div>
                {segGroup.segs.length - 1 !== index && (
                  <div className="col-12">
                    <Row className="justify-content-md-end flt-bkg-layover-info mx-0 py-2">
                      <div className="col-9 text-center layover-info">
                        <span className="layover-time py-1 px-2">
                          Layover:{" "}
                          {getLayoverTime(
                            segGroup.segs[index],
                            segGroup.segs[index + 1]
                          )}
                        </span>
                      </div>
                    </Row>
                  </div>
                )}
              </Row>
            ))}
          </section>
        ))}
      {/* {segGroups.length > 0 &&
        segGroups.map((segGroup) =>
          segGroup.segs.map((item, index) => (
            
            <div>
              <div className="airline-detail-header">
                <div>
                  {
                    result.airline !== undefined ?
                    <AirlineLogo val={result.airline} />
                    :
                    <FlightTakeoffIcon className="primary-fill" fontSize="small" />
                  }
                </div>
                <div className="ml-4 mr-4 airline-header-detail-text ml-8 ">
                  {findAirlinebyCode(result.airline)} {item.eqpType}
                </div>
                <div className="ml-4 mr-4 airline-header-detail-text ">
                  <div className="circle"></div>
                </div>
                <div className="ml-4 mr-4 airline-header-detail-text ">
                  {item.flightNum}
                </div>
                <div className="ml-4 mr-4 airline-header-detail-text ">
                  <div className="circle"></div>
                </div>
                <div className="ml-4 mr-4 airline-header-detail-text ">
                  {fareGroups.segInfos[index].cabinClass}
                </div>
              </div>
              <div className="airline-detail-content-container">
                <div className="airline-detail-content-details">
                  <div className="airline-details-from-content flex-4">
                    <div className="airline-detail-content-item ">
                      <div className="airline-departure-time">
                        {getDeparture(item.departureOn)}
                      </div>
                      <div className="airline-depart-date-time">
                        {Days[new Date(item.departureOn).getDay()]},{" "}
                        {new Date(item.departureOn).getDate()}{" "}
                        {months[new Date(item.departureOn).getMonth()]}{" "}
                        {new Date(item.departureOn)
                          .getFullYear()
                          .toString()
                          .substring(2, 4)}
                      </div>
                      <div className="airline-depart-date-time opacity-6">
                        {findAirportbyCode(item.origin)}, {item.origin}
                      </div>
                    </div>
                    <div className="airline-detail-content-item "></div>
                    <div className="airline-detail-content-item "></div>
                  </div>
                  <div className="airline-details-via-content flex-3">
                    <div className="airline-total-duration-text-light horizontal-center">
                      {getDuration(item.duration)}
                    </div>
                    <div className="airline-stops-line-container"></div>
                  </div>
                  <div className="airline-details-to-content flex-4">
                    <div className="airline-departure-time">
                      {getArrival(item.arrivalOn)}
                    </div>
                    <div className="airline-depart-date-time">
                      {Days[new Date(item.arrivalOn).getDay()]},{" "}
                      {new Date(item.arrivalOn).getDate()}{" "}
                      {months[new Date(item.arrivalOn).getMonth()]}{" "}
                      {new Date(item.arrivalOn)
                        .getFullYear()
                        .toString()
                        .substring(2, 4)}
                    </div>
                    <div className="airline-depart-date-time opacity-6">
                      {findAirportbyCode(item.destination)}, {item.destination}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )} */}
    </div>
  );
}
