import React from 'react'
import Cancellation from './Cancellation';
import DateChange from './DateChange';

const MiniRulesComponent = ({ miniRules, currentTicket, vendor, purchaseId,
    value, currencyCode, selectedItinerary, traceId, clientId,
    additionalClientmarkup, flag, adtCount, chdCount, infCount, refundable }) => {
    debugger;
    return (
        <div>
            {value === "Cancellation" ?
                <Cancellation
                    miniRules={miniRules}
                    currentTicket={currentTicket}
                    vendor={vendor}
                    currencyCode={currencyCode}
                    selectedItinerary={selectedItinerary}
                    traceId={traceId}
                    clientId={clientId}
                    additionalClientmarkup={additionalClientmarkup}
                    flag={flag}
                    refundable={refundable}
                    adtCount={adtCount}
                    chdCount={chdCount}
                    infCount={infCount}
                    purchaseId={purchaseId}
                /> : ""}
            {value === "Date Change" ?
                <DateChange
                    miniRules={miniRules}
                    currentTicket={currentTicket}
                    vendor={vendor}
                    currencyCode={currencyCode}
                    selectedItinerary={selectedItinerary}
                    traceId={traceId}
                    clientId={clientId}
                    additionalClientmarkup={additionalClientmarkup}
                    flag={flag}
                    refundable={refundable}
                    adtCount={adtCount}
                    chdCount={chdCount}
                    infCount={infCount}
                    purchaseId={purchaseId}
                /> : ""}
        </div>
    )
}

export default MiniRulesComponent