import React, { useEffect, useState } from "react";
import "./RegisterClientUser.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@material-ui/core";
import countries from "../../../data/Country";
import TimeZones from "../../../data/TimeZones";
import Currencies from "../../../data/Currencies";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Link } from "react-router-dom";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

export default function RegisterClientUser({
  registerClientUsertoggleDrawer,
  handleSnackbarToggle,
  setRegisterClientUserdrawer
}) {
  const [email, setemail] = useState("");

  function handleRegisterUser() {
    const headers = {
      "Content-Type": "application/json",
    };
    let url =
      process.env.REACT_APP_IDENTITY_SERVER + "ids/User/RegisterNewClient/";

    axios
      .post(
        url,
        JSON.stringify({
          email: email,
          isStaff: false,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        handleSnackbarToggle(res.data.message, "success");
        setRegisterClientUserdrawer(false);
        setemail("");
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle(err.response.data.detail, "error");
      });
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={registerClientUsertoggleDrawer(false)}
        ></CloseIcon>
      </div>
      <React.Fragment>
        <div className="configuration-heading-container configuration-title-flex-container">
          <div>Register Client</div>
        </div>
        <div className="configuration-heading-container configuration-title-flex-container configuration-title-helptext">
          Enter the email id where client will get email link to register new
          client.
        </div>
        <div className="configuration-heading-container configuration-title-flex-container">
          <TextField
            variant="outlined"
            label="Email"
            size="small"
            color="secondary"
            value={email}
            onChange={(event) => setemail(event.target.value)}
          />
        </div>
        <div className="configuration-heading-container configuration-title-flex-container">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{
              textTransform: "none",
            }}
            onClick={handleRegisterUser}
          >
            Send Invitation
          </Button>
        </div>
      </React.Fragment>
    </div>
  );
}
